import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import HomeTopBar from "../../components/HomeTopBar";


const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  

  return (
    <>
    <HomeTopBar />
    <Box m="20px" padding={"6rem"}>
      <Header title="FAQ" subtitle="Frequently Asked Questions" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Is MountX CRM Free?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            MountX CRM is free for small businesses with up to 2 users and less than 300 customers. If you have more than 2 users or more than 300 customers, paid plans start from varying ranges and you can cancel anytime - contact sales to get our latest pricing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Data Security?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            MountX CRM uses Google Cloud Platform to store all your data. Google Cloud Platform is one of the most secure cloud platforms in the world. You can read more about Google Cloud Platform’s security here.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How can I migrate my data from another CRM?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            MountX CRM has several methods: an API that allows you to programmatically import data from other CRMs, or use our provided Google Cloud Storage bucket to upload your data files into, or even simply upload a CSV file with your data through the UI. For custom integration needs, please contact support.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            MountX CRM vs. Other CRMs?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            MountX CRM is a modern, easy-to-use CRM that your whole team can use. Other CRMs are complex and requires a lot of training and are not easy to use. They are expensive and have long term contracts. With MountX CRM, there are no contracts and you can cancel anytime. MountX CRM is built for small businesses in mind to give the same powerful features of customer lifecycle, team management, and AI features that other CRMs offer but at a fraction of the cost and complexity.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Our Privacy Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            View our privacy policy here: <a href="/FAQ/privacypolicy"><b>Privacy Policy</b></a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
    </>
  );
};

export default FAQ;
