import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { FcGoogle } from 'react-icons/fc';
import { toast } from "react-toastify";
import mountxLogo from '../../assets/mountxcrmlogo1.png';
import boatImg from '../../assets/boat.png';
import CircularProgress from '@mui/material/CircularProgress';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const Login = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
      'Authorization': `Basic ${encodedCredentials}`,
      'Content-Type': 'application/json'
    });

    // Define the URL
    // const localurl = 'http://127.0.0.1:5000/api/v1/read';


    // const [loginApproved, setLoginApproved] = useState("");
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const notifysuccess = () => toast.success("Logged in!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
    const notifyfail = () => toast.error("Invalid Login Credentials!", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });


    // Function to fetch calendar events using the access token
    // const fetchCalendarEvents = (oauth2token) => {
    //     const headers = {
    //         'Authorization': `Bearer ${oauth2token}`,
    //         'Content-Type': 'application/json'
    //     };

    //     fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', { headers })
    //     .then(response => response.json())
    //     .then(events => {
    //         console.log('Calendar Events from login:', events);
    //         // Process and display calendar events in your app
    //     })
    //     .catch(error => console.error('Error fetching calendar events from login:', error));
    // };


    const responseGoogle = async (response) => {

        setIsLoading(true);

        // console.log("Full response from Google:", response);

        // is the credential the oauth2 token?

        // fetchCalendarEvents(oauth2token);  // Fetch calendar events using the token


        const decoded = jwt_decode(response.credential);

        const { email, name, sub, picture } = decoded;

        const data = {
            "kind_id": "users", 
            "filters": {
                "filter1": {"filter_field": "user_email", 
                    "filter_op": "=",
                    "filter_value": email},
                } 
        };

        const url = process.env.REACT_APP_API_URL + '/api/v1/read'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
        })
        .then(loginresponse => loginresponse.json())
        .then(result => {
            // Handle the API response or perform any necessary actions
            // console.log('loginresponse result:')
            // console.log(result);

            if (result.retrieved_data === 'No result is returned') {
                setIsLoading(false);
                notifyfail();
                return null;
                // navigate('/register', { replace: true})
            } else {
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString); 
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    user_kind_id: item.user_kind_id,
                    user_id: item.user_id,
                    user_email: item.user_email,
                    user_name: item.user_name,
                    user_owner_name: item.user_owner_name,
                    user_type: item.user_type,
                    user_login_type: item.user_login_type,
                    user_image: item.user_image,
                }));

                localStorage.setItem('user', JSON.stringify(jsonArray[0]));

                notifysuccess();

                navigate('/dashboard', { replace: true})
            }
        })
        .catch(error => {
            // Handle errors that occurred during the API call
            setError(error);
            console.error(error);
        });

    };


    const isNonMobile = useMediaQuery("(min-width:200px)");

    const checkoutSchema = yup.object().shape({
        email: yup.string().email("invalid email").required("required"),
        password: yup.string().required("required"),
    });
    
    
    const initialValues = {
        email: "",
        password: "",
    };



    const handleLogin = async (event, values) => {
        event.preventDefault();
        setIsLoading(true);

        // const json_data = {
        //     "user_email": values.email,
        //     "user_password": values.password
        // }

        // const response =  await fetch('https://daring-bit-306611.wl.r.appspot.com/api/v1/login', {
        //     method: 'POST',
        //     body: JSON.stringify(json_data),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        // });
            
        // const data =  await response.json();

        // console.log('logged in');
            
        // if (response.status === 200) {
        //         const expiresAt = new Date().getTime() + (3600 * 120000); // 5 days from now, 1000 for 1 hour from now
        //         localStorage.setItem('token', data.access_token);
        //         localStorage.setItem('expiresAt', expiresAt);

        //         console.log('got token: ', data.access_token);

        //         const url = process.env.REACT_APP_API_URL + '/api/v1/read'

        //         const user_data = {
        //             "kind_id": "users", 
        //             "filters": {
        //                 "filter1": {"filter_field": "user_email", 
        //                     "filter_op": "=",
        //                     "filter_value": values.email},
        //                 "filter2": {"filter_field": "user_password", 
        //                     "filter_op": "=",
        //                     "filter_value": values.password},
        //                 } 
        //         };

        //         fetch(Url, {
        //             method: 'POST',
        //             body: JSON.stringify(user_data),
        //             headers: {
        //                 'Content-Type': 'application/json'
        //                 }
        //         })
        //         .then(loginresponse => loginresponse.json())
        //         .then(result => {
        //             // Handle the API response or perform any necessary actions
        //             // console.log('loginresponse result:')
        //             // console.log(result);

        //             if (result.retrieved_data === 'No result is returned') {
        //                 setIsLoading(false);
        //                 notifyfail();
        //                 return null;
        //                 // navigate('/register', { replace: true})
        //             } else {
        //                 const myJsonString = JSON.stringify(result);
        //                 const jsonObject = JSON.parse(myJsonString); 
        //                 const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
        //                     ...item,
        //                 }));

        //                 // remove item.user_password from the object
        //                 delete jsonArray[0].user_password;

        //                 localStorage.setItem('user', JSON.stringify(jsonArray[0]));

        //                 notifysuccess();

        //                 navigate('/dashboard', { replace: true})
        //             }
        //         })
        //         .catch(error => {
        //             // Handle errors that occurred during the API call
        //             setError(error);
        //             console.error(error);
        //         });

        //     } else {
        //         setIsLoading(false);
        //         notifyfail();
        //         return null;
        //     }

        const data = {
                    "kind_id": "users", 
                    "filters": {
                        "filter1": {"filter_field": "user_email", 
                            "filter_op": "=",
                            "filter_value": values.email},
                        "filter2": {"filter_field": "user_password", 
                            "filter_op": "=",
                            "filter_value": values.password},
                        } 
        };

        const url = process.env.REACT_APP_API_URL + '/api/v1/read'


        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
        })
        .then(loginresponse => loginresponse.json())
        .then(result => {
            // Handle the API response or perform any necessary actions
            // console.log('loginresponse result:')
            // console.log(result);
            // console.log('logged into local API')

            if (result.retrieved_data === 'No result is returned') {
                setIsLoading(false);
                notifyfail();
                return null;
                // navigate('/register', { replace: true})
            } else {
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString); 
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    user_kind_id: item.user_kind_id,
                    user_id: item.user_id,
                    user_email: item.user_email,
                    user_owner_name: item.user_owner_name,
                    user_type: item.user_type,
                    user_login_type: item.user_login_type,
                }));

                // remove item.user_password from the object if it exists
                // delete jsonArray[0].user_password;

                localStorage.setItem('user', JSON.stringify(jsonArray[0]));

                notifysuccess();

                navigate('/dashboard', { replace: true})
            }
        })
        .catch(error => {
            // Handle errors that occurred during the API call
            setError(error);
            console.error(error);
        });
    }


    // used only for Formik, but when actually clicking the login button, the above handleLogin function is used.
    const handleFormSubmit = (values) => {
        // console.log(values);
        // console.log(values.email);
        return values
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } 
    }


  return (
    <>
    <div className='flex-1 flex-row'>
        <img 
            src={mountxLogo}
            alt="camp"
            onClick={() => navigate('/')}
            style={{
                height: '50px', 
                width: '50px', 
                zIndex: '10',
                position: 'absolute',
                top: '1rem',
                left: '1rem',
                cursor: 'pointer',
            }}
        >
        </img>
    </div>
    <div
        className='flex flex-col xl:flex-row w-full h-screen bg-white'
    >
        <div 
            className='flex flex-1 justify-center items-center flex-col py-10' 
        >
            <div>
                <h2 className='text-3xl md:h2-bold pt-6 sm:pt-12 text-black'>Login to MountX CRM</h2>
                <p className='text-light-300 pt-5 small-medium text-black'>To use MountX CRM login with your registered email</p>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, paddingTop: "10px",
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 4", 
                                    backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                    input: {
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                    },
                                    '.MuiInputLabel-root': {
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                    },
                                    '.MuiFilledInput-root': {
                                        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                    },
                                    '.MuiFilledInput-underline:before': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                    },
                                    '.MuiFilledInput-underline:hover:before': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                    },
                                    '.MuiFilledInput-underline:after': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ gridColumn: "span 4", 
                                    backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                    input: {
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                    },
                                    '.MuiInputLabel-root': {
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                    },
                                    '.MuiFilledInput-root': {
                                        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                    },
                                    '.MuiFilledInput-underline:before': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                    },
                                    '.MuiFilledInput-underline:hover:before': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                    },
                                    '.MuiFilledInput-underline:after': {
                                        borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                    },
                                }}
                            />
                        </Box>
                        <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" justifyContent="start" mt="20px">
                            <Button
                                sx={{"margintop": "1rem", gridColumn: "span 4"}} 
                                color='secondary'
                                variant='contained'
                                onClick={(event) => handleLogin(event, values)}
                            >
                                Login
                            </Button>
                        </Box>
                    </form>
                    )}
                </Formik>
            </div>
            <div className='pt-2 pb-2'>
                <p className='text-light-300 font-bold pt-5 small-medium md:base-regular'>Or, login with Google</p>
            </div>
            <div className='app__login-container-content-googlelogin'>
                <GoogleLogin 
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                        <button
                            type='button'
                            className='googlelogin-button bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none'
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            style={{'justifyContent': 'center', 
                                    'alignItems': 'center',
                                    'width': '200px',
                                    'height': '100px',
                                    'backgroundColor': 'lightgreen', 
                                    'fontSize': '30px', 
                                    'color': 'black',
                                    'cursor': 'pointer',
                                    }}
                        >
                            <FcGoogle className='mr-4' />Sign in with Google
                        </button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                />

                {/* <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Log in with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    scope="https://www.googleapis.com/auth/calendar"
                    accessType="offline"  // Important for refresh token
                    responseType="code,token"  // This ensures you receive an access token directly
                    prompt="consent"  // Ensure the user is prompted to give permissions
                /> */}
            </div>
            <div className='pt-2 pb-2'>
                <p className='text-light-300 pt-5 small-medium text-black'>Don't have an account? Register <a className='text-blue-500' href='/register'>here</a></p>
            </div>
        </div>
        <img
            src={boatImg}
            alt='loginLogo'
            className='hidden xl:block h-screen w-1/2 object-cover bg-no-repeat'
        >
        </img>
    </div>
    </>
  )
};

export default Login;