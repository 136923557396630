import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const OAuth2Callback = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const accessToken = params.get('access_token');
      if (accessToken) {
        // console.log('got Access Token from Google:', accessToken);
        // Store the token in local or session storage
        localStorage.setItem('googleAccessToken', accessToken);
        // Redirect to the homepage or another route
        // history.push('/dashboard');
        navigate('/events');
      } else {
        console.error('Error: Access token not found');
        console.log('Error: no access token was retrieved');
      }
    }
  }, []);

  return (
    <div>
      <p>Verifying Google's OAuth2..</p>
    </div>
  );
};

export default OAuth2Callback;
