import { createContext, useState, useContext, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";


// light blue and grey (looks good)
// --primary-100:#d4eaf7;
// --primary-200:#b6ccd8;
// --primary-300:#3b3c3d;
// --accent-100:#71c4ef;
// --accent-200:#00668c;
// --text-100:#1d1c1c;
// --text-200:#313d44;
// --bg-100:#fffefb;
// --bg-200:#f5f4f1;
// --bg-300:#cccbc8;
  

// dark blue and red blush color scheme (looks good)
// --primary-100:#2C3A4F;
// --primary-200:#56647b;
// --primary-300:#b4c2dc;
// --accent-100:#FF4D4D;
// --accent-200:#ffecda;
// --text-100:#FFFFFF;
// --text-200:#e0e0e0;
// --bg-100:#1A1F2B;
// --bg-200:#292e3b;
// --bg-300:#414654;
  


// dark sage green color scheme
// --primary-100:#2E8B57;
// --primary-200:#61bc84;
// --primary-300:#c6ffe6;
// --accent-100:#8FBC8F;
// --accent-200:#345e37;
// --text-100:#FFFFFF;
// --text-200:#e0e0e0;
// --bg-100:#1E1E1E;
// --bg-200:#2d2d2d;
// --bg-300:#454545;


// navy and blueish color scheme
// --primary-100:#2C3E50;
// --primary-200:#57687c;
// --primary-300:#b4c7dd;
// --accent-100:#F7CAC9;
// --accent-200:#926b6a;
// --text-100:#333333;
// --text-200:#5c5c5c;
// --bg-100:#F2F2F2;
// --bg-200:#e8e8e8;
// --bg-300:#bfbfbf;



  

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "light"
    ? {
        grey: {
          100: "#141414",  // original: #141414 , new: #d4eaf7 light blue
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",   // original: #666666 , new: #cccbc8,  new: #d4eaf7
          600: "#858585",
          700: "#a3a3a3",  // original: #a3a3a3 , new: #cccbc8 
          800: "#c2c2c2",
          900: "#e0e0e0",  // original: #e0e0e0 , new: #cccbc8 
        },
        primary: {
          100: "#040509",  // original:  #040509 , new: #d4eaf7,  new:  #4E36F5
          200: "#080b12",  // original: #080b12  ,  new: #b6ccd8 #d4eaf7
          300: "#0c101b",
          400: "#fffefb", // original: #f2f0f0 , new: ##f5f4f1, #121621   , this is the dashboard squares/tiles background color
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",  // original: #70d8bd , new: #4E36F5
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }
    : {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],  // original: colors.grey[700]  "#a3a3a3"  , new: #cccbc8
              main: colors.grey[500],  // original: colors.grey[500]  "#666666"  , new: #cccbc8
              light: colors.grey[100],  // original: colors.grey[100] "#141414"  , new: #cccbc8
            },
            background: {
              default: "#fcfcfc",  // original #fcfcfc, new: #d4eaf7
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(localStorage.getItem("colorMode") || "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", newMode); // Save to localStorage
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};




// Context for managing sidebar height
export const SidebarHeightContext = createContext({
  sidebarHeight: '400vh', // Default sidebar height
  setSidebarHeight: () => {},
});

export const useSidebarHeight = () => {
  const [sidebarHeight, setSidebarHeight] = useState('400vh');

  // Memorize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    sidebarHeight, 
    setSidebarHeight,
  }), [sidebarHeight]);

  return contextValue;
};

// Export provider and custom hook for easy import
export const SidebarHeightProvider = ({ children }) => {
  const contextValue = useSidebarHeight();
  return (
    <SidebarHeightContext.Provider value={contextValue}>
      {children}
    </SidebarHeightContext.Provider>
  );
};

// Custom hook to use the sidebar height context
export const useCustomSidebarHeight = () => useContext(SidebarHeightContext);




// for notifications a.k.a. logs Context Provider
const NotificationContext = createContext({
  notifications: [], 
  setNotifications: () => {},
});


export const NotificationProvider = ({ children }) => {

  const [notifications, setNotifications] = useState([]);

  // fetching notifications or a.k.a logs
  useEffect(() => {
      const fetchNotifications = async () => {

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  


        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

        try {
          // Make an API request to retrieve data from the Google Cloud Datastore
          const url = process.env.REACT_APP_API_URL + '/api/v1/read'
          const data = {
                      "kind_id": "logs", 
                      "filters": {
                        "filter1": {"filter_field": "kind_id", 
                              "filter_op": "=",
                              "filter_value": kind_id},
                        } 
                        };
          const response = await fetch(url, {
                          method: 'POST',
                          headers: headers,
                          body: JSON.stringify(data)
                        })

          const result = await response.json();
          const myJsonString = JSON.stringify(result);
          const jsonObject = JSON.parse(myJsonString);

          if (jsonObject.retrieved_data === 'No result is returned') {
              // setIsLoading(false);
              setNotifications([]);
          } else {
              const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
              ...item,
              }));

              // sort jsonArray based on date_time field descending
              jsonArray.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));

              // filter jsonArray where kind_id is equal to the user_kind_id
              const specificArray = jsonArray.filter((item) => item.kind_id === kind_id);

              // only show notifications where the read_users field does not contain the current user
              const filteredArray = specificArray.filter((item) => !item.read_users.includes(loginUserName));
      
              setNotifications(filteredArray);
              // console.log('notifications fetched.')
              // console.log(jsonArray)
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
    };

    fetchNotifications();
  }, []);


  // Memorize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    notifications, 
    setNotifications,
  }), [notifications]);


  return (
      <NotificationContext.Provider value={ contextValue }>
          {children}
      </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
