import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




const DeleteAR = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });


    const [ActivitiesData, setActivitiesData] = useState([]);
    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectField, setSelectField] = useState('');


    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchActivities = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "cta"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            cta_keyid: '0000000',
                            customer_name: 'Example Customer 1',
                            status: 'OPEN',
                            status2: 'OPEN',
                            notes: "Example Notes 1",
                            owner_name: 'Example Owner 1',
                            action_request_name: 'Example Activity 1',
                            category: 'Example Category 1',
                            priority: 'High',
                            created_date: '2021-10-01',
                            due_date: '2021-10-01',
                            guide: 'Example Playbook 1'
                        }]
                setActivitiesData(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                key_id: item.key_id,
                customer_name: item.customer_name,
                status: item.status.toUpperCase(),
                status2: item.status.toUpperCase(),
                notes: item.notes,
                owner_name: item.owner_name,
                action_request_name: item.action_request_name,
                category: item.category,
                priority: item.priority,
                created_date: item.created_date,
                due_date: item.due_date,
                guide: item.guide
                }));
        
                setActivitiesData(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchActivities();
      }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }




    const handleSubmitDeleteAR = (event, selectField) => {
        event.preventDefault();

        // find the hs_property_keyid in HSPropertyData by using the value of selectField
        const hspropertykeyidArray = ActivitiesData.map((item, index) => (
            Object.entries(item).map(([key, value]) => {
              if (value === selectField) {
                  return key;
              } else {
                  return null;
              }
            })
        ));

        const hspropertykeyid = hspropertykeyidArray.flat().filter(value => value !== null)[0];

        // console.log('this is hspropertykeyid:')
        // console.log(hspropertykeyid)

        // extract the numbers from the hspropertykeyid
        const hspropertyIndexforDict = hspropertykeyid.match(/\d+/g).map(Number);

        // using the index, get the value of the keyDict array which is the key_id to use for deleting property
        const keyid_DictValue = {};

        // console.log('this is keyid_DictValue:')
        // console.log(keyid_DictValue)

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const json_data = {"kind_id": kind_id, 
                            "key_id": keyid_DictValue};

        const deleteUrl = process.env.REACT_APP_API_URL + '/api/v1/delete';

        fetch(deleteUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                console.log('Property has been deleted: ')
                console.log(result);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });
    };



    const handleFormSubmit = (values) => {
        // console.log(values);
    
    
        const checkUser = localStorage.getItem('user');
    
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
    
        const NewCustomerData = {}
    
        // add kind_id to NewCustomerData
        NewCustomerData["kind_id"] = kind_id;
        // add customerName to customer_name
        NewCustomerData["customer_name"] = values.customerName;
        // required object_type field
        NewCustomerData["object_type"] = "customer";
        // required lifecycle_stage field
        NewCustomerData["lifecycle_stage"] = "Onboarding";
        // required healthscore field
        NewCustomerData["healthscore"] = 100;
    
        // if values.startDate is not empty, then add it to customerData
        if (values.startDate !== "") {
          NewCustomerData["start_date"] = values.startDate;
        }
        // if values.email is not empty, then add it to customerData
        if (values.email !== "") {
          NewCustomerData["email"] = values.email;
        }
        // if values.contact is not empty, then add it to customerData
        if (values.contact !== "") {
          NewCustomerData["contact"] = values.contact;
        }
        // if values.address1 is not empty, then add it to customerData
        if (values.address1 !== "") {
          NewCustomerData["address1"] = values.address1;
        }
        // if values.accountOwner is empty, set it to loginUserName which is owner_name from above.  If it is not empty, then add it to customerData (changing accountOwner to owner_name as per the datastore schema)
        if (values.accountOwner == "") {
          NewCustomerData["owner_name"] = loginUserName;
        } else {
          NewCustomerData["owner_name"] = values.accountOwner;
        }
    
        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'
    
        const json_data = {"kind_id": kind_id, 
                                "key_id": values.customerName, 
                                "data": NewCustomerData};
    
        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                console.log('New Customer has been created');
                console.log(result);
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });
      };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };


    return (
        <Box m="30px" width="90%">
            <Header title="Delete" subtitle="Activities" />

            <h3 className='font-bold text-2xl mt-16'>Delete Activities</h3>
            <div
                className="w-full md:w-1/3 h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Existing Activities</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {   ActivitiesData.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('action_request_name')) {
                                return (
                                    <MenuItem value={value}>{value}</MenuItem>
                                )
                                } else {
                                    return null;
                                }
                        })
                        ))}
                    </Select>
                    <FormHelperText>Select Activity</FormHelperText>
                </FormControl>
            </div>
            <div>
                <Button
                    sx={{marginTop: "1rem", width: "200px", border: `1px solid ${colors.greenAccent[200]}`}}
                    type='button'
                    variant='contained'
                    onClick={(event) => handleSubmitDeleteAR(event, selectField)}
                >
                    Delete Activity
                </Button>
            </div>
        </Box>
    );
}

export default DeleteAR;

