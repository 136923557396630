import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from '../../components/ToastNotify';

import ModeEditIcon from '@mui/icons-material/ModeEdit';



const NPSSurveys = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [templatesData, setTemplatesData] = useState([]);
    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectField, setSelectField] = useState('');

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchCampaignTemplates = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listtemplates';
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                          })
    
            const result = await response.json();

            // console.log('this is result:')
            // console.log(result)

            // const myJsonString = JSON.stringify(result);
            // const jsonObject = JSON.parse(myJsonString);

            // console.log('this is list templates jsonObject:')
            // console.log(jsonObject)

            if (result === 'No result is returned') {
                const jsonArray = [{
                            Name: 'Example NPS Template 1',
                            CreatedTimestamp: '2024-10-01',
                        }]
                setTemplatesData(jsonArray);
                setIsLoading(false);
            } else {

                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);

                const newdataStr = jsonObject.replace(/datetime\.datetime\((\d{4}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d+).*?\)/g, (match, year, month, day, hour, minute, second, micro) => {
                    // Constructing an ISO string representation (ignoring timezone and microseconds for simplicity)
                    return `"${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}"`;
                });

                // there was an erroneous paranthesis inside the string after modifying the timestamps, replace ) with empty string
                const newdataStr2 = newdataStr.replace(/\)/g, '');

                // replacing single quotes with double quotes for valid json array format
                const newdataStr3 = newdataStr2.replace(/\[{'/g, '[{"').replace(/'}]/g, '"}]').replace(/', '/g, '", "').replace(/': '/g, '": "').replace(/', "/g, '", "').replace(/", '/g, '", "');

                // there was still a single quote on one of the values, so I replaced it with double quotes
                const newdataStr4 = newdataStr3.replace(/\'/g, '"');

                // console.log('this is newdataStr4:')
                // console.log(newdataStr4)

                const jsonArray = JSON.parse(newdataStr4);

                console.log('this is jsonArray:')
                console.log(jsonArray)

                if (jsonArray.length > 0) {
                    // if jsonArray.Name contains the string '_nps_', then setTemplatesData(jsonArray)
                    const filteredArray = jsonArray.filter(item => item.Name.includes('_nps_'));
                    if (filteredArray.length > 0) {
                        setTemplatesData(filteredArray);
                    } else {
                        const jsonArray = [{}]
                        setTemplatesData(jsonArray);
                    }
                } else {
                    const jsonArray = [{}]
                    setTemplatesData(jsonArray);
                }
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchCampaignTemplates();
      }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    const handleSubmitDeleteTemplate = (event, selectField) => {
        event.preventDefault();

        // selectField is the template name to be deleted
        // console.log('this is selectField:')
        // console.log(selectField)

        const json_data = {"template_name": selectField };

        const deleteUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/deletetemplate';

        fetch(deleteUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Template has been deleted');
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
    };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };


    return (
        <Box m="30px" width="90%">
            <Header title="NPS" subtitle="Create, Edit, or Delete" />
            <div className='mt-2 flex flex-row items-start'>
                <Button
                        sx={{width: "160px"}}
                        type='button'
                        color='secondary'
                        variant='contained'
                        onClick={(event) => navigate('/surveys/create', {replace: true})}
                    >
                        Create New NPS
                </Button>
            </div>
            

            <div className='mt-4 border-[1px] border-gray-200 flex-col w-[50%] h-96 rounded-lg shadow-lg shadow-gray-300'>
                <div className='grid grid-cols-2 text-center p-4 h-auto bg-[#f8f9fa]'>
                    <p className='font-bold text-md'>NPS Name</p>
                    <p className='font-bold text-md'>Created Date</p>
                </div>
                <div className='grid grid-cols-2 text-center p-4 h-auto'>
                    { templatesData.map((item) => (
                        Object.entries(item).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('Name')) {

                                const newValueArray = [];
                                // extract all the strings after the underscore in the value
                                const newValue1 = value.split('_')[1];
                                newValueArray.push(newValue1);

                                if (value.split('_')[2]) {
                                    const newValue2 = value.split('_')[2];
                                    newValueArray.push(newValue2);
                                }
                                if (value.split('_')[3]) {
                                    const newValue3 = value.split('_')[3];
                                    newValueArray.push(newValue3);
                                }
                                if (value.split('_')[4]) {
                                    const newValue4 = value.split('_')[4];
                                    newValueArray.push(newValue4);
                                }
                                if (value.split('_')[5]) {
                                    const newValue5 = value.split('_')[5];
                                    newValueArray.push(newValue5);
                                }
                                if (value.split('_')[6]) {
                                    const newValue6 = value.split('_')[6];
                                    newValueArray.push(newValue6);
                                }
                                if (value.split('_')[7]) {
                                    const newValue7 = value.split('_')[7];
                                    newValueArray.push(newValue7);
                                }

                                // combine all the values in newValueArray into one string
                                const finalValue = newValueArray.join(' ');
                                const newfinalValue = finalValue.replace('nps', ' ');

                                const newURLSafeValue = value.replace(/_/g, '-');

                                return (
                                    <p className='text-md p-4 h-6 cursor-pointer' onClick={(event) => {navigate(`/campaigns/templates/${newURLSafeValue}`)}}>{newfinalValue} <ModeEditIcon sx={{ marginLeft: "5px", fontSize: "12px"}}/></p>
                                )
                            } else if (key.includes('CreatedTimestamp')) {
                                const newDateValue = new Date(value).toLocaleDateString();
                                return (
                                    <p className='text-md p-4 h-6'>{newDateValue}</p>
                                )
                            } else {
                                return null;
                            }
                        })
                    ))}
                </div>


            </div>
            <h3 className='font-bold text-2xl mt-16'>Delete NPS</h3>
            <div
                className="w-[40%] h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "50%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Existing NPS</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {   templatesData.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('Name')) {
                                // const newValue = value.split('_')[1];
                                // trim the first 16 characters from the value
                                value = value.substring(25, value.length).replace(/_/g, ' ').replace('srvy', '');
                                return (
                                    <MenuItem value={value}>{value}</MenuItem>
                                )
                                } else {
                                    return null;
                                }
                        })
                        ))}
                    </Select>
                    <FormHelperText>Select NPS</FormHelperText>
                </FormControl>
            </div>
            <div>
                <Button
                    sx={{marginTop: "1rem", width: "160px"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitDeleteTemplate(event, selectField)}
                >
                    Delete NPS
                </Button>
            </div>
        </Box>
    );
}

export default NPSSurveys;

