import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { NotificationProvider, SidebarHeightProvider } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Customers from "./scenes/customers";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Actionrequests from "./scenes/actionrequests";
import Nps from "./scenes/nps";
import Addnps from "./scenes/addnps";
import Npsdata from "./scenes/npsdata";
import Scorecards from "./scenes/scorecards";
import ScorecardManagement from "./scenes/scorecardmanagement";
import Addproperty from "./scenes/scorecardmanagement/addproperty";
import Lifecycle from "./scenes/lifecycle";
import Customerprofile from "./scenes/customerprofile";
import Home from "./scenes/home";
import Login from "./scenes/login";
import Register from "./scenes/register";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Contracts from "./scenes/contracts";
import SearchPage from "./scenes/searchpage";
import NewCustomerPage from "./scenes/newcustomer";
import NewTeamPage from "./scenes/newteam";
import SendEmail from "./scenes/sendemail";
import NewField from "./scenes/newfield";
import ContactSales from "./scenes/salescontact";
import ContactUs from "./scenes/contactus";
import Features from "./scenes/features";
import FAQ from "./scenes/faq";
import Pricing from "./scenes/pricing";
import DeleteField from "./scenes/deletefield";
import ActionRequestManagement from "./scenes/actionrequestmanagement";
import CreateAR from "./scenes/actionrequestmanagement/create";
import DeleteAR from "./scenes/actionrequestmanagement/delete";
import RuleBasedAR from "./scenes/actionrequestmanagement/rulebased";
import ReassignAR from "./scenes/actionrequestmanagement/reassign";
import DeleteRules from "./scenes/actionrequestmanagement/deleterules";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LifecycleManagement from "./scenes/lifecyclemanagement";
import NewContact from "./scenes/newcontact";
import ActionRequestPage from "./scenes/actionrequestpage";
import BarChartCustomPage from "./scenes/barchartcustompage";
import AddMultipleCustomers from "./scenes/addmultiplecustomers";
import UserProfile from "./scenes/userprofile";
import ManagePage from "./scenes/manage";
import Campaigns from "./scenes/campaigns";
import CampaignTemplates from "./scenes/campaigntemplates";
import CreateTemplates from "./scenes/campaigntemplatescreate";
import EditTemplate from "./scenes/campaigntemplatesedit";
import CampaignSubscriptions from "./scenes/campaignsubs";
import CampaignTemplatesAI from "./scenes/campaigntemplatesAI";
import CampaignStripo from "./scenes/campaignstripo";
import SurveyPage from "./scenes/surveypage";
import SurveysCreate from "./scenes/surveyscreate";
import Surveys from "./scenes/surveys";
import NPSSurveys from "./scenes/npssurveys";
import NPSCreate from "./scenes/npssurveyscreate";
import SurveyQuestionsEdit from "./scenes/surveyquestionsedit";
import UnsubscribePage from "./scenes/unsubscribe";
import CampaignManage from "./scenes/campaignmanage";
import Leads from "./scenes/leads";
import NewLeadPage from "./scenes/newlead";
import LeadPage from "./scenes/leadpage";
import Opportunities from "./scenes/opportunities";
import NewOpportunityPage from "./scenes/newopportunity";
import OpportunityPage from "./scenes/opportunitypage";
import OpportunityNewField from "./scenes/opportunitynewfield";
import LeadNewField from "./scenes/leadnewfield";
import DeleteFieldsMenu from "./scenes/deletefieldsmenu";
import DeleteFieldLead from "./scenes/deletefieldlead";
import DeleteFieldOpportunity from "./scenes/deletefieldopportunity";
import ManageLeadPicklist from "./scenes/leadpicklist";
import ManageOpportunityPicklist from "./scenes/opportunitypicklist";
import LeadNewOpportunity from "./scenes/leadnewopportunity";
import ContactPage from "./scenes/contactpage";
import RetailAccounts from "./scenes/retail";
import DistributorAccounts from "./scenes/distributor";
import Logs from "./scenes/logs";
import SalesSearch from "./scenes/salessearch";
import NewSale from "./scenes/salesadd";
import Sales from "./scenes/sales";
import Events from "./scenes/events";
import EventsCreate from "./scenes/eventscreate";
import EventsPage from "./scenes/eventspage";
import PrivacyPolicyPage from "./scenes/privacypolicy";
import LoginTest from "./scenes/logintest";
import OAuth2Callback from "./components/oAuthCallback";
import NewOpportunityforAccount from "./scenes/newopportunityaccount";
import NewSaleforAccount from "./scenes/salesaddforaccount";



function App() {

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ToastContainer />
      <ColorModeContext.Provider value={colorMode}>
      <SidebarHeightProvider>
      <NotificationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/oauth2/callback" element={<OAuth2Callback />} />
                <Route path="/register" element={<Register />} />
                <Route path="/userprofile" element={<UserProfile />} />
                <Route path="/manage" element={<ManagePage />} />
                <Route path="/search/:searchTerm" element={<SearchPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/lifecycle" element={<Lifecycle />} />
                <Route path="/lifecycle/manage" element={<LifecycleManagement />} />
                <Route path="/team" element={<Team />} />
                <Route path="/addteam" element={<NewTeamPage />} />
                <Route path="/healthscores" element={<Scorecards />} />
                <Route path="/healthscores/manage" element={<ScorecardManagement />} />
                <Route path="/healthscores/manage/addproperty" element={<Addproperty />} />
                <Route path="/managehealthscores" element={<Navigate to="/healthscores/manage" replace />} />
                <Route path="/activities" element={<Actionrequests />} />
                <Route path="/actionrequests" element={<Navigate to="/activities" replace />} />
                <Route path="/activities/:arkeyidparams" element={<ActionRequestPage />} />
                <Route path="/activities/manage" element={<ActionRequestManagement />} />
                <Route path="/activities/create" element={<CreateAR />} />
                <Route path="/activities/rulebased" element={<RuleBasedAR />} />
                <Route path="/activities/delete" element={<DeleteAR />} />
                <Route path="/activities/delete/rules" element={<DeleteRules />} />
                <Route path="/activities/reassign" element={<ReassignAR />} />
                <Route path="/nps" element={<Nps />} />
                <Route path="/addnps" element={<Addnps />} />
                <Route path="/npsdata" element={<Npsdata />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/addlead" element={<NewLeadPage />} />
                <Route path="/lead-details/:leadname" element={<LeadPage />} />
                <Route path="/lead-details/:leadname/addopportunity" element={<LeadNewOpportunity />} />
                <Route path="/lead/newfield" element={<LeadNewField />} />
                <Route path="/lead/managepicklist" element={<ManageLeadPicklist />} />
                <Route path="/opportunities" element={<Opportunities />} />
                <Route path="/addopportunity" element={<NewOpportunityPage />} /> 
                <Route path="/opportunity-details/:opportunityname" element={<OpportunityPage />} />
                <Route path="/opportunity/newfield" element={<OpportunityNewField />} />
                <Route path="/opportunity/managepicklist" element={<ManageOpportunityPicklist />} />
                <Route path="/accounts" element={<Customers />} />
                <Route path="/customers" element={<Navigate to="/accounts" replace />} />
                <Route path="/addaccount" element={<NewCustomerPage />} />
                <Route path="/addmultipleaccounts" element={<AddMultipleCustomers />} />
                <Route path="/account-profile/:customername" element={<Customerprofile />} />
                <Route path="/account-profile/:customername/newcontact" element={<NewContact />} />
                <Route path="/account-profile/:customername/newopportunity" element={<NewOpportunityforAccount />} />
                <Route path="/account-profile/:customername/newsale" element={<NewSaleforAccount />} />
                <Route path="/accounts/retail" element={<RetailAccounts />} />
                <Route path="/accounts/distributor" element={<DistributorAccounts />} />
                <Route path="/newfield" element={<NewField />} />
                <Route path="/fields/delete" element={<DeleteFieldsMenu />} />
                <Route path="/lead/deletefield" element={<DeleteFieldLead />} />
                <Route path="/opportunity/deletefield" element={<DeleteFieldOpportunity />} />
                <Route path="/customer/deletefield" element={<DeleteField />} />
                <Route path="/deletefield" element={<Navigate to="/fields/delete" replace />} />
                <Route path="/sendemail" element={<SendEmail />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/campaigns/manage" element={<CampaignManage />} />
                <Route path="/campaigns/templates" element={<CampaignTemplates />} />
                <Route path="/campaigns/templates/create" element={<CreateTemplates />} />
                <Route path="/campaigns/templates/:templateNameParam" element={<EditTemplate />} />
                <Route path="/campaigns/subscriptions" element={<CampaignSubscriptions />} />
                <Route path="/campaigns/templates/ai" element={<CampaignTemplatesAI />} />
                <Route path="/events" element={<Events />} />
                <Route path="/events/create" element={<EventsCreate />} />
                <Route path="/events/:eventkeyidparams" element={<EventsPage />} />
                <Route path="/surveys/templates" element={<Surveys />} />
                <Route path="/surveys" element={<Navigate to="/surveys/templates" replace />} />
                <Route path="/surveys/create" element={<SurveysCreate />} />
                <Route path="/surveys/:surveyId/questions/edit" element={<SurveyQuestionsEdit />} />  {/* this page is for editing survey questions */}
                <Route path="/surveys/:surveyId" element={<SurveyPage />} /> {/* this page is for actual customer answering survey questions after being directed from email link */}
                <Route path="/nps/templates" element={<NPSSurveys />} />
                <Route path="/nps/create" element={<NPSCreate />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/contact-details/:contactname" element={<ContactPage />} />
                {/* <Route path="/contracts" element={<Contracts />} /> */}
                <Route path="/unsubscribe/:customerid" element={<UnsubscribePage />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/contact/sales" element={<ContactSales />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/sales/search" element={<SalesSearch />} />
                <Route path="/sales/add" element={<NewSale />} />
                <Route path="/contact/support" element={<ContactUs />} />
                <Route path="/features" element={<Features />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/FAQ/privacypolicy" element={<PrivacyPolicyPage />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/barchart/custom" element={<BarChartCustomPage />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/geography" element={<Geography />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </NotificationProvider>
      </SidebarHeightProvider>
      </ColorModeContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
