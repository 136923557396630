import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button } from "@mui/material";
import Header from "../../components/Header";
import BarChartCustom from "../../components/BarChartCustom";

import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const BarChartCustomPage = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [user, setUser ] = useState(null);
    const [custData, setCustData ] = useState([]);  // customer data fields for dropdown menu
    const [allCustData, setAllCustData ] = useState([]);  // all customer data
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [values, setValues] = useState([]);  // values for bar chart
    const [noCustomers, setNoCustomers] = useState(false);

    const [selectedField, setSelectedField] = useState('');  // user selected field from dropdown menu
    const [filteredData, setFilteredData] = useState([{
                xAxis: "",
                value: 0,
              }]);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);
    

    // fetching customer data for customer fields
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });


                try {
                    if (user_type === 'admin' || user_type === 'manager' || user_type === 'team user') {
                        // Make an API request to retrieve data from the Google Cloud Datastore
                        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                        const data = {
                                    "kind_id": kind_id, 
                                    "filters": {
                                    "filter1": {"filter_field": "object_type", 
                                            "filter_op": "=",
                                            "filter_value": "customer"},
                                    } 
                                    };
                        const response = await fetch(url, {
                                        method: 'POST',
                                        headers: headers,
                                        body: JSON.stringify(data)
                                    })

                        const result = await response.json();
                        const myJsonString = JSON.stringify(result);
                        const jsonObject = JSON.parse(myJsonString);
                        if (jsonObject.retrieved_data === 'No result is returned') {
                            setNoCustomers(true);
                        } else {
                            const jsonArray = jsonObject.retrieved_data.map(item => ({
                                ...item,
                            }));
                            setCustData(jsonArray[0]);  // only need the first customer object to display customer fields in dropdown menu
                            setAllCustData(jsonArray);
                        }
                        
                    } else {
                        // for 'individual user' user_type, only retrieve data for that user
                        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                        const data = {
                                    "kind_id": kind_id, 
                                    "filters": {
                                    "filter1": {"filter_field": "object_type", 
                                            "filter_op": "=",
                                            "filter_value": "customer"},
                                    "filter2": {"filter_field": "owner_name", 
                                            "filter_op": "=",
                                            "filter_value": loginUserName},
                                    } 
                                    };
                        const response = await fetch(url, {
                                        method: 'POST',
                                        headers: headers,
                                        body: JSON.stringify(data)
                                    })

                        const result = await response.json();
                        const myJsonString = JSON.stringify(result);
                        const jsonObject = JSON.parse(myJsonString);
                        if (jsonObject.retrieved_data === 'No result is returned') {
                            setNoCustomers(true);
                        } else {
                            const jsonArray = jsonObject.retrieved_data.map(item => ({
                                ...item,
                            }));
                            setCustData(jsonArray[0]);  // only need the first customer object to display customer fields in dropdown menu
                            setAllCustData(jsonArray);
                        }
                    }
                setIsLoading(false);

                } catch (error) {
                    setError(error.message);
                }
            }
        fetchData();
    }, []);


    
    // const ARdata = actionRequests.length > 0 ? 
    //     [
    //       {
    //         xAxis: (actionRequests.slice(0,1).map((ar) => ar.owner_name)),
    //         Overdue: 8,  // overdue AR's
    //         OverdueColor: "hsl(296, 70%, 50%)",  
    //         Open: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'open' ? count + 1 : count, 0)),  // open AR's
    //         OpenColor: "hsl(97, 70%, 50%)",
    //         Closed: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'closed' ? count + 1 : count, 0)),  // closed AR's
    //         ClosedColor: "hsl(344, 70%, 50%)",
    //         WIP: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'wip' ? count + 1 : count, 0)), // WIP AR's
    //         WIPColor: "hsl(291, 70%, 50%)",
    //       },
    //     ]
    //     : [
    //       {
    //         xAxis: "Owner not found",
    //         Overdue: 0,  // overdue AR's
    //         OverdueColor: "hsl(296, 70%, 50%)",  
    //         Open: 0,  // open AR's
    //         OpenColor: "hsl(97, 70%, 50%)",
    //         Closed: 0,  // closed AR's
    //         ClosedColor: "hsl(344, 70%, 50%)",
    //         WIP: 0, // WIP AR's
    //         WIPColor: "hsl(291, 70%, 50%)",
    //       },
    //     ];



    const handleFieldChange = (event) => {
        setSelectedField(event.target.value);

        const chosenField = event.target.value

        const valuesArray = allCustData.map((item) => {
            return item[chosenField]
        })

        const uniqueValuesArray = [...new Set(valuesArray)];

        const countUniqueValues = uniqueValuesArray.length

        // for every unique value in the uniqueValuesArray, count how many times it appears in the valuesArray
        const countValues = uniqueValuesArray.map((item) => {
            return valuesArray.filter((value) => value === item).length
        })

        // create a key value pair for each unique value and its count
        const customDataArray = uniqueValuesArray.map((item, index) => {
            return {
                xAxis: item,
                value: countValues[index]
            }
        })


        // const customDataArray = [{
        //                             xAxis: "test",
        //                             value: countValuesInt
        //                         }]


        setFilteredData(customDataArray)

    };



    if (noCustomers) {
        return <div className='flex flex-col justify-center items-center text-xl'>You have no accounts or customers and no fields to view...add an account here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addaccount')}>Add New Account</Button></div>;
    }

    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="20px" width="90%">
        <Header title="Custom Bar Chart" subtitle="Choose Account Fields for a Bar Chart" />

        <FormControl sx={{ marginTop: "2rem", width: {xs: "100%", sm: "100%", md: "30%"} }}>
            <InputLabel id="demo-simple-select-helper-label">Select Field</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedField}
                label="Select Field"
                onChange={handleFieldChange}
            >
                { Object.entries(custData).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

                            if (key.includes('key_id') || key.includes('kind_id') || key.includes('score_history') || 
                                key.includes('object_type') || key.includes('created_date') || key.includes('healthscore_reasons') ||
                                key.includes('notes') ) {
                                return null
                            } else {
                                return (
                                    <MenuItem value={key}>{formattedKey}</MenuItem>
                                )
                            }
                        })}
            </Select>
            <FormHelperText>Select Field for Bar Chart</FormHelperText>
        </FormControl>
        <Box height="75vh">
            <BarChartCustom data={filteredData} field_name={selectedField}/>
        </Box>
        </Box>
    );
};


export default BarChartCustomPage;
