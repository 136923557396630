import React, { useState, useEffect } from 'react';
import { ToastNotify } from '../../components/ToastNotify';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    Popover,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    TextField
  } from '@mui/material';
import { saveUserSelectedColumns } from '../../utils/saveColumns';



const Customers = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [usersData, setUsersData] = useState(null);

    const [selectedColumns, setSelectedColumns] = useState(['id', 'customer_name', 'lifecycle_stage', 'created_date', 'owner_name']); // Add default columns here
    const [AllPossibleColumns, setAllPossibleColumns] = useState([]); // Add all possible columns here

    const [anchorEl, setAnchorEl] = useState(null);

    const [columnNumbers, setColumnNumbers] = useState(
        AllPossibleColumns.reduce((acc, column) => {
          acc[column.field] = 0; // Default numbers for each column
          return acc;
        }, {})
    );


    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);


    
    const handleInputChange = (index, field, value) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

        // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
        const indexMinusOne = index - 1;

        const customersObject = data[indexMinusOne];
        const key_id__ = customersObject.key_id;  // all data retrieved from datastore shoujld have the field key_id as the API add its into the response

        data[indexMinusOne][field] = value;

        const { id, ...arrayWithoutSpecificKeys } = data[indexMinusOne];


        const json_data = {"kind_id": kind_id, 
                            "key_id": key_id__, 
                            "data": arrayWithoutSpecificKeys
                        };
    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Changes Saved: ')
                // console.log(result);
                // ToastNotify('success', 'Changes Saved');
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Error, something went wrong.');
                console.error(error);
            });
    };


    // fetching both customers data and users data
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // fetching users data first
        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                          "filter1": {"filter_field": "user_kind_id", 
                                "filter_op": "=",
                                "filter_value": kind_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            owner_name: loginUserName,
                        }]
                setUsersData(jsonArray);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));
        
                setUsersData(jsonArray);
            }
        } catch (error) {
            setError(error.message);
        }

        // fetching customers data
        try {
            if (user_type === 'admin' || user_type === 'manager' || user_type === 'team user') {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: 'Example Customer 1',
                                owner_name: 'Example Owner 1',
                                lifecycle_stage: 'Purchase',
                                healthscore: '90',
                                healthscore_color: 'green',
                                cta_open: '2',
                                industry: 'Example Industry',
                                specialty: 'Example Specialty',
                                last_touch_date: '2023-09-01',
                                cta_count: '2',
                                created_date: '2023-09-01',
                                start_date: '2023-09-01',
                                renewal_date: '2024-09-01',
                            }]
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);

                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));
            
                    setData(jsonArray);


                    // to update all the possible columns state
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type' && item !== 'healthscore_reasons');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedColumnsArray = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));

                    setAllPossibleColumns(formattedColumnsArray);
                    setIsLoading(false);
                }
            } else {
                // for user_type 'individual user' only show the customers that belong to the user
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                                "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: 'Example Customer 1',
                                owner_name: 'Example Owner 1',
                                lifecycle_stage: 'Purchase',
                                healthscore: '90',
                                healthscore_color: 'green',
                                cta_open: '2',
                                industry: 'Example Industry',
                                specialty: 'Example Specialty',
                                last_touch_date: '2023-09-01',
                                cta_count: '2',
                                created_date: '2023-09-01',
                                start_date: '2023-09-01',
                                renewal_date: '2024-09-01',
                            }]
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);

                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));
            
                    setData(jsonArray);


                    // to update all the possible columns state
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type' && item !== 'healthscore_reasons');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedColumnsArray = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));

                    setAllPossibleColumns(formattedColumnsArray);
                    setIsLoading(false);
                }
            }
            
          } catch (error) {
            setError(error.message);
          }
          setIsLoading(false);
        };
    
        fetchData();
    }, []);


    // fetching user selected columns and column order from datastore if it exists
    useEffect(() => {
        const fetchColumns = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  


            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            // Encode username and password for Basic Auth
            const encodedCredentials = window.btoa(`${username}:${password}`);

            // Setup headers
            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });


            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "columns"},
                            "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            "filter3": {"filter_field": "page", 
                                    "filter_op": "=",
                                    "filter_value": "customers"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setIsLoading(false);
                    return null;
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index,
                        ...item,
                    }));
                    setSelectedColumns(jsonArray[0]["selected_columns"]);
                    setColumnNumbers(jsonArray[0]["column_order"]);
                }

            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchColumns();
    }, []);


    
    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    const handleColumnsChange = (newColumns) => {
        setSelectedColumns(newColumns);
        // setAnchorEl(null); // Close the popover after selecting columns

        // saving the selected columns and column order to datastore for the user and for this specific page
        // saveUserSelectedColumns("healthscores", newColumns, columnNumbers)
        
      };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const ModifiedColumnsforGrid = () => {

        const modifiedColumns = AllPossibleColumns.map((item) => {
                    if (item.field === 'owner_name') {
                        return {
                            ...item,
                            editable: true,
                            width: 130,
                            renderCell: (params) => (
                                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden'}}>
                                  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{params.value}</span>
                                  <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px"}} />
                                </div>
                              ),
                            renderEditCell: (params) => {
                                return (
                                    <Select
                                        value={params.value}
                                        onChange={(event) =>
                                        handleInputChange(
                                            params.id,
                                            params.field,
                                            event.target.value
                                        )
                                        }
                                        style={{ height: "30px", fontSize: "12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                    >
                                        {usersData.map((item) => (
                                            <MenuItem value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                                        ))}
                                    </Select>
                                );
                            },
                        }
                    } else if (item.field === 'customer_name') {
                        return { 
                            ...item,
                            headerName: 'Account Name',
                            width: 140,
                            textDecoration: "none",
                            color: "inherit",
                            cellClassName: "name-column--cell",
                            renderCell: (params) => {
                                const cellValue = params.value;
                                return (
                                    <div>
                                        <Link 
                                            to={`/account-profile/${cellValue}`}
                                            style={{ textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                        >
                                            {cellValue}
                                        </Link>
                                    </div>
                                    
                                );
                            },
                        }
                    } else if (item.field === 'notes') {
                        return { 
                            ...item,
                            width: 250,
                        }
                    } else if (item.field === 'id') {
                        return { 
                            ...item,
                            width: 15,
                            headerName: 'Row',
                        }
                    } else {
                        return item;
                    }
                })

        const modifiedFilteredSortedColumns = modifiedColumns.filter((column) => selectedColumns.includes(column.field)).sort((a, b) => columnNumbers[a.field] - columnNumbers[b.field])

        return modifiedFilteredSortedColumns;
    };



    return (
        <Box m='30px' display="flex" flexDirection="column">
            <Header
                title="Accounts"
                subtitle="List of all Accounts"
            />
            <Button
                sx={{"margin": "0 0 0.1rem 0", "width": "200px"}} 
                color='secondary'
                variant='contained'
                onClick={(event) => {navigate('/addaccount')}}
            >
                Add New Account
            </Button>
            <Button
                sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
                variant="contained" 
                onClick={handleClick}
            >
                Select Columns
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                <Box p={2}>
                <Typography>Select Columns, set Order, and click Save.</Typography>
                <Divider />
                <List>
                    {AllPossibleColumns.map((column) => (
                    <ListItem key={column.field}>
                        <ListItemText primary={column.headerName} />
                        <IconButton
                            onClick={() =>
                                handleColumnsChange(
                                selectedColumns.includes(column.field)
                                    ? selectedColumns.filter((col) => col !== column.field)
                                    : [...selectedColumns, column.field]
                                )
                            }
                            >
                            {selectedColumns.includes(column.field) ? (
                                <CheckCircleIcon />
                            ) : (
                                <RadioButtonUncheckedIcon />
                            )}
                        </IconButton>
                        <TextField
                            sx={{ width: "75px" }}
                            // do not allow negative numbers
                            InputProps={{ inputProps: { min: 1 } }}
                            type="number"
                            value={columnNumbers[column.field]}
                            onChange={(e) =>
                                setColumnNumbers({
                                ...columnNumbers,
                                [column.field]: parseInt(e.target.value, 10) || 0,
                                })
                            }
                        />
                    </ListItem>
                    ))}
                </List>
                <Divider />
                <Button
                    sx={{ mt: 2 }}
                    variant='contained'
                    onClick={(event) => saveUserSelectedColumns("customers", selectedColumns, columnNumbers)}
                >
                    Save
                </Button>
                </Box>
            </Popover>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{"& .MuiDataGrid-root": {
                        border: "none",
                        overflow: "auto",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    
                }}
            >
                <DataGrid
                    rows={data}
                    columns={ModifiedColumnsforGrid()}
                    onColumnsChange={handleColumnsChange}
                />
            </Box>
        </Box>
    );
};

export default Customers;