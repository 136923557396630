import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChartAR from "../../components/BarChartAR";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const Dashboard = () => {

  // Define the URL
  // const localurl = 'http://127.0.0.1:5000/api/v1/read';


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const navigate = useNavigate();
  const [user, setUser ] = useState(null);
  const [userType, setUserType ] = useState('user');
  const [customers, setCustomers] = useState([]);
  const [actionRequests, setActionRequests ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // for healthscores history array (multiple customers healthscores history for specific owner)
  const [hsHistoryData, setHsHistoryData] = useState([]);


  useEffect(() => {

    // if (CheckTokenExpired()) {
    //   ToastNotify("error", "Session has expired. Please log in again.");
    //   navigate('/login', {replace: true})
    //   return;
    // }

    const UserjsonArray = [];

    const checkUser = localStorage.getItem('user');

    if (checkUser === null) {
    
        navigate('/login', {replace: true})
    
    } else {
    const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

    Object.entries(userInfojsonObject).map(([key, value]) => {
        // Perform operations with the key-value pair
        const obj = { key, value }; // Create an object with key-value pair
        UserjsonArray.push(obj);
    });
    
    setUser(UserjsonArray);
    };
  }, []);


  // fetching both customer data and healthscores data (score_history and 3 lowest healthscores) as they are in the same entity
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  


        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
        });

        if (user_type === 'admin' || user_type === 'manager' || user_type === 'team user') {
          const data = {
            "kind_id": kind_id, 
            "filters": {
              "filter1": {"filter_field": "object_type", 
                    "filter_op": "=",
                    "filter_value": "customer"},
              } 
          };

          const url = process.env.REACT_APP_API_URL + '/api/v1/read'

          const response = await fetch(url, {
                          method: 'POST',
                          headers: headers,
                          body: JSON.stringify(data)
                        })

          const result = await response.json();
          const myJsonString = JSON.stringify(result);
          const jsonObject = JSON.parse(myJsonString);
          // today's date in YYYY-MM-DD format
          const today = new Date().toISOString().substring(0, 10);
          if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{
              id: 1,
              customer_name: "Example Customer 1",
              lifecycle_stage: "New",
              healthscore: 100,
              amount: "",
              owner_name: loginUserName,
              created_date: today,
            }]
            setCustomers(jsonArray);

            const HShistoryArray = [];
            
            const transformedArray = [{
              x: today,
              y: 100 // Convert 'y' to an integer
            }];
             // final array for healthscores line chart in dashboard
             HShistoryArray.push({
              id: "Example Customer 1",
              color: colors.greenAccent[500],
              data: transformedArray,
              });
            setHsHistoryData(HShistoryArray);

          } else {

            const jsonArray = jsonObject.retrieved_data.map(item => ({
              ...item,
            }));
            setCustomers(jsonArray);
            // code for lowest 3 healthscores line chart ONLY
            // this contains all the customer's score_history after looping through and adding to this array
            const HShistoryArray = [];
            // Process each element in the array
            for (const element of jsonArray) {
              const customername = element.customer_name;
              const jsonString = element.score_history;
              // console.log('this is jsonString for customer: ' + customername)
              // console.log(jsonString)
              // check if jsonString is actually an array, if it is an array, then convert it to a string object
              if (Array.isArray(jsonString)) {
                const stringifiedJsonString = JSON.stringify(jsonString);
                const parsedArray = JSON.parse(stringifiedJsonString);
                const transformedArray = parsedArray.map(item => ({
                    x: item.x,
                    y: parseInt(item.y, 10) // Convert 'y' to an integer
                }));
                HShistoryArray.push({
                    id: customername,
                    color: colors.greenAccent[500],
                    data: transformedArray,
                });
              } else {
                 // Parse the JSON string into a JavaScript array
                const parsedArray = JSON.parse(jsonString);
                // If you want to transform the array to the desired format
                const transformedArray = parsedArray.map(item => ({
                    // convert item.x to a date in YYYY-mm format
                    x: new Date(item.x).toISOString().substring(0, 10),
                    y: parseInt(item.y, 10) // Convert 'y' to an integer
                }));
                // console.log('this is transformedArray:')
                // console.log(transformedArray);
                // console.log('this is CustHSLinedata:')
                // console.log(CustHSLinedata)
                // final array for line chart
                HShistoryArray.push({
                        id: customername,
                        color: colors.greenAccent[500],
                        data: transformedArray,
                      });
              }
            };
            // console.log('HShistoryArray:');
            // console.log(HShistoryArray);
            // select only 3 arrays where the last item.y is the lowest out of all the item.y inside the data arrays from HShistoryArray
            const lowest3HS = HShistoryArray.sort((a, b) => a.data[a.data.length - 1].y - b.data[b.data.length - 1].y).slice(0,3);
            // Sort each 'data' array in 'lowest3HS' by date
            lowest3HS.forEach((item) => {
              item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            // for each 'data' array in 'lowest3HS', change the order of the 2nd and 3rd items in the array to be the 3rd and 2nd items in the array
            // console.log('this is lowest3HS:')
            // console.log(lowest3HS);
            // changing the 2nd and 3rd colors of array to blue and red
            // check if there is a 2nd item in the array
            if (lowest3HS[1]) {
              lowest3HS[1].color = tokens("dark").blueAccent[300];
            }
            if (lowest3HS[2]) {
              lowest3HS[2].color = colors.grey[600]
            }
            // console.log('HShistoryArray:');
            // console.log(HShistoryArray);
            setHsHistoryData(lowest3HS);
          }

        } else {
          

          const data = {
            "kind_id": kind_id, 
            "filters": {
              "filter1": {"filter_field": "object_type", 
                    "filter_op": "=",
                    "filter_value": "customer"},
              "filter2": {"filter_field": "owner_name", 
                    "filter_op": "=",
                    "filter_value": loginUserName},
              } 
              };

              const url = process.env.REACT_APP_API_URL + '/api/v1/read'

          const response = await fetch(url, {
                          method: 'POST',
                          headers: headers,
                          body: JSON.stringify(data)
                        })

          const result = await response.json();
          const myJsonString = JSON.stringify(result);
          const jsonObject = JSON.parse(myJsonString);
          // today's date in YYYY-MM-DD format
          const today = new Date().toISOString().substring(0, 10);
          if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{
              id: 1,
              customer_name: "Example Customer 1",
              lifecycle_stage: "New",
              healthscore: 100,
              amount: "",
              owner_name: loginUserName,
              created_date: today,
            }]
            setCustomers(jsonArray);

            const HShistoryArray = [];
            
            const transformedArray = [{
              x: today,
              y: 100 // Convert 'y' to an integer
            }];
             // final array for healthscores line chart in dashboard
             HShistoryArray.push({
              id: "Example Customer 1",
              color: colors.greenAccent[500],
              data: transformedArray,
              });
            setHsHistoryData(HShistoryArray);
          } else {
            const jsonArray = jsonObject.retrieved_data.map(item => ({
              ...item,
            }));

            setCustomers(jsonArray);


            // code for lowest 3 healthscores line chart ONLY

            // this contains all the customer's score_history after looping through and adding to this array
            const HShistoryArray = [];
            
            // Process each element in the array
            for (const element of jsonArray) {

              const customername = element.customer_name;

              const jsonString = element.score_history;

              // console.log('this is jsonString for customer: ' + customername)
              // console.log(jsonString)

              // if jsonString is undefined or null then return an array with today's date and healthscore of 100
              if (jsonString === undefined || jsonString === null) {
                const today = new Date().toISOString().substring(0, 10);
                const transformedArray = [{
                  x: today,
                  y: 100 // Convert 'y' to an integer
                }];

                HShistoryArray.push({
                  id: customername,
                  color: colors.greenAccent[500],
                  data: transformedArray,
                });
              } else {
              
              // check if jsonString is actually an array, if it is an array, then convert it to a string object
              if (Array.isArray(jsonString)) {
                const stringifiedJsonString = JSON.stringify(jsonString);
                const parsedArray = JSON.parse(stringifiedJsonString);
                const transformedArray = parsedArray.map(item => ({
                    x: item.x,
                    y: parseInt(item.y, 10) // Convert 'y' to an integer
                }));

                HShistoryArray.push({
                    id: customername,
                    color: colors.greenAccent[500],
                    data: transformedArray,
                });

              } else {
                  // Parse the JSON string into a JavaScript array
                  const parsedArray = JSON.parse(jsonString);
                  
                  // If you want to transform the array to the desired format
                  const transformedArray = parsedArray.map(item => ({
                      // convert item.x to a date in YYYY-MM-DD format
                      x: new Date(item.x).toISOString().substring(0, 10),
                      y: parseInt(item.y, 10) // Convert 'y' to an integer
                  }));
                  
                  // console.log('this is transformedArray:')
                  // console.log(transformedArray);
          
                  // console.log('this is CustHSLinedata:')
                  // console.log(CustHSLinedata)
          
                  // final array for line chart
                  HShistoryArray.push({
                          id: customername,
                          color: colors.greenAccent[500],
                          data: transformedArray,
                        });
                }
            };
          };

            // console.log('HShistoryArray:');
            // console.log(HShistoryArray);

            // select only 3 arrays where the last item.y is the lowest out of all the item.y inside the data arrays from HShistoryArray
            const lowest3HS = HShistoryArray.sort((a, b) => a.data[a.data.length - 1].y - b.data[b.data.length - 1].y).slice(0,3);

            // console.log('this is lowest3HS:')
            // console.log(lowest3HS);

            // changing the 2nd and 3rd colors of array to blue and red
            // check if there is a 2nd item in the array
            if (lowest3HS[1]) {
              lowest3HS[1].color = tokens("dark").blueAccent[300];
            }
            if (lowest3HS[2]) {
              lowest3HS[2].color = colors.grey[600]
            }

            // console.log('HShistoryArray:');
            // console.log(HShistoryArray);

            setHsHistoryData(lowest3HS);
          }
        }
        
         
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);



  // for action requests data
  useEffect(() => {
    const fetchActionRequests = async () => {
      setIsLoading(true);
      try {
        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        if (user_type === 'admin' || user_type === 'manager' || user_type === 'team user') {
          try {
              const url = process.env.REACT_APP_API_URL + '/api/v1/read'

              const data = {
                  "kind_id": kind_id, 
                  "filters": {
                      "filter1": {"filter_field": "object_type", 
                          "filter_op": "=",
                          "filter_value": "cta"},
                      } 
                  };
              
              const response = await fetch(url, {
                              method: 'POST',
                              headers: headers,
                              body: JSON.stringify(data)
                          })
      
              const result = await response.json();
              const myJsonString = JSON.stringify(result);
              const jsonObject = JSON.parse(myJsonString);
              
              if (jsonObject.retrieved_data === 'No result is returned') {
                  const jsonArray = [{
                              id: 1,
                              key_id: '0000000',
                              customer_name: 'Example Customer 1',
                              status: 'OPEN',
                              status2: 'OPEN',
                              notes: 'Example Notes: Worked on this after onboarding call.',
                              owner_name: 'Example Owner 1',
                              action_request_name: 'Example Action Request 1',
                              category: 'Example Category 1',
                              priority: 'High',
                              created_date: '2021-10-01',
                              due_date: '2021-10-01',
                              guide: 'Example Playbook 1'
                          }]
                  setActionRequests(jsonArray);
              } else {
                  const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                  id: index + 1,
                  key_id: item.key_id,
                  customer_name: item.customer_name,
                  status: item.status.toUpperCase(),
                  status2: item.status.toUpperCase(),
                  notes: item.notes,
                  owner_name: item.owner_name,
                  action_request_name: item.action_request_name,
                  category: item.category,
                  priority: item.priority,
                  created_date: item.created_date,
                  guide: item.guide,
                  due_date: item.due_date,
                  }));

                  // sort actionrequests byy created_date descending
                  jsonArray.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
          
                  setActionRequests(jsonArray);
              }
          } catch (error) {
              setError(error.message);
          }
      } else {
          try {
              
              const url = process.env.REACT_APP_API_URL + '/api/v1/read'
              const data = {
                          "kind_id": kind_id, 
                          "filters": {
                          "filter1": {"filter_field": "object_type", 
                                  "filter_op": "=",
                                  "filter_value": "cta"},
                              "filter2": {"filter_field": "owner_name", 
                                  "filter_op": "=",
                                  "filter_value": loginUserName},
                          } 
                          };
              const response = await fetch(url, {
                              method: 'POST',
                              headers: headers,
                              body: JSON.stringify(data)
                          })
      
              const result = await response.json();
              const myJsonString = JSON.stringify(result);
              const jsonObject = JSON.parse(myJsonString);

              if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            key_id: '0000000',
                            customer_name: 'Example Customer 1',
                            status: 'OPEN',
                            status2: 'OPEN',
                            notes: 'Example Notes: Worked on this after onboarding call.',
                            owner_name: 'Example Owner 1',
                            action_request_name: 'Example Action Request 1',
                            category: 'Example Category 1',
                            priority: 'High',
                            created_date: '2021-10-01',
                            due_date: '2021-10-01',
                            guide: 'Example Playbook 1'
                        }]
                setActionRequests(jsonArray);
            } else {
              const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                key_id: item.key_id,
                customer_name: item.customer_name,
                status: item.status.toUpperCase(),
                status2: item.status.toUpperCase(),
                notes: item.notes,
                owner_name: item.owner_name,
                action_request_name: item.action_request_name,
                category: item.category,
                priority: item.priority,
                created_date: item.created_date,
                guide: item.guide,
                due_date: item.due_date,
              }));

              // sort actionrequests byy created_date descending
              jsonArray.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      
              setActionRequests(jsonArray);
            }
          } catch (error) {
              setError(error.message);
          }
      }
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchActionRequests();
  }, []);




  const ARdata = actionRequests?.length > 0 ?
        [
          {
            xAxis: (userType === 'admin' ? "All Owners": (actionRequests.slice(0,1).map((ar) => ar.owner_name)) ),
            // Overdue: 8,  // overdue AR's
            OverdueColor: "hsl(296, 70%, 50%)",  
            Open: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'open' ? count + 1 : count, 0)),  // open AR's
            OpenColor: "hsl(97, 70%, 50%)",
            Closed: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'closed' ? count + 1 : count, 0)),  // closed AR's
            ClosedColor: "hsl(344, 70%, 50%)",
            WIP: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'wip' ? count + 1 : count, 0)), // WIP AR's
            WIPColor: "hsl(291, 70%, 50%)",
          },
        ]
        : [
          {
            xAxis: "",
            // Overdue: 0,  // overdue AR's
            OverdueColor: "hsl(296, 70%, 50%)",  
            Open: 0,  // open AR's
            OpenColor: "hsl(97, 70%, 50%)",
            Closed: 0,  // closed AR's
            ClosedColor: "hsl(344, 70%, 50%)",
            WIP: 0, // WIP AR's
            WIPColor: "hsl(291, 70%, 50%)",
          },
        ];





  function roundDown (stringNumber) {
    const number = parseFloat(stringNumber); // Convert string to number
    const roundedNumber = Math.floor(number * 10) / 10; // Round down to 1 decimal place
    const roundedNumberString = roundedNumber.toString(); // Convert number back to string
    return roundedNumberString;
  }

  // function to round down to 2 decimal places
  function roundDown2Decimals (stringNumber) {
    const number = parseFloat(stringNumber); // Convert string to number
    const roundedNumber = Math.floor(number * 100) / 100; // Round down to 2 decimal places
    const roundedNumberString = roundedNumber.toString(); // Convert number back to string
    return roundedNumberString;
  }

  function convertDecimaltoPercent (decimal) {
    const percentString = (decimal * 100) + "%";
    return percentString;
  }


  const custHSArray = []
  // sum up all the customer.healthscore and push it into custHSArray
  customers.length > 0 ? customers.map((customer) => {
      // convert customer.healthscore to a number
      const custHS = parseFloat(customer.healthscore)
      custHSArray.push(custHS)
    })
  : custHSArray.push(0)

  // sum up all the items in custHSArray
  const sumHealthscore = custHSArray.reduce((a, b) => a + b, 0)

  // average healthscore from customers the user is owner of
  const averageHealthscore = roundDown(customers.length > 0 ?
        sumHealthscore / customers.length
        : 0);

  // count of customers with healthscore below 70
  const totalHealthscoreBelow70 = customers.length > 0 ? 
        (customers.reduce((count, customer) => customer.healthscore < 70 ? count + 1 : count, 0))
        : 'n/a';

  const TextTotalHealthscoreBelow70 = totalHealthscoreBelow70 > 1 ?
         totalHealthscoreBelow70.toString() + " customers below 70"
         : totalHealthscoreBelow70.toString() + " customer below 70";

  // decimal percent of customers with healthscore below 70 (for Progress Circle on row 3 of dashboard)
  const decimalPercentHealthscoreAbove70 = customers.length > 0 ?
        1 - (totalHealthscoreBelow70 / customers.length)
        : 0

  const decimalPercentHealthscoreBelow70 = customers.length > 0 ?
        roundDown2Decimals( ((totalHealthscoreBelow70 / customers.length ) * 100) )
        : 0
  
  const TextDecimalPercentHealthscoreBelow70 = decimalPercentHealthscoreBelow70.toString() + "% of Customers are at risk"

  const totalOpenActionRequests = actionRequests.length > 0 ? 
        (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'open' ? count + 1 : count, 0))
        : 0;
  
  // const totalOverDueActionRequests = actionRequests.length > 0 ? 
  //       (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'overdue' ? count + 1 : count, 0))
  //       : 0;

  // const totalOpenAndOverDueActionRequests = actionRequests.length > 0 ?
  //       totalOpenActionRequests + totalOverDueActionRequests
  //       : 0;
  
  
  // const totalWIPActionRequests = actionRequests.length > 0 ? 
  //       (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'wip' ? count + 1 : count, 0))
  //       : 0;

  const decimalPercentOpenActionRequests = totalOpenActionRequests >= 1 ?
        totalOpenActionRequests / actionRequests.length
        : totalOpenActionRequests

  const totalCustomersNew = customers.length > 0 ? 
        (customers.reduce((count, customer) => customer.lifecycle_stage === 'New' ? count + 1 : count, 0))
        : 'n/a';

  const totalDecimalPercentNew = customers.length > 0 ? 
        roundDown( (customers.reduce((count, customer) => customer.lifecycle_stage === 'New' ? count + 1 : count, 0)) / customers.length )
        : 'n/a';

  const totalStringPercentNew = customers.length > 0 ? 
        convertDecimaltoPercent( roundDown( (customers.reduce((count, customer) => customer.lifecycle_stage === 'New' ? count + 1 : count, 0)) / customers.length ) )
        : 'n/a';

  const totalAccounts = customers.length

  const totalDecimalPercentAccounts= customers.length > 0 ? 
        roundDown( (customers.reduce((count, customer) => customer ? count + 1 : count, 0)) / customers.length )
        : 'n/a';

  const totalStringPercentAccounts = customers.length > 0 ? 
        convertDecimaltoPercent( roundDown( (customers.reduce((count, customer) => customer ? count + 1 : count, 0)) / customers.length ) )
        : 'n/a';



  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error === 'Failed to fetch') {
          window.location.reload();
      } else {
          // return <div>Error: {error}</div>;
          navigate('/accounts', {replace: true})
      }
  }


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="" subtitle="Dashboard" />

        <Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        // gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="160px"
        gap="20px"
        sx={{
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)', // 1 column grid for xs devices
            sm: 'repeat(2, 1fr)', // 2 columns grid for sm devices
            md: 'repeat(3, 1fr)', // 3 columns grid for md devices
            lg: 'repeat(4, 1fr)', // 4 columns grid for lg devices
            xl: 'repeat(12, 1fr)' // 12 columns grid for xl devices
          },
          gridAutoRows: 'minmax(160px, auto)',
          gap: '20px',
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* ROW 1 */}
        {/* {customers?.slice(0,1).map((customer) => ( */}
          <>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(event) => {navigate('/healthscores')}}
            sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
          >
            <StatBox
              title={averageHealthscore}
              subtitle="Average Healthscore"
              progress={averageHealthscore / 100}
              increase={TextTotalHealthscoreBelow70}
              icon={
                <HealthAndSafetyIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(event) => {navigate('/actionrequests')}}
            sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
          >
            <StatBox
              title={totalOpenActionRequests}
              subtitle="Open Activities"
              progress={decimalPercentOpenActionRequests}
              increase={((roundDown(decimalPercentOpenActionRequests * 100)).toString() + "%")}
              icon={
                <PendingActionsOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(event) => {navigate('/accounts')}}
            sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
          >
            <StatBox
              title={totalCustomersNew}
              subtitle="New Accounts"
              progress={totalDecimalPercentNew}
              increase={totalStringPercentNew}
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(event) => {navigate('/accounts')}}
            sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
          >
            <StatBox
              title={totalAccounts}
              subtitle="Total Accounts"
              progress={totalDecimalPercentAccounts}
              increase={totalStringPercentAccounts}
              icon={
                <AccountBalanceWalletOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          </>
        {/* ))} */}

        {/* ROW 2 */}
        <Box
          display={{ xs: 'none', md: 'block' }}  // Hidden on xs (mobile devices) and visible on md (medium screens) and larger
          gridColumn={{ xs: "span 12", sm: "span 6", md: "span 8" }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Lowest 3 Healthscores
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* {averageHealthscore} */}
              </Typography>
            </Box>
            <Box height={"38px"}>
              {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton> */}
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} customData={hsHistoryData}/>
          </Box>
        </Box>
        <Box
          height="350px"
          gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4" }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          sx={{ border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
        >
          <Box
            sx={{height: "100%", overflowY: "auto"}}
          >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Activities
            </Typography>
          </Box>
          { actionRequests.length === 0 ?
          (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography color={colors.grey[100]} variant="h5">
                No activities found
              </Typography>
            </Box>
          ) :
          
            actionRequests?.map((ar) => (
              <Box
                key={`ActionRequest-${ar.key_id}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
                // decrease the height of the scrollbar in the box 
                sx={{cursor: 'pointer',  }}
                onClick={(event) => {navigate(`/activities/${ar.key_id}`)}}
              >
                <Box width="150px">
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                    width="full"
                  >
                    { ar.action_request_name ? ar.action_request_name.substring(0, 18) : null }...
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {ar.owner_name ? ar.owner_name : null}
                  </Typography>
                </Box>
                <Box sx={{
                          width: '100px',
                          justifyContent: 'start',
                          alignItems: 'start',
                          display: { xs: 'none', md: 'block' },  // Hidden on xs (mobile devices) and visible on md (medium screens) and larger
                          color: colors.grey[100]
                        }}
                >
                  {ar.created_date? ar.created_date : null}
                </Box>
                <Box sx={{
                          width: '150px',
                          display: { xs: 'none', md: 'block' },  // Hidden on xs (mobile devices) and visible on md (medium screens) and larger
                          color: colors.grey[100]
                        }}
                  >
                  {ar.customer_name ? ar.customer_name : null}
                </Box>
                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                  width="70px"
                  justifyContent={"center"}
                  alignContent={"center"}
                  textAlign={"center"}
                >
                  {ar.status ? ar.status.toUpperCase() : null}
                </Box>
              </Box>
            ))
          }
          
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn={{ xs: "span 12", sm: "span 8", md: "span 6" }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          onClick={(event) => {navigate('/healthscores')}}
          sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
        >
          <Typography variant="h5" fontWeight="600">
            At Risk Customers
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle 
              progress={decimalPercentHealthscoreAbove70}
              size="125" 
            />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              {TextDecimalPercentHealthscoreBelow70}
            </Typography>
            <Typography>Healthscores below 70</Typography>
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: "span 12", sm: "span 8", md: "span 6" }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          onClick={(event) => {navigate('/actionrequests')}}
          sx={{cursor: 'pointer', border: "1px solid #f5f4f1", borderRadius: "20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)"}}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Activities Status
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChartAR isDashboard={true} data={ARdata} />
          </Box>
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Customer Location
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
