import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';



const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    fieldName: yup.string().required("required"),
});


const initialValues = {
    fieldName: "",
    // fieldValue: "",
};



const ManageOpportunityPicklist = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();

    const initialPicklists = [
        { id: 1, "name": 'Stage', options: ['Forecast', 'Demo', 'Proposal/Quote', 'Negotiations', 'Closed Won', 'Lost'], selectedOption: "", "newOption": "" },
        { id: 2, "name": 'Status', options: ['New', 'WIP', 'Waiting on Customer', 'Closed'], selectedOption: "", "newOption": "" },
        { id: 3, "name": 'Next Steps', options: ['Call Opportunity', 'Email Opportunity', 'Setup Demo', 'Schedule Meeting' , 'Transfer Opportunity', 'Answer Questions', 'None'], selectedOption: "", "newOption": ""  },
        { id: 4, "name": 'Source', options: ['Website', 'Google', 'Linkedin', 'Vendor A', 'Vendor B', 'Social Media', 'Referral'], selectedOption: "", "newOption": "" },
        { id: 5, "name": 'Competitors', options: ['Competitor A', 'Competitor B', 'Competitor C'], "selectedOption": "", "newOption": "" },
    ];
    
    const [picklists, setPicklists] = useState(initialPicklists);
    const [newPicklistName, setNewPickListName] = useState('');

    const [ user, setUser ] = useState(null);
    const [ data, setData ] = useState([]);
    const [ error, setError] = useState(null);
    const [ isLoading, setIsLoading] = useState(false);
    const [ noOpportunities, setNoOpportunities ] = useState(false);



    // fetching all opportunities to modify the picklists for all opportunities
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            if (checkUser === null) {
                navigate('/login', {replace: true})
            } else {
                const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

                Object.entries(userInfojsonObject).map(([key, value]) => {
                    // Perform operations with the key-value pair
                    const obj = { key, value }; // Create an object with key-value pair
                    UserjsonArray.push(obj);
                });
                setUser(UserjsonArray);
            };

            
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                        "filter_op": "=",
                                        "filter_value": "opportunity"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setNoOpportunities(true);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map(item => ({
                        ...item,
                    }));
                    setData(jsonArray);
                    setIsLoading(false);
                }

                } catch (error) {
                    setError(error.message);
                }
        }
        fetchData();
    }, []);



    // Handle the new picklist name input field
    const handleNewPickListName = (value) => {
        setNewPickListName(value);
    };

    // Add the new picklist
    const addPicklist = () => {
        const newPicklist = {
            id: picklists.length + 1,
            name: newPicklistName,
            options: [],
            selectedOption: '',
            newOption: ''
        };
        setPicklists([...picklists, newPicklist]);
        setNewPickListName('');
    };

    // Delete a picklist
    const deletePicklist = (id) => {
        setPicklists(picklists.filter(picklist => picklist.id !== id));
    };


    // Add an option to a picklist
    const handleNewOptionChange = (index, value) => {
        const updatedPicklists = [...picklists];
        updatedPicklists[index].newOption = value;
        // console.log('this is newOption:')
        // console.log(updatedPicklists[index].newOption)
        setPicklists(updatedPicklists);
    };

    // Submit the new option to the picklist's options array
    const submitNewOption = (index) => {
        const updatedPicklists = [...picklists];
        const optionsArray = updatedPicklists[index].options
        const value = updatedPicklists[index].newOption;
        updatedPicklists[index].options = [...optionsArray, value];  // Add the new option to the options array
        updatedPicklists[index].newOption = ''; // Reset the input field after grabbing the newOption value from above
        setPicklists(updatedPicklists);

        // const picklist = picklists.find(picklist => picklist.id === id);
        // addOptionToPicklist(id, picklist.newOption);
        
    };

    // Add an option to a picklist
    const addOptionToPicklist = (id, option) => {
        setPicklists(picklists.map(picklist => {
            if (picklist.id === id) {
                return { ...picklist, options: [...picklist.options, option] };
            }
            return picklist;
        }));
    };

    // Remove an option from a picklist
    const removeOptionFromPicklist = (id, option) => {
        setPicklists(picklists.map(picklist => {
            if (picklist.id === id) {
                return { ...picklist, options: picklist.options.filter(opt => opt !== option) };
            }
            return picklist;
        }));
    };

    // Handle option selection
    const handleSelect = (id, option) => {
        setPicklists(picklists.map(picklist => {
            if (picklist.id === id) {
                return { ...picklist, selectedOption: option };
            }
            return picklist;
        }));
    };


    // Save the selected options to the database
    const saveSelectionToDatabase = async () => {
  
        // console.log('Picklist data:', picklists)

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        try {
            data.map((item) => {
                const newData = {};
                newData["picklists"] = picklists;
    
                const json_data = {"kind_id": kind_id, 
                                    "key_id": item.key_id, 
                                    "data": newData};
    
                const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        // console.log('New field has been added for lead: ' + item.lead_name)
                        // console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                    });
            });
            ToastNotify("success", "Changes Saved");
        } catch (error) {
            ToastNotify("error", "something went wrong");
            console.error(error);
        }

    };




    if (noOpportunities) {
        return <div className='flex flex-col justify-center items-center text-xl'>You have no Opportunities, you must first add a Opportunity here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addopportunity')}>Add New Opportunity</Button></div>;
    }


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }    



    return (
        <Box m="20px" width="90%">
        <Header title="Manage" subtitle="Opportunity Picklists" />

        <div className='w-full h-auto grid grid-cols-1 gap-4'> 
            <div  className='col-span-1 h-auto flex flex-col p-8 border-[1px] border-gray-300'>
                <div className='w-full h-auto flex flex-col md:flex-row md:flex-wrap gap-4'>
                    {picklists.map((picklist, index) => (
                        <div key={`picklist-${index}`} className='w-full mt-4'>
                            <h3 className="font-bold">{picklist.name}</h3>
                            <select
                                key={`select-${index}`}
                                className='w-[200px] h-[40px] cursor-pointer border-[1px] border-gray-300 rounded-md text-black'
                                onChange={(e) => handleSelect(picklist.id, e.target.value)} 
                                value={picklist.selectedOption}
                            >
                                {picklist.options.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                            <input
                                className='mt-4 md:mt-0 md:ml-4 w-[200px] h-[40px] cursor-pointer border-[1px] border-gray-300 rounded-md text-black'
                                type="text"
                                value={picklist.newOption}
                                onChange={(e) => handleNewOptionChange(index, e.target.value)}
                                placeholder=" New Option"
                            />
                            <Button color='secondary' variant='contained' sx={{width: '200px', ml: {xs: "0rem", md: "1rem"}, mt: {xs: "1rem", md: "0rem"}, }} onClick={() => submitNewOption(index)}>Add Option</Button>
                            <Button color='secondary' variant='contained' sx={{width: '200px', ml: {xs: "0rem", md: "1rem"}, mt: {xs: "1rem", md: "0rem"}, }} onClick={() => removeOptionFromPicklist(picklist.id, picklist.selectedOption)}>Remove Selected Option</Button>
                            <Button color='secondary' variant='contained' sx={{width: '200px', ml: {xs: "0rem", md: "1rem"}, mt: {xs: "1rem", md: "0rem"}, }} onClick={() => deletePicklist(picklist.id)}>Delete Picklist</Button>
                        </div>
                    ))}
                    <Button color='secondary' variant='contained' sx={{width: '200px', height: "35px", mt: "3rem"}} onClick={saveSelectionToDatabase}>Save</Button>
                    <div className="w-full flex flex-col mt-8">
                        <h3 className="font-bold">New Picklist</h3>
                        <input
                            className='mt-4 w-[200px] h-[40px] cursor-pointer border-[1px] border-gray-300 rounded-md text-black'
                            type="text"
                            value={newPicklistName}
                            onChange={(e) => handleNewPickListName(e.target.value)}
                            placeholder=" New Picklist Name"
                        />
                        <Button color='secondary' variant='contained' sx={{width: '200px', height: "35px", mt: "3rem"}} onClick={addPicklist}>Add Picklist</Button>
                    </div>
                    
                </div>
            </div>
        </div>
        </Box>
    );
};



export default ManageOpportunityPicklist;



