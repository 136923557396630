import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../../components/ToastNotify';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

// date in the format MM/DD/YYYY
const dateinValidFormat = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    actionRequestName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    priority: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    customer_name: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed"),
    category: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    guide: yup.string(),
    due_date: yup.string().matches(dateinValidFormat, "Date must be in MM/DD/YYYY format")
});


const initialValues = {
    actionRequestName: "",
    category: "",
    priority: "",
    customer_name: "",
    guide: "",
    due_date: "",
};



const CreateAR = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [usersData, setUsersData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectFieldOwnerName, setSelectFieldOwnerName] = useState('');  // need this to set the owner_name field in the form

    // no longer needed,  need customers data to loop over and create activitys for each customer
    // const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    // fetching users
    useEffect(() => {
        const fetchUsers = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                          "filter1": {"filter_field": "user_kind_id", 
                                "filter_op": "=",
                                "filter_value": kind_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{

                        }]
                setUsersData(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));
        
                setUsersData(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchUsers();
    }, []);


    // fetching customers to check if the new sale for the account or customer name exists
    useEffect(() => {
        const fetchCustData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                        } 
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })

            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            customer_name: 'Example Customer 1',
                            owner_name: 'Example Owner 1',
                            lifecycle_stage: 'Purchase',
                            healthscore: '90',
                            healthscore_color: 'green',
                            cta_open: '2',
                            industry: 'Example Industry',
                            specialty: 'Example Specialty',
                            last_touch_date: '2023-09-01',
                            cta_count: '2',
                            created_date: '2023-09-01',
                            start_date: '2023-09-01',
                            renewal_date: '2024-09-01',
                }]
                setCustomers(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
                setIsLoading(false);
            }
        } catch (error) {
            setError(error.message);
        }

        };

        fetchCustData();
    }, []);



    const handleFormSubmit = (values) => {
        // console.log(values);
    
    
        const checkUser = localStorage.getItem('user');
    
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
    
        const NewARData = {}
    
        // add kind_id to NewARData
        NewARData["kind_id"] = kind_id;
        // required activity name field
        NewARData["action_request_name"] = values.actionRequestName;
        // required object_type field
        NewARData["object_type"] = "cta";
        // required created_date field
        const today = new Date().toISOString().slice(0, 10);
        NewARData["created_date"] = today;
        // required priority field
        NewARData["priority"] = values.priority;
        // required category field
        NewARData["category"] = values.category;
        // required status field
        NewARData["status"] = 'open';
        // required days_overdue field
        NewARData["days_overdue"] = '';
        // required created_by field
        NewARData["created_by"] = loginUserName;
    
        
        // if values.guide is not empty, then add it to NewARData
        if (values.guide !== "") {
            NewARData["guide"] = values.guide;
        }

        // if values.due_date is not empty, then add it to NewARData
        if (values.due_date !== "") {
            const dueDate = values.due_date;
            const dueDateString = dueDate.toString();
            // convert date string format of MM/DD/YYYY to YYYY-MM-DD format
            const newFormatDate = dueDateString.split('/')[2] + '-' + dueDateString.split('/')[0] + '-' + dueDateString.split('/')[1];
            NewARData["due_date"] = newFormatDate;
        }

        if (values.owner_name == "") {
            ToastNotify('error', 'Please select an owner for this activity.');
        } else {
            NewARData["owner_name"] = selectFieldOwnerName;
        }

    
        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        // if values.customer_name is not empty, then create activity for only one customer
        if (values.customer_name !== "") {

            // check if customer name exists, if not, toast notify the user customer name doesn't exist and return
            const customerName = values.customer_name.trim();
            const customerNameExists = customers.filter((customer) => customer.customer_name === customerName);

            if (customerNameExists.length === 0) {
                ToastNotify("error", "Account Name does not exist. Please check the spelling and names are case sensitive.");
                return;
            }

            NewARData["customer_name"] = values.customer_name.trim();

            const json_data = {"kind_id": kind_id, 
                                "data": NewARData};
    
            fetch(Createurl, {
                method: 'POST',
                body: JSON.stringify(json_data),
                headers: headers
                })
                .then(response => response.json())
                .then(result => {
                    // Handle the API response or perform any necessary actions
                    ToastNotify('success', 'New Activity has been created for customer: ' + values.customer_name);
                    // console.log('New Activity has been created for customer: ' + values.customer_name);
                    // console.log(result);
                })
                .catch(error => {
                    // Handle errors that occurred during the API call
                    ToastNotify('error', 'Error, something went wrong.');
                    console.error(error);
                });

        } else {
            // print the length of customers
            // console.log(customers.length);

            const json_data = {"kind_id": kind_id, 
                                "data": NewARData};
    
            fetch(Createurl, {
                method: 'POST',
                body: JSON.stringify(json_data),
                headers: headers
                })
                .then(response => response.json())
                .then(result => {
                    // Handle the API response or perform any necessary actions
                    ToastNotify('success', 'New Activity has been created');
                    // console.log('New Activity has been created');
                    // console.log(result);
                })
                .catch(error => {
                    // Handle errors that occurred during the API call
                    ToastNotify('error', 'Error, something went wrong.');
                    console.error(error);
                });
        }
    };


    const handleChangeOwner = (event) => {
        const owner = event.target.value;
        setSelectFieldOwnerName(owner);
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m="30px" width="50%">
            <Header title="Create" subtitle="Activities" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                    >
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Activity Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.actionRequestName}
                        name="actionRequestName"
                        error={!!touched.actionRequestName && !!errors.actionRequestName}
                        helperText={touched.actionRequestName && errors.actionRequestName}
                        sx={{ gridColumn: "span 4" }}
                    />
                    <FormControl sx={{ gridColumn: "span 4", marginTop: "1rem" }}>
                        <InputLabel id={`select-owner-label-input`}>Assignee</InputLabel>
                        <Select
                            labelId={`select-owner-label-selectid`}
                            id={`select-owner-select-id`}
                            value={selectFieldOwnerName}
                            label="Select Owner"
                            onChange={(event) => handleChangeOwner(event)}
                        >
                            {usersData.map((item) => (
                                    // const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                        <MenuItem key={item.user_owner_name} value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Select a Person for this Activity</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Priority"
                        placeholder='i.e. Critical, High, Medium, or Low'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.priority}
                        name="priority"
                        error={!!touched.priority && !!errors.priority}
                        helperText={touched.priority && errors.priority}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Category"
                        placeholder='i.e. customer outreach, risk mitigation, customer onboarding, monthly check-in, etc.'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.category}
                        name="category"
                        error={!!touched.category && !!errors.category}
                        helperText={touched.category && errors.category}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <div className='mt-2'>
                        <h2 className='font-bold'>
                            (Optional) Associate Activity with an Account
                        </h2>
                        <p className='text-gray-500 text-xs'>
                            (Optional) Enter an account name to create an activity associated with an account, othwerise you can leave this field blank.
                        </p>
                    </div>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Customer Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.customer_name}
                        name="customer_name"
                        error={!!touched.customer_name && !!errors.customer_name}
                        helperText={touched.customer_name && errors.customer_name}
                        sx={{ gridColumn: "span 4" }}
                    />
                    {/* <div className='mt-2'>
                        <h2 className='font-bold'>
                            Create Activity for a team member
                        </h2>
                        <p className='text-gray-500 text-xs'>
                            (Optional) Select the specific team member who should be the owner of this activity, otherwise you can leave it blank and it will be assigned to the owner of the customer you entered above.
                        </p>
                    </div>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Owner Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.owner_name}
                        name="owner_name"
                        error={!!touched.owner_name && !!errors.owner_name}
                        helperText={touched.owner_name && errors.owner_name}
                        sx={{ gridColumn: "span 4" }}
                    /> */}
                    <div className='mt-2'>
                        <h2 className='font-bold'>
                            (Optional) Guide
                        </h2>
                        <p className='text-gray-500 text-xs'>
                            (Optional) You may enter instructions for the owner on how to complete this activity.
                        </p>
                    </div>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Guide"
                        placeholder='i.e. Please call the customer to schedule a meeting.'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.guide}
                        name="guide"
                        error={!!touched.guide && !!errors.guide}
                        helperText={touched.guide && errors.guide}
                        sx={{ gridColumn: "span 4" }}
                    />
                    <div className='mt-2'>
                        <h2 className='font-bold'>
                            (Optional) Due Date
                        </h2>
                        <p className='text-gray-500 text-xs'>
                            (Optional) You may enter a date for when this activity should be completed.
                        </p>
                    </div>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Due Date"
                        placeholder='i.e. MM/DD/YYYY format'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.due_date}
                        name="due_date"
                        error={!!touched.due_date && !!errors.due_date}
                        helperText={touched.due_date && errors.due_date}
                        sx={{ gridColumn: "span 4" }}
                    />
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                    <Button 
                        type="submit" 
                        color="secondary" 
                        variant="contained"
                    >
                        Create Activity
                    </Button>
                    </Box>
                </form>
                )}
            </Formik>
        </Box>
    );
}

export default CreateAR;

