import React, { useState } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { saveLog } from '../utils/logChanges';


const ContactDetails = ({contactData, contactname}) => {

    const notifysuccess = () => toast.success("Changes Saved.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
    const notifyfail = () => toast.error("Error, something went wrong.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });


    const [editedContactData, setEditedContactData] = useState([...contactData]);
    const [isEditing, setIsEditing] = useState(false);


    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();

    const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


    // const checkoutSchema = yup.object().shape({
    //     editedField: yup.string().required("required"),
    //     startDate: yup.string().required("required"),
    //     email: yup.string().email("invalid email").required("required"),
    //     contact: yup
    //         .string()
    //         .matches(phoneRegExp, "Phone number is not valid")
    //         .required("required"),
    //     // address1: yup.string().required("required"),
    //     address2: yup.string().required("required"),
    // });


    const initialValues = contactData[0];


    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const json_data = {"kind_id": kind_id, 
                            "key_id": contactData[0].key_id, 
                            "data": values};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers,
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                const diffFieldArray = Object.keys(values).filter(key => values[key] !== initialValues[key]);
                const diffValueArray = diffFieldArray.map(key => values[key]);
                // console.log('diffField', diffFieldArray);
                // console.log('differenceValue', diffValueArray);
                diffFieldArray.map((item, index) => {
                    saveLog(new Date().toLocaleString(), 'Contact', contactData[0].key_id, contactData[0].contact_name, diffFieldArray[index], `Changed ${diffFieldArray[index]} to ${diffValueArray[index]}`)
                });
                notifysuccess();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                notifyfail();
                console.error(error);
            });

    };


    return (
        <Box m="30px" width="90%">
            <Header title="Contact Details" subtitle="Basic Information" />
    
            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
                // validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                {editedContactData.map((item, index) => (
                                    <>
                                        {Object.keys(item).sort().map((fieldName) => (
                                            fieldName.includes('object_type') || fieldName.includes('kind_id') || fieldName.includes('healthscore_reasons') || 
                                            fieldName.includes('nps_score_history') || fieldName.includes('contact_img_url') || fieldName.includes('notes') ||
                                            fieldName.includes('healthscore_history') || fieldName.includes('key_id') || fieldName.includes('picklists') ||
                                            fieldName.includes('score_history') || fieldName.includes('contact_id') ||
                                            fieldName.includes('healthscore_category') || fieldName.includes('healthscore_color') ? null :
                                            (
                                                fieldName === 'contact_customer_name' ? (
                                                <div className='flex flex-col'>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        label="Account Name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 2", cursor: "pointer" }}
                                                    />
                                                    <ForwardOutlinedIcon
                                                        onClick={(event) => {values[fieldName] === '' ? navigate('/accounts') : navigate('/account-profile/' + values[fieldName]);}}
                                                        sx={{fontSize: "20px", cursor: "pointer", }}
                                                    />
                                                </div>
                                                ) : 
                                                fieldName === 'lead_source' ? (
                                                    <div className='flex flex-col'>
                                                        <TextField
                                                            fullWidth
                                                            type="text"
                                                            label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values[fieldName]}
                                                            name={fieldName}
                                                            error={!!touched.editedField && !!errors.editedField}
                                                            helperText={touched.editedField && errors.editedField}
                                                            sx={{ gridColumn: "span 2", cursor: "pointer" }}
                                                        />
                                                        <ForwardOutlinedIcon
                                                            onClick={(event) => {navigate('/lead-details/' + values[fieldName]);}} 
                                                            sx={{fontSize: "20px", cursor: "pointer", }}
                                                        />
                                                    </div>
                                                ) :
                                                (
                                                    <div>
                                                        <TextField
                                                            fullWidth
                                                            type="text"
                                                            label={fieldName.replace('contact_', '')} // .replace('Contact_', '')  fieldName.charAt(0).toUpperCase() + fieldName.slice(1) // capitalize first letter in fieldName 
                                                            onBlur={handleBlur} 
                                                            onChange={handleChange}
                                                            value={values[fieldName]}
                                                            name={fieldName}
                                                            error={!!touched.editedField && !!errors.editedField}
                                                            helperText={touched.editedField && errors.editedField}
                                                            sx={{ gridColumn: "span 2" }}
                                                        />
                                                    </div>
                                                )
                                            )
                                        ))}
                                    </>
                                ))}
                            </Box>
                            <Box 
                                display="flex"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                                sx={{ flexDirection: { xs: "column", md: "row" }, }}
                            >
                                {/* <Button 
                                    type="button"
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => {navigate('/contact/newfield');}}
                                >
                                    Add New Field
                                </Button> */}
                                <Button 
                                    // type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => handleFormSubmit(values)}
                                >
                                    Save Edits
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>
        </Box>
    );
};

export default ContactDetails;
