import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Select, Button, TextField, IconButton, useTheme, Typography  } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CircularProgress from '@mui/material/CircularProgress';

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';



const ManagePage = () => {

    const navigate = useNavigate();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const [selected, setSelected] = useState("Add One Customer");
    const [user, setUser ] = useState(null);
    const [userType, setUserType] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const UserjsonArray = [];
  
        const checkUser = localStorage.getItem('user');
  
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
  
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        setIsLoading(true);

        const checkUserType = async () => {
            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const user_id = fetchedUserinner.user_id  // using this variable to get user_id
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            setUserType(user_type);
            setIsLoading(false);
        }

        checkUserType();

    }, []);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        (userType === 'admin' || userType === 'manager') ? (
            <Box m='20px'>
                <Header
                    title="Manage"
                    subtitle="Settings"
                />
                    <Box
                        className='flex flex-col w-full justify-center items-center mt-16'
                    >
                        <Box className='w-full flex flex-col md:w-[500px] border-[1px] border-gray-500 rounded-md p-8'>
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: "15px 0 5px 20px", fontSize: '2rem', fontWeight: 'bold' }}
                            >
                                Role: {userType.charAt(0).toUpperCase() + userType.slice(1)}
                            </Typography>
                            <div className='mt-8 flex flex-col gap-8 w-full justify-center items-center'>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: {sm:'300px', md:'425px'}, }}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        onClick={(event) => navigate("/healthscores/manage")}
                                        sx={{ width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Manage Healthscores
                                    </Button>
                                    <p className='text-sm text-gray-500 text-center mt-8 p-[5px]'>
                                        Create, Edit, Delete Healthscore Properties to calculate Healthscores..
                                    </p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        onClick={(event) => navigate("/activities/manage")}
                                        sx={{ width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Manage Activities
                                    </Button>
                                    <p className='text-sm text-gray-500 text-center mt-8 p-[5px]'>
                                        Create, Delete, Reassign Activities..
                                    </p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/lifecycle/manage")}
                                        sx={{ width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Manage Lifecycle Stages
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Create, Edit, Delete Lifecycle Stages..</p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/addaccount")}
                                        sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Add One Account
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Create a new account..</p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/addmultipleaccounts")}
                                        sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Add Multiple Accounts
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Add or Import multiple accounts..</p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/newfield")}
                                        sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Add Account Fields
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Add a new field to all accounts..</p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/fields/delete")}
                                        sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Delete Fields
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Delete an existing field from Accounts, Leads, or Opportunities..</p>
                                </Box>
                            </div>
                            <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                                <Box width="425px">
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        backgroundColor={colors.greenAccent[500]}
                                        onClick={(event) => navigate("/team")}
                                        sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                    >
                                        Manage Team
                                    </Button>
                                    <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Create, Edit, or Delete team members..</p>
                                </Box>
                            </div>
                        </Box>
                    </Box>
            </Box>
        ) : (
            <Box m='20px'>
                <Header
                    title="Manage"
                    subtitle="Settings"
                />
                <Box
                    className='flex flex-col w-full justify-center items-center mt-16'
                >
                    <Box className='w-full flex flex-col md:w-[500px] border-[1px] border-gray-500 rounded-md p-8'>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            sx={{ m: "15px 0 5px 20px", fontSize: '2rem', fontWeight: 'bold' }}
                        >
                            Role: {userType.charAt(0).toUpperCase() + userType.slice(1)}
                        </Typography>
                        <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                            <Box width="425px">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    backgroundColor={colors.greenAccent[500]}
                                    onClick={(event) => navigate("/addaccount")}
                                    sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                >
                                    Add One Account
                                </Button>
                                <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Create a new account..</p>
                            </Box>
                        </div>
                        <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                            <Box width="425px">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    backgroundColor={colors.greenAccent[500]}
                                    onClick={(event) => navigate("/addmultipleaccounts")}
                                    sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                >
                                    Add Multiple Accounts
                                </Button>
                                <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Add or Import multiple customers..</p>
                            </Box>
                        </div>
                        <div className='mt-8 flex flex-row gap-8 w-full justify-center items-center'>
                            <Box width="425px">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    backgroundColor={colors.greenAccent[500]}
                                    onClick={(event) => navigate("/newfield")}
                                    sx={{  width: "100%", height: 75, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold", fontSize: '1rem' }}
                                >
                                    Add Account Fields
                                </Button>
                                <p className='text-sm text-gray-500 items-center text-center mt-[17px] p-[5px]'>Quickly add a new field to all accounts..</p>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Box>
        )
    )
};

export default ManagePage;