import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';



const AddMultipleCustomers = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [custData, setCustData] = useState([]);
    const [allCustomersData, setAllCustomersData] = useState([]);
    const [placeholderState, setPlaceholderState] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);


    // fetching customers data
    useEffect(() => {
        const fetchCustData = async () => {
            setIsLoading(true);

            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    // do nothing as the handle upload function will add in the required fields for customer object
                    const jsonArray = [{

                            }]
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        ...item,
                    }));
            
                    //filter jsonArray on the first object and setCustData  // only need one customer object to get the field names
                    const filteredJsonArray = jsonArray.filter(item => item.object_type === 'customer');
                    setCustData(filteredJsonArray);
                    setAllCustomersData(jsonArray);
                }
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
            };
    
        fetchCustData();
    }, []);



    const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };


    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
    
        Papa.parse(file, {
          complete: (result) => {
            // 'result.data' contains the parsed CSV data
            // console.log('Parsed CSV Data:', result.data);
            setCsvData(result.data);
          },
          header: true, // Set to true if your CSV file has a header row
        });
    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    const handleUpload = () => {
        // console.log('This is the CSV Data:');
        // console.log(csvData);

        const outsidescope_inner_data = [];

        try {
            csvData.map((item, index) => {
                // if customerName is empty, skip this item
                if (item.CustomerName === '') {
                    return;
                }
    
                const inner_data = {};
    
                // map over the custData array and add all the keys to the inner_data object and set the values to empty strings
                custData.map((item, index) => {
                    const CustomerFieldKeys = Object.keys(item);
                    // lowercase the keys
                    CustomerFieldKeys.map((item, index) => {
                        CustomerFieldKeys[index] = item.toLowerCase();
                    })
    
                    const values = "";
                    CustomerFieldKeys.map((item, index) => {
                        inner_data[item] = values;
                    })
                });
    
                 // today's date in YYYY-MM-DD format
                 const today = new Date().toISOString().slice(0, 10);
    
                // placing the healthscore update to inner_data object specifically here so that if the customer is also adding healthscore, it will be updated
                inner_data['healthscore'] = "100";
                inner_data['score_history'] = [{"x": today, "y": "100"}];

                inner_data['lifecycle_stage'] = 'New';
    
    
                // dynamically assign variables for every item in the csvData array
                const keys = Object.keys(item);
                const values = Object.values(item);
               
                keys.map((item, index) => {
                    if (item === 'CustomerName') {
                        inner_data['customer_name'] = values[index];
                    } else {
                        // lowercase the item
                        const itemLower = item.toLowerCase();
                        if (itemLower === 'healthscore') {
                            inner_data['healthscore'] = values[index];
                            inner_data['score_history'] = [{"x": today, "y": values[index]}];
                        }
    
                        inner_data[itemLower] = values[index];
                    }
                });
    
                inner_data['object_type'] = 'customer';
                inner_data['created_date'] = today;
    
                // console.log('This is the inner_data:');
                // console.log(inner_data);
    
                const checkUser = localStorage.getItem('user');
    
                const fetchedUserString = JSON.stringify(checkUser); 
                const fetchedUserObject = JSON.parse(fetchedUserString);
                const fetchedUserinner = JSON.parse(fetchedUserObject);
                const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
                const user_type = fetchedUserinner.user_type  // using this variable to get user_type
                const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
        
                const json_data = {"kind_id": kind_id, 
                                    "key_id": item.CustomerName,  // from csvData
                                    "data": inner_data
                                    };
        
                const url = process.env.REACT_APP_API_URL + '/api/v1/create'
        
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers,
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        console.log('Changes Saved: ')
                        console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                        
                    });
                

                // set outsidescope_inner_data as the inner_data object
                outsidescope_inner_data[0] = inner_data;
            });


            // console.log('This is the outsidescope_inner_data:');
            // console.log(outsidescope_inner_data[0]);

            // remove '[object object]' from the outsidescope_inner_data object
            delete outsidescope_inner_data[0]['[object Object]'];

            // for every key inside the inner_data object, check to see if the key exists in the custData array, if it doesn't, add it to all the customer objects in the AllCustomersData array
            const inner_data_keys = Object.keys(outsidescope_inner_data[0]);
            const custDataKeys = Object.keys(allCustomersData[0]);

            //lowercase the keys in inner_data_keys
            inner_data_keys.map((item, index) => {
                inner_data_keys[index] = item.toLowerCase();
            })
            // lowercase the keys in custDataKeys
            custDataKeys.map((item, index) => {
                custDataKeys[index] = item.toLowerCase();
            })

            inner_data_keys.map((item, index) => {
                if (custDataKeys.includes(item)) {
                    return;
                } else {
                    // add the key to all the customer objects in the AllCustomersData array
                    allCustomersData.map((custItem, index) => {
                        custItem[item] = '';
                    })
                }
            });

            // for all the customer objects in the AllCustomersData array, make a post request to update the customer objects with the new fields
            allCustomersData.map((item, index) => {
                const checkUser = localStorage.getItem('user');
                const fetchedUserString = JSON.stringify(checkUser); 
                const fetchedUserObject = JSON.parse(fetchedUserString);
                const fetchedUserinner = JSON.parse(fetchedUserObject);
                const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
                const user_type = fetchedUserinner.user_type  // using this variable to get user_type
                const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

                const json_data = {"kind_id": kind_id,
                                    "key_id": item.customer_name,
                                    "data": item
                                };
                
                const url = process.env.REACT_APP_API_URL + '/api/v1/update'

                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        console.log('Fields were updated for customer: ' + item.customer_name)
                        console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                        
                    });
            });

            ToastNotify('success', 'Changes Saved');
        } catch (error) {
            console.error(error);
            ToastNotify('error', 'Error, something went wrong');
        }

    };
    
    
    const csvheaders = ['CustomerName']; // Add your headers here

    const downloadCsv = () => {
        const csvContent = csvheaders.join(',')

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'customers_template.csv'; // Set your desired filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m="30px">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
            <Header
                    title="Add Multiple Accounts"
                    subtitle="Import Accounts using provided CSV File"
            />
            <div 
                className='app__tierlevel-container'
                style={{'marginTop': '20px', 'marginBottom':'20px'}}
            >
                {/* <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => navigate('/npsdata')}
                >
                    See All NPS Responses
                </Button> */}
            </div>
            <div className='flex flex-col w-full h-auto'>
                <Box sx={{ width: '100%' }}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        Tabs were originally here
                    </Box> */}
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full md:w-2/3 h-auto justify-center">
                                <div>
                                    <h3 className='font-bold'>Upload Accounts via CSV</h3>
                                </div>
                                <div className='flex flex-col w-full md:flex-row h-auto p-10 justify-center items-center border-[1px] border-gray-500'>
                                    <div className='flex flex-col w-auto h-auto flex-start p-4'>
                                        <div>
                                            <p>Download CSV Template</p>
                                        </div>
                                        <div className='flex flex-col w-auto h-auto'>
                                            <Button
                                                sx={{"marginTop": "1rem", "width": "200px", "height": "50px"}} 
                                                color='secondary'
                                                variant='contained' 
                                                onClick={(event) => downloadCsv()}
                                            >
                                                Download CSV
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-auto h-auto flex-start p-4'>
                                        <div>
                                            <p className='text-gray-500 text-sm'>Add account names to the 'CustomerName' column. Each account name must be unique.  You may add more columns to the CSV for any data associated with the account, the column names you add will be created as fields in the account object under the account profile section and the column values will be the field values.</p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-auto h-auto flex-start p-4 justify-center items-center'>
                                        <div>
                                            <p>Upload CSV Template with Accounts and/or data fields</p>
                                        </div>
                                        <div>
                                            <div 
                                                {...getRootProps()} 
                                                style={{
                                                    border: '2px dashed #cccccc',
                                                    borderRadius: '4px',
                                                    padding: '20px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                }}>
                                                <input {...getInputProps()} />
                                                {
                                                isDragActive ?
                                                    <p>Drop the CSV file here...</p> :
                                                    <p>Drag 'n' drop a CSV file here, or click to select one</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-auto h-auto'>
                                            <Button
                                                sx={{"marginTop": "1rem", "width": "200px", "height": "50px"}} 
                                                color='secondary'
                                                variant='contained' 
                                                onClick={(event) => handleUpload()}
                                            >
                                                Upload CSV
                                            </Button>
                                        </div>
                                    </div>

                                </div>


                                {/* <div>
                                    <h3 className='font-bold'>Add Customers via API</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row',
                                            'width': 'auto',
                                            'height': '400px',
                                            'justifyContent': 'center',
                                            'alignItems': 'center',
                                            'textAlign': 'center',
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey',
                                            'padding': '5px'
                                        }}
                                >
                                    <p>
                                        Coming Soon..
                                    </p>
                                </div>


                                <div className='app__hsmanagement-edit-properties-title'>
                                    <h3 className='font-bold'>Upload Customers via Google Cloud Bucket</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row', 
                                            'justifyContent': 'space-between', 
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey', 
                                            'cursor': 'pointer', 
                                            'padding': '10px'
                                        }}
                                >
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'width': '50px'}}
                                    >
                                        Google Cloud Bucket URL:
                                    </p>
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                                    >
                                        gs://example_bucket_url
                                    </p>
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                                    >

                                    </p>
                                </div> */}
                                
                            </div>
                        </div>
                </Box>
                <Button
                    sx={{ marginTop: "1rem", width: 200, }} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => navigate('/accounts')}
                >
                    View all Accounts
                </Button>
            </div>
        </Box>
  )
}

export default AddMultipleCustomers;