import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, MenuItem, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import CustomerDetails from '../../components/CustomerDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import OpportunityDetails from '../../components/OpportunityDetails';
import { saveLog } from '../../utils/logChanges';


const OpportunityPage = () => {

    const { opportunityname } = useParams();

    const opportunityNameModified = opportunityname + '_opp';

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // const custNameLower = [HSLineChartdata.find(series => series.id === opportunity.toLowerCase())];

    const initialPicklists = [
        { id: 1, "name": 'Stage', options: ['Forecast', 'Demo', 'Proposal/Quote', 'Negotiations', 'Closed Won', 'Lost'], selectedOption: "", "newOption": "" },
        { id: 2, "name": 'Status', options: ['New', 'WIP', 'Waiting on Customer', 'Closed'], selectedOption: "", "newOption": "" },
        { id: 3, "name": 'Next Steps', options: ['Call Lead', 'Email Lead', 'Setup Demo', 'Schedule Meeting' , 'Transfer Opportunity', 'Answer Questions', 'None'], selectedOption: "", "newOption": ""  },
        { id: 4, "name": 'Source', options: ['Website', 'Google', 'Linkedin', 'Vendor A', 'Vendor B', 'Social Media', 'Referral'], selectedOption: "", "newOption": "" },
        { id: 5, "name": 'Competitors', options: ['Competitor A', 'Competitor B', 'Competitor C'], "selectedOption": "", "newOption": "" },
    ];

    const [picklists, setPicklists] = useState(initialPicklists);

    const [data, setData] = useState([]);

    const [logs, setLogs] = useState([]);

    // for notes tab
    const [initialValues, setInitialValues] = useState([]); 
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    // for users, to reassign ownership on opportunity
    const [usersData, setUsersData] = useState([]);

    // for tab panels
    const [value, setValue] = useState(0);


    // for filenames to list files from gcp bucket
    const [files, setFiles] = useState([]);

    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileDownloadLoading, setFileDownloadLoading] = useState(false);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    // fetching opportunity data
    useEffect(() => {

        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
        
        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "key_id": opportunityNameModified
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })

            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString); 
            const jsonArray = jsonObject.retrieved_data.map(item => ({
                ...item,
            }));

            setData(jsonArray);

            const initialValuesForNotes = {"notes": jsonArray[0].notes}

            setInitialValues(initialValuesForNotes);

            setPicklists(jsonArray[0].picklists);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
        };

        fetchData();
    }, [opportunityNameModified]);



    // fetching logs or a.k.a. Changes Timeline or a.k.a. notifications data
    useEffect(() => {
        const fetchLogsData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        try { 
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'

            const data = {
                "kind_id": "logs", 
                "filters": {
                    "filter1": {"filter_field": "kind_id", 
                        "filter_op": "=",
                        "filter_value": kind_id},
                    "filter2": {"filter_field": "crm_object", 
                        "filter_op": "=",
                        "filter_value": "Opportunity"},
                    "filter3": {"filter_field": "name", 
                        "filter_op": "=",
                        "filter_value": opportunityname},
                    } 
                };
            
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            
            if (jsonObject.retrieved_data === 'No result is returned') {
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));

                // sort jsonArray based on date_time field descending
                jsonArray.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
        
                setLogs(jsonArray);
            }
        } catch (error) {
            setError(error.message);
        }

          setIsLoading(false);
        };
    
        fetchLogsData();
    }, []);



    // This function is to upload files to gcp bucket
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            ToastNotify('error', 'Please select a file first!');
            return;
        }

        setFileUploadLoading(true);


        try {
            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const editedOpportunityName = opportunityNameModified.toLowerCase().replace(/ /g, '_');

            const filename = file.name;

            const formData = new FormData();
            formData.append('file_name', filename);
            formData.append('kind_id', kind_id);
            formData.append('object_folder_name', editedOpportunityName);

            const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/getsignedurl';

            // const url = 'http://127.0.0.1:5000/api/v1/getsignedurl';

            // Setup headers
            const fileheaders = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
            });

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: fileheaders,
                // You may need to include headers depending on your API requirements, such as authentication tokens
                // Do not set 'Content-Type' header when uploading FormData; the browser will set it with the proper 'boundary'
            });

            const results = await response.json();

            const bucketUrl = results.url;

            // console.log('this is bucketUrl: ' + bucketUrl)

            const uploadResponse = await fetch(bucketUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            });

            // console.log('this is upload response: ' + uploadResponse)

            if (!uploadResponse.ok) {
                setFileUploadLoading(false);
                console.log('Failed to upload file');
                ToastNotify('error', 'Failed to upload file');
            } else {
                setFileUploadLoading(false);
                ToastNotify('success', 'File uploaded successfully!');
            }

        } catch (error) {
            setFileUploadLoading(false);
            console.error('Error during upload:', error);
            ToastNotify('error', 'Error during upload.');
        }
    };


    // function to download files from gcp bucket
    const handleDownload = async (fileName) => {
        setFileDownloadLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // kind_id is the bucket name
        // bucket folder is the opportunityname
        const editedOpportunityName = opportunityNameModified.toLowerCase().replace(/ /g, '_');
        const filepath = editedOpportunityName + '/' + fileName;

        const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/getdownloadurlfrombucket';

        const json_data = {
            "fileName": filepath,
            "bucketName": kind_id
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(json_data),
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();
            const downloadUrl = data.url;

            // Create an anchor element and simulate a click to download the file
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); // Set the file name for download
            document.body.appendChild(link);
            link.click();
            link.remove();

            setFileDownloadLoading(false);
        } catch (error) {
            setFileDownloadLoading(false);
            console.error('Download failed:', error);
            ToastNotify('Error', 'Error, could not download file. Something went wrong.');
        }

          
    };
    


    useEffect(() => {
        setIsLoading(true);
        if (opportunityname === 'Example Opportunity 1') {
            navigate('/opportunities', {replace: true})
        }
    }, [opportunityname]);


 

    // fetching files from gcp bucket and folder for the opportunity
    useEffect(() => {
        const fetchFiles = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            const editedOpportunityName = opportunityNameModified.toLowerCase().replace(/ /g, '_');

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listfilesfrombucket';
                const json_data = {
                            "bucketName": kind_id,
                            "folderName": editedOpportunityName + "/"
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(json_data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject === '') {
                    return null;
                } else {
                    // const jsonArray = jsonObject.map((item, index) => ({
                    //     id: index + 1,
                    //     ...item,
                    //     }));

                    // console.log('this is jsonObject from fetching files: ' + jsonObject)

                    setFiles(jsonObject);
                }
                
            } catch (error) {
                console.log('error or no files in bucket');
            }
            setIsLoading(false);
            };
    
        fetchFiles();
    }, [opportunityname]);



    // for loading tab panels
    useEffect(() => {
        // Read the hash from the URL
        const hash = window.location.hash.substring(1);
        
        // Set the tab value based on the hash
        if (hash) {
          const tabIndex = parseInt(hash, 10);
          if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < 7) {
            setValue(tabIndex);
          }
        }
    }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            console.log('Error:', error)
            return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
        }
    }

    

    // for tab panels
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    // for tab panels
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // for tab panels
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
      };

    
    // for Notes tab
    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // get all the values including the values in the select fields
        const allValues = {...values}

        const json_data = {"kind_id": kind_id, 
                            "key_id": opportunityNameModified, 
                            "data": allValues};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                const diffFieldArray = Object.keys(values).filter(key => values[key] !== initialValues[key]);
                const OldValueArray = diffFieldArray.map(key => initialValues[key]);
                const NewValueArray = diffFieldArray.map(key => values[key]);
                // console.log('OldValueArray', OldValueArray);
                // console.log('NewValueArray', NewValueArray);
                const oldValueNotes = OldValueArray[0];
                const newValueNotes = NewValueArray[0];
                // get the difference in text between oldValueNotes and newValueNotes
                const diffNotes = newValueNotes.replace(oldValueNotes, '');
                // console.log('diffNotes', diffNotes);
                saveLog(new Date().toLocaleString(), 'Opportunity', opportunityNameModified, opportunityname, "Notes", `Added New Note: ${diffNotes}`)
                ToastNotify('success', 'Notes Saved')
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong')
                console.error(error);
            });

    };



    // Handle picklist option selection
    const handleSelect = (id, option) => {
        setPicklists(picklists.map(picklist => {
            if (picklist.id === id) {
                return { ...picklist, selectedOption: option };
            }
            return picklist;
        }));
    };


    // Save the selected options to the database
    const saveSelectionToDatabase = async () => {

        // console.log('Picklist data:', picklists)

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        try {
            data.map((item) => {
                const newData = {};
                newData["picklists"] = picklists;
    
                const json_data = {"kind_id": kind_id, 
                                    "key_id": item.key_id, 
                                    "data": newData};
    
                const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        picklists.map((item) =>
                            item && item.selectedOption &&
                                saveLog(new Date().toLocaleString(), 'Opportunity', opportunityNameModified, opportunityname, item.name, `Changed Picklist ${item.name} to ${item.selectedOption}`)
                        )
                        // console.log('New field has been added for lead: ' + item.lead_name)
                        // console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                    });
            });
            ToastNotify("success", "Changes Saved");
        } catch (error) {
            ToastNotify("error", "something went wrong");
            console.error(error);
        }
    
    };


    // logs or a.k.a changes timeline
    const logsColumns = [
        // { 
        //     field: "id", 
        //     headerName: "Row",
        //     width: 10
        // },
        {
            field: "date_time",
            headerName: "Date & Time",
            width: 150,
        },
        {
            field: "owner_name",
            headerName: "Team Member",
            width: 125,
        },
        { 
            field: "crm_object", 
            headerName: "Object", 
            width: 100,
        },
        {
            field: "name",
            headerName: "Name",
            width: 125,
            textDecoration: "none",
            color: "inherit",
            cellClassName: "name-column--cell",
            renderCell: ({ row: { name, identifier, crm_object }}) => {
                return (
                    <div
                        onClick={(event) => 
                            crm_object === 'Lead' ? 
                            navigate(`/lead-details/${name}`)
                            : crm_object === 'Account' ?
                            navigate(`/account-profile/${name}`)
                            : crm_object === 'Opportunity' ?
                            navigate(`/opportunity-details/${name}`)
                            : crm_object === 'Activity' ?
                            navigate(`/activities/${identifier}`)
                            : crm_object === 'Contact' ?
                            navigate(`/contact-details/${identifier}`)
                            : null
                        }
                        className='cursor-pointer'
                    >
                        {name}
                    </div> 
                )
            }
        },
        {
            field: "field",
            headerName: "Updated Field",
            width: 125,
        },
        {
            field: "message",
            headerName: "Change Message",
            flex: 1,
        },
    ]




    return (
        <Box m="30px">
            <Header
                    title={opportunityname}
                    subtitle="Opportunity Details"
            />
            {/* <div className='mt-[20px] mb-[20px]'>
                <h3 className='text-xl font-bold'>
                    Tier Level:
                </h3>
                <p className='text-lg'>{data.map((item) => (item.tier_level ? item.tier_level.charAt(0).toUpperCase() + item.tier_level.slice(1) : null ))}</p>
            </div> */}
            <div className='flex flex-col w-[1500px] md:w-full'>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary" aria-label="customer profile tabs">
                            <Tab label="Summary" {...a11yProps(0)} id="tab-summary"/>
                            <Tab label="Assets" {...a11yProps(1)} id="tab-assets"/>
                            <Tab label="Changes Timeline" {...a11yProps(2)} id="tab-timeline"/>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <div 
                            className="flex flex-col w-full"
                        >
                            <div 
                                className="w-[80%] h-auto flex flex-col justify-center gap-[1.5rem]"
                            >
                                {/*  Picklist Section */}
                                <div className='w-full h-auto grid grid-cols-4 gap-4'> 
                                    <h3 className='text-base font-bold'>Highlights</h3>
                                </div>
                                <div className='w-full h-auto grid grid-cols-1 gap-4'> 
                                    <div  className='col-span-1 h-auto flex flex-col p-8 border-[1px] border-gray-300'>
                                        <div className='w-full h-auto flex flex-col md:flex-row md:flex-wrap gap-8'>
                                            {picklists.map(picklist => (
                                                <div className='mt-1' key={picklist.id}>
                                                    <h3 className='font-bold'>{picklist.name}</h3>
                                                    <select 
                                                        className='w-[200px] h-[40px] cursor-pointer border-[1px] border-gray-300 rounded-md text-black'
                                                        onChange={(e) => handleSelect(picklist.id, e.target.value)} 
                                                        value={picklist.selectedOption}
                                                    >
                                                        {picklist.options.map(option => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                    {/* { managePicklists ? <Button color='secondary' variant='contained' sx={{width: '200px', ml:'1rem'}} onClick={() => deletePicklist(picklist.id)}>Delete Picklist</Button> : null }                         */}
                                                </div>
                                            ))}
                                        </div>
                                        <Box 
                                            display="flex"
                                            justifyContent="start" 
                                            mt="20px"
                                            gap={"20px"}
                                            sx={{ flexDirection: { xs: "column", md: "row" }, }}
                                        >
                                            <Button 
                                                color="secondary" 
                                                variant="contained"
                                                sx={{width: '200px', height: '35px', marginTop: "1rem"}}
                                                onClick={(event) => navigate('/opportunity/managepicklist')}
                                            >
                                                Manage Picklist
                                            </Button>
                                            <Button 
                                                color="secondary" 
                                                variant="contained"
                                                sx={{width: '200px', height: '35px', marginTop: "1rem", marginRight: "7rem"}}
                                                onClick={saveSelectionToDatabase}
                                            >
                                                Save Selections
                                            </Button>
                                        </Box>
                                    </div>


                                </div>
                            </div>




                            <div 
                                className="w-[80%] h-auto flex flex-col justify-center gap-[1.5rem] mt-8"
                            >
                                {/*  Opportunity Details */}
                                <div style={{'gridColumn': '4', 
                                            'width': '100%', 
                                            'height': 'auto', 
                                            'display': 'flex', 
                                            'flexDirection': 'row', 
                                        }}
                                >
                                    <h3 className='text-base font-bold'>Opportunity Information</h3>
                                </div>
                                <div style={{'gridColumn': '1', 
                                            'width': '100%', 
                                            'height': 'auto', 
                                            'display': 'flex', 
                                            'flexDirection': 'column', 
                                            'justifyContent': 'space-between',
                                        }}
                                >
                                    <div  // Basic Opportunitys Info Box
                                        style={{'gridCoumn': 'span 1', 
                                                'height': 'auto', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'margin': '0 0 1rem 0', 
                                                'border': '1px solid lightgrey', 
                                                'padding': '10px',
                                            }}
                                    >
                                        <div 
                                            style={{'width': '100%', 
                                                    'display': 'flex', 
                                                    'flexDirection': 'column',
                                                }}
                                        >
                                            <OpportunityDetails opportunityData={data} opportunityname={opportunityNameModified}/>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <Box width="80%" mt="1rem">
                                {/* <Header title="Notes" subtitle="Edit Notes" /> */}
                                <h3 className='text-base font-bold'>Notes</h3>

                                <Formik
                                    onSubmit={(values) => handleFormSubmit(values)}
                                    initialValues={initialValues}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                    }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Box
                                                    display="grid"
                                                    gap="30px"
                                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                                    sx={{
                                                        marginTop: "2rem",
                                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                                    }}
                                                >
                                                    <div>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            rows={23}
                                                            type="text"
                                                            label="Notes"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.notes}
                                                            name="notes"
                                                            error={!!touched.editedField && !!errors.editedField}
                                                            helperText={touched.editedField && errors.editedField}
                                                            sx={{ gridColumn: "span 1" }}
                                                        />
                                                    </div>
                                                </Box>
                                                <Box 
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="space-between" 
                                                    mt="20px"
                                                    gap={"20px"}
                                                >
                                                    <Button 
                                                        type="submit" 
                                                        color="secondary" 
                                                        variant="contained"
                                                        sx={{width: '200px'}}
                                                        // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                                                    >
                                                        Save Edits
                                                    </Button>
                                                </Box>
                                            </form>
                                            )
                                        }
                                </Formik>
                            </Box>

                        </div>
                    </CustomTabPanel>


                    {/* Assets Tab Panel */}
                    <CustomTabPanel value={value} index={1}>
                        <div className="w-[70%] h-full gap-10 p-10 flex flex-col justify-center items-center border-[1px] border-gray-500 rounded-xl">
                            <h2 className='text-xl text-gray-500 font-bold'>Upload Assets or Files</h2>
                            <p>i.e. Pictures, PDFs, any text-based Files</p>
                            <input 
                                type="file" 
                                id="file-input" 
                                onChange={handleFileChange}
                                className='ml-8 w-[300px] h-[40px] cursor-pointer'
                            />
                            {fileUploadLoading && (
                                <div className='flex flex-col justify-center items-center'>
                                    <p>Uploading file...please wait until upload is complete...</p>
                                    <CircularProgress color="secondary" sx={{ fontSize: "50px", mt: "1rem" }}/>
                                </div>
                            )}
                        </div>
                        <div className="w-[70%] mt-8 h-full gap-2 p-10 flex flex-col justify-center items-center border-[1px] border-gray-500 rounded-xl">
                            <h2 className='text-xl text-gray-500 font-bold'>Assets or Files</h2>
                            {fileDownloadLoading && (
                                            <div className='mb-8 flex flex-col justify-center items-center'>
                                                <p>Downloading file..</p>
                                                <CircularProgress color="secondary" sx={{ fontSize: "50px", mt: "1rem" }}/>
                                            </div>
                            )}
                            {files.map((file, index) => (
                                <div className='mt-4 w-auto h-auto flex flex-row gap-10 items-center text-center justify-items-center justify-center'>
                                    <p key={index} className='w-[300px] text-lg text-gray-500 font-bold'>{ file.replace(/^.*[\\\/]/, '') }</p>
                                    <Button
                                        sx={{"width": "180px"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => handleDownload( file.replace(/^.*[\\\/]/, '') )}
                                    >
                                        Download
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </CustomTabPanel>


                    {/* Changes timeline Tab Panel */}
                    <CustomTabPanel value={value} index={2}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <Box m='20px'>
                                <Header
                                    title="Changes Timeline"
                                    subtitle="View Changes"
                                />
                                <Box
                                    m="40px 0 0 0"
                                    height="75vh"
                                    sx={{"& .MuiDataGrid-root": {
                                            border: "none",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none"
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300]
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: colors.grey[700],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                            backgroundColor: colors.grey[700],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={logs}
                                        columns={logsColumns}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                </Box>
                            </Box>
                        
                        </div>
                    </CustomTabPanel>


                </Box>
            </div>
        </Box>
    );
}

export default OpportunityPage;

