import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CircularProgress from '@mui/material/CircularProgress';
import { saveLog } from '../../utils/logChanges';



const Logs = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);



    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);



    // fetching logs or a.k.a. notifications data
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        try { 
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'

            const data = {
                "kind_id": "logs", 
                "filters": {
                    "filter1": {"filter_field": "kind_id", 
                        "filter_op": "=",
                        "filter_value": kind_id},
                    } 
                };
            
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            
            if (jsonObject.retrieved_data === 'No result is returned') {
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));

                // sort jsonArray based on date_time field descending
                jsonArray.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
        
                setData(jsonArray);
            }
        } catch (error) {
            setError(error.message);
        }

          setIsLoading(false);
        };
    
        fetchData();
    }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }

    

    const columns = [
        // { 
        //     field: "id", 
        //     headerName: "Row",
        //     width: 10
        // },
        {
            field: "date_time",
            headerName: "Date & Time",
            width: 150,
        },
        {
            field: "owner_name",
            headerName: "Team Member",
            width: 125,
        },
        { 
            field: "crm_object", 
            headerName: "Object", 
            width: 100,
        },
        {
            field: "name",
            headerName: "Name",
            width: 125,
            textDecoration: "none",
            color: "inherit",
            cellClassName: "name-column--cell",
            renderCell: ({ row: { name, identifier, crm_object }}) => {
                return (
                    <div
                        onClick={(event) => 
                            crm_object === 'Lead' ? 
                            navigate(`/lead-details/${name}`)
                            : crm_object === 'Account' ?
                            navigate(`/account-profile/${name}`)
                            : crm_object === 'Opportunity' ?
                            navigate(`/opportunity-details/${name}`)
                            : crm_object === 'Activity' ?
                            navigate(`/activities/${identifier}`)
                            : crm_object === 'Contact' ?
                            navigate(`/contact-details/${identifier}`)
                            : crm_object === 'Event' ?
                            navigate(`/events`)
                            : null
                        }
                        className='cursor-pointer'
                    >
                        {name}
                    </div> 
                )
            }
        },
        {
            field: "field",
            headerName: "Updated Field",
            width: 125,
        },
        {
            field: "message",
            headerName: "Change Message",
            flex: 1,
        },
    ]


    return (
        <Box m='20px'>
            <Header
                title="Logs"
                subtitle="Changes Log"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    width: { xs: "1300px", sm: "1300px", md: "100%"},
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default Logs;



