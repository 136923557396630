import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from "formik";
import { Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import { useCustomSidebarHeight } from "../../theme";



const sampleSurveyHTML = `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Survey Email</title>
                        <style>
                        body { font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; }
                        .container { background-color: #ffffff; width: 100%; max-width: 600px; margin: auto; padding: 20px; }
                        .logo { text-align: center; margin-bottom: 20px; }
                        .content { color: #444; }
                        h1 { color: #444444; }
                        p { line-height: 1.5; }
                        .button { display: block; width: fit-content; background-color: #0066cc; color: #ffffff; padding: 10px 20px; text-align: center; text-decoration: none; margin: 20px auto; border-radius: 5px; }
                        </style>
                        </head>
                        <body>
                        <div class="container">
                        <div class="logo">
                            <img src="YOUR_LOGO_URL" alt="Logo" style="max-width: 100px;">
                        </div>
                        <div class="content">
                            <h1>We'd love your feedback!</h1>
                            <p>Dear valued customer,</p>
                            <p>Your opinion is very important to us and we would love to hear about your experience. Could you spare a few moments to take our survey? Your feedback will help us improve our services and serve you better in the future.</p>
                            <a href="YOUR_SURVEY_LINK" class="button">Take the Survey</a>
                            <p>Thank you very much for your time and input. We really appreciate it!</p>
                            <p>Warm regards,<br>Your Team</p>
                        </div>
                        </div>
                        </body>
                        </html>
                        `;


const SurveysCreate = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();


    const [templateName, setTemplateName] = useState('');
    const [subject, setSubject] = useState('');
    // for the content of the survey email
    const [surveyEmailContent, setSurveyEmailContent] = useState(sampleSurveyHTML);  // this is the default content for the survey email

    // state to edit the survey email content - button click
    const [editboolean, setEditBoolean] = useState(false);

    // for the input of the questions
    const [fields, setFields] = useState([]);  // this is to store the question value inputs from the user
    const [inputValues] = useState({ inputValue: '' });  // this is only used to display the value for TextField objects (not storing actual input values from user, that gets stored in fields state above) and to assign an id to each TextField that gets generated dynamically from the user clicking button to add more logic or questions

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);



    const handleSubmitCreateTemplate = (event) => {
        event.preventDefault();

        if (templateName === '' || subject === '' || fields.length === 0) {
            ToastNotify('error', 'All fields are required, including at least one question.');
            return;
        }

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // template_name must be kind_id + templateName (without any spaces in templateName)
        const newTemplateName = kind_id + '_' + 'srvy' + '_' + templateName.replace(/\ /g, '_');

        // create the survey questions
        const NewSurveyQuestions = {}
        // add kind_id to NewSurveyQuestions
        NewSurveyQuestions["kind_id"] = kind_id;
        // required surveyid field
        // make newTemplateName URL friendly for surveyid
        const urlSafeTemplateName = newTemplateName.replace(/\s/g, '-');
        NewSurveyQuestions["surveyid"] = urlSafeTemplateName;
        // required object_type field
        NewSurveyQuestions["object_type"] = "surveyquestions";
        // required questions field
        NewSurveyQuestions["questions"] = fields;
    
        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        const json_questions_data = {"kind_id": kind_id, 
                                    "data": NewSurveyQuestions};

        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_questions_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                //console.log('New Rule Action Request has been created:');
                // console.log(result);
                // ToastNotify('success', 'Questions has been created');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Error, something went wrong.');
            });


        // create the survey email template 
        const noSpacesTemplateName = templateName.replace(/\ /g, '_');

        // to remove any html tags from the content
        const contentText = surveyEmailContent.replace(/<[^>]*>?/gm, '');

        const json_data = {"template_name": newTemplateName, 
                            "subject": subject, 
                            "html_part": surveyEmailContent, 
                            "text_part": contentText
                        };

        const createUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/createtemplate';

        fetch(createUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Survey has been created');
                navigate('/surveys/templates', {replace: true});
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
    };



    // to capture the Survey Question input fields
    const handleChangeValue = (event, index) => {
        const newFields = [...fields];
        newFields[index].values = event.target.value;
        setFields(newFields);
    };

    
    // button function for adding more questions (while also increasing page size) into survey
    const handleAddQuestion = () => {
        // Add a new field to the list
        setFields([...fields, { id: fields.length + 1, values: '' }]);

        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 15;
        setSidebarHeight(newHeight + 'vh');
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="30px" width="90%">
            <Header title="Create" subtitle="New Survey" />

            <div className='mt-4 border-[1px] border-gray-200 flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                <div className='w-full h-auto flex-col p-4'>
                    <label className='font-bold'>Survey Email Preview:</label>
                    <div className='mt-4 w-full h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden'>
                        <iframe 
                            srcDoc={surveyEmailContent}
                            width="100%"
                            sandbox="allow-scripts"
                            style={{ width: '100%', height: '100%', marginTop: '1rem'}}
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                    <div className='flex-col w-full mt-2'>
                        <Button
                            sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                            variant='contained'
                            color='secondary'
                            onClick={() => setEditBoolean(true)}
                        >
                            Edit Survey Email
                        </Button>
                        {editboolean ? (
                            <div className='flex-col w-full mt-4'>
                                <label className='font-bold'>Survey Email Content:</label>
                                <textarea
                                    className='border-[1px] border-gray-200 w-full h-[300px]'
                                    value={surveyEmailContent}
                                    onChange={(e) => setSurveyEmailContent(e.target.value)}
                                />
                            </div>
                        ) : null    
                        }
                    </div>
                    <div className='flex-col w-full mt-8'>
                        <label className='font-bold'>Survey Name:</label>
                        <input className='border-[1px] border-gray-200 w-full' type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                    </div>
                    <div className='flex-col w-full mt-8'>
                        <label className='font-bold'>Survey Subject Line:</label>
                        <input className='border-[1px] border-gray-200 w-full' type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <Formik
                    >
                        {({
                        errors,
                        touched,
                        handleBlur,
                        handleSubmit,
                        }) => (
                        <form onSubmit={handleSubmit}>

                        {fields.map((field, index) => (
                            <Box key={field.id}>
                                <TextField
                                    id={`input-value-${field.id}`}
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Survey Question"
                                    onBlur={handleBlur}
                                    onChange={(event) => handleChangeValue(event, index)}  // sets the value for the field in the state 'fields' which is an array
                                    value={inputValues[`inputValue${field.id}`]}
                                    name="inputValue"
                                    error={!!touched.inputValue && !!errors.inputValue}
                                    helperText={touched.inputValue && errors.inputValue}
                                    sx={{ gridColumn: "span 4", marginTop: "1rem"}}
                                />
                            </Box>
                        ))}
                        </form>
                        )}
                    </Formik>
                    <Button
                        sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                        variant='contained'
                        color='secondary'
                        onClick={handleAddQuestion}
                        >+ Add Question
                    </Button>
                </div>
            </div>

            <div className='flex w-[50%] justify-end pb-4'>
                <Button
                    sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
                    variant="contained" 
                    type='button'
                    onClick={(event) => handleSubmitCreateTemplate(event)}
                >
                    Create Survey
                </Button>
            </div>
        </Box>
    );
}

export default SurveysCreate;