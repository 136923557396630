import { Box, useTheme } from "@mui/material";
import GeographyChart from "../../components/GeographyChart";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';



const Geography = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);

  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);


  return (
    <Box m="20px">
      <Header title="Customers Map" subtitle="Geography location of Customers" />

      <Box
        height="75vh"
        border={`1px solid ${colors.grey[100]}`}
        borderRadius="4px"
      >
        <GeographyChart />
      </Box>
    </Box>
  );
};

export default Geography;
