import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';


const Contacts = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);
    const [contactData, setContactData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);



  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);



  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                        "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "contact"},
                        // "filter2": {"filter_field": "owner_name", 
                        //         "filter_op": "=",
                        //         "filter_value": loginUserName},
                        } 
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            
            if (jsonObject.retrieved_data === 'No result is returned') {
                setIsLoading(false);
                return null;
            } else {

                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                // data.map((item, index) => (
                //   <div key={index}>
                //       {Object.entries(item).map(([key, value]) => {
                //       const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
        
                setContactData(jsonArray);
                setIsLoading(false);
            }
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
        };

    fetchData();
  }, []);


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


  const contactColumns = [
    { field: "id", headerName: "Row"},
    // { field: "contact_keyid", headerName: "Contact ID"}, 
    { 
    field: "contact_name", 
    headerName: "Contact Name", 
    flex: 1,
    textDecoration: "none",
    color: "inherit",
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        const cellValue = params.value;
        return (
        <Link 
            to={`/contact-details/${cellValue}`}
            style={{ textDecoration: 'none', color: "inherit" }}
        >
            {cellValue}
        </Link>
        );
    },
    },
    {
        field: "contact_customer_name",
        headerName: "Account Name",
        flex: 1,
        textDecoration: "none",
        color: "inherit",
        renderCell: (params) => {
            const cellValue = params.value;
            return (
            <Link 
                to={`/account-profile/${cellValue}`}
                style={{ textDecoration: 'none', color: "inherit" }}
            >
                {cellValue}
            </Link>
            );
        },
    },
    {
    field: "contact_email",
    headerName: "Email",
    flex: 1
    },
    { 
    field: "contact_phone", 
    headerName: "Phone Number",
    flex: 1,
    },
    { 
    field: "contact_address", 
    headerName: "Address",
    flex: 1,
    },
    { 
    field: "contact_city", 
    headerName: "City", 
    flex: 1,
    },
    { 
    field: "contact_state", 
    headerName: "State", 
    flex: 1,
    },
    { 
    field: "contact_zipcode", 
    headerName: "Zip Code",
    },
    { 
    field: "contact_country", 
    headerName: "Country", 
    flex: 1,
    },
]



return (
    <Box m='20px'>
        <Header
            title="Contacts"
            subtitle="View Contacts"
        />
        { contactData.length === 0 &&
            <div className='mt-4'>
                <p>New Contacts can be added after adding an account and clicking the Contacts tab in the account profile.</p>
            </div>
        }
        
        <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{"& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.grey[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.grey[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
            }}
        >
            <DataGrid
                rows={contactData}
                columns={contactColumns}
                components={{ Toolbar: GridToolbar }}
            />
        </Box>
  </Box>
  );
};

export default Contacts;
