import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveLog } from '../utils/logChanges';
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const CustomerDetails = ({customerData, customerName}) => {

    const [editedCustomerData, setEditedCustomerData] = useState([...customerData]);

    const [selectFieldCategory, setSelectFieldCategory] = useState('');
    const [selectFieldBusiness, setSelectFieldBusiness] = useState('');  
    const [selectFieldProduct, setSelectFieldProduct] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    

    // if specificData contains fields category, business, product_of_interest, set them to state
    useEffect(() => {
        const updateSelectFields = async () => {
            setIsLoading(true);
            const specificData = customerData[0];

            try {
                if (specificData.hasOwnProperty('category')) {
                    setSelectFieldCategory(specificData.category);
                }
                if (specificData.hasOwnProperty('business')) {
                    setSelectFieldBusiness(specificData.business);
                }
                if (specificData.hasOwnProperty('product_of_interest')) {
                    setSelectFieldProduct(specificData.product_of_interest);
                }
            } catch (error) {
                console.log(error);
                window.location.reload();
            }
        };
        updateSelectFields();
        setIsLoading(false);
    }, [customerData]);


    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
      'Authorization': `Basic ${encodedCredentials}`,
      'Content-Type': 'application/json'
    });

    const notifysuccess = () => toast.success("Changes Saved.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
    const notifyfail = () => toast.error("Error, something went wrong.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });


    


    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();

    const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


    const checkoutSchema = yup.object().shape({
        editedField: yup.string().required("required"),
        customerName: yup.string().required("required"),
        startDate: yup.string().required("required"),
        email: yup.string().email("invalid email").required("required"),
        contact: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("required"),
        // address1: yup.string().required("required"),
        address2: yup.string().required("required"),
    });


    const initialValues = customerData[0];


    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        if (kind_id === 'client1717414405544' || kind_id === 'client1703727947492') {
            values.category = selectFieldCategory;
            values.business = selectFieldBusiness;
            values.product_of_interest = selectFieldProduct;
        }

        const json_data = {"kind_id": kind_id, 
                            "key_id": customerName, 
                            "data": values};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                const diffFieldArray = Object.keys(values).filter(key => values[key] !== initialValues[key]);
                const diffValueArray = diffFieldArray.map(key => values[key]);
                // console.log('diffField', diffFieldArray);
                // console.log('differenceValue', diffValueArray);
                diffFieldArray.map((item, index) => {
                    saveLog(new Date().toLocaleString(), 'Account', customerData[0].key_id, customerData[0].customer_name, diffFieldArray[index].replace('_', ' '), `Changed ${diffFieldArray[index].replace('_', ' ')} to ${diffValueArray[index]}`)
                });
                notifysuccess();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                notifyfail();
                console.error(error);
            });

    };


    const handleChangeCategory = (event) => {
        const category = event.target.value;
        setSelectFieldCategory(category);
    };
    
    const handleChangeBusiness = (event) => {
        const business = event.target.value;
        setSelectFieldBusiness(business);
    };

    const handleChangeProduct = (event) => {
        const product = event.target.value;
        setSelectFieldProduct(product);
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }


    return (
        <Box m="30px" width="90%">
            <Header title="Customer Details" subtitle="Basic Information" />
    
            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                { 
                                    kind_id === 'client1717414405544' || kind_id === 'client1703727947492' ? 
                                    (
                                    <>
                                        <FormControl sx={{ gridColumn: "span 1"}}>
                                        <InputLabel id={`select-category-label-input`}>Category</InputLabel>
                                        <Select
                                            labelId={`select-category-label-selectid`}
                                            id={`select-category-select-id`}
                                            value={selectFieldCategory}
                                            label="Select Category"
                                            onChange={(event) => handleChangeCategory(event)}
                                        >
                                            <MenuItem key="Retail" value="Retail">Retail</MenuItem>
                                            <MenuItem key="Ecommerce" value="Ecommerce">Ecommerce</MenuItem>
                                            <MenuItem key="Distributor" value="Distributor">Distributor</MenuItem>
                                            <MenuItem key="Government" value="Government">Government</MenuItem>
                                        </Select>
                                        <FormHelperText>Select Category</FormHelperText>
                                        </FormControl>
                                        <FormControl sx={{ gridColumn: "span 1"}}>
                                        <InputLabel id={`select-business-label-input`}>Business</InputLabel>
                                        <Select
                                            labelId={`select-business-label-selectid`}
                                            id={`select-business-select-id`}
                                            value={selectFieldBusiness}
                                            label="Select Business"
                                            onChange={(event) => handleChangeBusiness(event)}
                                        >
                                            <MenuItem key="B2G" value="B2G">B2G</MenuItem>
                                            <MenuItem key="B2B" value="B2B">B2B</MenuItem>
                                            <MenuItem key="B2B2C" value="B2B2C">B2B2C</MenuItem>
                                            <MenuItem key="B2C" value="B2C">B2C</MenuItem>
                                        </Select>
                                        <FormHelperText>Select Business</FormHelperText>
                                        </FormControl>
                                        <FormControl sx={{ gridColumn: "span 1"}}>
                                        <InputLabel id={`select-product-label-input`}>Product</InputLabel>
                                        <Select
                                            labelId={`select-product-label-selectid`}
                                            id={`select-product-select-id`}
                                            value={selectFieldProduct}
                                            label="Select Product"
                                            onChange={(event) => handleChangeProduct(event)}
                                        >
                                            <MenuItem key="QuickFinder™ COVID-19 / Flu Antigen Self Test" value="QuickFinder™ COVID-19 / Flu Antigen Self Test">QuickFinder™ COVID-19 / Flu Antigen Self Test</MenuItem>
                                            <MenuItem key="OHC COVID-19 / Flu Antigen Test Pro" value="OHC COVID-19 / Flu Antigen Test Pro">OHC COVID-19 / Flu Antigen Test Pro</MenuItem>
                                        </Select>
                                        <FormHelperText>Select Product of Interest</FormHelperText>
                                        </FormControl>
                                    </>
                                    ) :
                                    null
                                }
                                {editedCustomerData.map((item, index) => (
                                    <>
                                        {Object.keys(item).sort().map((fieldName) => (
                                            fieldName.includes('object_type') || fieldName.includes('kind_id') || fieldName.includes('healthscore_reasons') || 
                                            fieldName.includes('nps_score_history') || fieldName.includes('customer_img_url') || fieldName.includes('notes') ||
                                            fieldName.includes('healthscore_history') || fieldName.includes('key_id') || fieldName.includes('customer_id') ||
                                            fieldName.includes('customer_name') || fieldName.includes('score_history') || fieldName.includes('category') ||
                                            fieldName.includes('business') || fieldName.includes('product_of_interest') ||
                                            fieldName.includes('healthscore_category') || fieldName.includes('healthscore_color') ? null :
                                            (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 2" }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </>
                                ))}
                            </Box>
                            <Box 
                                display="flex"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                                sx={{ flexDirection: { xs: "column", md: "row" }, }}
                            >
                                <Button 
                                    type="button"
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => {navigate('/newfield');}}
                                >
                                    Add New Field
                                </Button>
                                <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => handleFormSubmit(values)}
                                >
                                    Save Edits
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>
        </Box>
    );
};

export default CustomerDetails;
