import { Paper, Box, IconButton, useTheme, Icon, Button, Link } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens, useNotifications } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';


const Topbar = () => {

  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;

  // Encode username and password for Basic Auth
  const encodedCredentials = window.btoa(`${username}:${password}`);

  // Setup headers
  const headers = new Headers({
  'Authorization': `Basic ${encodedCredentials}`,
  'Content-Type': 'application/json'
  });


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm ] = useState('');

  // for notifications context
  const { notifications, setNotifications } = useNotifications();

  // console.log('Notifications from topbar:')
  // console.log(notifications);



  // Function to add a new notification
  const addNotification = (notification) => {
      setNotifications(prev => [...prev, notification]);
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm) {
      navigate(`/search/${searchTerm}`);

      setSearchTerm('');
    }
  };


  const logout = () => {
    googleLogout();
    // Clear localStorage
    localStorage.clear();
    // Perform any additional logout logic (e.g., API calls, state reset)
    navigate('/', {replace: true})
  };


  const location = useLocation();

  // List of route paths where the Sidebar should not appear
  // const excludedPaths = ['/','/login','/register','/contact/sales','/contact/support','/features','/FAQ','/pricing', '/unsubscribe'];

  // Check if the current path is in the excludedPaths list
  // const shouldShowSidebar = !excludedPaths.includes(location.pathname);

  
  // USING THIS INSTEAD OF THE ABOVE CODE
  let shouldShowSidebar = true;

  switch (shouldShowSidebar) {
    case location.pathname.includes('unsubscribe'):
      shouldShowSidebar = false;
      break;
    case location.pathname === '/':
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('login'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('register'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('contact/sales'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('contact/support'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('features'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('FAQ'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('FAQ/privacypolicy'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('oauth2/callback'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('pricing'):
      shouldShowSidebar = false;
      break;
    default:
      shouldShowSidebar = true;
  }

  const [isHoveredAccountIcon, setIsHoveredAccountIcon] = useState(false);
  const [isHoveredSettingsIcon, setIsHoveredSettingsIcon] = useState(false);

  const handleMouseEnter = () => {
    setIsHoveredAccountIcon(true);
  };
  const handleMouseLeave = () => {
    setIsHoveredAccountIcon(false);
  };
  const handleMouseEnterSettings = () => {
    setIsHoveredSettingsIcon(true);
  };
  const handleMouseLeaveSettings = () => {
    setIsHoveredSettingsIcon(false);
  };


  const markAsRead = (notification, index) => {

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name;

    const updatedReadUsers = new Set([...notification.read_users, loginUserName]);
    const updatedNotification = {...notification, read_users: Array.from(updatedReadUsers)};

    const retrievedNotifications = [...notifications];
    // remove updatedNotification from retrievedNotifications array
    const newNotifications = retrievedNotifications.filter((item, i) => i !== index);

    setNotifications(newNotifications);

    const json_data = {"kind_id": "logs", 
                        "key_id": updatedNotification.key_id, 
                        "data": updatedNotification};

    const url = process.env.REACT_APP_API_URL + '/api/v1/update'

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(json_data),
        headers: headers
    })
    .then(response => response.json())
    .then(result => {
        // console.log("Database update result:", result);
    })
    .catch(error => {
        console.error("Error:", error);
    });
  };


  const markAllAsRead = () => {

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name;

    notifications.map(notification => {
      const updatedReadUsers = new Set([...notification.read_users, loginUserName]);
      const updatedNotification = {...notification, read_users: Array.from(updatedReadUsers)};

      const json_data = {"kind_id": "logs", 
                          "key_id": updatedNotification.key_id, 
                          "data": updatedNotification};

      const url = process.env.REACT_APP_API_URL + '/api/v1/update'

      fetch(url, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
      })
      .then(response => response.json())
      .then(result => {
          // console.log("Database update result:", result);
      })
      .catch(error => {
          console.error("Error:", error);
      });
    });

    setNotifications([]);
  };





  const NotificationIcon = ({ notifications }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showCount, setShowCount] = useState(true);

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name;

    const handleNotificationMouseEnter = () => {
        setIsOpen(true);
        setShowCount(false);
    };

    const handleNotificationMouseLeave = () => {
        setIsOpen(false);
        // setShowCount(false);
        // setNotifications(prev => prev.map(notification => {
        //     const updatedReadUsers = new Set([...notification.read_users, loginUserName]);
        //     return {...notification, read_users: Array.from(updatedReadUsers)};
        // }));
    };

    // Check if there is any notification that the user hasn't read
    const isAnyUnread = notifications.some(notification => 
      !notification?.read_users?.includes(loginUserName)
    );

    // filter notifications that the user hasn't read
    const unreadNotifications = notifications.filter(notification => 
      !notification?.read_users?.includes(loginUserName)
    );

    return (
        <div className="flex cursor-pointer"
             onMouseEnter={handleNotificationMouseEnter}
             onMouseLeave={handleNotificationMouseLeave}>
            <NotificationsOutlinedIcon /> {/* Replace with actual icon component */}
            {showCount && notifications.length > 0 && isAnyUnread && (
                <span className="absolute top-0 left-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                    {unreadNotifications.length}
                </span>
            )}
            {isOpen && (
                <div className="flex-col absolute z-50 right-0 bg-white shadow-lg mt-2 w-72 p-2 rounded-md">
                    {unreadNotifications.slice(0,5).map((notification, index) => (
                        <div 
                          key={index} 
                          className="px-4 py-2 border-b border-gray-200 last:border-b-0 cursor-pointer"
                        >
                          <div
                            onClick={(event) => notification.crm_object === 'Lead' ? 
                            navigate(`/lead-details/${notification.name}`)
                            : notification.crm_object === 'Account' ?
                            navigate(`/account-profile/${notification.identifier}`)
                            : notification.crm_object === 'Opportunity' ?
                            navigate(`/opportunity-details/${notification.name}`)
                            : notification.crm_object === 'Activity' ?
                            navigate(`/activities/${notification.identifier}`)
                            : notification.crm_object === 'Contact' ?
                            navigate(`/contact-details/${notification.name}`)
                            : null
                            }
                          >
                            <p className="text-base text-gray-500">
                              {notification.date_time}
                            </p>
                            <p className="text-base text-gray-500">
                              {notification.owner_name}
                            </p>
                            <p className="text-base text-gray-500">
                              Updated {notification.crm_object}: <b>{notification.name.toUpperCase()}</b>
                            </p>
                            <p className="text-base text-gray-500">
                              {notification.message.substring(0, 50)}...
                            </p>
                          </div>
                            <Button
                                sx={{"margin": "0.5rem 0 0 0", "width": "150px", }} 
                                variant="contained"
                                onClick={(event) => markAsRead(notification, index)}
                            >
                                Mark as Read
                            </Button>
                        </div>
                        
                    ))}
                    <div className="flex flex-row gap-2">
                      { unreadNotifications.length > 0 ?
                        (
                          <Button
                              sx={{"margin": "2rem 0 0 0", "width": "130px", }} 
                              variant="contained"
                              onClick={(event) => markAllAsRead()}
                          >
                              Mark All as Read
                          </Button>
                        ) : (
                            <div className="mt-8, w-[65px]">
                            </div>
                        )
                      }
                      <Button
                          sx={{"margin": "2rem 0 0 0", "width": "130px", }} 
                          variant="contained"
                          onClick={(event) => navigate('/logs')}
                      >
                          View All
                      </Button>
                    </div>
                </div>
            )}
        </div>
    );
  };


  


  return (
    <>
    {shouldShowSidebar && (
      <Box display="flex" justifyContent="space-between" p={2}>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          // backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <Paper
            component="form"
            onSubmit={handleSubmit}
            sx={{
              borderRadius: 20,
              border: '1px solid #e3e3e3',
              backgroundColor: 'white',
              pl: 2,
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              ml: "3rem",
              mr: { sm: 5 },
              justifyContent: 'space-between',
              width: { xs: '150px', sm: '300px' },
              height: '40px',
              // width: { xs: '100%', sm: '300px' },
            }}
          >
            <input 
              className="search-bar"
              placeholder="Search Accounts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{border: 'none',
                      borderRadius: '20px', 
                      outline: 'none', 
                      fontFamily: 'Source Sans Pro, sans-serif', 
                      fontSize: { xs: '10px', sm: '14px'},
                      width: '100%',
                      height: '100%',
                      color: 'black',
                    }}
            />
            <IconButton type="button" sx={{ display: { xs: 'none', md: 'block'}, p: { xs: 0, md: 1}, top: 0, right: 0}}>
              <SearchIcon sx={{color: 'gray',}}/>
            </IconButton>
          </Paper>
        </Box>

        {/* ICONS */}
        <Box display="flex" alignItems="center">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          {/* <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton> */}
          <IconButton>
            <NotificationIcon notifications={ notifications } />
          </IconButton>
          <IconButton>
            <SettingsOutlinedIcon 
              onClick={() => navigate('/manage')}
            />
          </IconButton>
          <div 
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
          >
            {isHoveredAccountIcon && (
                <div
                    style={{'border': '1px solid lightgrey',
                            'padding': '10px',
                            'top': '50px',
                            'right': '20px',
                            'zIndex': '2',
                            'position': 'absolute',
                            'backgroundColor': 'white',
                            'borderRadius': '15%',
                            'width': 'auto',
                            'height': 'auto',
                            'fontSize': '16px',}}
                >
                    <style>
                      {`
                      .line-item:hover {
                        background-color: rgba(0, 0, 0, 0.03);
                        width: 100%;
                        border-radius: 15%;
                      }
                      `}
                    </style>
                    <ul 
                      className="list-items"
                      style={{'textDecoration': 'none',
                              'listStyleType': 'none',
                              'cursor': 'pointer',
                            }}
                    >
                        <li 
                          onClick={() => navigate('/userprofile')}
                          className="line-item"
                          style={{'marginBottom': '1rem',
                                  // 'marginRight': '2rem',
                                  // 'marginLeft': '1rem',
                                  'color': 'black',
                                }}
                        >
                          Edit Profile
                        </li>
                        <li 
                          onClick={logout}
                          className="line-item"
                          style={{
                                  // 'marginRight': '2rem',
                                  // 'marginLeft': '-2rem',
                                  'color': 'black',
                                }}
                        >
                          Logout
                        </li>
                    </ul>
                </div>
            )}
            <IconButton>
              <PersonOutlinedIcon />
            </IconButton>
          </div>
        </Box>
      </Box>
    )}
    </>
  );
};

export default Topbar;
