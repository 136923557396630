import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from '../../components/ToastNotify';

import ModeEditIcon from '@mui/icons-material/ModeEdit';


// this is actually the Delete Recurring Campaigns page!!

const CampaignManage = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });


    const [recurringEmails, setRecurringEmails] = useState([]);

    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    // for deleting the selected trigger (recurring email)
    const [selectField, setSelectField] = useState('');

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    // fetching email triggers or the recurring emails settings, object_type = emailtrigger
    useEffect(() => {
        const fetchRecurringEmails = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "emailtrigger"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const today = new Date();
                const formattedDate = today.toISOString().slice(0, 10);

                const jsonArray = [{
                    trigger_name: 'Example Email',
                    created_date: formattedDate,
                }]
                setRecurringEmails(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setRecurringEmails(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchRecurringEmails();
      }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    const handleDeleteRecurringCampaign = (event, selectField) => {
        event.preventDefault();

        // selectField is the template name to be deleted
        // console.log('this is selectField:')
        // console.log(selectField)

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // find the key_id from the array keyid where it is not null
        const filteredTriggerArray = recurringEmails.find((item) => item.trigger_name === selectField);

        const keyid = filteredTriggerArray.key_id;

        // console.log('this is keyid after finding from array:')
        // console.log(keyid)

        const json_data = {"kind_id": kind_id, 
                            "key_id": keyid};

        const deleteUrl = process.env.REACT_APP_API_URL + '/api/v1/delete';

        fetch(deleteUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Recurring Trigger has been deleted');
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
    };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };


    
    // this is actually the Delete Recurring Campaigns page!!

    return (
        <Box m="30px" width="90%">
            <Header title="Delete" subtitle="Scheduled Campaigns" />
            {/* <div className='mt-2 flex flex-row items-start'>
                <Button
                        sx={{width: 200, border: "1px solid black" }}
                        type='button'
                        color='secondary'
                        variant='contained'
                        onClick={(event) => navigate('/campaigns', {replace: true})}
                    >
                        Create Recurring Campaigns
                </Button>
            </div> */}
            
            <div className='mt-4 border-[1px] border-gray-200 flex-col w-[1000px] md:w-full lg:w-full h-auto rounded-lg shadow-lg shadow-gray-300'>
                <div className='grid grid-cols-8 gap-2 text-center p-4 h-auto bg-[#f8f9fa]'>
                    <p className='font-bold text-md text-gray-500'>Recurring Campaign Name</p>
                    <p className='font-bold text-md text-gray-500'>Created Date</p>
                    <p className='font-bold text-md text-gray-500'>Frequency</p>
                    <p className='font-bold text-md text-gray-500'>Send Time</p>
                    <p className='font-bold text-md text-gray-500'>Send Weekday</p>
                    <p className='font-bold text-md text-gray-500'>Send Day</p>
                    <p className='font-bold text-md text-gray-500'>Send Month</p>
                    <p className='font-bold text-md text-gray-500'>Send Quarterly</p>
                </div>
                <div className='grid grid-cols-8 gap-2 text-center p-4 h-auto'>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('trigger_name')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('created_date')) {
                                    const newDateValue = new Date(value).toLocaleDateString();
                                    return (
                                        <p className='text-md p-4 h-6'>{newDateValue}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                // const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key === 'frequency') {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('send_hourtime')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('weekday_frequency')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('monthday_frequency')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('month_frequency')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                    <div>
                        { recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('quarterly_month_frequency')) {
                                    return (
                                        <p className='text-md p-4 h-6'>{value}</p>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        ))}
                    </div>
                </div>
            </div>

            <h3 className='font-bold text-2xl mt-16'>Delete Recurring Campaigns</h3>
            <div
                className="w-full md:w-1/2 h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Campaigns</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {recurringEmails.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                if (key.includes('trigger_name')) {
                                    // trim the first 16 characters from the value
                                    // const displayValue = value.substring(20, value.length).replace(/_/g, ' ');
                                    //const newValue = value.split('_')[1];
                                    return (
                                        <MenuItem value={value}>{value}</MenuItem>
                                    )
                                    } else {
                                        return null;
                                    }
                            })
                        ))}
                    </Select>
                    <FormHelperText>Select Recurring Campaign</FormHelperText>
                </FormControl>
            </div>
            <div>
                {/* <Button
                    sx={{marginTop: "1rem"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => navigate('/edit-campaign-template', {replace: true})}
                >
                    Edit Campaign Template
                </Button> */}
                <Button
                    sx={{marginTop: "1rem", width: 200, border: "1px solid black"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleDeleteRecurringCampaign(event, selectField)}
                >
                    Delete Recurring Campaign
                </Button>
            </div>
        </Box>
    );
}

export default CampaignManage;

