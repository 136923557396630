import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Formik } from "formik";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import { useCustomSidebarHeight } from "../../theme";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';



const EditTemplate = () => {

    const { templateNameParam } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    // console.log('templateNameParam: ', templateNameParam)

    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState([]);

    // for the subject of the survey email and preview
    const [subjectPart, setSubjectPart] = useState('');

    // for the content of the survey email and preview
    const [htmlEmailContent, setHtmlEmailContent] = useState('');

    // if template has dynamic fields
    const [dynamicFields, setDynamicFields] = useState([]);

    // key_id for the dynamic fields
    const [keyId, setKeyId] = useState(null)

    // for the customer fields to map with the dynamic fields
    const [customers, setCustomers] = useState([]);

    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = async (event) => {
        setIsChecked(event.target.checked);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const templateQueryName = templateNameParam.replace(/\ /g, '_').replace(/\-/g, '_');

        // console.log('this is templateQueryName: ', templateQueryName)

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "template_tokens"},
                            "filter2": {"filter_field": "template_name", 
                                "filter_op": "=",
                                "filter_value": templateQueryName},
                        } 
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                    "htmlToken": "",
                    "label": "",
                }]
                setDynamicFields(jsonArray);
                // setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));
            
                setDynamicFields(jsonArray[0]["dynamic_fields"]);
                setKeyId(jsonArray[0]["key_id"]);
                // console.log('this is jsonArray: ', jsonArray)
                // console.log('this is jsonArray[dynamic_fields]: ', jsonArray[0]["dynamic_fields"])
            }
            } catch (error) {
                setError(error.message);
            }
    };


    const isNonMobile = useMediaQuery("(min-width:600px)");


    useEffect(() => {
        setIsLoading(true);
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    useEffect(() => {
        setIsLoading(true);
        const fetchTemplate = async () => {
            

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            const templateQueryName = templateNameParam.replace(/\ /g, '_').replace(/\-/g, '_');

            const json_data = {"template_name": templateQueryName};

            const getTemplateUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/gettemplate';

            const response = await fetch(getTemplateUrl, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(json_data)
                          })
    
            const result = await response.json();

            // !! IMPORTANT !! the template field names are different!!
            // template name is TemplateName and subject is SubjectPart and html_part is HtmlPart

            // console.log('result: ', result);

            // console.log(typeof result);

            // convert result object to array
            const resultArray = [result];

            const jsonArray = resultArray.map((item, index) => ({
                TemplateName: item.TemplateName,
                SubjectPart: item.SubjectPart,
                HtmlPart: item.HtmlPart
            }));

            setInitialValues(jsonArray[0]);

            setSubjectPart(jsonArray[0].SubjectPart);

            // console.log('jsonArray[0]: ', jsonArray[0]);
            // console.log(typeof jsonArray[0])

            const contentHtml = jsonArray[0].HtmlPart;

            const unsubscribeSection = `
                                    <div class="unsubscribe-container" style="background-color: #f4f4f4; padding: 20px; text-align: center; font-family: Arial, sans-serif;">
                                    <p style="margin: 0; font-size: 14px; color: #666;">If you no longer wish to receive these emails,</p>
                                    <a href={{ unsubscribelink }} style="color: #0066cc; text-decoration: underline; font-size: 14px;">unsubscribe here</a>.
                                    </div>
                                    `;

            const updatedHtml = contentHtml.replace(unsubscribeSection, '');

            setHtmlEmailContent(updatedHtml); // jsonArray[0].HtmlPart;
            // setIsLoading(false);
            // console.log('this is initialValues: ', initialValues)
            // setIsLoading(false);
        }
        fetchTemplate();
        
    }, [templateNameParam]);


    // // fetching customers so the user can edit the mapping for specific customer fields for the dynamic fields
    useEffect(() => {
        setIsLoading(true);
        const fetchCustData = async () => {
        

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                }]
                setCustomers(jsonArray);
                // setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchCustData();
    }, []);


    

    const handleSubmitSaveEditedTemplate = () => {

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // console.log('this is subjectPart: ', subjectPart)
        // console.log('this is dynamicFields: ', dynamicFields)
        // console.log('this is type of dynamicFields: ', typeof dynamicFields)


        const nohyphensTemplateName = templateNameParam.replace(/\-/g, '_');

        // // template_name must be kind_id + templateName (without any spaces in templateName)
        // const newTemplateName = kind_id + '_' + templateName.replace(/\ /g, '_');

        // keyId will be null if there were no dynamic fields fetched when checking the checkbox for dynamic fields (user never created dynamic fields for the template)
        if (keyId === null) {
            // creating the dynamic fields for the template
            const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'

            const fullDynamicFieldsData = {}
            fullDynamicFieldsData['kind_id'] = kind_id;
            fullDynamicFieldsData['object_type'] = 'template_tokens';
            fullDynamicFieldsData['template_name'] = nohyphensTemplateName
            fullDynamicFieldsData['dynamic_fields'] = dynamicFields;

            const json_token_data = {"kind_id": kind_id,
                                    "data": fullDynamicFieldsData
                                    };
            fetch(createUrl, {
                method: 'POST',
                body: JSON.stringify(json_token_data),
                headers: headers
                })
                .then(loginresponse => loginresponse.json())
                .then(result => {
                    // Handle the API response or perform any necessary actions
                    // console.log('Template has been deleted: ')
                    // console.log(result);
                    // ToastNotify('success', 'Template was updated successfully');
                    // navigate('/campaigns/templates', {replace: true});
                })
                .catch(error => {
                    // Handle errors that occurred during the API call
                    console.error(error);
                    ToastNotify('error', 'Something went wrong');
                });
        } else {
            // updating the dynamic fields for the template
            // keyId was fetched and set to the state variable keyId when the dynamic fields were fetched from checking the checkbox
            const url = process.env.REACT_APP_API_URL + '/api/v1/update'

            const fullDynamicFieldsData = {}

            fullDynamicFieldsData['dynamic_fields'] = dynamicFields;

            const json_token_data = {"kind_id": kind_id,
                                    "key_id": keyId,
                                    "data": fullDynamicFieldsData
                                    };
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(json_token_data),
                headers: headers
                })
                .then(loginresponse => loginresponse.json())
                .then(result => {
                    // Handle the API response or perform any necessary actions
                    // console.log('Template has been deleted: ')
                    // console.log(result);
                    // ToastNotify('success', 'Template was updated successfully');
                    // navigate('/campaigns/templates', {replace: true});
                })
                .catch(error => {
                    // Handle errors that occurred during the API call
                    console.error(error);
                    ToastNotify('error', 'Something went wrong');
                });
        }
        
        // for updating the template

        const unsubscribeSection = `
                                    <div class="unsubscribe-container" style="background-color: #f4f4f4; padding: 20px; text-align: center; font-family: Arial, sans-serif;">
                                    <p style="margin: 0; font-size: 14px; color: #666;">If you no longer wish to receive these emails,</p>
                                    <a href={{ unsubscribelink }} style="color: #0066cc; text-decoration: underline; font-size: 14px;">unsubscribe here</a>.
                                    </div>
                                    `;

        // if htmlEmailContent already contains the unsubscribeSection, then don't add it again
        
        const updatedHtmlEmailContent =  htmlEmailContent.includes('unsubscribe-container') ? htmlEmailContent : htmlEmailContent + unsubscribeSection;

        // to extract only the text within the html content
        const contentText = updatedHtmlEmailContent.replace(/<[^>]*>?/gm, '');

        const json_data = {"template_name": nohyphensTemplateName, 
                            "subject": subjectPart, 
                            "html_part": updatedHtmlEmailContent, 
                            "text_part": contentText
                        };

        const updateUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/updatetemplate';

        fetch(updateUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Template was updated successfully');
                // navigate('/campaigns/templates', {replace: true});
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
    };



    // to capture the dynamic fields html token value for the template
    const handleDynamicFieldChange = (index, value) => {
        const updatedDynamicFields = [...dynamicFields];
        // remove spaces from the value as aws ses sendemailtemplate does not accept token names with spaces
        value = value.replace(/\s/g, '');
        updatedDynamicFields[index].htmlToken = value;
        setDynamicFields(updatedDynamicFields);
    };

    // to capture the dynamic fields selection of Customer Field to map with the token for the template
    const handleSelectDynamicField = (event, index) => {
        const newDynamicFields = [...dynamicFields];
        // 'label' below is the key that is in the json array, the index in newFields[index] is 0 if user only adds one Dynamic Field logic, 1 if user adds two Dynamic Fields logic, and so on.
        newDynamicFields[index].label = event.target.value;
        setDynamicFields(newDynamicFields);
        // console.log('this is fields: ')
        // console.log(fields)
    };


    const handleRemoveQuestion= (index) => {
        const updatedDynamicFields = dynamicFields.filter((_, i) => i !== index);
        setDynamicFields(updatedDynamicFields);
    };


    // to add more dynamic fields in the view and increasing the sidebar height dynamically
    const handleAddDynamicField = () => {
        // adding a new dynamic field to the view
        const newDynamicField = {
            label: '',   // `Dynamic Field ${dynamicFields.length + 1}`
            };
        setDynamicFields([...dynamicFields, newDynamicField]);
        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 15;
        setSidebarHeight(newHeight + 'vh');
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m="30px" width="90%">
            <Header title="Edit" subtitle="Email Template" />

            <div className='mt-4 border-[1px] border-gray-200 flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                <div className='w-full h-auto flex-col p-4'>
                <label className='font-bold'>Template Name:</label>
                <p className='text-gray-500 text-lg'>{templateNameParam.substring(20).replace(/\_/g, ' ').replace(/\-/g, ' ').replace('srvy', '')}</p>
                <div className='mt-8'>
                    <label className='font-bold'>Template Preview:</label>
                </div>
                <div className='mt-4 w-full h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden'>
                    <iframe 
                        srcDoc={htmlEmailContent}
                        width="100%"
                        sandbox="allow-scripts"
                        style={{ width: '100%', height: '100%', marginTop: '1rem'}}
                        allowFullScreen
                    >
                    </iframe>
                </div>
                <Box>
                    <Formik
                        onSubmit={() => handleSubmitSaveEditedTemplate()}
                        initialValues={initialValues}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleSubmit,
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                        sx={{
                                            marginTop: "2rem",
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                        }}
                                    >
                                        <div>
                                            <TextField
                                                // fullWidth
                                                type="text"
                                                label="Subject"
                                                onBlur={handleBlur}
                                                onChange={(e) => setSubjectPart(e.target.value)}
                                                value={subjectPart}
                                                name="SubjectPart"
                                                error={!!touched.editedField && !!errors.editedField}
                                                helperText={touched.editedField && errors.editedField}
                                                sx={{ gridColumn: "span 1", width: "100%", color: "black"}}
                                            />
                                        </div>
                                        <div>
                                            <div className='flex-col w-full mt-4'>
                                                <label className='font-bold'>HTML Email Content:</label>
                                                <textarea
                                                    className='border-[1px] border-gray-200 w-full h-[300px]'
                                                    value={htmlEmailContent}
                                                    onChange={(e) => setHtmlEmailContent(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </Box>
                                    <div className='mt-8 w-full flex flex-col items-start justify-center'>
                                        <p className='text-md'>Edit dynamic fields/tokens inside my email.  Each dynamic field should be surrounded by double curly braces inside your HTML content.</p>
                                        <Checkbox
                                            sx={{ '&:hover': { backgroundColor: 'transparent',}, }}
                                            checked={isChecked}
                                            onChange={handleCheckBoxChange}
                                            color="primary"
                                            backgroundColor="transparent"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                    {isChecked && (
                                        <div className='w-full flex-col mt-4'>
                                            <label className='font-bold mt-8'>Map Dynamic Fields:</label>
                                            <div className='flex-col w-full'>
                                                {dynamicFields.map((field, index) => (
                                                    // if field.htmlToken contains 'unsubscribelink' then don't display the field
                                                    (field.htmlToken.includes('unsubscribelink')) ? null :
                                                    <div key={index} className='w-full flex justify-between items-center'>
                                                        <input
                                                            className='w-[70%] h-[52px] border-[1px] border-gray-400 rounded-md p-2 m-2 text-gray-500'
                                                            type="text"
                                                            value={field.htmlToken}
                                                            onChange={(e) => handleDynamicFieldChange(index, e.target.value)}
                                                        />
                                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                            <InputLabel id={`field-label-${index}`}>Select a Field</InputLabel>
                                                            <Select
                                                                labelId={`field-label-${index}`}
                                                                id={`field-select-${index}`}
                                                                value={dynamicFields[index].label}
                                                                label="Select Field"
                                                                onChange={(event) => handleSelectDynamicField(event, index)}
                                                            >
                                                                {customers.slice(0,1).map((item) => (
                                                                    Object.entries(item).map(([key]) => {
                                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                        if (key.includes('id') || key.includes('object_type') || key.includes('key_id') || 
                                                                            key.includes('score_history') || key.includes('healthscore_reasons') || key.includes('notes') ) {
                                                                            return null;
                                                                        } else {
                                                                        return (
                                                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                                        );
                                                                        }
                                                                    })
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>Select from Customer Fields</FormHelperText>
                                                        </FormControl>              
                                                    </div>
                                                ))}
                                            </div>
                                            <Button
                                                sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={handleAddDynamicField}
                                                >+ Add Dynamic Field
                                            </Button>
                                        </div>
                                    )
                                    }
                                    
                                    <Box 
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between" 
                                        mt="2rem"
                                        gap={"20px"}
                                    >
                                        <Button 
                                            type="submit" 
                                            color="secondary" 
                                            variant="contained"
                                            sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                                            // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                                        >
                                            Save Edits
                                        </Button>
                                    </Box>
                                </form>
                                )
                            }
                    </Formik>
                </Box>
                {/* {(initialValues.TemplateName.includes('srvy')) ?
                    (
                        <div className='flex flex-col mt-8 justify-start'>
                            <label className='font-bold'>View Questions:</label>
                            <Button
                                onClick={navigate(`/surveys/${initialValues.TemplateName.replace(/\_/g, '-')}/questions/edit`)}
                                sx={{marginTop: "1rem", width: 165, border: '1px solid black'}}
                                type='button'
                                color='secondary'
                                variant='contained'
                            >
                                Survey Questions
                            </Button>
                        </div>
                    ) : null
                } */}
                
                
                </div>
            </div>

            {/* <div>
                <Button
                    sx={{marginTop: "1rem", width: "160px"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitSaveEditedTemplate(event)}
                >
                    Save Changes
                </Button>
            </div> */}
        </Box>
    );
}

export default EditTemplate;