import React from 'react';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import campImg from '../assets/camp.svg';
import mountainChosen2 from '../assets/mountain_chosen2.png';


const GetApp = () => {
  const navigate = useNavigate();

  return (
    <section className="flexCenter w-full flex-col pb-[100px]">
      <div className="get-app">
        <div className="z-20 flex w-full flex-1 flex-col items-start justify-center gap-12">
          <h2 className="text-white bold-40 lg:bold-64 xl:max-w-[320px]">Start for Free now!</h2>
          <p className="regular-16 text-white">No Contracts, No Commitments, Cancel Anytime.</p>
          <div className="flex w-full flex-col gap-3 whitespace-nowrap xl:flex-row">
            <Button 
              title="Start Now"
              icon={campImg}
              variant="btn_white"
              full
              onClick={(event) => {navigate('/register')}}
            />
            <Button 
              title="Contact Us"
              muiIcon="True"
              variant="btn_green"
              full
              onClick={(event) => {navigate('/contact/sales')}}
            />
          </div>
        </div>

        <div className="flex flex-1 items-center justify-end">
          <img className='rounded-full' src={mountainChosen2} alt="getappmountainchosen2" width={550} height={870} />
        </div>
      </div>
    </section>
  )
}

export default GetApp;