import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import SalesData from "../../components/SalesData";



const Sales = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser ] = useState(null);

    const [salesData, setSalesData ] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);



  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);



  // fetching Sales so the user can filter on specific customers and products to view
    useEffect(() => {
        const fetchSalesData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "sale"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setIsLoading(false);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));
            
                    setSalesData(jsonArray);
                    setIsLoading(false);
                    
                }
            } catch (error) {
                setError(error.message);
            }

        };

        fetchSalesData();
    }, []);


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



  return (
    <Box m='20px'>
        <SalesData salesData={salesData} />
    </Box>
  );
};

export default Sales;
