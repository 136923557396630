import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import { Formik } from "formik";
import { ToastNotify } from '../../components/ToastNotify';
import CircularProgress from '@mui/material/CircularProgress';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { saveLog } from '../../utils/logChanges';




const ActionRequestPage = () => {

    const { arkeyidparams } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState([]);  

    const [selectStatusField, setSelectStatusField] = useState('');
    const [selectOwnerField, setSelectOwnerField] = useState('');

    const [logChanges, setLogChanges] = useState([]);  // this is to know which stuff got changed when saving log changes

    const [usersData, setUsersData] = useState([]);  // this is for the reassign owner dropdown menu

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);


    const isNonMobile = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);


      
    useEffect(() => {
        setIsLoading(true);
        if (arkeyidparams === '0000000') {
            navigate('/actionrequests', {replace: true})
        }
    }, [arkeyidparams]);



    // fetch specific action request for this action request page
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            try { 
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'

                // convert arkeyidparams to a number
                const arkeyidparamsNumber = Number(arkeyidparams)

                const data = {
                    "kind_id": kind_id, 
                    "key_id": arkeyidparamsNumber
                    };
                
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                key_id: '0000000',
                                customer_name: 'Example Customer 1',
                                status: 'OPEN',
                                notes: 'Example Notes: Worked on this after onboarding call.',
                                owner_name: 'Example Owner 1',
                                action_request_name: 'Example Action Request 1',
                                category: 'Example Category 1',
                                priority: 'High',
                                created_date: '2021-10-01',
                                created_by: 'Example User 1',
                                due_date: '2021-10-01',
                                guide: 'Example Playbook 1'
                            }]
                    setData(jsonArray);
                    setInitialValues(jsonArray[0]);
                    setSelectOwnerField(jsonArray[0].owner_name);
                    setSelectStatusField(jsonArray[0].status);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        key_id: item.key_id,
                        customer_name: item.customer_name,
                        status: item.status.toUpperCase(),
                        notes: item.notes,
                        owner_name: item.owner_name,
                        action_request_name: item.action_request_name,
                        category: item.category,
                        priority: item.priority,
                        created_date: item.created_date,
                        created_by: item.created_by,
                        guide: item.guide,
                        due_date: item.due_date,
                    }));
                    setData(jsonArray);
                    // console.log('this is jsonArray[0]: ', jsonArray[0])
                    setInitialValues(jsonArray[0]);
                    setSelectOwnerField(jsonArray[0].owner_name);
                    setSelectStatusField(jsonArray[0].status);
                }
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };
    
        fetchData();
    }, []);



    // fetching users so user can reassign owner for the action request
    useEffect(() => {
        const fetchUsers = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                          "filter1": {"filter_field": "user_kind_id", 
                                "filter_op": "=",
                                "filter_value": kind_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{

                        }]
                setUsersData(jsonArray);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));
        
                setUsersData(jsonArray);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchUsers();
      }, []);





    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error.message}</div>
            // navigate('/actionrequests', {replace: true})
            // return <div className='flex flex-col justify-center items-center text-xl'>You have no customers...add a customer here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addcustomer')}>Add New Customer</Button></div>;
        }
    }




    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // convert arkeyidparams to a number
        const arkeyidparamsNumber = Number(arkeyidparams)

        // get all the values including the values in the select fields
        const allValues = {...values, status: selectStatusField, owner_name: selectOwnerField}

        const json_data = {"kind_id": kind_id, 
                            "key_id": arkeyidparamsNumber, 
                            "data": allValues};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                logChanges.map((logChanges) => {
                    saveLog(new Date().toLocaleString(), 'Activity', arkeyidparams,  allValues.action_request_name, logChanges.field, `Changed ${logChanges.field} to ${logChanges.value}`);
                })
                ToastNotify('success', 'Changes Saved')
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong')
                console.error(error);
            });

    };


    const handleStatusChange = (event) => {
        setSelectStatusField(event.target.value);
        setLogChanges([...logChanges, {field: 'status', value: event.target.value}]);
    };

    const handleOwnerChange = (event) => {
        setSelectOwnerField(event.target.value);
        setLogChanges([...logChanges, {field: 'owner_name', value: event.target.value}]);
    };


    return (
        <Box m="30px" width="90%">
            <Header title="Activity Page" subtitle="Edit Details" />

            <div className='mt-4 w-full md:w-[30%] flex flex-row justify-between'>
                <h3 className='text-xl font-bold'>Activity Name:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.action_request_name))}</p>
            </div>
            
            { initialValues.customer_name === undefined ? null :
                (
                    <div className='mt-4 w-full md:w-[30%] flex flex-row justify-between items-center'>
                        <h3 className='text-xl font-bold'>Account Name:</h3>
                        <p className='text-xl text-gray-500 cursor-pointer' onClick={(event) =>  navigate(`/account-profile/${data.map((item) => (item.customer_name))}#3`)}>{data.map((item) => (item.customer_name))}</p>
                        <ForwardOutlinedIcon
                            onClick={(event) => {navigate(`/account-profile/${data.map((item) => (item.customer_name))}`) }} 
                            sx={{fontSize: "20px", cursor: "pointer", }}
                        />
                    </div>
                )
            }
            
            <div className='mt-4 w-full md:w-[30%] flex flex-row justify-between'>
                <h3 className='text-xl font-bold'>Created Date:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.created_date.split('-')[1] + '/' + item.created_date.split('-')[2] + '/' + item.created_date.split('-')[0]))}</p>
            </div>

            { initialValues.due_date === undefined ? null :
                (
                    <div className='mt-4 w-full md:w-[30%] flex flex-row justify-between'>
                        <h3 className='text-xl font-bold'>Due Date:</h3>
                        <p className='text-xl text-gray-500'>{data.map((item) => (item.due_date.split('-')[1] + '/' + item.due_date.split('-')[2] + '/' + item.due_date.split('-')[0]))}</p>
                    </div>
                )
            }
            
            { initialValues.created_by === undefined ? null :
                (
                    <div className='mt-4 w-full md:w-[30%] flex flex-row justify-between items-center'>
                        <h3 className='text-xl font-bold'>Created By:</h3>
                        <p className='text-xl text-gray-500 cursor-pointer'>{data.map((item) => (item.created_by))}</p>
                    </div>
                )
            }
            

            <div className='flex flex-col'>
                <FormControl sx={{ marginTop: "2rem", width: {xs: "100%", md:"30%"} }}>
                    <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectStatusField}
                        label="Select Field"
                        onChange={handleStatusChange}
                    >
                        <MenuItem value="OPEN">OPEN</MenuItem>
                        <MenuItem value="WIP">WIP</MenuItem>
                        <MenuItem value="CLOSED">CLOSED</MenuItem>
                    </Select>
                    <FormHelperText>Update Status</FormHelperText>
                </FormControl>

                <FormControl sx={{ marginTop: "2rem", width: {xs: "100%", md:"30%"} }}>
                    <InputLabel id="demo-simple-select-helper-label">Assignee</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectOwnerField}
                        label="Select Field"
                        onChange={handleOwnerChange}
                    >
                        {/* { Object.entries(usersData).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

                            if (key.includes('owner_name')) {
                                return (
                                    <MenuItem value={value}>{value}</MenuItem>
                                )
                            } else {
                                return null;
                            }
                        })} */}
                        {usersData.map((item) => (
                            <MenuItem value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Reassign Assignee</FormHelperText>
                </FormControl>
            </div>
            
    
            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                sx={{
                                    marginTop: "2rem",
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                }}
                            >
                                {data.map((item, index) => (
                                    <>
                                        {Object.keys(item).sort().map((fieldName) => (
                                            fieldName.includes('action_request_name') || fieldName.includes('created_date') || fieldName.includes('notes') ||
                                            fieldName.includes('owner_name') || fieldName.includes('status') ||
                                            fieldName.includes('object_type') || fieldName.includes('kind_id') || fieldName.includes('healthscore_reasons') || 
                                            fieldName.includes('nps_score_history') || fieldName.includes('customer_img_url') || 
                                            fieldName.includes('healthscore_history') || fieldName.includes('key_id') || fieldName.includes('due_date') ||
                                            fieldName.includes('customer_name') || fieldName.includes('score_history') || fieldName.includes('created_by') ||
                                            fieldName.includes('healthscore_category') || fieldName.includes('healthscore_color') ? null :
                                            (
                                                <div>
                                                    <TextField
                                                        // fullWidth
                                                        type="text"
                                                        label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 1", width: {xs: "100%", md:"30%"} }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </>
                                ))}
                                <div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={16}
                                        type="text"
                                        label="Notes"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.notes}
                                        name="notes"
                                        error={!!touched.editedField && !!errors.editedField}
                                        helperText={touched.editedField && errors.editedField}
                                        sx={{ gridColumn: "span 1", width: {xs: "100%", md:"30%"} }}
                                    />
                                </div>
                            </Box>
                            <Box 
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                            >
                                <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                                >
                                    Save Edits
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>
            <Button
                sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
                variant="contained"
                onClick={(event) => navigate(`/account-profile/${data.map((item) => (item.customer_name))}#3`)}
            >
                Back to Account Profile
            </Button>
        </Box>
    );
}

export default ActionRequestPage;