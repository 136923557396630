import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Select, Typography, TextField } from "@mui/material";
import Header from "../../components/Header";
import CircularProgress from '@mui/material/CircularProgress';
import { Formik } from "formik";
import * as yup from "yup";
import { ToastNotify } from '../../components/ToastNotify';
import useMediaQuery from "@mui/material/useMediaQuery";




const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;


const checkoutSchema = yup.object().shape({
    user_email: yup.string().email("invalid email"),
    user_password: yup.string().min(8, "Password must be at least 8 characters")
});



const UserProfile = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // only allowing the user to edit their email login and password.  The rest of the fields are read-only.  
    // If they want to change their name, they need to get the admin to create a new user and delete the old one.
    const [initialValues, setInitialValues] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const isNonMobile = useMediaQuery("(min-width:600px)");


    // fetching only current user
    useEffect(() => {
        const fetchCurrentUser = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const user_id = fetchedUserinner.user_id  // using this variable to get user_id
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                            "filter1": {"filter_field": "user_kind_id", 
                                    "filter_op": "=",
                                    "filter_value": kind_id},
                            "filter2": {"filter_field": "user_id", 
                                    "filter_op": "=",
                                    "filter_value": user_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{

                        }]
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));
        
                setInitialValues(jsonArray[0]);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchCurrentUser();
    }, []);



    const handleFormSubmit = (values) => {
        // console.log(values);
    
        try {

            if (values.user_password === "") {
                delete values.user_password;
            }

            const NewProfileData = {...values};
        
            const key_id = "User" + values.user_id;
        
            const Updateurl = process.env.REACT_APP_API_URL + '/api/v1/update'
        
            const json_data = {"kind_id": "users", 
                                    "key_id": key_id, 
                                    "data": NewProfileData};
        
            fetch(Updateurl, {
                method: 'POST',
                body: JSON.stringify(json_data),
                headers: headers
                })
                .then(response => response.json())
                .then(result => {
                    // Handle the API response or perform any necessary actions
                    // console.log('New Customer has been created');
                    // console.log(result);
                    ToastNotify("success", "Changes Saved");
                })
                .catch(error => {
                    // Handle errors that occurred during the API call
                    console.error(error);
                    ToastNotify("error", "Something went wrong");
                });
          
        } catch (error) {
          ToastNotify("error", "Something went wrong.");
          console.log(error);
        }
        
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m='20px'>
            <Header
                title="Your Profile"
                subtitle="Edit Profile"
            />
            <Box
                className='flex flex-col w-full md:w-1/2 justify-items-start mt-16 border-[1px] border-gray-500 rounded-md p-8'
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                // convert TextField to just a label
                                disabled
                                fullWidth
                                variant='filled'
                                label="Company Name"
                                onBlur={handleBlur}
                                value={values.user_company_name}
                                name="user_company_name"
                                helperText="Registered Company"
                                sx={{ gridColumn: "span 4"}}
                            />
                            <TextField
                                disabled
                                fullWidth
                                variant="filled"
                                type="text"
                                label="User Type"
                                onBlur={handleBlur}
                                value={values.user_type}
                                name="user_type"
                                helperText="This is set by your Admin or Manager"
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                disabled
                                fullWidth
                                variant='filled'
                                type="text"
                                label="Full Name"
                                onBlur={handleBlur}
                                value={values.user_owner_name}
                                name="loginUserName"
                                helperText="This is your display name in MountX CRM, i.e. Account Owner Name.  If you want to change your name, you need to get the admin to create a new user and delete the old one."
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Login Email Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.user_email}
                                name="user_email"
                                error={!!touched.user_email && !!errors.user_email}
                                helperText="If you want to change your login email address, edit your email address here."
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Login Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.user_password}
                                name="user_password"
                                error={!!touched.user_password && !!errors.user_password}
                                helperText="If you want to change your password, edit your password here."
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button 
                                type="submit" 
                                color="secondary" 
                                variant="contained"
                            >
                                Save Changes
                            </Button>
                        </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default UserProfile;