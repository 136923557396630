import React from "react";
import { Box, Button, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from "../../components/ToastNotify";


const DeleteFieldLead = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();

    const [selectField, setSelectField] = useState('');
    const [ user, setUser ] = useState(null);
    // filtered for one lead data
    const [ leadData, setLeadData ] = useState([]);
    // for all leads data
    const [ allLeadData, setAllLeadData ] = useState([]);
    const [ error, setError] = useState(null);
    const [ isLoading, setIsLoading] = useState(true);
    const [ noLeads, setNoLeads ] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            if (checkUser === null) {
                navigate('/login', {replace: true})
            } else {
                const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

                Object.entries(userInfojsonObject).map(([key, value]) => {
                    // Perform operations with the key-value pair
                    const obj = { key, value }; // Create an object with key-value pair
                    UserjsonArray.push(obj);
                });
                setUser(UserjsonArray);
            };

            
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "lead"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    // do nothing
                    setNoLeads(true);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map(item => ({
                        ...item,
                    }));
        
                    setLeadData(jsonArray[0]);
                    setAllLeadData(jsonArray);
                }
                
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        }
        fetchData();
    }, []);


    

    const handleSubmitDeleteField = async (event, selectField) => {
        event.preventDefault();

        try {
            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            allLeadData.map((item) => {
                const json_data = {"kind_id": kind_id, 
                                    "key_id": item.key_id, 
                                    "entity_property": selectField};

                const url = process.env.REACT_APP_API_URL + '/api/v1/delete';

                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        // console.log('Lead field has been deleted for lead: ' + item.lead_name);
                        // console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                    });
            })
            ToastNotify('success', 'Lead field has been deleted');
        } catch (error) {
            setError(error.message);
            ToastNotify('error', 'Error, something went wrong');
        }

        
    };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };



    if (noLeads) {
        return <div className='flex flex-col justify-center items-center text-xl'>You have no leads and no fields...add a lead first by clicking here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addlead')}>Add New Lead</Button></div>;
    }

    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="20px">
        <Header title="Delete Field" subtitle="Delete a Lead Field" />

        <h3 className='font-bold text-2xl mt-16'>Delete a field on the Lead Object</h3>
            <div
                className="w-full md:w-1/3 h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Existing Fields</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {   
                            Object.entries(leadData).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('object_type') || key.includes('kind_id') || key.includes('key_id') || key.includes('picklists') ||
                                key.includes('lifecycle_stage') || key.includes('owner_name') || key.includes('created_date') || key.includes('customer_name') ||
                                key.includes('lead_name') || key.includes('healthscore') || key.includes('score_history') ||
                                key.includes('notes') || key.includes('amount') || key.includes('lead_id') ) {
                                    return null;
                                } else {
                                    return (
                                        <MenuItem value={key}>{formattedKey}</MenuItem>
                                    )
                                }
                            })
                        }
                    </Select>
                    <FormHelperText>Delete a Field from one of the existing Lead Fields under Lead Details Information</FormHelperText>
                </FormControl>
            </div>
            <div>
                <Button
                    sx={{marginTop: "1rem"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitDeleteField(event, selectField)}
                >
                    Delete Field
                </Button>
            </div>
        </Box>
    );
};



export default DeleteFieldLead;
