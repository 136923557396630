import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { saveLog } from "../../utils/logChanges";


const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    opportunityName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    // startDate: yup.string(),
    // email: yup.string().email("invalid email"),
    // contact: yup
    //     .string()
    //     .matches(phoneRegExp, "Phone number is not valid"),
    // address1: yup.string(),
});


const initialValues = {
    opportunityName: "",
    // startDate: "",
    // email: "",
    // contact: "",
    // address1: "",
};


const NewOpportunityPage = () => {

  const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);

  const [usersData, setUsersData] = useState([]);
  const [selectFieldOwnerName, setSelectFieldOwnerName] = useState('');  // need this to set the owner_name field in the form

  const initialPicklists = [
      { id: 1, "name": 'Stage', options: ['Forecast', 'Demo', 'Proposal/Quote', 'Negotiations', 'Closed Won', 'Lost'], selectedOption: "", "newOption": "" },
      { id: 2, "name": 'Status', options: ['New', 'WIP', 'Waiting on Customer', 'Closed'], selectedOption: "", "newOption": "" },
      { id: 3, "name": 'Next Steps', options: ['Call Lead', 'Email Lead', 'Setup Demo', 'Schedule Meeting' , 'Transfer Opportunity', 'Answer Questions', 'None'], selectedOption: "", "newOption": ""  },
      { id: 4, "name": 'Source', options: ['Website', 'Google', 'Linkedin', 'Vendor A', 'Vendor B', 'Social Media', 'Referral'], selectedOption: "", "newOption": "" },
      { id: 5, "name": 'Competitors', options: ['Competitor A', 'Competitor B', 'Competitor C'], "selectedOption": "", "newOption": "" },
  ];

  const [picklists, setPicklists] = useState(initialPicklists);
  
  const [opportunityFields, setOpportunityFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);


  // fetching users
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": "users", 
                    "filters": {
                      "filter1": {"filter_field": "user_kind_id", 
                            "filter_op": "=",
                            "filter_value": kind_id},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);

        if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{

                    }]
            setUsersData(jsonArray);
            setIsLoading(false);
        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
            id: index + 1,
            ...item,
            }));
    
            setUsersData(jsonArray);
            setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);


  // fetching picklists so the new lead can have the same picklists as the existing leads
  useEffect(() => {
    const fetchPicklists = async () => {
      
      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "opportunity"},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);
        if (jsonObject.retrieved_data === 'No result is returned') {
            // const jsonArray = [{ }]
            // setOpportunitysFields(jsonArray);

        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
            }));
    
            // get the keys or fields from the first object in the array
            const keys = Object.keys(jsonArray[0]);
            // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
            const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                            && item !== 'object_type' && item !== 'customer_name' && item !== 'id');
            setOpportunityFields(filteredKeys);

            // get the picklists array from this one opportunity to set the picklists for the new opportunity
            const picklistfromOneOpportunity = jsonArray[0].picklists;
            // set the selectedOption for each picklist to empty string
            picklistfromOneOpportunity.map((item) => {
                item.selectedOption = "";
            });

            setPicklists(picklistfromOneOpportunity);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPicklists();
  }, []);


  

  const handleFormSubmit = (values) => {
    // console.log(values);

    try {
      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      const NewOpportunityData = {}

      // add all the fields from opportunitysFields array to NewOpportunitysData since we need to add all the already created fields for existing customers with empty values
      opportunityFields.map((item) => {
        NewOpportunityData[item] = "";
      });

      // add kind_id to NewOpportunitysData
      NewOpportunityData["kind_id"] = kind_id;
      // add opportunityName to opportunity_name
      NewOpportunityData["opportunity_name"] = values.opportunityName.trim();
      NewOpportunityData["customer_name"] = "";
      NewOpportunityData["lead_source"] = "";
      NewOpportunityData["amount"] = "";
      // required customer_id field, it is kind_id + '-' + unixTimestamp
      // generate a unix timestamp to the millisecond and convert it to a string
      const unixTimestamp = new Date().getTime().toString();
      NewOpportunityData["opportunity_id"] = kind_id + '-' + unixTimestamp;
      // required object_type field
      NewOpportunityData["object_type"] = "opportunity";
      // required lifecycle_stage field
      // required created_date field
      const today = new Date().toISOString().slice(0, 10);
      NewOpportunityData["created_date"] = today
      // required notes field
      NewOpportunityData["notes"] = "";
      NewOpportunityData["picklists"] = picklists;

      // if values.accountOwner is empty, set it to loginUserName which is owner_name from above.  If it is not empty, then add it to customerData (changing accountOwner to owner_name as per the datastore schema)
      if (selectFieldOwnerName === '') {
        NewOpportunityData["owner_name"] = loginUserName;
      } else {
        NewOpportunityData["owner_name"] = selectFieldOwnerName;
      }

      const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

      const json_data = {"kind_id": kind_id, 
                              "key_id": values.opportunityName + '_opp', 
                              "data": NewOpportunityData};

      fetch(Createurl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(response => response.json())
          .then(result => {
              // Handle the API response or perform any necessary actions
              // console.log('New Customer has been created');
              // console.log(result);
              saveLog(new Date().toLocaleString(), 'Opportunity', values.opportunityName.trim(), values.opportunityName.trim(), '', `Created New Opportunity: ${values.opportunityName.trim()}`)
              ToastNotify("success", "New Opportunity has been created");
          })
          .catch(error => {
              // Handle errors that occurred during the API call
              console.error(error);
              ToastNotify("error", "Something went wrong");
          });
      
    } catch (error) {
      ToastNotify("error", "Something went wrong.");
      console.log(error);
    }
    
  };

  const handleChangeOwner = (event) => {
    const owner = event.target.value;
    setSelectFieldOwnerName(owner);
  };

  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  return (
    <Box m="30px" width="90%">
      <Header title="Create" subtitle="New Opportunity" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: {xs:"100%", md:"50%"},
                display: "grid",
                gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                gap: "30px"
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Opportunity Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.opportunityName}
                name="opportunityName"
                error={!!touched.opportunityName && !!errors.opportunityName}
                helperText={touched.opportunityName && errors.opportunityName}
                sx={{ gridColumn: "span 4" }}
              />
              <div className="col-span-4">
                <p className="text-xs text-gray-500 ">
                  The account owner is the person who is responsible for this
                  opportunity.  If you do not make a selection, the opportunity owner will be set to you.
                </p>
              </div>
              <FormControl sx={{ gridColumn: "span 4"}}>
                <InputLabel id={`select-owner-label-input`}>Owner</InputLabel>
                <Select
                    labelId={`select-owner-label-selectid`}
                    id={`select-owner-select-id`}
                    value={selectFieldOwnerName}
                    label="Select Owner"
                    onChange={(event) => handleChangeOwner(event)}
                >
                    {usersData.map((item) => (
                            // const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                <MenuItem key={item.user_owner_name} value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>Select an Owner for this Opportunity</FormHelperText>
            </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="start" mt="40px">
              <Button
                sx={{ width: "200px" }}
                color="secondary" 
                variant="contained"
                onClick={(event) => handleFormSubmit(values)}
              >
                Add New Opportunity
              </Button>
              <div className="mt-8 w-[150px]">
                <p className="text-xs text-gray-500 ">
                  You may add opportunity fields in the opportunity details page after the opportunity has been created.
                </p>
              </div>
            </Box>
          </form>
        )}
      </Formik>

      {/* <div className="mt-24">
        <h2 className="text-md font-bold">Looking to mass add mutliple opportunitys? Click below.</h2>
        <Button
          sx={{ width: "200px", marginTop: "2rem"}}
          color="secondary" 
          variant="contained"
          onClick={(event) => navigate('/addmultipleopportunitys')}
        >
          Add Multiple Opportunitys
        </Button>
      </div> */}
    </Box>
  );
};



export default NewOpportunityPage;
