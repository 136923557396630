import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { HiX } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';


const HomeTopBar = () => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div
            className='flex flex-col justify-center items-center top-0 left-0 w-full h-auto xl:relative'
        >
            <div 
                className='flex flex-row justify-center items-center gap-8 xl:gap-[4rem] xl:pb-4 top-0 fixed p-[3px] w-full h-[2.5rem] text-[18px] z-30 backdrop-blur-sm'
            >
                <div 
                    className='mt-[1rem] cursor-pointer hidden xl:block justify-start text-black hover:text-gray-400 transition'
                >
                    <p 
                        onClick={() => {
                            navigate('/');
                        }}
                        className='mt-[1rem] mb-[1rem]'
                    >
                        MountX CRM
                    </p>
                </div>

                <div 
                    className='cursor-pointer hidden xl:block xl:ml-[8rem] text-black hover:text-gray-400 transition'
                >
                    <p 
                        onClick={() => navigate('/features')}
                        className=' mt-[1rem] mr-[1rem]'
                    >
                        Features
                    </p>
                </div>
                <div
                    className='cursor-pointer hidden xl:block xl:ml-[2rem] text-black hover:text-gray-400 transition'
                >
                    <p
                        onClick={() => navigate('/FAQ')}
                        className='mt-[1rem] mr-[1rem]'
                    >
                        FAQ
                    </p>
                </div>
                <div 
                    className='cursor-pointer xl:ml-[2rem] text-black hover:text-gray-400 transition'
                >
                    <p
                        onClick={() => navigate('/contact/sales')}
                        className='mt-[1rem] mr-[1rem]'
                    >
                        Sales
                    </p>
                </div>
                <div 
                    className='cursor-pointer xl:ml-[2rem] text-black hover:text-gray-400 transition'
                >
                    <p
                        onClick={() => navigate('/contact/support')}
                        className='mt-[1rem] mr-[1rem]'
                    >
                        Support
                    </p>
                </div>
                <div 
                    className='cursor-pointer mt-[1rem] xl:ml-[16rem] text-black hover:text-gray-400 transition'
                    onClick={() => navigate('/register')}
                >
                    Register
                </div>

                <div className='cursor-pointer mt-[1rem] xl:ml-[1rem] z-30 text-black hover:text-gray-400 transition'
                    onClick={() => navigate('/login')}
                >
                    Login
                </div>
                {/* <div style={{
                    cursor: 'pointer',
                    marginLeft: '2rem',
                    marginRight: '2em',
                    }}
                >
                    <p onClick={() => setIsOpen(true)}>
                        Menu
                    </p> */}

                    {/* {isOpen && (
                        <motion.div
                            transition={{
                                duration: 0.01,
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                }}
                            whileInView={{ x: [300, 0] }}
                            style={{ 
                                position: 'absolute',
                                top: '0',
                                bottom: '0',
                                right: '0',
                                zIndex: '5',
                                padding: '1rem',
                                width: '300px',
                                height: '100vh',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                flexDirection: 'column',
                                background: 'white',
                                backgroundColor: 'white',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'repeat',
                            }}
                        > */}
                            {/* <Toggle onClick={() => setIsOpen(isOpen => !isOpen)} /> */}
                            {/* <HiX
                                onClick={() => setIsOpen(false)}
                                style={{
                                    marginTop: '1rem',
                                    width: '35px',
                                    height: '35px',
                                    color: 'black',
                                    margin: '0.5rem 1rem',
                                    cursor: 'pointer',
                                }}
                            />
                            
                            <ul
                                className='
                                    list-none
                                    m-0
                                    p-0
                                    w-full
                                    h-full
                                    flex
                                    flex-col
                                    flex-start

                                '
                            >
                                <li className='mb-2'>
                                    <a href={`/account`}>My Account</a>
                                </li>
                                <li className='mb-2'>
                                    <a href={`/treatments`}>My Treatments</a>
                                </li>
                                <li className='mb-2'>
                                    <a href={`/reviews`}>My Reviews</a>
                                </li>
                                <li className='mb-2'>
                                <a href={`/messages`}>My Messages</a>
                                </li> */}

                                {/* {(  // couldn't use !currentUser && before the parenthesis
                                    <>
                                        <div 
                                            className="mb-2 cursor-pointer sm:block"
                                            onClick={() => navigate('/login')}
                                        >
                                            Login
                                        </div>
                                        <div 
                                            className="mb-2 cursor-pointer sm:block"
                                            onClick={() => navigate('/register')}
                                        >
                                            Register
                                        </div>
                                    </>
                                )}

                                <li className='mb-2' onClick={() => setIsOpen(false)}>
                                    Close Menu
                                </li>
                            </ul>
                        </motion.div>
                    )} */}
                {/* </div> */}
            </div>
        </div>
        // end of top bar
    )
}

export default HomeTopBar;