import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Popover,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    TextField
  } from '@mui/material';

import { saveUserSelectedColumns } from '../../utils/saveColumns';

import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Slider from '@mui/material/Slider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LineChart from '../../components/LineChart';
import NpsComponent from '../../components/NpsComponent';


import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CustomerDetails from '../../components/CustomerDetails';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import { saveLog } from '../../utils/logChanges';
import SalesData from '../../components/SalesData';


const Customerprofile = () => {

    const { customername } = useParams();

    // const custNameLower = [HSLineChartdata.find(series => series.id === customername.toLowerCase())];

    const [data, setData] = useState([]);
    const [editedData, setEditedData] = useState([]);
    const [HSData, setHSData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [ARdata, setARData] = useState([]);
    const [ARcount, setARCount] = useState(0);
    const [opportunityData, setOpportunityData] = useState([]);
    const [salesData, setSalesData] = useState([]);

    // for notes tab
    const [initialValues, setInitialValues] = useState([]); 

    const [logs, setLogs] = useState([]);
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    // for users, to reassign ownership on customer and action requests
    const [usersData, setUsersData] = useState([]);

    // for opportunities and opportunity columns
    const [selectedColumns, setSelectedColumns] = useState(['id', 'customer_name', 'created_date', 'owner_name', 'opportunity_name']); // Add default columns here
    const [AllPossibleColumns, setAllPossibleColumns] = useState([]); // Add all possible columns here

    const [anchorEl, setAnchorEl] = useState(null);

    const [columnNumbers, setColumnNumbers] = useState(
        AllPossibleColumns.reduce((acc, column) => {
          acc[column.field] = 0; // Default numbers for each column
          return acc;
        }, {})
    );

    // for tab panels
    const [value, setValue] = useState(0);

    // for trending healthscores line chart
    const [hsHistoryData, setHsHistoryData] = useState([]);

    // for trending NPS line chart and Pie chart
    const [NPSHistoryData, setNPSHistoryData] = useState([]);
    // for NPS score
    const [NPSscoreData, setNPSscoreData] = useState(0);

    // for filenames to list files from gcp bucket
    const [files, setFiles] = useState([]);

    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileDownloadLoading, setFileDownloadLoading] = useState(false);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            ToastNotify('error', 'Please select a file first!');
            return;
        }

        setFileUploadLoading(true);


        try {
            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const filename = file.name;

            const formData = new FormData();
            formData.append('file_name', filename);
            formData.append('kind_id', kind_id);
            formData.append('object_folder_name', customername);

            const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/getsignedurl';

            // const url = 'http://127.0.0.1:5000/api/v1/getsignedurl';

            // Setup headers
            const fileheaders = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
            });

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: fileheaders,
                // You may need to include headers depending on your API requirements, such as authentication tokens
                // Do not set 'Content-Type' header when uploading FormData; the browser will set it with the proper 'boundary'
            });

            const results = await response.json();

            const bucketUrl = results.url;

            // console.log('this is bucketUrl: ' + bucketUrl)

            const uploadResponse = await fetch(bucketUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            });

            // console.log('this is upload response: ' + uploadResponse)

            if (!uploadResponse.ok) {
                setFileUploadLoading(false);
                console.log('Failed to upload file');
                ToastNotify('error', 'Failed to upload file');
            } else {
                setFileUploadLoading(false);
                ToastNotify('success', 'File uploaded successfully!');
            }

        } catch (error) {
            setFileUploadLoading(false);
            console.error('Error during upload:', error);
            ToastNotify('error', 'Error during upload.');
        }
    };



    // This function is to upload files to gcp bucket
    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //         ToastNotify('error', 'Please select a file first!');
    //     return;
    //     }

    //     const checkUser = localStorage.getItem('user');

    //     const fetchedUserString = JSON.stringify(checkUser); 
    //     const fetchedUserObject = JSON.parse(fetchedUserString);
    //     const fetchedUserinner = JSON.parse(fetchedUserObject);
    //     const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    //     const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    //     const kind_id = fetchedUserinner.user_kind_id  

    //     const username = process.env.REACT_APP_API_USERNAME;
    //     const password = process.env.REACT_APP_API_PASSWORD;

    //     const encodedCredentials = window.btoa(`${username}:${password}`);

    //     const headers = new Headers({
    //         'Authorization': `Basic ${encodedCredentials}`,
    //         'Content-Type': 'application/json'
    //         });

    //     // Prepare the file to be sent in a FormData object for easy processing on the server side
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('kind_id', kind_id);
    //     formData.append('object_folder_name', customername);

    //     try {
    //     const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/uploadfiletobucket';


    //     // Setup headers
    //     const fileheaders = new Headers({
    //     'Authorization': `Basic ${encodedCredentials}`,
    //     });

    //     const response = await fetch(url, {
    //         method: 'POST',
    //         body: formData,
    //         headers: fileheaders,
    //         // You may need to include headers depending on your API requirements, such as authentication tokens
    //         // Do not set 'Content-Type' header when uploading FormData; the browser will set it with the proper 'boundary'
    //     });

    //     if (response.ok) {
    //         // Handle success, such as showing a message to the user
    //         // console.log('File uploaded successfully');
    //         ToastNotify('success', `File uploaded successfully!`);
    //     } else {
    //         // Handle server errors or invalid responses
    //         console.error('Upload failed');
    //         ToastNotify('error', 'Error, something went wrong.');
    //     }
    //     } catch (error) {
    //     // Handle network errors or other issues
    //     console.error('Error during upload:', error);
    //     }
    // };


    // function to download files from gcp bucket
    const handleDownload = async (fileName) => {
        setFileDownloadLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // kind_id is the bucket name
        // bucket folder is the customername
        const editedCustomerName = customername.toLowerCase().replace(/ /g, '_');
        const filepath = editedCustomerName + '/' + fileName;

        const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/getdownloadurlfrombucket';

        const json_data = {
            "fileName": filepath,
            "bucketName": kind_id
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(json_data),
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();
            const downloadUrl = data.url;

            // Create an anchor element and simulate a click to download the file
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); // Set the file name for download
            document.body.appendChild(link);
            link.click();
            link.remove();

            setFileDownloadLoading(false);
        } catch (error) {
            setFileDownloadLoading(false);
            console.error('Download failed:', error);
            ToastNotify('Error', 'Error, could not download file. Something went wrong.');
        }

        // const url = 'http://127.0.0.1:5000/api/v1/downloadfilefrombucket';

        // const json_data = {
        //     "fileName": filepath,
        //     "bucketName": kind_id
        // };

        // try {
        //     const response = await fetch(url, {
        //         method: 'POST',
        //         headers: headers,
        //         body: JSON.stringify(json_data),
        //     });

        //     if (!response.ok) throw new Error('Network response was not ok');

        //     const blob = await response.blob(); // Convert the response to a Blob
        //     const downloadUrl = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = downloadUrl;
        //     link.setAttribute('download', fileName); // Set the file name for download
        //     document.body.appendChild(link);
        //     link.click();

        //     link.remove(); // Clean up and remove the link
        //     window.URL.revokeObjectURL(downloadUrl); // Clean up and revoke the blob URL
        //     setFileDownloadLoading(false);
        // } catch (error) {
        //     setFileDownloadLoading(false);
        //     console.error('Download failed:', error);
        //     ToastNotify('error', 'Error, could not download file. Something went wrong.');
        // }

          
    };
    



    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        setIsLoading(true);
        if (customername === 'Example Customer 1') {
            navigate('/customers', {replace: true})
        }
    }, [customername]);


    // default stages
    const marks = [
        {
            value: 0,
            label: 'New',
        },
        {
            value: 5,
            label: 'Onboarding',
        },
        {
            value: 10,
            label: 'Use',
        },
        {
            value: 15,
            label: 'Engage',
        },
        {
            value: 20,
            label: 'Optimize',
        },
        {
            value: 25,
            label: 'Renew',
        },
    ];

    const [customStages, setCustomStages] = useState(marks);

    // slider values when changed
    const [sliderValue, setSliderValue] = useState('');

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        // console.log('this is the new sliderValue: ' + newValue)
    };


    const saveSliderStageChange = () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // don't need this because on this page, we only have one customer and the customer data is in the array 'data' and not 'customers' array like in lifecycle page
        // in the customers array, find the customer_name that matches the customer_name in the url
        // const filteredCust = customers.filter(customer => customer.customer_name === customerName);

        // console.log('this is filteredCust: ')
        // console.log(filteredCust)

        data[0].lifecycle_stage = getTextStageValue(sliderValue, customStages);

        // console.log('this is data[0].lifecycle_stage: ')
        // console.log(data[0].lifecycle_stage)

        const json_data = {"kind_id": kind_id, 
                            "key_id": customername, 
                            "data": data[0]};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                saveLog(new Date().toLocaleString(), 'Account', customername,  customername, 'Stage', `Changed Stage to ${data[0].lifecycle_stage}`);
                ToastNotify('success', 'Changes Saved');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong');
                console.error(error);
            });
    };

    
    function valuetext(value) {
        return `${value}`;
    };

    function valueLabelFormat(value) {
        return customStages.findIndex((mark) => mark.value === value) + 1;
    };



    // fetching customer data
    useEffect(() => {

        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
        
        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "key_id": customername
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })

            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString); 
            const jsonArray = jsonObject.retrieved_data.map(item => ({
                ...item,
            }));

            setData(jsonArray);

            const initialValuesForNotes = {"notes": jsonArray[0].notes}

            setInitialValues(initialValuesForNotes);

            // to access a field in the jsonArray object from above, use jsonArray[0].field_name
            // console.log('This is the jsonArray customer_id: ')
            // console.log(jsonArray[0].customer_id)
            // setCustomeridData(jsonArray[0].customer_id);


            // to set the healthscore score_history state for the trend line chart
            // Assuming there is only one element in the array, we can extract the score_history field for the line chart

            // !! there should always be a score_history b/c when a customer gets created, it creates a score_history field inside the customer object !!
            const jsonString = jsonArray[0].score_history;
            
            // check if jsonString is actually an array, if it is an array, then convert it to a string object
            if (Array.isArray(jsonString)) {
                const stringifiedJsonString = JSON.stringify(jsonString);
                const parsedArray = JSON.parse(stringifiedJsonString);
                const transformedArray = parsedArray.map(item => ({
                    x: item.x,
                    y: parseInt(item.y, 10) // Convert 'y' to an integer
                }));
                const HShistoryArray = [
                    {
                        id: customername,
                        color: colors.greenAccent[500],
                        data: transformedArray,
                    },
                ];
                setHsHistoryData(HShistoryArray);
            } else {
                 // Parse the JSON string into a JavaScript array
                const parsedArray = JSON.parse(jsonString);
                
                // If you want to transform the array to the desired format
                const transformedArray = parsedArray.map(item => ({
                    x: item.x,
                    y: parseInt(item.y, 10) // Convert 'y' to an integer
                }));
                
                // console.log('this is transformedArray:')
                // console.log(transformedArray);
        
                // console.log('this is CustHSLinedata:')
                // console.log(CustHSLinedata)
        
                // final array for line chart
                const HShistoryArray = [
                    {
                        id: customername,
                        color: colors.greenAccent[500],
                        data: transformedArray,
                    },
                ];
        
                setHsHistoryData(HShistoryArray);
            }


        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
        };

        fetchData();
    }, []);



    // fetching contacts
    useEffect(() => {
        const fetchContactData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "contact"},
                            "filter2": {"filter_field": "contact_customer_name", 
                                    "filter_op": "=",
                                    "filter_value": customername},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    return null;
                    setIsLoading(false);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        key_id: item.key_id,
                        contact_name: item.contact_name,
                        email: item.contact_email,
                        phone: item.contact_phone,
                        address: item.contact_address,
                        city: item.contact_city,
                        state: item.contact_state,
                        zipcode: item.contact_zipcode,
                        country: item.contact_country,
                        contact_type: item.contact_type,
                        title: item.contact_title,
                        notes: item.contact_notes
                        }));

                        setContactData(jsonArray);
                }
                
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
            };
    
        fetchContactData();
    }, []);



    // fetching action requests
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try { 
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'

                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "cta"},
                        "filter2": {"filter_field": "customer_name", 
                            "filter_op": "=",
                            "filter_value": customername},
                        } 
                    };
                
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                key_id: '0000000',
                                customer_name: 'Example Customer 1',
                                status: 'OPEN',
                                status2: 'OPEN',
                                notes: '',
                                owner_name: 'Example Owner 1',
                                action_request_name: 'Example Action Request 1',
                                category: 'Example Category 1',
                                priority: 'High',
                                created_date: '2023-10-01',
                                created_by: 'Example Creator 1',
                                due_date: '2023-10-01',
                                guide: 'Example Guide 1: Reach out to Customer via email.'
                            }]
                    setARData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    key_id: item.key_id,
                    customer_name: item.customer_name,
                    status: item.status.toUpperCase(),
                    status2: item.status.toUpperCase(),
                    notes: item.notes,
                    owner_name: item.owner_name,
                    action_request_name: item.action_request_name,
                    category: item.category,
                    priority: item.priority,
                    created_date: item.created_date,
                    created_by: item.created_by,
                    due_date: item.due_date,
                    guide: item.guide
                    }));
            
                    setARData(jsonArray);

                    // in jsonArray count the number of action requests that are open
                    const openAR = jsonArray.filter(ar => ar.status.toUpperCase() === 'OPEN');
                    const openARcount = openAR.length;
                    setARCount(openARcount);
                }
            } catch (error) {
                setError(error.message);
            }
        

        setIsLoading(false);
        };
    
        fetchData();
    }, []);




    // fetching customers data which also contains NPS score and nps_score_history for trending NPS line chart and Pie chart
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                        "filter2": {"filter_field": "customer_name", 
                            "filter_op": "=",
                            "filter_value": customername},
                    } 
                    };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                
                const result = await response.json();
                // console.log('this is result: ')
                // console.log(result)
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString); 
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const transformedArray = [{
                        x: new Date().toISOString().slice(0, 10),
                        y: 0
                    }];
                    // final array for line chart
                    const NPShistoryArray = [
                            {
                                id: customername,
                                color: colors.greenAccent[500],
                                data: transformedArray,
                            },
                        ];
                
                    setNPSHistoryData(NPShistoryArray);
                    setNPSscoreData(0);
                } else {

                    // check if jsonObject.retrieved_data contains a nps_score_history field
                    if (jsonObject.retrieved_data[0].nps_score_history === undefined) {
                        const transformedArray = [{
                            x: new Date().toISOString().slice(0, 10),
                            y: 0
                        }];
                        // final array for line chart
                        const NPShistoryArray = [
                                {
                                    id: customername,
                                    color: colors.greenAccent[500],
                                    data: transformedArray,
                                },
                            ];
                    
                        setNPSHistoryData(NPShistoryArray);
                        setNPSscoreData(0);
                    } else {
                        const jsonArray = jsonObject.retrieved_data.map(item => ({
                            nps_score_history: item.nps_score_history,
                            nps_score: item.nps_score,
                        }));

                        // console.log('this is jsonArray:')
                        // console.log(jsonArray)
                
                        // const originalArray = [
                        //   { score_history_test: '[{"x":"2023-01", "y":"100"}, {"x":"2023-02", "y":"90"}, {"x":"2023-03", "y":"70"}]' }
                        // ];
                        
                        // Assuming there is only one element in the array, you can access it directly
                        const jsonString = jsonArray[0].nps_score_history;
                        
                        // Parse the JSON string into a JavaScript array
                        const parsedArray = JSON.parse(jsonString);
                        
                        // If you want to transform the array to the desired format
                        const transformedArray = parsedArray.map(item => ({
                            x: item.x,
                            y: parseInt(item.y, 10) // Convert 'y' to an integer
                        }));
                        
                        // console.log('this is transformedArray:')
                        // console.log(transformedArray);
                
                        // console.log('this is CustHSLinedata:')
                        // console.log(CustHSLinedata)
                
                        // final array for line chart
                        const NPShistoryArray = [
                            {
                                id: customername,
                                color: colors.greenAccent[500],
                                data: transformedArray,
                            },
                        ];
                
                        // console.log('this is NPShistoryArray:')
                        // console.log(NPShistoryArray)
        
                        setNPSHistoryData(NPShistoryArray);
        
                        const nps_score = jsonArray[0].nps_score;
        
                        // console.log('this is nps_score:')
                        // console.log(nps_score)
        
                        setNPSscoreData(nps_score);
                    }
                }
        
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };
    
        fetchData();
    }, []);



    // fetching users
    useEffect(() => {
        const fetchUsers = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                          "filter1": {"filter_field": "user_kind_id", 
                                "filter_op": "=",
                                "filter_value": kind_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{

                        }]
                setUsersData(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));
        
                setUsersData(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchUsers();
    }, []);


    // fetching lifecycle stages if admin created custom stages
    useEffect(() => {
        const fetchLifecycleStages = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "lifecycle_stages"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                // do nothing
                return null
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));
        
                const stagesArray = jsonArray["0"].stages

                // console.log('this is stagesArray: ')
                // console.log(stagesArray)

                setCustomStages(stagesArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchLifecycleStages();
    }, []);



    // fetching files from gcp bucket and folder for the customer
    useEffect(() => {
        const fetchFiles = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listfilesfrombucket';
                const json_data = {
                            "bucketName": kind_id,
                            "folderName": customername.toLowerCase().replace(/ /g, '_') + "/"
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(json_data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject === '') {
                    return null;
                    setIsLoading(false);
                } else {

                    setFiles(jsonObject);
                }
                
            } catch (error) {
                console.log('error or no files in bucket');
                // console.log(error);
            }
            setIsLoading(false);
            };
    
        fetchFiles();
    }, [customername]);



    // for loading tab panels
    useEffect(() => {
        // Read the hash from the URL
        const hash = window.location.hash.substring(1);
        
        // Set the tab value based on the hash
        if (hash) {
          const tabIndex = parseInt(hash, 10);
          if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < 7) {
            setValue(tabIndex);
          }
        }
    }, []);



    
    // fetching logs or a.k.a. Changes Timeline or a.k.a. notifications data
    useEffect(() => {
        const fetchLogsData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        try { 
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'

            const data = {
                "kind_id": "logs", 
                "filters": {
                    "filter1": {"filter_field": "kind_id", 
                        "filter_op": "=",
                        "filter_value": kind_id},
                    "filter2": {"filter_field": "crm_object", 
                        "filter_op": "=",
                        "filter_value": "Account"},
                    "filter3": {"filter_field": "name", 
                        "filter_op": "=",
                        "filter_value": customername},
                    } 
                };
            
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            
            if (jsonObject.retrieved_data === 'No result is returned') {
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                }));

                // sort jsonArray based on date_time field descending
                jsonArray.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
        
                setLogs(jsonArray);
            }
        } catch (error) {
            setError(error.message);
        }

          setIsLoading(false);
        };
    
        fetchLogsData();
    }, []);



    // fetching user selected Opportunity columns and column order from datastore if it exists
    useEffect(() => {
        const fetchColumns = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });


            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "columns"},
                            "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            "filter3": {"filter_field": "page", 
                                    "filter_op": "=",
                                    "filter_value": "opportunities"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    return null;
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index,
                        ...item,
                    }));
                    setSelectedColumns(jsonArray[0]["selected_columns"]);
                    setColumnNumbers(jsonArray[0]["column_order"]);
                }

            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchColumns();
    }, []);



    // fetching both opportunity data, also adds all possible columns to be displayed for opportunities column selection
    useEffect(() => {
        const fetchOpportunityData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });


            // fetching opportunity data
            try {
                    // Make an API request to retrieve data from the Google Cloud Datastore
                    const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                    const data = {
                                "kind_id": kind_id, 
                                "filters": {
                                    "filter1": {"filter_field": "object_type", 
                                        "filter_op": "=",
                                        "filter_value": "opportunity"},
                                    "filter2": {"filter_field": "customer_name", 
                                        "filter_op": "=",
                                        "filter_value": customername},
                                } 
                                };
                    const response = await fetch(url, {
                                    method: 'POST',
                                    headers: headers,
                                    body: JSON.stringify(data)
                                })
            
                    const result = await response.json();
                    const myJsonString = JSON.stringify(result);
                    const jsonObject = JSON.parse(myJsonString);
                    if (jsonObject.retrieved_data === 'No result is returned') {
                        const today = new Date();
                        const todaysDate = today.toISOString().split('T')[0];
                        const jsonArray = [{
                                    id: 1,
                                    opportunity_name: 'Example Opportunity 1',
                                    company_name: 'Example Company 1',
                                    owner_name: 'Opportunity Owner 1',
                                    opportunity_stage: 'Prospect',
                                    next_steps: 'Follow up with the opportunity',
                                    hierarchy: 'Example Hierarchy 1',
                                    interests: 'Medical Equipment',
                                    competitors: 'Example Competitor 1',
                                    source: 'Google Ads',
                                    industry: 'Example Industry',
                                    specialty: 'Example Specialty',
                                    revenue: '$1,500,000',
                                    employees: '15',
                                    address: '123 Example Street',
                                    phone_number: '123-456-7890',
                                    created_date: todaysDate,
                                }]
                        setOpportunityData(jsonArray);

                        // get the keys from the first object in the array
                        const keys = Object.keys(jsonArray[0]);
                        // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                        const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                        && item !== 'object_type');
                        // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                        const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                        setAllPossibleColumns(formattedKeys);

                    } else {
                        const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                            id: index + 1,
                            ...item,
                        }));
                
                        setOpportunityData(jsonArray);

                        // to update all the possible columns state
                        const keys = Object.keys(jsonArray[0]);
                        // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                        const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                        && item !== 'object_type' && item !== 'healthscore_reasons');
                        // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                        const formattedColumnsArray = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));

                        setAllPossibleColumns(formattedColumnsArray);
                        setIsLoading(false);
                    }
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };
    
        fetchOpportunityData();
    }, [customername]);



      // fetching Sales so the user can filter on specific customers and products to view
      useEffect(() => {
        const fetchSalesData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "sale"},
                                "filter2": {"filter_field": "customer_name", 
                                        "filter_op": "=",
                                        "filter_value": customername},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setIsLoading(false);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));
            
                    setSalesData(jsonArray);
                    setIsLoading(false);
                    
                }
            } catch (error) {
                setError(error.message);
            }

        };

        fetchSalesData();
    }, []);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } if (error === 'jsonObject.retrieved_data.map is not a function') {
            return <div className='mt-24 flex flex-col justify-center items-center'><h2 className='text-lg font-bold'>{`No customer named '${customername}'`}</h2></div>;
        } else {
            window.location.reload();
            // return <div>Error: {error}</div>;
        }
    }




    const ARhandleInputChange = (index, field, value) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
        const indexMinusOne = index - 1;

        const firstObject = ARdata[indexMinusOne];
        const cta_keyid__ = firstObject.key_id;

        ARdata[indexMinusOne][field] = value;

        const { id, status2, ...arrayWithoutSpecificKeys } = ARdata[indexMinusOne];


        const json_data = {"kind_id": kind_id, 
                            "key_id": cta_keyid__, 
                            "data": arrayWithoutSpecificKeys
                        };

    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                ToastNotify('success', 'Changes Saved');
                navigate(`/account-profile/${customername}#4`, {replace: true})
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong');
                console.error(error);
            });
    };


    const handleInputChangeOpportunities = (index, field, value) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
        const indexMinusOne = index - 1;

        const customersObject = data[indexMinusOne];
        const key_id__ = customersObject.key_id;  // all data retrieved from datastore shoujld have the field key_id as the API add its into the response

        data[indexMinusOne][field] = value;

        const { id, ...arrayWithoutSpecificKeys } = data[indexMinusOne];


        const json_data = {"kind_id": kind_id, 
                            "key_id": key_id__, 
                            "data": arrayWithoutSpecificKeys
                        };
    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Changes Saved: ')
                // console.log(result);
                // ToastNotify('success', 'Changes Saved');
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Error, something went wrong.');
                console.error(error);
            });
    };


    

    // for tab panels
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    // for tab panels
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // for tab panels
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
      };


    
    
    function getStageValue(stage, customStages) {

        // only prints out if there's a 7th stage
        // console.log('this is customStages: ' + customStages[6].label)

        if (customStages[0] !== undefined) {
            if (stage === customStages[0].label) {
                return 0
            }
        }
        if (customStages[1] !== undefined) {
            if (stage === customStages[1].label) {
                return 5
            }
        }
        if (customStages[2] !== undefined) {
            if (stage === customStages[2].label) {
                return 10
            }
        }
        if (customStages[3] !== undefined) {
            if (stage === customStages[3].label) {
                return 15
            }
        }
        if (customStages[4] !== undefined) {
            if (stage === customStages[4].label) {
                return 20
            }
        }
        if (customStages[5] !== undefined) {
        if (stage === customStages[5].label) {
            return 25
        }
        }
        if (customStages[6] !== undefined) {
            if (stage === customStages[6].label) {
                return 30
            }
        }
        if (customStages[7] !== undefined) {
            if (stage === customStages[7].label) {
                return 35
            }
        }
        if (customStages[8] !== undefined) {
            if (stage === customStages[8].label) {
                return 40
            }
        }
        if (customStages[9] !== undefined) {
            if (stage === customStages[9].label) {
                return 45
            }
        }
        if (customStages[10] !== undefined) {
            if (stage === customStages[10].label) {
                return 50
            }
        }
        if (customStages[11] !== undefined) {
            if (stage === customStages[11].label) {
                return 55
            }
        }
        if (customStages[12] !== undefined) {
            if (stage === customStages[12].label) {
                return 60
            }
        }
        if (customStages[13] !== undefined) {
            if (stage === customStages[13].label) {
                return 65
            }
        }
        if (customStages[14] !== undefined) {
            if (stage === customStages[14].label) {
                return 70
            }
        }
        if (customStages[15] !== undefined) {
            if (stage === customStages[15].label) {
                return 75
            }
        }
        if (customStages[16] !== undefined) {
            if (stage === customStages[16].label) {
                return 80
            }
        }
        if (customStages[17] !== undefined) {
            if (stage === customStages[17].label) {
                return 85
            }
        }
        if (customStages[18] !== undefined) {
            if (stage === customStages[18].label) {
                return 90
            }
        }
        if (customStages[19] !== undefined) {
            if (stage === customStages[19].label) {
                return 95
            }
        }
    };


    function getTextStageValue(stage, customStages) {
        if (stage === 0) {
            return customStages[0].label
        }
        if (stage === 5) {
            return customStages[1].label
        }
        if (stage === 10) {
            return customStages[2].label
        }
        if (stage === 15) {
            return customStages[3].label
        }
        if (stage === 20) {
            return customStages[4].label
        }
        if (stage === 25) {
            return customStages[5].label
        }
        if (stage === 30) {
            return customStages[6].label
        }
        if (stage === 35) {
            return customStages[7].label
        }
        if (stage === 40) {
            return customStages[8].label
        }
        if (stage === 45) {
            return customStages[9].label
        }
        if (stage === 50) {
            return customStages[10].label
        }
        if (stage === 55) {
            return customStages[11].label
        }
        if (stage === 60) {
            return customStages[12].label
        }
        if (stage === 65) {
            return customStages[13].label
        }
        if (stage === 70) {
            return customStages[14].label
        }
        if (stage === 75) {
            return customStages[15].label
        }
        if (stage === 80) {
            return customStages[16].label
        }
        if (stage === 85) {
            return customStages[17].label
        }
        if (stage === 90) {
            return customStages[18].label
        }
        if (stage === 95) {
            return customStages[19].label
        }
    };
    

    function valuetext(value) {
    return `${value}`;
    }

    function valueLabelFormat(value) {
        return customStages.findIndex((mark) => mark.value === value) + 1;
    };




    const contactColumns = [
        { field: "id", headerName: "ID"},
        // { field: "contact_keyid", headerName: "Contact ID"}, 
        { 
        field: "contact_name", 
        headerName: "Name", 
        flex: 1,
        textDecoration: "none",
        color: "inherit",
        cellClassName: "name-column--cell",
        renderCell: (params) => {
            const cellValue = params.value;
            return (
            <Link 
                to={`/contact-details/${cellValue}`}
                style={{ textDecoration: 'none', color: "inherit" }}
            >
                {cellValue}
            </Link>
            );
        },
        },
        {
        field: "contact_type",
        headerName: "Contact Type",
        flex: 1
        },
        {
        field: "title",
        headerName: "Title",
        flex: 1
        },
        {
        field: "email",
        headerName: "Email",
        flex: 1
        },
        { 
        field: "phone", 
        headerName: "Phone Number",
        flex: 1,
        },
        { 
        field: "address", 
        headerName: "Address",
        flex: 1,
        },
        { 
        field: "city", 
        headerName: "City", 
        flex: 1,
        },
        { 
        field: "state", 
        headerName: "State", 
        flex: 1,
        },
        { 
        field: "zipcode", 
        headerName: "Zip Code",
        },
        { 
        field: "country", 
        headerName: "Country", 
        flex: 1,
        },
        { 
        field: "notes", 
        headerName: "Notes", 
        flex: 1,
        },
    ]



    const ARcolumns = [
        { field: "id", headerName: "ID"}, 
        { 
            field: "customer_name", 
            headerName: "Customer Name", 
            flex: 1,
            textDecoration: "none",
        },
        {
            field: "action_request_name",
            headerName: "Activity Name",
            flex: 1,
            textDecoration: "none",
            color: "inherit",
            cellClassName: "name-column--cell",
            renderCell: ({ row: { key_id, action_request_name }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {action_request_name}
                    </Link>
                )
            }
        },
        {
            field: "status",
            headerName: "Edit Status",
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <span style={{width: '75px'}}>{params.value}</span>
                <ModeEditIcon style={{ marginLeft: "5px", }} />
                </div>
            ),
            renderEditCell: (params) => {
                return (
                    <Select
                        value={params.value}
                        onChange={(event) =>
                            ARhandleInputChange(
                                params.id,
                                params.field,
                                event.target.value
                            )
                        }
                        style={{ height: "30px", width: "150px", fontSize: "12px" }}
                    >
                        <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                        <MenuItem value={"WIP"}>WIP</MenuItem>
                        <MenuItem value={"OPEN"}>OPEN</MenuItem>
                    </Select>
                );
            },
            renderHeader: () => (
                <Box display="flex" alignItems="center">
                    <span>Edit Status</span>
                    {/* <ModeEditIcon style={{ marginLeft: "8px" }} /> */}
                </Box>
                ),
        },
        { 
        field: "status2", 
        headerName: "Status Indicator", 
        flex: 1,
        justifyContent: "flex-start",
        renderCell: ({ row: { status }}) => {
            return (
            <Box
                width="100%"
                m="0 auto"
                p="5px"
                display="flex"
                justifyContent="flex-start"
                backgroundColor = {
                status.toLowerCase() == "closed"
                    ? green[100] :
                    status.toLowerCase() == "wip"
                    ? yellow[100] :
                    status.toLowerCase() == "open"
                    ? red[100]
                    : red[100]
                }
                borderRadius="4px"
            >
                {status.toLowerCase() == "open" && <LockOpenOutlinedIcon />}
                {status.toLowerCase() == "wip" && <SecurityOutlinedIcon />}
                {status.toLowerCase() == "closed" && <AdminPanelSettingsOutlinedIcon />}
                <Typography color={grey} sx={{ ml: "5px" }}>
                {status}
                </Typography>
            </Box>
            )
        }
        },
        { 
            field: "notes",
            headerName: "Notes",
            flex: 1,
            renderCell: ({ row: { key_id, notes }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {notes}
                    </Link>
                )
            }
        },
        { 
            field: "priority",
            headerName: "Priority",
            flex: 1,
            renderCell: ({ row: { key_id, priority }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {priority}
                    </Link>
                )
            }
        },
        { 
            field: "category",
            headerName: "Category",
            flex: 1,
            renderCell: ({ row: { key_id, category }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {category}
                    </Link>
                )
            }
        },
        { 
            field: "created_date",
            headerName: "Created Date",
            flex: 1,
            renderCell: ({ row: { key_id, created_date }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {created_date}
                    </Link>
                )
            }
        },
        { 
            field: "created_by",
            headerName: "Created By",
            flex: 1,
            renderCell: ({ row: { key_id, created_by }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {created_by}
                    </Link>
                )
            }
        },
        { 
            field: "due_date",
            headerName: "Due Date",
            flex: 1,
            renderCell: ({ row: { key_id, due_date }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {due_date}
                    </Link>
                )
            }
        },
        { 
            field: "guide",
            headerName: "Guide",
            flex: 1,
            renderCell: ({ row: { key_id, guide }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {guide}
                    </Link>
                )
            }
        },
        {
            field: "owner_name",
            headerName: "Assignee",
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                  <span style={{width: '75px'}}>{params.value}</span>
                  <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px"}} />
                </div>
              ),
            renderEditCell: (params) => {
                return (
                    <Select
                        value={params.value}
                        onChange={(event) =>
                        ARhandleInputChange(
                            params.id,
                            params.field,
                            event.target.value
                        )
                        }
                        style={{ height: "30px", width: "150px", fontSize: "12px" }}
                    >
                        {usersData.map((item) => (
                            <MenuItem value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                        ))}
                    </Select>
                );
              },
            renderHeader: () => (
                <Box display="flex" alignItems="center">
                    <span>Reassign Assignee</span>
                    {/* <ModeEditIcon style={{ marginLeft: "8px" }} /> */}
                </Box>
                ),
        },
    ]


    
    // for Notes tab
    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // get all the values including the values in the select fields
        const allValues = {...values}

        const json_data = {"kind_id": kind_id, 
                            "key_id": customername, 
                            "data": allValues};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                const diffFieldArray = Object.keys(values).filter(key => values[key] !== initialValues[key]);
                const OldValueArray = diffFieldArray.map(key => initialValues[key]);
                const NewValueArray = diffFieldArray.map(key => values[key]);
                // console.log('OldValueArray', OldValueArray);
                // console.log('NewValueArray', NewValueArray);
                const oldValueNotes = OldValueArray[0];
                const newValueNotes = NewValueArray[0];
                // get the difference in text between oldValueNotes and newValueNotes
                const diffNotes = newValueNotes.replace(oldValueNotes, '');
                // console.log('diffNotes', diffNotes);
                saveLog(new Date().toLocaleString(), 'Account', customername, customername, "Notes", `Added New Note: ${diffNotes}`)
                ToastNotify('success', 'Notes Saved');
                navigate(`/account-profile/${customername}#5`, {replace: true})
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong')
                console.error(error);
            });

    };



    // logs or a.k.a changes timeline
    const logsColumns = [
        // { 
        //     field: "id", 
        //     headerName: "Row",
        //     width: 10
        // },
        {
            field: "date_time",
            headerName: "Date & Time",
            width: 150,
        },
        {
            field: "owner_name",
            headerName: "Team Member",
            width: 125,
        },
        { 
            field: "crm_object", 
            headerName: "Object", 
            width: 100,
        },
        {
            field: "name",
            headerName: "Name",
            width: 125,
            textDecoration: "none",
            color: "inherit",
            cellClassName: "name-column--cell",
            renderCell: ({ row: { name, identifier, crm_object }}) => {
                return (
                    <div
                        onClick={(event) => 
                            crm_object === 'Lead' ? 
                            navigate(`/lead-details/${name}`)
                            : crm_object === 'Account' ?
                            navigate(`/account-profile/${name}`)
                            : crm_object === 'Opportunity' ?
                            navigate(`/opportunity-details/${name}`)
                            : crm_object === 'Activity' ?
                            navigate(`/activities/${identifier}`)
                            : crm_object === 'Contact' ?
                            navigate(`/contact-details/${identifier}`)
                            : null
                        }
                        className='cursor-pointer'
                    >
                        {name}
                    </div> 
                )
            }
        },
        {
            field: "field",
            headerName: "Updated Field",
            width: 125,
        },
        {
            field: "message",
            headerName: "Change Message",
            flex: 1,
        },
    ]



    // for opportunities columns
    const handleColumnsChange = (newColumns) => {
        setSelectedColumns(newColumns);
        // setAnchorEl(null); // Close the popover after selecting columns

        // saving the selected columns and column order to datastore for the user and for this specific page
        // saveUserSelectedColumns("healthscores", newColumns, columnNumbers)
        
      };


    // for selecting columns for opportunities
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    // for selecting columns for opportunities
    const handleClose = () => {
        setAnchorEl(null);
    };

    // for selecting columns for opportunities
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    // columns for opportunities
    const ModifiedColumnsforGrid = () => {

        const modifiedColumns = AllPossibleColumns.map((item) => {
                    if (item.field === 'owner_name') {
                        return {
                            ...item,
                            editable: true,
                            width: 130,
                            renderCell: (params) => (
                                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden'}}>
                                  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{params.value}</span>
                                  <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px"}} />
                                </div>
                              ),
                            renderEditCell: (params) => {
                                return (
                                    <Select
                                        value={params.value}
                                        onChange={(event) =>
                                        handleInputChangeOpportunities(
                                            params.id,
                                            params.field,
                                            event.target.value
                                        )
                                        }
                                        style={{ height: "30px", fontSize: "12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                    >
                                        {usersData.map((item) => (
                                            <MenuItem value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                                        ))}
                                    </Select>
                                );
                            },
                        }
                    } else if (item.field === 'opportunity_name') {
                        return { 
                            ...item,
                            width: 140,
                            textDecoration: "none",
                            color: "inherit",
                            cellClassName: "name-column--cell",
                            renderCell: (params) => {
                                const cellValue = params.value;
                                return (
                                    <div>
                                        <Link 
                                            to={`/opportunity-details/${cellValue}`}
                                            style={{ textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                        >
                                            {cellValue}
                                        </Link>
                                    </div>
                                    
                                );
                            },
                        }
                    }  else if (item.field === 'customer_name') {
                        return { 
                            ...item,
                            headerName: 'Account Name',
                            width: 140,
                            textDecoration: "none",
                            color: "inherit",
                            cellClassName: "name-column--cell",
                            renderCell: (params) => {
                                const cellValue = params.value;
                                return (
                                    <div>
                                        <Link 
                                            to={`/account-profile/${cellValue}`}
                                            style={{ textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                        >
                                            {cellValue}
                                        </Link>
                                    </div>
                                    
                                );
                            },
                        }
                    } else if (item.field === 'notes') {
                        return { 
                            ...item,
                            width: 250,
                        }
                    } else if (item.field === 'id') {
                        return { 
                            ...item,
                            width: 15,
                            headerName: 'Row',
                        }
                    } else {
                        return item;
                    }
                })

        const modifiedFilteredSortedColumns = modifiedColumns.filter((column) => selectedColumns.includes(column.field)).sort((a, b) => columnNumbers[a.field] - columnNumbers[b.field])

        return modifiedFilteredSortedColumns;
    };




    return (
        <Box m="30px">
            <Header
                    title={customername}
                    subtitle="Account Details"
            />
            <div className='mt-[20px] mb-[20px]'>
                <h3 className='text-xl font-bold'>
                    Status:
                </h3>
                <p className='text-lg'>{data.map((item) => (item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : null ))}</p>
            </div>
            <div className='flex flex-col w-[1500px] md:w-full'>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary" aria-label="customer profile tabs">
                            <Tab label="Summary" {...a11yProps(0)} id="tab-summary"/>
                            <Tab label="Contacts" {...a11yProps(1)} id="tab-contacts"/>
                            <Tab label="Healthscore" {...a11yProps(2)} id="tab-healthscore"/>
                            <Tab label="Activities" {...a11yProps(3)} id="tab-activities"/>
                            {/* <Tab label="NPS" {...a11yProps(4)} id="tab-nps"/> */}
                            <Tab label="Documentation" {...a11yProps(4)} id="tab-documentation"/>
                            <Tab label="Notes" {...a11yProps(5)} id="tab-notes"/>
                            <Tab label="Changes Timeline" {...a11yProps(6)} id="tab-timeline"/>
                            <Tab label="Opportunities" {...a11yProps(7)} id="tab-opportunities"/>
                            <Tab label="Sales" {...a11yProps(8)} id="tab-sales"/>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <div 
                            className="app__lifecycle-progress-container"
                            style={{'display': 'flex', 'flexDirection': 'column'}}
                        >
                            <div 
                                className="app__lifecycle-details-container"
                                style={{'width': '80%', 
                                        'height': 'auto', 
                                        'display': 'flex', 
                                        'flexDirection': 'column', 
                                        'justifyContent': 'center',
                                        'gap': '1.5rem',
                                    }}
                            >
                                <div>
                                    <h3 className='text-base font-bold'>Stage</h3>
                                    <p>Double-click on Stage to change</p>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row', 
                                            'alignContent': 'center', 
                                            'alignItems': 'center', 
                                            'justifyContent': 'space-between', 
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey', 
                                            'padding': '10px',
                                        }}
                                >
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'width': '125px', 'fontWeight': 'bold'}} 
                                    > 
                                        {data.map((item) => (item.lifecycle_stage))} Stage
                                    </p>
                                    <Box sx={{ width: "70%"}}>
                                        {data.map((item) => (
                                            <Slider
                                                aria-label="Restricted values"
                                                defaultValue={getStageValue(item.lifecycle_stage, customStages)}
                                                valueLabelFormat={valueLabelFormat}
                                                getAriaValueText={valuetext}
                                                step={null}
                                                valueLabelDisplay="off"
                                                marks={customStages}
                                                max={customStages[customStages.length - 1].value}
                                                color='secondary'
                                                onChange={(event, newValue) => handleSliderChange(event, newValue)}
                                            />
                                        ))}
                                    </Box>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={(event) => saveSliderStageChange(data.customer_name)}
                                        className='w-[120px] h-[30px]'
                                    >
                                        Save Changes
                                    </Button>
                                </div>


                                {/* <div className='app__hsmanagement-edit-properties-title'>
                                    <h3 className='text-base font-bold'>Insights</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row', 
                                            'justifyContent': 'space-between', 
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey',
                                            'padding': '10px'
                                        }}
                                >
                                    <div 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center'
                                            }}
                                    >
                                        <p className='text-sm font-bold'>Healthscore:</p>
                                        <span> </span> 
                                        {data.map((item, index) => ( // data is actually customer data (customer fields) and when a customer is created, a field 'healthscore' is auto created and set to 100
                                                <div>
                                                    {item.healthscore}
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center'
                                            }}
                                    >
                                        <p className='text-sm font-bold'>NPS:</p>
                                        <span> </span> 
                                        <div>
                                            {NPSscoreData}
                                        </div>
                                    </div>
                                    <div 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center'
                                            }}
                                    >
                                        <p className='text-sm font-bold'>Open Activities:</p>
                                        <span> </span> 
                                        {ARcount}
                                    </div>
                                </div> */}
                                

                                {/* Bottom Container for Basic Customer Info, etc. */}
                                
                                <div style={{'gridColumn': '4', 
                                            'width': '100%', 
                                            'height': 'auto', 
                                            'display': 'flex', 
                                            'flexDirection': 'row', 
                                        }}
                                >
                                    <h3 className='text-base font-bold'>Basic Customer Information</h3>
                                </div>
                                <div style={{'gridColumn': '1', 
                                            'width': '100%', 
                                            'height': 'auto', 
                                            'display': 'flex', 
                                            'flexDirection': 'column', 
                                            'justifyContent': 'space-between',
                                        }}
                                >
                                    <div  // Basic Customer Info Box
                                        style={{'gridCoumn': 'span 1', 
                                                'height': 'auto', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'margin': '0 0 1rem 0', 
                                                'border': '1px solid lightgrey', 
                                                'padding': '10px',
                                            }}
                                    >
                                        <div 
                                            style={{'width': '100%', 
                                                    'display': 'flex', 
                                                    'flexDirection': 'column',
                                                }}
                                        >
                                            <CustomerDetails customerData={data} customerName={customername}/>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </CustomTabPanel>

                    
                    {/* Contacts Tab Panel */}
                    <CustomTabPanel value={value} index={1}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <Box m='20px'>
                                <Header
                                    title="Contacts"
                                    subtitle="View Contacts"
                                />
                                <Button
                                        sx={{"margin": "1rem 0 0 0", "width": "180px"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => navigate(`/account-profile/${customername}/newcontact`, {replace: true})}
                                    >
                                        Add Contact
                                </Button>
                                <Box
                                    m="40px 0 0 0"
                                    height="75vh"
                                    sx={{"& .MuiDataGrid-root": {
                                            border: "none",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none"
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300]
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: colors.grey[700],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                            backgroundColor: colors.grey[700],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={contactData}
                                        columns={contactColumns}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                </Box>
                            </Box>
                        
                        </div>
                    </CustomTabPanel>

                    {/* Healthscores Tab Panel */}
                    <CustomTabPanel value={value} index={2}>
                        <div
                            className='w-full h-auto flex-col justify-between items-center m-1 border-2 border-gray-400 rounded-md p-2.5'
                        >
                            <Box m="30px">
                                <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
                                <Header
                                        title="HealthScore"
                                        subtitle="Trending Healthscore"
                                />
                                <div 
                                    className='app__tierlevel-container'
                                    style={{'marginTop': '20px', 'marginBottom':'20px'}}
                                >
                                    {/* <Button
                                        sx={{"marginTop": "1rem"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => navigate('/npsdata')}
                                    >
                                        See All NPS Responses
                                    </Button> */}
                                </div>
                                <div className='app__container'>
                                    <Box sx={{ width: '100%' }}>
                                        <div 
                                            className="app__nps-container"
                                            style={{'display': 'flex', 'flexDirection': 'column'}}
                                        >
                                            <div 
                                                className="app__lifecycle-details-container"
                                                style={{'width': '80%', 
                                                        'height': 'auto', 
                                                        'display': 'flex', 
                                                        'flexDirection': 'column', 
                                                        'justifyContent': 'center'
                                                    }}
                                            >
                                                {/* <div>
                                                    <h3 className='font-bold'>Monthly Healthscore</h3>
                                                </div> */}
                                                <div 
                                                    style={{'display': 'flex', 
                                                            'flexDirection': 'row',
                                                            'width': 'full',
                                                            'height': '400px',
                                                            'justifyContent': 'space-between', 
                                                            'margin': '0 0 1rem 0', 
                                                            'border': '1px solid lightgrey',
                                                            'padding': '5px'
                                                        }}
                                                >
                                                    <LineChart customData={hsHistoryData}/>
                                                </div>

                                                {/* <div className='mt-4'>
                                                    <h3 className='font-bold'>Scorecard Properties</h3>
                                                </div>
                                                <div 
                                                    className="flex flex-row justify-between mb-1 border-[1px] p-2.5"
                                                >
                                                    {HSData.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className='w-full flex flex-row justify-between items-center'
                                                        >
                                                            {Object.entries(item).map(([key, value]) => {
                                                                const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

                                                                return (
                                                                    <div 
                                                                        key={key}
                                                                        className='w-[150px] p-[5px]'
                                                                    >
                                                                        <p className='font-bold'>{formattedKey}<span>: </span></p>
                                                                        <p>{value}</p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    ))}
                                                </div> */}

                                            </div>
                                        </div>
                                    </Box>
                                    <Button
                                        sx={{"marginTop": "1rem"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => navigate('/healthscores')}
                                    >
                                        All Customers HealthScores
                                    </Button>
                                </div>
                            </Box>
                        </div>
                    </CustomTabPanel>


                    {/* Action Requests Tab Panel */}
                    <CustomTabPanel value={value} index={3}>
                        <div 
                                style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                            >
                            {/* <Actionrequests customer_id={customeridData}/> */}
                            <Box m='20px'>
                                <Header
                                    title="Activities"
                                    subtitle="List of Activities"
                                />
                                {ARdata.map((item) => (item.customer_name === 'Example Customer 1' ? <div className="pt-4"><h2 className="font-bold text-gray-500">No Activities under customer.</h2></div> : null )) }
                                <Box
                                    m="40px 0 0 0"
                                    height="75vh"
                                    sx={{"& .MuiDataGrid-root": {
                                            border: "none",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none"
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300]
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: colors.grey[700],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                            backgroundColor: colors.grey[700],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={ARdata}
                                        columns={ARcolumns}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </CustomTabPanel>


                    {/* NPS Tab Panel */}
                    {/* <CustomTabPanel value={value} index={4}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <NpsComponent 
                                nps_score_value={NPSscoreData} 
                                nps_history={NPSHistoryData}
                            />
                            <Button
                                sx={{"margin": "1rem 0 0 1.8rem", "width": "180px"}} 
                                color='secondary'
                                variant='contained' 
                                onClick={(event) => navigate('/addnps', {replace: true})}
                            >
                                Add NPS Score
                            </Button>
                        </div>
                    </CustomTabPanel> */}


                    {/* Documentation Tab Panel */}
                    <CustomTabPanel value={value} index={4}>
                        <div className="w-[70%] h-full gap-10 p-10 flex flex-col justify-center items-center border-[1px] border-gray-500 rounded-xl">
                            <h2 className='text-xl text-gray-500 font-bold'>Upload Assets or Files</h2>
                            <p>i.e. Pictures, PDFs, any text-based Files</p>
                            <input 
                                type="file" 
                                id="file-input" 
                                onChange={handleFileChange}
                                className='ml-8 w-[300px] h-[40px] cursor-pointer'
                            />
                            {fileUploadLoading && (
                                <div className='flex flex-col justify-center items-center'>
                                    <p>Uploading file...please wait until upload is complete...</p>
                                    <CircularProgress color="secondary" sx={{ fontSize: "50px", mt: "1rem" }}/>
                                </div>
                            )}
                        </div>
                        <div className="w-[70%] mt-8 h-full gap-2 p-10 flex flex-col justify-center items-center border-[1px] border-gray-500 rounded-xl">
                            <h2 className='text-xl text-gray-500 font-bold'>Assets or Files</h2>
                            {fileDownloadLoading && (
                                            <div className='mb-8 flex flex-col justify-center items-center'>
                                                <p>Downloading file..</p>
                                                <CircularProgress color="secondary" sx={{ fontSize: "50px", mt: "1rem" }}/>
                                            </div>
                            )}
                            {files.map((file, index) => (
                                <div className='mt-4 w-auto h-auto flex flex-row gap-10 items-center text-center justify-items-center justify-center'>
                                    <p key={index} className='w-[300px] text-lg text-gray-500 font-bold'>{ file.replace(/^.*[\\\/]/, '') }</p>
                                    <Button
                                        sx={{"width": "180px"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => handleDownload( file.replace(/^.*[\\\/]/, '') )}
                                    >
                                        Download
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </CustomTabPanel>


                    {/* Notes Tab Panel */}
                    <CustomTabPanel value={value} index={5}>
                        <Box m="30px" width="50%">
                            <Header title="Notes" subtitle="Edit Notes" />

                            <Formik
                                onSubmit={(values) => handleFormSubmit(values)}
                                initialValues={initialValues}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box
                                                display="grid"
                                                gap="30px"
                                                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                                sx={{
                                                    marginTop: "2rem",
                                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                                }}
                                            >
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={23}
                                                        type="text"
                                                        label="Notes"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.notes}
                                                        name="notes"
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 1" }}
                                                    />
                                                </div>
                                            </Box>
                                            <Box 
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between" 
                                                mt="20px"
                                                gap={"20px"}
                                            >
                                                <Button 
                                                    type="submit" 
                                                    color="secondary" 
                                                    variant="contained"
                                                    sx={{width: '200px'}}
                                                    // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                                                >
                                                    Save Edits
                                                </Button>
                                            </Box>
                                        </form>
                                        )
                                    }
                            </Formik>
                        </Box>
                    </CustomTabPanel>


                    {/* Changes timeline Tab Panel */}
                    <CustomTabPanel value={value} index={6}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <Box m='20px'>
                                <Header
                                    title="Changes Timeline"
                                    subtitle="View Changes"
                                />
                                <Box
                                    m="40px 0 0 0"
                                    height="75vh"
                                    sx={{"& .MuiDataGrid-root": {
                                            border: "none",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none"
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300]
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: colors.grey[700],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                            backgroundColor: colors.grey[700],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={logs}
                                        columns={logsColumns}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                </Box>
                            </Box>
                        
                        </div>
                    </CustomTabPanel>


                    {/* Opportunities Tab Panel */}
                    <CustomTabPanel value={value} index={7}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <Box m='30px' display="flex" flexDirection="column">
                                <Header
                                    title="Opportunities"
                                    subtitle="List of Opportunities"
                                />
                                <Button
                                    sx={{"margin": "0 0 0.1rem 0", "width": "200px"}} 
                                    color='secondary'
                                    variant='contained'
                                    onClick={(event) => {navigate(`/account-profile/${customername}/newopportunity`)}}
                                >
                                    Add New Opportunity
                                </Button>
                                <Button
                                    sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
                                    variant="contained" 
                                    onClick={handleClick}
                                >
                                    Select Columns
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                >
                                    <Box p={2}>
                                    <Typography>Select Columns, set Order, and click Save.</Typography>
                                    <Divider />
                                    <List>
                                        {AllPossibleColumns.map((column) => (
                                            <ListItem key={column.field}>
                                                <ListItemText primary={column.headerName} />
                                                <IconButton
                                                    onClick={() =>
                                                        handleColumnsChange(
                                                        selectedColumns.includes(column.field)
                                                            ? selectedColumns.filter((col) => col !== column.field)
                                                            : [...selectedColumns, column.field]
                                                        )
                                                    }
                                                    >
                                                    {selectedColumns.includes(column.field) ? (
                                                        <CheckCircleIcon />
                                                    ) : (
                                                        <RadioButtonUncheckedIcon />
                                                    )}
                                                </IconButton>
                                                <TextField
                                                    sx={{ width: "75px" }}
                                                    // do not allow negative numbers
                                                    InputProps={{ inputProps: { min: 1 } }}
                                                    type="number"
                                                    value={columnNumbers[column.field]}
                                                    onChange={(e) =>
                                                        setColumnNumbers({
                                                        ...columnNumbers,
                                                        [column.field]: parseInt(e.target.value, 10) || 0,
                                                        })
                                                    }
                                                />
                                            </ListItem>
                                        
                                        ))}
                                    </List>
                                    <Divider />
                                    <Button
                                        sx={{ mt: 2 }}
                                        variant='contained'
                                        onClick={(event) => saveUserSelectedColumns("opportunities", selectedColumns, columnNumbers)}
                                    >
                                        Save
                                    </Button>
                                    </Box>
                                </Popover>
                                <Box
                                    m="40px 0 0 0"
                                    height="75vh"
                                    sx={{"& .MuiDataGrid-root": {
                                            border: "none",
                                            overflow: "auto",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300],
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: colors.grey[700],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                            backgroundColor: colors.grey[700],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                        
                                    }}
                                >
                                    <DataGrid
                                        rows={opportunityData}
                                        columns={ModifiedColumnsforGrid()}
                                        onColumnsChange={handleColumnsChange}
                                    />
                                </Box>
                            </Box>
                        
                        </div>
                    </CustomTabPanel>


                    {/* Sales Tab Panel */}
                    <CustomTabPanel value={value} index={8}>
                        <div 
                            style={{'width': '100%', 'height': 'auto', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'margin': '0 0 1rem 1rem', 'border': '1px solid lightgrey', 'padding': '10px'}}
                        >
                            <Box m='20px'>
                                <Button
                                        sx={{"margin": "1rem 0 1rem 0", "width": "180px"}} 
                                        color='secondary'
                                        variant='contained' 
                                        onClick={(event) => navigate(`/account-profile/${customername}/newsale`, {replace: true})}
                                    >
                                        New Sale
                                </Button>
                                <SalesData salesData={salesData} />
                            </Box>
                        </div>
                    </CustomTabPanel>


                </Box>
            </div>
        </Box>
    );
}

export default Customerprofile;

