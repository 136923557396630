import { ToastNotify } from '../components/ToastNotify';



export const saveUserSelectedColumns = async (page, columns, columnNumbers) => {
    // columnNumbers is the order of the columns to be displayed in the DataGrid table


    const checkUser = localStorage.getItem('user');

    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

    const NewColumnsData = {}

    // add kind_id to NewCustomerData
    NewColumnsData["kind_id"] = kind_id;
    // add customerName to customer_name
    NewColumnsData["owner_name"] = loginUserName;
    // required object_type field
    NewColumnsData["object_type"] = "columns";
    // required which page the columns are for
    NewColumnsData["page"] = page;
    // required columns the user selected
    NewColumnsData["selected_columns"] = columns;
    // required column order the user inputted
    NewColumnsData["column_order"] = columnNumbers;


    const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

    const json_data = {"kind_id": kind_id,
                        "key_id": "columns" + " " + page + " " + loginUserName,
                        "data": NewColumnsData};

    fetch(Createurl, {
        method: 'POST',
        body: JSON.stringify(json_data),
        headers: headers
        })
        .then(response => response.json())
        .then(result => {
            // Handle the API response or perform any necessary actions
            // console.log('New Customer has been created');
            // console.log(result);
            ToastNotify("success", "Columns saved");
        })
        .catch(error => {
            // Handle errors that occurred during the API call
            console.error(error);
            ToastNotify("error", "Something went wrong");
        });
  };