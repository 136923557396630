import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import { useCustomSidebarHeight } from "../../theme";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


const CreateTemplates = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [templateName, setTemplateName] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');

    const [customers, setCustomers] = useState([]);

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    // default dyanmic field for the template
    const dynamicFieldsArray = [
        {
            "htmlToken": "",
            "label": "",
        },

    ];

    const [dynamicFields, setDynamicFields] = useState(dynamicFieldsArray);

    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    
    // fetching customers so the user can map specific customer fields for the dynamic fields
    useEffect(() => {
        setIsLoading(true);
        const fetchCustData = async () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            customer_name: 'Example Customer 1',
                            owner_name: 'Example Owner 1',
                            lifecycle_stage: 'Purchase',
                            healthscore: '90',
                            healthscore_color: 'green',
                            cta_open: '2',
                            industry: 'Example Industry',
                            specialty: 'Example Specialty',
                            last_touch_date: '2023-09-01',
                            cta_count: '2',
                            created_date: '2023-09-01',
                            start_date: '2023-09-01',
                            renewal_date: '2024-09-01',
                }]
                // setCustomers(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchCustData();
    }, []);


    const handleSubmitCreateTemplate = (event) => {
        event.preventDefault();

        if (templateName === '' || subject === '' || content === '') {
            ToastNotify('error', 'All fields are required');
            return;
        }

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const noSpacesTemplateName = templateName.replace(/\ /g, '_');

        // template_name must be kind_id + templateName (without any spaces in templateName) when creating new template
        const newTemplateName = kind_id + '_' + templateName.replace(/\ /g, '_');


        // create the json array for the dynamic fields creation into datastore
        const NewDynamicFieldsData = {}
        // add kind_id to array
        NewDynamicFieldsData["kind_id"] = kind_id;
        // required template_name field
        NewDynamicFieldsData["template_name"] = newTemplateName;
        // required object_type field
        NewDynamicFieldsData["object_type"] = "template_tokens";
        // required dynamic fields
        const dynamicFieldsWithUnsubscribe = [...dynamicFields, {"htmlToken": "unsubscribelink", "label": ""}];

        NewDynamicFieldsData["dynamic_fields"] = dynamicFieldsWithUnsubscribe;
        
        const json_token_data = {"kind_id": kind_id, 
                                    "data": NewDynamicFieldsData, 
                                    };

        const url = process.env.REACT_APP_API_URL + '/api/v1/create'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_token_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // console.log(result);
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });

        
        const unsubscribeSection = `
                                    <div class="unsubscribe-container" style="background-color: #f4f4f4; padding: 20px; text-align: center; font-family: Arial, sans-serif;">
                                    <p style="margin: 0; font-size: 14px; color: #666;">If you no longer wish to receive these emails,</p>
                                    <a href={{ unsubscribelink }} style="color: #0066cc; text-decoration: underline; font-size: 14px;">unsubscribe here</a>.
                                    </div>
                                    `;
        
        const contentWithUnsubscribe = content + unsubscribeSection;

        const contentText = contentWithUnsubscribe.replace(/<[^>]*>?/gm, '');

        const json_data = {"template_name": newTemplateName, 
                            "subject": subject, 
                            "html_part": contentWithUnsubscribe, 
                            "text_part": contentText
                        };

        const createUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/createtemplate';

        fetch(createUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Template has been created');
                navigate('/campaigns/templates', {replace: true});
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
        
        
        
    };



    // to capture the dynamic fields html token value for the template
    const handleDynamicFieldChange = (index, value) => {
        const updatedDynamicFields = [...dynamicFields];
        // remove spaces from the value as aws ses sendemailtemplate does not accept token names with spaces
        value = value.replace(/\s/g, '');
        updatedDynamicFields[index].htmlToken = value;
        setDynamicFields(updatedDynamicFields);
    };

    // to capture the dynamic fields selection of Customer Field to map with the token for the template
    const handleSelectDynamicField = (event, index) => {
        const newDynamicFields = [...dynamicFields];
        // 'label' below is the key that is in the json array, the index in newFields[index] is 0 if user only adds one Dynamic Field logic, 1 if user adds two Dynamic Fields logic, and so on.
        newDynamicFields[index].label = event.target.value;
        setDynamicFields(newDynamicFields);
        // console.log('this is fields: ')
        // console.log(fields)
    };


    const handleRemoveQuestion= (index) => {
        const updatedDynamicFields = dynamicFields.filter((_, i) => i !== index);
        setDynamicFields(updatedDynamicFields);
    };


    // to add more dynamic fields in the view and increasing the sidebar height dynamically
    const handleAddDynamicField = () => {
        // adding a new dynamic field to the view
        const newDynamicField = {
            label: '',   // `Dynamic Field ${dynamicFields.length + 1}`
            };
        setDynamicFields([...dynamicFields, newDynamicField]);
        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 15;
        setSidebarHeight(newHeight + 'vh');

        // console.log(dynamicFields);
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="30px" width="90%">
            <Header title="Create" subtitle="New Campaign Template" />

            <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                <div className='w-full h-auto flex-col p-4'>
                    <div className='flex-col w-full'>
                        <label className='font-bold'>Campaign Template Name:</label>
                        <input className='border-[1px] border-gray-200 w-full' type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                    </div>
                    <div className='flex-col w-full mt-8'>
                        <label className='font-bold'>Email Subject:</label>
                        <input className='border-[1px] border-gray-200 w-full' type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className='flex-col w-full mt-8'>
                        <label className='font-bold'>Text or HTML Content:</label>
                        <textarea className='border-[1px] border-gray-200 w-full h-[300px]' value={content} onChange={(e) => setContent(e.target.value)} />
                    </div>
                    <div className='flex-col w-full mt-8'>
                        <div className='w-[50%] flex flex-col items-start justify-center'>
                            <p className='text-md'>I would also like to add dynamic fields/tokens inside my email.  Each dynamic field should be surrounded by double curly braces inside your HTML content.</p>
                            <Checkbox
                                sx={{ '&:hover': { backgroundColor: 'transparent',}, }}
                                checked={isChecked}
                                onChange={handleCheckBoxChange}
                                color="primary"
                                backgroundColor="transparent"
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        {isChecked && (
                            <>
                            <label className='font-bold'>Map Dynamic Fields:</label>
                            <div className='flex-col w-full'>
                                {dynamicFields.map((field, index) => (
                                    <div key={index} className='w-full flex justify-between items-center'>
                                        <input
                                            className='w-[70%] h-[52px] border-[1px] border-gray-400 rounded-md p-2 m-2 text-gray-500'
                                            type="text"
                                            value={field.htmlToken}
                                            onChange={(e) => handleDynamicFieldChange(index, e.target.value)}
                                        />
                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                            <InputLabel id={`field-label-${index}`}>Select a Field</InputLabel>
                                            <Select
                                                labelId={`field-label-${index}`}
                                                id={`field-select-${index}`}
                                                value={dynamicFields[index].label}
                                                label="Select Field"
                                                onChange={(event) => handleSelectDynamicField(event, index)}
                                            >
                                                {customers.slice(0,1).map((item) => (
                                                    Object.entries(item).map(([key]) => {
                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                        if (key.includes('id') || key.includes('object_type') || key.includes('key_id') || 
                                                            key.includes('score_history') || key.includes('healthscore_reasons') || key.includes('notes') ) {
                                                            return null;
                                                        } else {
                                                        return (
                                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                        );
                                                        }
                                                    })
                                                ))}
                                            </Select>
                                            <FormHelperText>Select from Customer Fields</FormHelperText>
                                        </FormControl>              
                                    </div>
                                ))}
                            </div>
                            <Button
                                sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                                variant='contained'
                                color='secondary'
                                onClick={handleAddDynamicField}
                                >+ Add Dynamic Field
                            </Button>
                            </>
                            )
                        }
                    </div>
                    <div className='mt-8'>
                        <label className='font-bold'>Template Preview:</label>
                    </div>
                    <div className='mt-4 w-full h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden'>
                        <iframe 
                            srcDoc={content}
                            width="100%"
                            sandbox="allow-scripts"
                            style={{ width: '100%', height: '100%', marginTop: '1rem'}}
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                </div>
            </div>

            <div className='pt-4 pb-4'>
                <Button
                    sx={{marginTop: "1rem", width: "160px"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitCreateTemplate(event)}
                >
                    Create Template
                </Button>
            </div>
        </Box>
    );
}

export default CreateTemplates;