import React from 'react';
import EmailIcon from '@mui/icons-material/Email';


const Button = ({ type, title, icon, muiIcon, variant, full, onClick }) => {


  return (
    <button
      className={`flexCenter gap-3 rounded-full border h-[50px] xl:h-[60px] ${variant} ${full && 'w-full'}`}
      type={type}
      onClick={onClick}
    >
      {icon && <img src={icon} alt={title} width={24} height={24} />}
      {muiIcon && <EmailIcon style={{fontSize: "24px"}} />}
      <label className="text-black bold-16 whitespace-nowrap cursor-pointer">{title}</label>
    </button>
  )
}

export default Button;