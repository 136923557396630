import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CircularProgress from '@mui/material/CircularProgress';
import { saveLog } from '../../utils/logChanges';
import GoogleCalendarAuth from '../../components/GoogleAuthCalendar';
import GoogleOAuth from '../../components/oAuthSignIn';
import GoogleCalendarButtons from '../../components/GoogleAuthCalendar';
import { ToastNotify } from '../../components/ToastNotify';
import { google } from '@google-cloud/datastore/build/protos/protos';



const Events = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [usersData, setUsersData] = useState([]);

    const [editedData, setEditedData] = useState([]);  // for action request Notes field

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id



    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);



    // fetching events data
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id 

        const oauth2token = localStorage.getItem('googleAccessToken');

        if (oauth2token != null) {
            fetchCalendarEvents();
        } else {

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            try { 
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'

                const localurl = 'http://127.0.0.1:5000/api/v1/read';

                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "event"},
                        } 
                    };
                
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const today = new Date();
                    const todaysDate = today.toISOString().split('T')[0];
                    // remove the first character in todaysDate if it is a '0'
                    if (todaysDate.charAt(0) === '0') {
                        todaysDate = todaysDate.substring(1, 9)
                    }
                    const jsonArray = [{
                        id: 1,
                        key_id: '0000000',
                        event_start_date: todaysDate,
                        event_end_date: todaysDate,
                        event_name: 'Example Event Name',
                        attendees: 'Bob, David, and I',
                        created_by: 'Mike',
                        created_date: todaysDate,
                        notes: 'Going to meet the customer at the office.',
                    }];
                    setData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));

                    // sort jsonArray based on date_time field descending
                    jsonArray.sort((a, b) => new Date(b.event_start_date) - new Date(a.event_start_date));

                    // reset the id field starting with 1
                    jsonArray.map((item, index) => {
                        item.id = index + 1;
                    });
            
                    setData(jsonArray);
                }
            } catch (error) {
                setError(error.message);
            }
        }

        setIsLoading(false);
        };
    
        fetchData();
    }, []);




    const handleInputChange = (index, field, value) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
        const indexMinusOne = index - 1;

        const ctaObject = data[indexMinusOne];
        const key_id__ = ctaObject.key_id;

        data[indexMinusOne][field] = value;

        const { id, status2, ...arrayWithoutSpecificKeys } = data[indexMinusOne];


        const json_data = {"kind_id": kind_id, 
                            "key_id": key_id__, 
                            "data": arrayWithoutSpecificKeys
                        };
    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Changes Saved: ')
                // console.log(result);
                saveLog(new Date().toLocaleString(), 'Activity', key_id__, arrayWithoutSpecificKeys.action_request_name, field, `Changed ${field} to ${value}`);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });

    };

    const formatDateTime = (dateTime) => {
        // Remove the part after the '+' including '+'
        if (dateTime === undefined) {
            return '';
        }

        if (dateTime.includes('+')) {
            const dateWithoutTimezone = dateTime.split('+')[0];
            // Replace 'T' with a space
            const newDateFormat = dateWithoutTimezone.replace('T', ' T');
            // remove any characters after 17 characters
            return newDateFormat.slice(0, 17);
        } else {
            const newDateFormat = dateTime.replace('T', ' T');
            return newDateFormat.slice(0, 17);
        }
    };


    // for Google Calendar API
    // Function to fetch calendar events using the access token after syncing calendar
    const fetchCalendarEvents = () => {

        const oauth2token = localStorage.getItem('googleAccessToken');

        const headers = {
            'Authorization': `Bearer ${oauth2token}`,
            'Content-Type': 'application/json'
        };

        fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', { headers })
        .then(response => response.json())
        .then(events => {
            // console.log('Calendar Events from Events page:', events);

            // console.log('Event items:', events.items)

            // Process and display calendar events in your app
            const eventsArray = events.items.map((event, index) => ({
                id: index + 1,
                event_start_date: event.start?.dateTime || event.start?.date || '',
                event_end_date: event.end?.dateTime || event.end?.date || '',
                event_name: event.summary,
                attendees: event.attendees ? event.attendees.map(attendee => attendee.email).join(', ') : '',
                created_by: event.creator?.email || '',
                created_date: event.created,
                notes: event.description,
            }));


            // sort jsonArray based on event_start_date datetime field descending
            eventsArray.sort((a, b) => new Date(b.event_start_date) - new Date(a.event_start_date));

            // format the event_start_date and event_end_date fields
            eventsArray.map((item, index) => {
                item.event_start_date = formatDateTime(item.event_start_date);
                item.event_end_date = formatDateTime(item.event_end_date);
                item.created_date = formatDateTime(item.created_date);
            });

            // reset the id field starting with 1
            eventsArray.map((item, index) => {
                item.id = index + 1;
            });
            
            setData(eventsArray);
            // window.location.reload();
        })
        .catch(error => {
            console.error('Error fetching calendar events from Events page:', error);
            // ToastNotify('error', 'Error fetching Google calendar events.');
        });
    };

    
    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    // if there is no localStorage.getItem('user') then do nothing
    function get_kind_id() {
        if (localStorage.getItem('user')) {
            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id 
            return kind_id;
        }
        return 'client1234567890';
    }

    

    const modifiedColumns = () => {

        const kind_id = get_kind_id();

        if (kind_id === 'client1717414405544' || kind_id === 'client1703727947492') {
            const customColumns = [
                { 
                    field: "id", 
                    headerName: "Row",
                    width: 10
                }, 
                { 
                    field: "event_start_date", 
                    headerName: "Event Start Date", 
                    width: 130,
                    renderCell: ({ row: { event_start_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{ event_start_date ? event_start_date.split('-')[1] + '/' + event_start_date.split('-')[2] + '/' + event_start_date.split('-')[0] : ''}</p>
                        )
                    }
                },
                { 
                    field: "event_end_date", 
                    headerName: "Event End Date", 
                    width: 130,
                    renderCell: ({ row: { event_end_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{event_end_date ? event_end_date.split('-')[1] + '/' + event_end_date.split('-')[2] + '/' + event_end_date.split('-')[0] : ''}</p>
                        )
                    }
                },
                {
                    field: "event_name",
                    headerName: "Event Name",
                    width: 250,
                    textDecoration: "none",
                    color: "inherit",
                    cellClassName: "name-column--cell",
                    renderCell: ({ row: { key_id, event_name }}) => {
                        return (
                            <Link 
                                to={`/events/${key_id}`}
                                style={{ textDecoration: 'none', color: "inherit" }}
                            >
                                {event_name}
                            </Link>
                            // <p style={{ textDecoration: 'none', color: "inherit" }}>{event_name}</p>
                        )
                    }
                },
                {
                    field: "attendees",
                    headerName: "Attendees",
                    width: 250,
                },
                {
                    field: "category",
                    headerName: "Category",
                    width: 150,
                },
                {
                    field: "product_of_interest",
                    headerName: "Product of Interest",
                    width: 250,
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    width: 150,
                },
                {
                    field: "created_date",
                    headerName: "Created Date",
                    width: 130,
                    renderCell: ({ row: { created_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{ created_date ? created_date.split('-')[1] + '/' + created_date.split('-')[2] + '/' + created_date.split('-')[0] : ''  }</p>
                        )
                    }
                },
                { 
                    field: "notes",
                    headerName: "Notes/Description",
                    flex: 1,
                    renderCell: ({ row: { key_id, notes }}) => {
                        return (
                            // <Link 
                            // to={`/events/${key_id}`}
                            // style={{ textDecoration: 'none', color: "inherit" }}
                            // >
                            //     {notes}
                            // </Link>
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{notes.substring(0, 50)}...</p>
                        )
                    }
                },
            ];

            return customColumns;
        } else {
            const genericColumns = [
                { 
                    field: "id", 
                    headerName: "Row",
                    width: 10
                }, 
                { 
                    field: "event_start_date", 
                    headerName: "Event Start Date", 
                    width: 130,
                    renderCell: ({ row: { event_start_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{event_start_date ? event_start_date.split('-')[1] + '/' + event_start_date.split('-')[2] + '/' + event_start_date.split('-')[0] : ''}</p>
                        )
                    }
                },
                { 
                    field: "event_end_date", 
                    headerName: "Event End Date", 
                    width: 130,
                    renderCell: ({ row: { event_end_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{event_end_date ? event_end_date.split('-')[1] + '/' + event_end_date.split('-')[2] + '/' + event_end_date.split('-')[0] : ''}</p>
                        )
                    }
                },
                {
                    field: "event_name",
                    headerName: "Event Name",
                    width: 250,
                    textDecoration: "none",
                    color: "inherit",
                    cellClassName: "name-column--cell",
                    renderCell: ({ row: { key_id, event_name }}) => {
                        return (
                            <Link 
                                to={`/events/${key_id}`}
                                style={{ textDecoration: 'none', color: "inherit" }}
                            >
                                {event_name}
                            </Link>
                            // <p style={{ textDecoration: 'none', color: "inherit" }}>{event_name}</p>
                        )
                    }
                },
                {
                    field: "attendees",
                    headerName: "Attendees",
                    width: 250,
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    width: 150,
                },
                {
                    field: "created_date",
                    headerName: "Created Date",
                    width: 130,
                    renderCell: ({ row: { created_date }}) => {
                        return (
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{created_date ? created_date.split('-')[1] + '/' + created_date.split('-')[2] + '/' + created_date.split('-')[0] : ''}</p>
                        )
                    }
                },
                { 
                    field: "notes",
                    headerName: "Notes/Description",
                    flex: 1,
                    renderCell: ({ row: { key_id, notes }}) => {
                        return (
                            // <Link 
                            // to={`/events/${key_id}`}
                            // style={{ textDecoration: 'none', color: "inherit" }}
                            // >
                            //     {notes}
                            // </Link>
                            <p style={{ textDecoration: 'none', color: "inherit" }}>{notes.substring(0, 50)}...</p>
                        )
                    }
                },
            ];

            return genericColumns;
        }
    } 

    


    return (
        <Box m='20px'>
            <Header
                title="Events"
                subtitle="List of Events"
            />
            <Button
                variant="contained"
                onClick={() => navigate('/events/create')}
                sx={{mt: "1rem", width: 165, height: 40, "border": `1px solid ${colors.greenAccent[200]}`}}
            >
                Create Event
            </Button>

            {/* { localStorage.getItem('googleAccessToken') ? null : 
                (
                    <Button
                        variant="contained"
                        onClick={() => navigate('/events/create')}
                        sx={{mt: "1rem", width: 165, height: 40, "border": `1px solid ${colors.greenAccent[200]}`}}
                    >
                        Create Event
                    </Button>
                )
            } */}
            {/* <div className='mt-4 flex flex-col gap-2'>
                <h2 className='font-bold text-md'>Authorize to Sync</h2>
                <GoogleOAuth />
            </div>

            <div className='mt-4 flex flex-col gap-2'>
                <h2 className='font-bold text-md'>Fetch Calendar Events</h2>
                <Button 
                    variant='contained' 
                    sx={{ width: 165, height: 40, "border": `1px solid ${colors.greenAccent[200]}`}} 
                    onClick={fetchCalendarEvents}
                >
                    Fetch calendar events
                </Button>
            </div> */}
            

            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    width: { xs: "1300px", sm: "1300px", md: "100%"},
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    columns={modifiedColumns()}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default Events;



