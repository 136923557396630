import React, { useState, useEffect } from 'react';
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';
import img4 from '../../assets/img4.jpg';
import { Box, Button } from "@mui/material";
import Header from "../../components/Header";


const items = [
    { imgSrc: img1, author: "AI", title: "DESIGN SLIDER1", topic: "ANIMAL1", description: "Lorem ipsum dolor..." },
    { imgSrc: img2, author: "AI", title: "DESIGN SLIDER2", topic: "ANIMAL2", description: "Lorem ipsum dolor..." },
    { imgSrc: img3, author: "AI", title: "DESIGN SLIDER3", topic: "ANIMAL3", description: "Lorem ipsum dolor..." },
    { imgSrc: img4, author: "AI", title: "DESIGN SLIDER4", topic: "ANIMAL4", description: "Lorem ipsum dolor..." },
];


const CampaignTemplatesAI = () => {

    // Current index for managing which card is in focus
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 8000);
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const currentItem = items[currentIndex];
        document.body.style.transition = 'background-image 1s ease-in-out';
        document.body.style.backgroundImage = `url(${currentItem.imgSrc})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
    }, [currentIndex]);


    const rearrangeItems = (index) => {
        let newItems = [...items];
        const activeItem = newItems.splice(index, 1);
        return [...activeItem, ...newItems];
    };


    const CarouselItem = ({ imgSrc, author, title, topic, description, isActive, onClick }) => {
        const activeClass = isActive ? 'opacity-100 scale-110' : 'opacity-50 scale-90'; // Add scale effect for active item
        return (
            <div 
                className={`cursor-pointer relative w-[300px] h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden transition-transform duration-500 ${activeClass}`}
                onClick={onClick}
            >
                <img className='absolute w-full h-full object-cover rounded-2xl' src={imgSrc} alt="" />
                <div className="relative text-center pt-[90%]">
                    <div className="text-white font-bold tracking-widest">{author}</div>
                    <div className="text-white text-2xl font-bold leading-tight">{title}</div>
                    <div className="text-2xl font-bold leading-tight text-orange-600">{topic}</div>
                    <div className="text-sm text-gray-300">{description}</div>
                </div>
            </div>
        );
    };

    // const CarouselItem = ({ imgSrc, author, title, topic, description, isActive, onClick }) => {
    //     const activeClass = isActive ? 'opacity-100 scale-105' : 'opacity-50'; // Add scale effect for active item
    //     return (
    //         <div 
    //             className={`cursor-pointer relative w-[300px] h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden transition-transform duration-500 ${activeClass}`}
    //             onClick={onClick}
    //         >
    //             <img className='absolute w-full h-full object-cover rounded-2xl' src={imgSrc} alt="" />
    //             <div className="relative text-center pt-[90%]">
    //                 <div className="text-white font-bold tracking-widest">{author}</div>
    //                 <div className="text-white text-2xl font-bold leading-tight">{title}</div>
    //                 <div className="text-2xl font-bold leading-tight text-orange-600">{topic}</div>
    //                 <div className="text-sm text-gray-300">{description}</div>
    //             </div>
    //         </div>
    //     );
    // };



    // const MainCarouselItem = ({ imgSrc, author, title, topic, description }) => (
    //     <div className='relative w-full h-[700px] border-[1px] border-gray-300 rounded-2xl overflow-hidden opacity-100'>
    //         <img className='absolute w-full h-full object-cover rounded-2xl' src={imgSrc} alt="" />
    //         <div className="relative text-center pt-[50%]">
    //             <div className="text-white font-bold tracking-widest">{author}</div>
    //             <div className="text-white text-2xl font-bold leading-tight">{title}</div>
    //             <div className="text-2xl font-bold leading-tight text-orange-600">{topic}</div>
    //             <div className="text-sm text-gray-300">{description}</div>
    //         </div>
    //     </div>
    // );


    // const CarouselItem = ({ imgSrc, author, title, topic, description, isActive, index }) => {
    //     const activeClass = isActive ? 'opacity-100' : 'opacity-50';
    //     const clickItem = (index) => {
    //         setCurrentIndex(index);
    //     }

    //     return (
    //         <div 
    //             className={`cursor-pointer relative w-[300px] h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden ${activeClass}`}
    //             onClick={() => clickItem(index)}
    //         >
    //             <img className='absolute w-full h-full object-cover rounded-2xl' src={imgSrc} alt="" />
    //             <div className="relative text-center pt-[90%]">
    //                 <div className="text-white font-bold tracking-widest">{author}</div>
    //                 <div className="text-white text-2xl font-bold leading-tight">{title}</div>
    //                 <div className="text-2xl font-bold leading-tight text-orange-600">{topic}</div>
    //                 <div className="text-sm text-gray-300">{description}</div>
    //             </div>
    //         </div>
    //     );
    // };



    const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length); // Correctly move to the next item
    };

    const prevItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length); // Correctly move to the previous item
    };

    const orderedItems = [
        items[currentIndex],
        ...items.slice(currentIndex + 1),
        ...items.slice(0, currentIndex),
    ];

    // const orderedItems = rearrangeItems(currentIndex);

    return (
        <Box m="20px">
            <Header 
                title="A.I. Campaign Templates" 
                subtitle="Create your own campaign templates with A.I." 
            />
            <div className='flex-col w-full h-screen'>
                <div className='flex flex-col items-center w-full h-screen round-2xl'>
                    {/* <div className="w-[50%] h-auto justify-center items-center">
                        <MainCarouselItem {...items[currentIndex]} isActive={true} />
                    </div> */}
                    
                    <div className="absolute bottom-12 right-48 h-auto w-[90%] flex flex-row justify-end gap-8 items-center">
                        {/* {items.map((item, index) => (
                            <CarouselItem key={index} {...item} isActive={index === currentIndex} index={index}/>
                        ))} */}
                        {orderedItems.map((item, index) => (
                            <CarouselItem 
                                key={index} 
                                {...item} 
                                isActive={index === 0} 
                                onClick={() => setCurrentIndex((currentIndex + index) % items.length)} 
                            />
                        ))}
                    </div>
                    <div className="absolute bottom-12 left-96 justify-start flex gap-2 items-center w-full">
                        <button onClick={prevItem} className="w-[60px] h-[60px] text-[25px] rounded-full bg-gray-200/30 border-[1px] text-black font-mono font-bold hover:bg-gray-600 hover:text-white">←</button>
                        <button onClick={nextItem} className="w-[60px] h-[60px] text-[25px] rounded-full bg-gray-200/30 border-[1px] text-black font-mono font-bold hover:bg-gray-600 hover:text-white">→</button>
                    </div>
                    
                </div>
            </div>
        </Box>
    );
};

export default CampaignTemplatesAI;