import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { motion } from 'framer-motion';
import { FOOTER_CONTACT_INFO, FOOTER_LINKS, SOCIALS } from '../../constants';
import Footer from '../../components/Footer';
import GetApp from '../../components/GetApp';
import HomeTopBar from '../../components/HomeTopBar';
import mountxLogo from '../../assets/mountxcrmlogo1.png';
import starImg from '../../assets/star.svg';
import meterImg from '../../assets/meter.svg';
import person1Img from '../../assets/person-1.png';
import person2Img from '../../assets/person-2.png';
import person3Img from '../../assets/person-3.png';
import person4Img from '../../assets/person-4.png';
import mountainChosen3 from '../../assets/mountain_chosen3.png';
import mountainChosen5 from '../../assets/mountain_chosen5.png';
import mountainChosen6 from '../../assets/mountain_chosen6.jpg';
import mountainChosen7 from '../../assets/mountain_chosen7.jpg';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';


const FEATURES = [
    {
      title: 'Team Collaboration: Action Requests',
      icon: <PendingActionsOutlinedIcon style={{fontSize: "24px", color: "white"}} />,
      variant: 'green',
      description:
        'Coordinate with your team like never before through Action Requests. Assign tasks, track progress, get notified, and achieve your goals together, all within MountX CRM. You can also create custom rules to trigger action requests based on any custom fields you create.',
    },
    {
      title: 'Healthscores',
      icon: <HealthAndSafetyIcon style={{fontSize: "24px", color: "white"}}/>,
      variant: 'green',
      description:
        "Understand your customers better with our dynamic Healthscore system. Track engagement, satisfaction, and overall relationship health at a glance - empowering you to take action when it matters most.  Customize and edit Healthscores and their properties, track trending healthscores over time, manage and ensure your customers are not at risk.",
    },
    // {
    //   title: 'Leverage A.I.',
    //   icon: techImg,
    //   variant: 'green',
    //   description:
    //     'Generative AI Technology can recommend next best actions, summarize leads, analyze sentiment, and even craft email messages on your behalf. Easily setup automated emails on your behalf so you can do the important things.',
    // },
    {
      title: 'Lifecycle Management',
      icon: <MonitorHeartIcon style={{fontSize: "24px", color: "white"}}/>,
      variant: 'orange',
      description:
        'Navigate your customers through their lifecycle with your brand effortlessly. From acquisition to retention, our tools help you maintain meaningful connections at every stage.  Set custom customer lifecycle stages, track lifecycles over time, and automatically trigger actions or calculate healthscores when customers enter or exits lifecycle stages.',
    },
    {
        title: 'Customer Data Storage',
        icon: <SnippetFolderIcon style={{fontSize: "24px", color: "white"}}/>,
        variant: 'green',
        description:
          'Centralize all your customer information in one secure, easily accessible place. Import customer data easily via CSV or other methods. Say goodbye to scattered data and hello to organized efficiency.',
      },
];


const PEOPLE_URL = [
    person1Img,
    person2Img,
    person3Img,
    person4Img,
];



const Home = () => {

    const navigate = useNavigate();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isOpen, setIsOpen] = useState(false)


    const FeatureItem = ({ title, pic, muiIcon, description }) => {
        return (
          <li className="flex w-full flex-1 flex-col items-start">
            <div className="rounded-full p-4 lg:p-7 bg-blue-300">
                {pic && <img src={pic} alt="map" width={28} height={28} /> }
                {muiIcon && muiIcon }
            </div>
            <h2 className="text-black bold-20 lg:bold-32 mt-5 capitalize">
              {title}
            </h2>
            <p className="regular-16 mt-5 bg-white/80 text-gray-500 lg:mt-[30px] lg:bg-none">
              {description}
            </p>
          </li>
        )
    }

    const CampSite = ({ backgroundImage, title, subtitle, peopleJoined }) => {
        return (
            <div className={`h-full w-full lg:rounded-r-5xl 2xl:rounded-5xl`}>
                <img
                    src={mountainChosen3}
                    alt="mountainchosen3"
                    //   width={500}
                    //   height={500}
                    className='bg-cover w-full h-full bg-no-repeat rounded-2xl lg:rounded-r-5xl 2xl:rounded-5xl z-0'
                />
                <div className="absolute top-14 z-10 flex h-full flex-col items-start justify-between p-6 lg:px-20 lg:py-10">
                    <div className="-ml-8 flexCenter gap-4">
                    <div className="rounded-full p-4">
                        <img
                            src={mountxLogo}
                            alt="map"
                            width={54}
                            height={54}
                            className='rounded-md'
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <h4 className="bold-18 text-black">{title}</h4>
                        <p className="regular-14 text-black">{subtitle}</p>
                    </div>
                    </div>
                    
                    {/* <div className="p-6 lg:px-10 lg:py-10 absolute top-32 left-1 z-10 flexCenter gap-6">
                    <span className="flex -space-x-4 overflow-hidden">
                        {PEOPLE_URL.map((url) => (
                        <img 
                            className="inline-block h-10 w-10 rounded-full"
                            src={url}
                            key={url}
                            alt="person"
                            width={52}
                            height={52}
                        />
                        ))}
                    </span>
                    <p className="bold-16 md:bold-20 text-white">{peopleJoined}</p>
                    </div> */}
                </div>
            </div>
        )
    }



    return (
        <div style={{
            'display': 'flex', 
            'flexDirection': 'column',
            'justifyContent': 'center',
            'alignItems': 'center',
            'backgroundColor': '#fcfcfc',
            'top': '0',
            'left': '0',
            'width': '100%',
            'height': 'auto',
            'position': 'relative',
            }}
        >
            <HomeTopBar />
            {/* End of top navbar */}



            <section 
                id='section1' 
                className="w-full h-[100vh] flex flex-col justify-center items-center relative scrollSnapAlign: start"  // xl:flex-row flex-wrap
            >
                <img 
                    src={mountainChosen6}
                    alt="mountchosenmain"
                    className='w-full h-full object-cover object-center absolute z-0 top-0 left-0'
                    // style={{clipPath: "polygon(0 0, 100% 0, 100% 10%, 0 80%)"}}
                />

                <div 
                    className='flex flex-col justify-start items-center p-[5px] xl:top-[12rem] xl:w-[600px] xl:h-[400px]'
                >
                    <img 
                        src={mountxLogo} 
                        alt="camp"
                        className='h-[50px] w-[50px] rounded-2xl z-50 hidden xl:block xl:absolute xl:top-2 xl:left-8'
                    />
                    <h2
                        className="z-10 mt-8 text-4xl font-bold xl:mt-1 xl:text-[90px] text-black"
                    >
                        MountX CRM
                    </h2>
                    <p 
                        className='z-10 text-black text-center rounded-2xl text-xl xl:text-2xl xl:text-black xl:font-bold xl:mt-8 xl:text-[18px] xl:w-[400px] xl:h-[120px] xl:pt-2' // xl:bg-[#292c27] xl:text-gray-200
                    >
                        Empower your team with Healthscores, Lifecycle Management, and Action Requests.
                    </p>

                    {/* <div className="z-10 my-11 flex flex-wrap gap-5">
                        <div className="flex items-center gap-2">
                            {Array(5).fill(1).map((_, index) => (
                            <img 
                                src={starImg}
                                key={index}
                                alt="star"
                                width={24}
                                height={24}
                            />
                            ))}
                        </div>

                        <p className="bold-16 lg:bold-20 text-blue-500">
                            100+
                            <span className="regular-16 lg:regular-20 ml-1 text-blue-500">Excellent Reviews</span>
                        </p>
                    </div> */}

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        width: '300px',
                        height: '200px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: '1',
                        }}
                    >
                        <button 
                            className='h-[50px] w-[200px] border-[1px] border-[#0A2540] bg-[#0A2540] text-white font-bold text-[18px] rounded-md mr-[1rem] cursor-pointer hover:bg-white hover:text-[#0A2540] hover:border-[#0A2540] transition-all duration-300 ease-in-out'
                            onClick={() => navigate('/register')}
                        >
                            Start now
                        </button>
                        <button 
                            className='h-[50px] w-[200px] ml-[1rem] border-[1px] border-[#0A2540] bg-white text-[#0A2540] font-bold text-[18px] rounded-md cursor-pointer hover:bg-[#7b7b7b] hover:text-white hover:border-[#7b7b7b] transition-all duration-300 ease-in-out'
                            onClick={() => navigate('/contact/sales')}
                        >
                            Contact Sales
                        </button>
                    </div>
                </div>
                <div 
                    className="flex flex-col w-[300px] h-[150px] justify-center items-center z-10 p-[5px] bg-[#292c27] rounded-xl" // mb-32
                >
                    <div className="flex flex-col justify-center">
                        <div className="flexBetween">
                            <p className="text-sm xl:text-xl text-gray-400">Lifecycle Stage</p>
                        </div>
                        <p className="text-xl bold-20 text-white">Onboarding</p>
                    </div>
                    <div className="flex flex-row space-x-8 mt-4">
                        <div className="flex flex-col">
                            <p className="regular-16 block text-gray-400">Days in Stage</p>
                            <p className="bold-20 text-white">23 Days</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="regular-16 block text-gray-400">Risk</p>
                            <p className="bold-20 text-white">Low</p>
                        </div>
                    </div>
                </div>
                {/* <img 
                    src={heroImg}
                    alt="hero"
                    className='h-[30%] w-[30%] z-10 hidden xl:block'  // top-[6rem] right-[1rem] xl:absolute xl:right-0
                /> */}
            </section>
            {/* End of first section */}


            <section 
                id='section2' 
                className="flex flex-col justify-center items-center"
            >
                <div className="padding-container max-container w-full justify-center items-center pt-12 pb-24">
                    <img src={mountxLogo} alt="camp" width={50} height={50} />
                    <p className="uppercase regular-18 -mt-1 mb-3 text-green-500">
                        Let us do the work for you
                    </p>
                    <div className="flex flex-wrap justify-between gap-5 lg:gap-10">
                        <h2 className="text-black bold-40 lg:bold-64 xl:max-w-[390px]">Easy Path to Customer Success</h2>
                        <p className="regular-16 text-gray-500 xl:max-w-[520px]">Only with MountX CRM will you be able to keep track of all your customers, manage your team, get actions done in lightning speed, and ensure your customers stay happy and healthy. With 20+ years of working in the customer success field, experience how we can navigate you and your customers through the wilderness of a customer's lifecycle journey and reach your success criteria.</p>
                    </div>
                </div>

                <div className="flexCenter max-container relative w-full">
                    <img 
                        src={mountainChosen7}
                        alt="mountchosen7"
                        className='w-full object-cover object-center rounded-2xl 2xl:rounded-5xl'
                    />

                    <div className="absolute flex bg-white py-8 pl-5 pr-7 gap-3 rounded-3xl border shadow-md md:left-[5%] lg:top-20">
                    <img 
                        src={meterImg}
                        alt="meter"
                        width={16}
                        height={158}
                        className="h-full w-auto"
                    />
                    <div className="flexBetween flex-col">
                        <div className='flex w-full flex-col'>
                            <div className="flexBetween w-full">
                                <p className="regular-16 text-gray-500">Destination</p>
                                <p className="ml-1 bold-16 text-green-500">Renewal</p>
                            </div>
                            <p className="bold-20 mt-2">Mount Rigi</p>
                        </div>

                        <div className='flex w-full flex-col'>
                            <p className="regular-16 text-gray-500">Start track</p>
                            <h4 className="bold-20 mt-2 whitespace-nowrap text-green-500">Onboarding</h4>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            {/* End of 2nd section */}


            
            <section id='section3' className="flex-col flexCenter overflow-hidden bg-feature-bg bg-center bg-no-repeat py-24">
                <div className="max-container padding-container relative w-full flex justify-end">
                    <div className="flex flex-1 lg:min-h-[900px]">
                    <img
                        src={mountainChosen5}
                        alt="mountainchosen5"
                        width={550}
                        height={1000}
                        className="feature-phone"  // 'feature-phone' has styles applied in the index.css file
                    />
                    </div>

                    <div className="z-20 flex w-full flex-col lg:w-[60%]">
                    <div className='relative'>
                        <img
                            src={mountxLogo}
                            alt="camp"
                            width={50}
                            height={50}
                            className="absolute left-[-5px] top-[-28px] w-10 lg:w-[50px]"
                        />
                        <h2 className="text-black bold-40 lg:bold-64 mt-2">Our Features</h2>
                    </div>
                    <ul className="mt-10 grid gap-10 md:grid-cols-2 lg:mg-20 lg:gap-20">
                        {FEATURES.map((feature) => (
                            <FeatureItem 
                                key={feature.title}
                                title={feature.title} 
                                muiIcon={feature.icon}
                                description={feature.description}
                            />
                        ))}
                    </ul>
                    </div>
                </div>
            </section>
            {/* End of 3rd section */}



            <section id='section4' className="2xl:max-container w-full relative flex flex-col py-10 lg:mb-10 lg:py-20 xl:mb-20">
                <div className="hide-scrollbar flex h-[340px] w-full items-start justify-start gap-8 overflow-x-auto lg:h-[400px] xl:h-[640px]">
                    <CampSite 
                        backgroundImage="bg-bg-img-1"
                        title="MountX CRM"
                        subtitle="CRM & Customer Success Platform"
                        peopleJoined="50+ Joined"
                    />
                </div>

                <div className="flexEnd mt-2 px-6 lg:-mt-60 lg:mr-6">
                    <div className="bg-blue-300 p-4 md:max-w-[250px] lg:max-w-[200px] xl:max-w-[734px] xl:rounded-5xl xl:px-16 xl:py-20 relative w-full overflow-hidden rounded-3xl">
                        <h2 className="regular-12 md:regular-16 2xl:regular-32 capitalize text-white">
                            <strong>Tired of Paying so much</strong> for so little?
                        </h2>
                        <p className="regular-14 xl:regular-16 mt-5 text-white">
                            Start for free for a month, then low monthly rates depending on your business size, if it's not for you then cancel anytime.  We are here to support any business, contact sales now!
                        </p>
                        <div className="mt-4 lg:px-2 lg:py-2 left-1 z-10 flexCenter gap-6">
                            <span className="flex -space-x-4 overflow-hidden">
                                {PEOPLE_URL.map((url) => (
                                <img 
                                    className="inline-block h-10 w-10 rounded-full"
                                    src={url}
                                    key={url}
                                    alt="person"
                                    width={52}
                                    height={52}
                                />
                                ))}
                            </span>
                            <p className="bold-16 md:bold-20 text-white">50+ Joined in the Past Month</p>
                        </div>
                        {/* <img 
                            src="/assets/quote.svg"
                            alt="camp-2"
                            width={186}
                            height={219}
                            className="camp-quote"
                        /> */}
                    </div>
                </div>
            </section>
            {/* End of 4th section */}

            <GetApp />
            {/* End of 5th section */}

            <Footer />

        </div>
    );
};

export default Home;