import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import CircularProgress from '@mui/material/CircularProgress';

import { Formik } from "formik";
import { Box, Button, TextField } from "@mui/material";

import { tokens, useCustomSidebarHeight } from "../../theme";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ToastNotify } from '../../components/ToastNotify';
import { date } from 'yup';


// for recurring campaigns/emails

const operators = [
    {
        "Equal to": "=",
        "Not equal to": "!=",
        "Less than": "<",
        "Less than or equal to": "<=",
        "Greater than": ">",
        "Greater than or equal to": ">=",
        "Between": "between",
        "Contains": "contains",
    }
]

// for recurring campaigns/emails

const Campaigns = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [recurringCampaignName, setRecurringCampaignName] = useState('');

    const [templateName, setTemplateName] = useState('');
    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('');

    const [templatesData, setTemplatesData] = useState([]);

    // for selection of template
    const [selectField, setSelectField] = useState('');
    // for selectin of Frequency
    const [selectFrequencyField, setSelectFrequencyField] = useState('');
    const [selectTimeField, setSelectTimeField] = useState('');
    // for selection of Weekday Frequency
    const [selectWeekdayField, setSelectWeekdayField] = useState('');
    // for selection of Month Frequency in Yearly selection
    const [selectMonthField, setSelectMonthField] = useState('');
    // for selection of Month Day Frequency
    const [selectMonthdayField, setSelectMonthdayField] = useState('');
    // for selection of Quartery month Frequency
    const [selectQuarterlyMonthField, setSelectQuarterlyMonthField] = useState('');

    const [timeZone, setTimeZone] = useState('US/Eastern');

    // customers data for both filtering customers and filtering customer fields for the trigger of emails
    const [customers, setCustomers] = useState([]);
    // for filtering customers (actual selected customers to send emails to)
    const [chosenCustomers, setChosenCustomers] = useState([]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // for filtering customers
    const [fields, setFields] = useState([]);  // this is to store the filtered customer rules, basically all the selected fields, operators, and values 
    const [inputValues] = useState({ inputValue: '' });  // this is only used to display the value for TextField objects after operators (not storing actual input values from user, that gets stored in fields state above) and to assign an id to each TextField that gets generated dynamically from the user clicking button to add more logic

    // for triggering emails
    const [triggerFields, setTriggerFields] = useState([]);  // this is to store the triggers for emails, basically all the selected trigger fields, trigger operators, and trigger values
    
    const [inputTriggerValues] = useState({ inputTriggerValue: '' });  // this is only used to display the value for TextField objects after operators (not storing actual input values from user, that gets stored in triggerFields state above) and to assign an id to each TextField that gets generated dynamically from the user clicking button to add more logic

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
        setIsChecked(event.target.checked);
      };


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    // fetching customers so the user can filter on specific customers to send emails to
    useEffect(() => {
        const fetchCustData = async () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            customer_name: 'Example Customer 1',
                            owner_name: 'Example Owner 1',
                            lifecycle_stage: 'Purchase',
                            healthscore: '90',
                            healthscore_color: 'green',
                            cta_open: '2',
                            industry: 'Example Industry',
                            specialty: 'Example Specialty',
                            last_touch_date: '2023-09-01',
                            cta_count: '2',
                            created_date: '2023-09-01',
                            start_date: '2023-09-01',
                            renewal_date: '2024-09-01',
                }]
                setCustomers(jsonArray);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchCustData();
    }, []);



    // fetching campaign templates
    useEffect(() => {
        const fetchCampaignTemplates = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listtemplates';
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                            })
        
                const result = await response.json();

                // console.log('this is result:')
                // console.log(result)

                // const myJsonString = JSON.stringify(result);
                // const jsonObject = JSON.parse(myJsonString);

                // console.log('this is list templates jsonObject:')
                // console.log(jsonObject)

                if (result === 'No result is returned') {
                    const jsonArray = [{
                                Name: 'Example Campaign Template 1',
                                CreatedTimestamp: '2024-10-01',
                            }]
                    setTemplatesData(jsonArray);
                    setIsLoading(false);
                } else {

                    const myJsonString = JSON.stringify(result);
                    const jsonObject = JSON.parse(myJsonString);

                    const newdataStr = jsonObject.replace(/datetime\.datetime\((\d{4}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d+).*?\)/g, (match, year, month, day, hour, minute, second, micro) => {
                        // Constructing an ISO string representation (ignoring timezone and microseconds for simplicity)
                        return `"${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}"`;
                    });

                    // there was an erroneous paranthesis inside the string after modifying the timestamps, replace ) with empty string
                    const newdataStr2 = newdataStr.replace(/\)/g, '');

                    // replacing single quotes with double quotes for valid json array format
                    const newdataStr3 = newdataStr2.replace(/\[{'/g, '[{"').replace(/'}]/g, '"}]').replace(/', '/g, '", "').replace(/': '/g, '": "').replace(/', "/g, '", "').replace(/", '/g, '", "');

                    // there was still a single quote on one of the values, so I replaced it with double quotes
                    const newdataStr4 = newdataStr3.replace(/\'/g, '"');

                    // console.log('this is newdataStr4:')
                    // console.log(newdataStr4)

                    const jsonArray = JSON.parse(newdataStr4);

                    // console.log('this is jsonArray:')
                    // console.log(jsonArray)

                    const specificArray = jsonArray.filter(item => item.Name.includes(kind_id));
            
                    setTemplatesData(specificArray);
                    setIsLoading(false);
                }
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };
    
        fetchCampaignTemplates();
    }, []);




    // for filtering customers
    const handleChangeField = (event, index, values) => {
        const newFields = [...fields];
        newFields[index].selectField = event.target.value;
        setFields(newFields);
        // console.log('this is fields: ')
        // console.log(fields)
    };

    // for filtering customers
    const handleChangeOperator = (event, index) => {
        const newFields = [...fields];
        newFields[index].selectOperator = event.target.value;
        setFields(newFields);
    };

    // for filtering customers (field input for value of the selected field)
    const handleChangeValue = (event, index) => {
        const newFields = [...fields];
        newFields[index].values = event.target.value;
        setFields(newFields);
    };

    // button function for adding more filters (while also increasing page size) for filtering customers
    const handleAddField = () => {
        // Add a new field to the list
        setFields([...fields, { id: fields.length + 1, selectField: '', selectOperator: '' }]);

        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 30;
        setSidebarHeight(newHeight + 'vh');
    };


    // for selection of template
    const handleChangeTemplate = (event) => {
        setSelectField(event.target.value);
    };

    // for selection of Frequency
    const handleChangeFrequency = (event) => {
        setSelectFrequencyField(event.target.value);
    };

    // for selection of Time
    const handleChangeTime = (event) => {
        setSelectTimeField(event.target.value);
    };

    // for selection of WeekDay Frequency
    const handleChangeWeekdayFrequency = (event) => {
        setSelectWeekdayField(event.target.value);
    };

    // for selection of Month Frequency in Yearly selection
    const handleChangeMonthFrequency = (event) => {
        setSelectMonthField(event.target.value);
    };

    // for selection of Month day Frequency
    const handleChangeMonthdayFrequency = (event) => {
        setSelectMonthdayField(event.target.value);
    };

    // for selection of Quarterly Month Frequency
    const handleChangeQuarterlyMonthFrequency = (event) => {
        setSelectQuarterlyMonthField(event.target.value);
    };

    // for selection of TimeZone
    const handleChangeTimeZone = (event) => {
        setTimeZone(event.target.value);
    };



    const handleFilterCustomers = (event) => {
        event.preventDefault();

        // console.log('this is the fields state: ')
        // console.log(fields)

        try {
            // filter customers based on the logic in the Fields state
            customers.filter((customer) => {
                // for every key inside customer, replace the keys with spaces with underscores and make it lowercase
                const customerModified = Object.fromEntries(Object.entries(customer).map(([key, value]) => [key.replace(/ /g, '_').toLowerCase(), value]));

                fields.map((field) => {
                    if (field.selectOperator === 'Equal to') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values)
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue === dateValue) {
                                // console.log('this is the customer that matches the = filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() === field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the = filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Not equal to') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue != dateValue) {
                                // console.log('this is the customer that matches the != filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() != field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the != filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Less than') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue < dateValue) {
                                // console.log('this is the customer that matches the < filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() < field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the < filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Less than or equal to') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            // console.log('this is the modified field which includes date: ')
                            // console.log(modifiedField)
                            const dateValue = new Date(field.values);
                            // console.log('this is customerModified[modifiedField]: ')
                            // console.log(customerModified[modifiedField])
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue <= dateValue) {
                                // console.log('this is the customer that matches the <= filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() <= field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the <= filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Greater than') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue > dateValue) {
                                // console.log('this is the customer that matches the > filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() > field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the > filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Greater than or equal to') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue >= dateValue) {
                                // console.log('this is the customer that matches the >= filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase() >= field.values.toLowerCase()) {
                            // console.log('this is the customer that matches the >= filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Between') {
                        const valuesArray = field.values.split(',');
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue1 = new Date(valuesArray[0]);
                            const dateValue2 = new Date(valuesArray[1]);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue > dateValue1 && dateCustValue < dateValue2) {
                                // console.log('this is the customer that matches the date between filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField] > valuesArray[0] && customerModified[field.selectField] < valuesArray[1]) {
                            // console.log('this is the customer that matches the between filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    } else if (field.selectOperator === 'Contains') {
                        const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                        if (modifiedField.includes('date')) {
                            const dateValue = new Date(field.values);
                            const dateCustValue = new Date(customerModified[modifiedField]);
                            if (dateCustValue == dateValue) {
                                // console.log('this is the customer that matches the date contains filter: ')
                                // console.log(customer)
                                const emailAddress = customerModified['email_address']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({email: emailAddress, name: custName});
                            }
                        }
                        if (customerModified[modifiedField].toLowerCase().includes(field.values.toLowerCase())) {
                            // console.log('this is the customer that matches the contain filter: ')
                            // console.log(customer)
                            const emailAddress = customerModified['email_address']
                            const custName = customerModified['customer_name']
                            chosenCustomers.push({email: emailAddress, name: custName});
                        }
                    }
                })
            })
        } catch (error) {
            console.error('Error filtering customers:', error);
        }

        // count the number of times a customer appears in the chosenCustomers array
        const counts = chosenCustomers.reduce((a, c) => {
            a[c.name] = (a[c.name] || 0) + 1;
            return a;
        }, {});

        // console.log('this is the counts: ')
        // console.log(counts)

        // only select the customers with the highest count, if they're are ties then select the ones who tied
        const maxCount = Math.max(...Object.values(counts));
        const maxCountCustomers = Object.keys(counts).filter(k => counts[k] === maxCount);

        // console.log('this is the maxCountCustomers: ')
        // console.log(maxCountCustomers)

        // filter the chosenCustomers array to only include the customers from maxCountCustomers
        const filteredCustomers = chosenCustomers.filter((customer) => maxCountCustomers.includes(customer.name));

        // removing duplicates from the chosenCustomers array
        const uniqueCustomers = [...new Map(filteredCustomers.map(item => [item['name'], item])).values()];
        // console.log('this is the unique customers: ')
        // console.log(uniqueCustomers)
        setChosenCustomers(uniqueCustomers);
    };


    // data rules for triggering emails
    const handleChangeTriggerField = (event, index) => {
        const newFields = [...triggerFields];
        // 'selectField' below is the key that is in the json array, the index in newFields[index] is 0 if user only adds one rule logic, 1 if user adds two rules logic, and so on.
        newFields[index].selectField = event.target.value;
        setTriggerFields(newFields);
        // console.log('this is fields: ')
        // console.log(fields)
    };

    const handleChangeTriggerOperator = (event, index) => {
        const newFields = [...triggerFields];
        // 'selectOperator' below is the key that is in the json array, the index in newFields[index] is 0 if user only adds one rule logic, 1 if user adds two rules logic, and so on.
        newFields[index].selectOperator = event.target.value;
        setTriggerFields(newFields);
    };


    const handleChangeTriggerValue = (event, index) => {
        const newFields = [...triggerFields];
        // 'values' below is the key that is in the json array, the index in newFields[index] is 0 if user only adds one rule logic, 1 if user adds two rules logic, and so on.
        newFields[index].values = event.target.value;
        setTriggerFields(newFields);
    };

    // when user clicks the button to add a new rule for the trigger, it will set the triggerFields state with a new field
    const handleAddTriggerField = () => {
        // Add a new field to the list
        setTriggerFields([...triggerFields, { id: triggerFields.length + 1 }]);

        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 30;
        setSidebarHeight(newHeight + 'vh');
    };



    // Recurring Campaign name is going to be the trigger_name inside datastore
    const handleChangeRecurringCampaignName = (event) => {
        setRecurringCampaignName(event.target.value);
    };


    // final submit / schedule button
    // to save the trigger rules and selected template and selected frequency and customer filter for sending emails
    const handleFormTriggerSubmit = async () => {
        // console.log('this is the trigger fields: ')
        // console.log(triggerFields)

        // console.log('this is the filtered customers fields')
        // console.log(fields)

        // console.log('this is selected template: ')
        // console.log(selectField)

        // console.log('this is selected frequency: ')
        // console.log(selectFrequencyField)

        const checkUser = localStorage.getItem('user');
    
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
    
        const NewTriggerData = {}
    
        // add kind_id to NewTriggerData
        NewTriggerData["kind_id"] = kind_id;
        // Recurring Campaign name is going to be the trigger_name inside datastore
        NewTriggerData["trigger_name"] = recurringCampaignName;
        // required object_type field
        NewTriggerData["object_type"] = "emailtrigger";
        // required created_date field
        const today = new Date().toISOString().slice(0, 10);
        NewTriggerData["created_date"] = today;
        // required template name field
        NewTriggerData["template_name"] = selectField;
        // required frequency field
        NewTriggerData["frequency"] = selectFrequencyField;
        // required send hourtime field
        NewTriggerData["send_hourtime"] = selectTimeField;
        NewTriggerData["weekday_frequency"] = selectWeekdayField;
        NewTriggerData["monthday_frequency"] = selectMonthdayField;
        NewTriggerData["quarterly_month_frequency"] = selectQuarterlyMonthField;
        // this month frequency is only used for yearly frequency
        NewTriggerData["month_frequency"] = selectMonthField;
        // required timezone field
        NewTriggerData["cust_time_zone"] = timeZone;
        // required filter customers field
        NewTriggerData["filter_customers_rule"] = fields;
        // required trigger rules field
        NewTriggerData["trigger_rules"] = triggerFields;


        // run trigger now? field (true or false)
        if (isChecked === true) {
            // run rule now
        }
        

        // console.log('this is the NewTriggerData: ')
        // console.log(NewTriggerData)
    
        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        const json_data = {"kind_id": kind_id, 
                            "data": NewTriggerData};

        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers,
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('New Rule Action Request has been created:');
                // console.log(result);
                ToastNotify('success', 'Schedued Email Rule has been created');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Error, something went wrong.');
            });


    };




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <div
            className='w-full h-auto flex-col justify-between items-center m-1 p-2.5'
        >
            <Box m="10px">
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
                <Header
                        title="Recurring Emails"
                        subtitle="Send recurring emails to your customers."
                />
                <div 
                    className='app__tierlevel-container'
                    style={{'marginTop': '20px', 'marginBottom':'20px'}}
                >
                </div>
                <div className='app__container'>
                    <Box sx={{ width: '100%' }}>
                        <div 
                            style={{'display': 'flex', 'flexDirection': 'column'}}
                        >
                            <div 
                                style={{'width': '100%', 
                                        'height': 'auto', 
                                        'display': 'flex', 
                                        'flexDirection': 'column', 
                                        'justifyContent': 'center'
                                    }}
                            >
                                <div
                                    className='flex flex-col gap-4 items-start p-4 h-auto'
                                >
                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2
                                            className='font-bold text-2xl'
                                        >
                                            Step 1: Filter for Customers
                                        </h2>
                                        <Formik
                                        >
                                            {({
                                            values,
                                            errors,
                                            touched,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            }) => (
                                            <form onSubmit={handleSubmit}>

                                            {fields.map((field, index) => (
                                                <Box key={field.id}>
                                                    <div className="span-4">
                                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                            <InputLabel id={`field-label-${field.id}`}>Select a Field</InputLabel>
                                                            <Select
                                                                labelId={`field-label-${field.id}`}
                                                                id={`field-select-${field.id}`}
                                                                value={field.selectField}
                                                                label="Select Field"
                                                                onChange={(event) => handleChangeField(event, index, values)}
                                                            >
                                                                {customers.slice(0,1).map((item) => (
                                                                    Object.entries(item).map(([key]) => {
                                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                        if (key.includes('id') || key.includes('object_type') || key.includes('key_id') || 
                                                                            key.includes('score_history') || key.includes('healthscore_reasons') || key.includes('notes') ) {
                                                                            return null;
                                                                        } else {
                                                                        return (
                                                                            <MenuItem key={key} value={key}>{formattedKey}</MenuItem>
                                                                        );
                                                                        }
                                                                    })
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>Select from Customer Fields</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <div className="span-4">
                                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                            <InputLabel id={`operator-label-${field.id}`}>Operators</InputLabel>
                                                            <Select
                                                                labelId={`operator-label-${field.id}`}
                                                                id={`operator-select-${field.id}`}
                                                                value={field.selectOperator}
                                                                label="Select Operator"
                                                                onChange={(event) => handleChangeOperator(event, index)}
                                                            >
                                                                {operators.map((item) => (
                                                                    Object.entries(item).map(([key]) => {
                                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                        return (
                                                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                                        );
                                                                    })
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>Select an Operator</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <TextField
                                                        id={`input-value-${field.id}`}
                                                        fullWidth
                                                        variant="filled"
                                                        type="text"
                                                        label="Value"
                                                        onBlur={handleBlur}
                                                        onChange={(event) => handleChangeValue(event, index)}  // sets the value for the field in the state 'fields' which is an array
                                                        value={inputValues[`inputValue${field.id}`]}
                                                        name="inputValue"
                                                        error={!!touched.inputValue && !!errors.inputValue}
                                                        helperText={touched.inputValue && errors.inputValue}
                                                        sx={{ gridColumn: "span 4", marginTop: "1rem"}}
                                                    />
                                                    <div className='mt-1'>
                                                        <p className='text-gray-500 text-xs'>
                                                            Set the Value for the action request to trigger. Multiple values must be separated by commas.
                                                        </p>
                                                    </div>
                                                </Box>
                                            ))}
                                            <Button
                                                sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={handleAddField}
                                                >+ Add Filter
                                            </Button>
                                            <Button
                                                sx={{ marginTop: "2rem", marginLeft: {xs: "0rem", md: "2rem"}, width: 165, border: '1px solid black', }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={(event) => handleFilterCustomers(event)}
                                                > Set Filter
                                            </Button>
                                            </form>
                                            )}
                                        </Formik>

                                        <h2
                                            className='font-bold text-lg mt-8'
                                        >
                                            Filtered Count:  {chosenCustomers.length}
                                        </h2>
                                        <h2
                                            className='font-bold text-lg mt-1'
                                        >
                                            Filtered Customers:
                                        </h2>
                                        <textarea 
                                            className='text-black border-[1px] border-gray-500 w-full h-[200px]' 
                                            readOnly
                                            value={chosenCustomers.map((item) => ' ' + item.name + ': ' + item.email + '\n')} 
                                        />
                                    </div>
                                    

                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2 className='font-bold text-2xl'>Step 2: Select a Campaign Template</h2>
                                        <div
                                            className="w-full h-auto flex-col justify-start"
                                        >
                                            <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                <InputLabel id="demo-simple-select-helper-label">Templates</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={selectField}
                                                    label="Select Field"
                                                    onChange={handleChangeTemplate}
                                                >
                                                    {   templatesData.map((item) => (
                                                        Object.entries(item).map(([key, value]) => {
                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                        if (key.includes('Name')) {
                                                            const newValue = value.substring(20).replace(/\_/g, ' ');
                                                            return (
                                                                <MenuItem value={value}>{newValue}</MenuItem>
                                                            )
                                                            } else {
                                                                return null;
                                                            }
                                                    })
                                                    ))}
                                                </Select>
                                                <FormHelperText>Select Template</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    


                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2 className='font-bold text-2xl'>Step 3: Select the Frequency</h2>
                                        <div
                                            className="w-full h-auto flex-col justify-start mt-4"
                                        >
                                            <h2 className='font-bold text-md'>Set the frequency to trigger and send emails</h2>
                                            <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                <InputLabel id="simple-select-frequency-label">Frequency</InputLabel>
                                                <Select
                                                    labelId="simple-select-frequency-label"
                                                    id="simple-select-frequency"
                                                    value={selectFrequencyField}
                                                    label="Select Field"
                                                    onChange={handleChangeFrequency}
                                                >
                                                    <MenuItem value="daily">Daily</MenuItem>
                                                    <MenuItem value="weekly">Weekly</MenuItem>
                                                    <MenuItem value="monthly">Monthly</MenuItem>
                                                    <MenuItem value="quarterly">Quarterly</MenuItem>
                                                    <MenuItem value="yearly">Every Year</MenuItem>
                                                </Select>
                                                <FormHelperText>Select Frequency</FormHelperText>
                                            </FormControl>
                                            {selectFrequencyField === 'daily' ? (
                                                <div className='mt-1'>
                                                    <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                        <InputLabel id="simple-select-time-label">Time Selection</InputLabel>
                                                        <Select
                                                            labelId="simple-select-time-label"
                                                            id="simple-select-time"
                                                            value={selectTimeField}
                                                            label="Select Field"
                                                            onChange={handleChangeTime}
                                                        >
                                                            <MenuItem value="1">1:00 a.m.</MenuItem>
                                                            <MenuItem value="2">2:00 a.m.</MenuItem>
                                                            <MenuItem value="3">3:00 a.m.</MenuItem>
                                                            <MenuItem value="4">4:00 a.m.</MenuItem>
                                                            <MenuItem value="5">5:00 a.m.</MenuItem>
                                                            <MenuItem value="6">6:00 a.m.</MenuItem>
                                                            <MenuItem value="7">7:00 a.m.</MenuItem>
                                                            <MenuItem value="8">8:00 a.m.</MenuItem>
                                                            <MenuItem value="9">9:00 a.m.</MenuItem>
                                                            <MenuItem value="10">10:00 a.m.</MenuItem>
                                                            <MenuItem value="11">11:00 a.m.</MenuItem>
                                                            <MenuItem value="12">12:00 p.m.</MenuItem>
                                                            <MenuItem value="13">13:00 p.m.</MenuItem>
                                                            <MenuItem value="14">14:00 p.m.</MenuItem>
                                                            <MenuItem value="15">15:00 p.m.</MenuItem>
                                                            <MenuItem value="16">16:00 p.m.</MenuItem>
                                                            <MenuItem value="17">17:00 p.m.</MenuItem>
                                                            <MenuItem value="18">18:00 p.m.</MenuItem>
                                                            <MenuItem value="19">19:00 p.m.</MenuItem>
                                                            <MenuItem value="20">20:00 p.m.</MenuItem>
                                                            <MenuItem value="21">21:00 p.m.</MenuItem>
                                                            <MenuItem value="22">22:00 p.m.</MenuItem>
                                                            <MenuItem value="23">23:00 p.m.</MenuItem>
                                                            <MenuItem value="24">24:00 a.m.</MenuItem>
                                                        </Select>
                                                        <FormHelperText>Select Time</FormHelperText>
                                                    </FormControl>
                                                    <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                        <InputLabel id="simple-select-timezone-label">Timezone Selection</InputLabel>
                                                        <Select
                                                            labelId="simple-select-timezone-label"
                                                            id="simple-select-timezone"
                                                            value={timeZone}
                                                            label="Select Field"
                                                            onChange={handleChangeTimeZone}
                                                        >
                                                            <MenuItem value="US/Eastern">US/Eastern</MenuItem>
                                                            <MenuItem value="US/Central">US/Central</MenuItem>
                                                            <MenuItem value="US/Pacific">US/Pacific</MenuItem>
                                                            <MenuItem value="UTC">UTC</MenuItem>
                                                            <MenuItem value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
                                                            <MenuItem value="Europe/Berlin">Europe/Berlin</MenuItem>
                                                            <MenuItem value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
                                                            <MenuItem value="Europe/London">Europe/London</MenuItem>
                                                            <MenuItem value="Europe/Madrid">Europe/Madrid</MenuItem>
                                                            <MenuItem value="Europe/Paris">Europe/Paris</MenuItem>
                                                            <MenuItem value="Europe/Rome">Europe/Rome</MenuItem>
                                                            <MenuItem value="EuropeAmsterdam">EuropeAmsterdam</MenuItem>
                                                            <MenuItem value="Europe/Zurich">Europe/Zurich</MenuItem>
                                                            <MenuItem value="Asia/Bangkok">Asia/Bangkok</MenuItem>
                                                            <MenuItem value="Asia/Dubai">Asia/Dubai</MenuItem>
                                                            <MenuItem value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
                                                            <MenuItem value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
                                                            <MenuItem value="Asia/Istanbul">Asia/Istanbul</MenuItem>
                                                            <MenuItem value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
                                                            <MenuItem value="Asia/Manila">Asia/Manila</MenuItem>
                                                            <MenuItem value="Asia/Qatar">Asia/Qatar</MenuItem>
                                                            <MenuItem value="Asia/Seoul">Asia/Seoul</MenuItem>
                                                            <MenuItem value="Asia/Taipei">Asia/Taipei</MenuItem>
                                                            <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
                                                        </Select>
                                                        <FormHelperText>Select Timezone</FormHelperText>
                                                    </FormControl>
                                                    <p className='text-gray-500 text-xs mt-4'>
                                                        Setting the frequency to trigger emails every day at hour {selectTimeField}.
                                                    </p>
                                                </div>
                                                ) : selectFrequencyField === 'weekly' ? (
                                                    <div className='mt-1'>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-weekday-label">Weekday Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-weekday-label"
                                                                id="simple-select-weekday"
                                                                value={selectWeekdayField}
                                                                label="Select Field"
                                                                onChange={handleChangeWeekdayFrequency}
                                                            >
                                                                <MenuItem value="monday">Monday</MenuItem>
                                                                <MenuItem value="tuesday">Tuesday</MenuItem>
                                                                <MenuItem value="wednesday">Wednesday</MenuItem>
                                                                <MenuItem value="thursday">Thursday</MenuItem>
                                                                <MenuItem value="friday">Friday</MenuItem>
                                                                <MenuItem value="saturday">Saturday</MenuItem>
                                                                <MenuItem value="sunday">Sunday</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Day of the Week</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-time-label">Time Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-time-label"
                                                                id="simple-select-time"
                                                                value={selectTimeField}
                                                                label="Select Field"
                                                                onChange={handleChangeTime}
                                                            >
                                                                <MenuItem value="1">1:00 a.m.</MenuItem>
                                                                <MenuItem value="2">2:00 a.m.</MenuItem>
                                                                <MenuItem value="3">3:00 a.m.</MenuItem>
                                                                <MenuItem value="4">4:00 a.m.</MenuItem>
                                                                <MenuItem value="5">5:00 a.m.</MenuItem>
                                                                <MenuItem value="6">6:00 a.m.</MenuItem>
                                                                <MenuItem value="7">7:00 a.m.</MenuItem>
                                                                <MenuItem value="8">8:00 a.m.</MenuItem>
                                                                <MenuItem value="9">9:00 a.m.</MenuItem>
                                                                <MenuItem value="10">10:00 a.m.</MenuItem>
                                                                <MenuItem value="11">11:00 a.m.</MenuItem>
                                                                <MenuItem value="12">12:00 p.m.</MenuItem>
                                                                <MenuItem value="13">13:00 p.m.</MenuItem>
                                                                <MenuItem value="14">14:00 p.m.</MenuItem>
                                                                <MenuItem value="15">15:00 p.m.</MenuItem>
                                                                <MenuItem value="16">16:00 p.m.</MenuItem>
                                                                <MenuItem value="17">17:00 p.m.</MenuItem>
                                                                <MenuItem value="18">18:00 p.m.</MenuItem>
                                                                <MenuItem value="19">19:00 p.m.</MenuItem>
                                                                <MenuItem value="20">20:00 p.m.</MenuItem>
                                                                <MenuItem value="21">21:00 p.m.</MenuItem>
                                                                <MenuItem value="22">22:00 p.m.</MenuItem>
                                                                <MenuItem value="23">23:00 p.m.</MenuItem>
                                                                <MenuItem value="24">24:00 a.m.</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Time</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-timezone-label">Timezone Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-timezone-label"
                                                                id="simple-select-timezone"
                                                                value={timeZone}
                                                                label="Select Field"
                                                                onChange={handleChangeTimeZone}
                                                            >
                                                                <MenuItem value="US/Eastern">US/Eastern</MenuItem>
                                                                <MenuItem value="US/Central">US/Central</MenuItem>
                                                                <MenuItem value="US/Pacific">US/Pacific</MenuItem>
                                                                <MenuItem value="UTC">UTC</MenuItem>
                                                                <MenuItem value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
                                                                <MenuItem value="Europe/Berlin">Europe/Berlin</MenuItem>
                                                                <MenuItem value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
                                                                <MenuItem value="Europe/London">Europe/London</MenuItem>
                                                                <MenuItem value="Europe/Madrid">Europe/Madrid</MenuItem>
                                                                <MenuItem value="Europe/Paris">Europe/Paris</MenuItem>
                                                                <MenuItem value="Europe/Rome">Europe/Rome</MenuItem>
                                                                <MenuItem value="EuropeAmsterdam">EuropeAmsterdam</MenuItem>
                                                                <MenuItem value="Europe/Zurich">Europe/Zurich</MenuItem>
                                                                <MenuItem value="Asia/Bangkok">Asia/Bangkok</MenuItem>
                                                                <MenuItem value="Asia/Dubai">Asia/Dubai</MenuItem>
                                                                <MenuItem value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
                                                                <MenuItem value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
                                                                <MenuItem value="Asia/Istanbul">Asia/Istanbul</MenuItem>
                                                                <MenuItem value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
                                                                <MenuItem value="Asia/Manila">Asia/Manila</MenuItem>
                                                                <MenuItem value="Asia/Qatar">Asia/Qatar</MenuItem>
                                                                <MenuItem value="Asia/Seoul">Asia/Seoul</MenuItem>
                                                                <MenuItem value="Asia/Taipei">Asia/Taipei</MenuItem>
                                                                <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Timezone</FormHelperText>
                                                        </FormControl>
                                                        <p className='text-gray-500 text-xs mt-4'>
                                                            Setting the frequency to trigger emails every week on {selectWeekdayField} at hour {selectTimeField}.
                                                        </p>
                                                    </div>
                                                    ) : selectFrequencyField === 'monthly' ? (
                                                    <div className='mt-1'>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-monthday-label">Day Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-monthday-label"
                                                                id="simple-select-monthday"
                                                                value={selectMonthdayField}
                                                                label="Select Field"
                                                                onChange={handleChangeMonthdayFrequency}
                                                            >
                                                                <MenuItem value="1">1</MenuItem>
                                                                <MenuItem value="2">2</MenuItem>
                                                                <MenuItem value="3">3</MenuItem>
                                                                <MenuItem value="4">4</MenuItem>
                                                                <MenuItem value="5">5</MenuItem>
                                                                <MenuItem value="6">6</MenuItem>
                                                                <MenuItem value="7">7</MenuItem>
                                                                <MenuItem value="8">8</MenuItem>
                                                                <MenuItem value="9">9</MenuItem>
                                                                <MenuItem value="10">10</MenuItem>
                                                                <MenuItem value="11">11</MenuItem>
                                                                <MenuItem value="12">12</MenuItem>
                                                                <MenuItem value="13">13</MenuItem>
                                                                <MenuItem value="14">14</MenuItem>
                                                                <MenuItem value="15">15</MenuItem>
                                                                <MenuItem value="16">16</MenuItem>
                                                                <MenuItem value="17">17</MenuItem>
                                                                <MenuItem value="18">18</MenuItem>
                                                                <MenuItem value="19">19</MenuItem>
                                                                <MenuItem value="20">20</MenuItem>
                                                                <MenuItem value="21">21</MenuItem>
                                                                <MenuItem value="22">22</MenuItem>
                                                                <MenuItem value="23">23</MenuItem>
                                                                <MenuItem value="24">24</MenuItem>
                                                                <MenuItem value="25">25</MenuItem>
                                                                <MenuItem value="26">26</MenuItem>
                                                                <MenuItem value="27">27</MenuItem>
                                                                <MenuItem value="28">28</MenuItem>
                                                                <MenuItem value="29">29</MenuItem>
                                                                <MenuItem value="30">30</MenuItem>
                                                                <MenuItem value="31">31</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Day of the Month</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-time-label">Time Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-time-label"
                                                                id="simple-select-time"
                                                                value={selectTimeField}
                                                                label="Select Field"
                                                                onChange={handleChangeTime}
                                                            >
                                                                <MenuItem value="1">1:00 a.m.</MenuItem>
                                                                <MenuItem value="2">2:00 a.m.</MenuItem>
                                                                <MenuItem value="3">3:00 a.m.</MenuItem>
                                                                <MenuItem value="4">4:00 a.m.</MenuItem>
                                                                <MenuItem value="5">5:00 a.m.</MenuItem>
                                                                <MenuItem value="6">6:00 a.m.</MenuItem>
                                                                <MenuItem value="7">7:00 a.m.</MenuItem>
                                                                <MenuItem value="8">8:00 a.m.</MenuItem>
                                                                <MenuItem value="9">9:00 a.m.</MenuItem>
                                                                <MenuItem value="10">10:00 a.m.</MenuItem>
                                                                <MenuItem value="11">11:00 a.m.</MenuItem>
                                                                <MenuItem value="12">12:00 p.m.</MenuItem>
                                                                <MenuItem value="13">13:00 p.m.</MenuItem>
                                                                <MenuItem value="14">14:00 p.m.</MenuItem>
                                                                <MenuItem value="15">15:00 p.m.</MenuItem>
                                                                <MenuItem value="16">16:00 p.m.</MenuItem>
                                                                <MenuItem value="17">17:00 p.m.</MenuItem>
                                                                <MenuItem value="18">18:00 p.m.</MenuItem>
                                                                <MenuItem value="19">19:00 p.m.</MenuItem>
                                                                <MenuItem value="20">20:00 p.m.</MenuItem>
                                                                <MenuItem value="21">21:00 p.m.</MenuItem>
                                                                <MenuItem value="22">22:00 p.m.</MenuItem>
                                                                <MenuItem value="23">23:00 p.m.</MenuItem>
                                                                <MenuItem value="24">24:00 a.m.</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Time</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-timezone-label">Timezone Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-timezone-label"
                                                                id="simple-select-timezone"
                                                                value={timeZone}
                                                                label="Select Field"
                                                                onChange={handleChangeTimeZone}
                                                            >
                                                                <MenuItem value="US/Eastern">US/Eastern</MenuItem>
                                                                <MenuItem value="US/Central">US/Central</MenuItem>
                                                                <MenuItem value="US/Pacific">US/Pacific</MenuItem>
                                                                <MenuItem value="UTC">UTC</MenuItem>
                                                                <MenuItem value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
                                                                <MenuItem value="Europe/Berlin">Europe/Berlin</MenuItem>
                                                                <MenuItem value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
                                                                <MenuItem value="Europe/London">Europe/London</MenuItem>
                                                                <MenuItem value="Europe/Madrid">Europe/Madrid</MenuItem>
                                                                <MenuItem value="Europe/Paris">Europe/Paris</MenuItem>
                                                                <MenuItem value="Europe/Rome">Europe/Rome</MenuItem>
                                                                <MenuItem value="EuropeAmsterdam">EuropeAmsterdam</MenuItem>
                                                                <MenuItem value="Europe/Zurich">Europe/Zurich</MenuItem>
                                                                <MenuItem value="Asia/Bangkok">Asia/Bangkok</MenuItem>
                                                                <MenuItem value="Asia/Dubai">Asia/Dubai</MenuItem>
                                                                <MenuItem value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
                                                                <MenuItem value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
                                                                <MenuItem value="Asia/Istanbul">Asia/Istanbul</MenuItem>
                                                                <MenuItem value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
                                                                <MenuItem value="Asia/Manila">Asia/Manila</MenuItem>
                                                                <MenuItem value="Asia/Qatar">Asia/Qatar</MenuItem>
                                                                <MenuItem value="Asia/Seoul">Asia/Seoul</MenuItem>
                                                                <MenuItem value="Asia/Taipei">Asia/Taipei</MenuItem>
                                                                <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Timezone</FormHelperText>
                                                        </FormControl>
                                                        <p className='text-gray-500 text-xs mt-4'>
                                                            Setting the frequency to trigger emails every month on day {selectMonthdayField} at hour {selectTimeField}.
                                                        </p>
                                                    </div>
                                                ) : selectFrequencyField === 'quarterly' ? (
                                                    <div className='mt-1'>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-quarterly-label">Month Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-quarterly-label"
                                                                id="simple-select-quarterly"
                                                                value={selectQuarterlyMonthField}
                                                                label="Select Field"
                                                                onChange={handleChangeQuarterlyMonthFrequency}
                                                            >
                                                                <MenuItem value="1st month">1st Month of the Quarter</MenuItem>
                                                                <MenuItem value="2nd month">2nd Month of the Quarter</MenuItem>
                                                                <MenuItem value="3rd month">3rd Month of the Quarter</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select the Month in the Quarter</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-quarterly-day-label">Day Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-quarterly-day-label"
                                                                id="simple-select-quarterly-day"
                                                                value={selectMonthdayField}
                                                                label="Select Field"
                                                                onChange={handleChangeMonthdayFrequency}
                                                            >
                                                                <MenuItem value="1">1</MenuItem>
                                                                <MenuItem value="2">2</MenuItem>
                                                                <MenuItem value="3">3</MenuItem>
                                                                <MenuItem value="4">4</MenuItem>
                                                                <MenuItem value="5">5</MenuItem>
                                                                <MenuItem value="6">6</MenuItem>
                                                                <MenuItem value="7">7</MenuItem>
                                                                <MenuItem value="8">8</MenuItem>
                                                                <MenuItem value="9">9</MenuItem>
                                                                <MenuItem value="10">10</MenuItem>
                                                                <MenuItem value="11">11</MenuItem>
                                                                <MenuItem value="12">12</MenuItem>
                                                                <MenuItem value="13">13</MenuItem>
                                                                <MenuItem value="14">14</MenuItem>
                                                                <MenuItem value="15">15</MenuItem>
                                                                <MenuItem value="16">16</MenuItem>
                                                                <MenuItem value="17">17</MenuItem>
                                                                <MenuItem value="18">18</MenuItem>
                                                                <MenuItem value="19">19</MenuItem>
                                                                <MenuItem value="20">20</MenuItem>
                                                                <MenuItem value="21">21</MenuItem>
                                                                <MenuItem value="22">22</MenuItem>
                                                                <MenuItem value="23">23</MenuItem>
                                                                <MenuItem value="24">24</MenuItem>
                                                                <MenuItem value="25">25</MenuItem>
                                                                <MenuItem value="26">26</MenuItem>
                                                                <MenuItem value="27">27</MenuItem>
                                                                <MenuItem value="28">28</MenuItem>
                                                                <MenuItem value="29">29</MenuItem>
                                                                <MenuItem value="30">30</MenuItem>
                                                                <MenuItem value="31">31</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Day of the Month</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-time-label">Time Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-time-label"
                                                                id="simple-select-time"
                                                                value={selectTimeField}
                                                                label="Select Field"
                                                                onChange={handleChangeTime}
                                                            >
                                                                <MenuItem value="1">1:00 a.m.</MenuItem>
                                                                <MenuItem value="2">2:00 a.m.</MenuItem>
                                                                <MenuItem value="3">3:00 a.m.</MenuItem>
                                                                <MenuItem value="4">4:00 a.m.</MenuItem>
                                                                <MenuItem value="5">5:00 a.m.</MenuItem>
                                                                <MenuItem value="6">6:00 a.m.</MenuItem>
                                                                <MenuItem value="7">7:00 a.m.</MenuItem>
                                                                <MenuItem value="8">8:00 a.m.</MenuItem>
                                                                <MenuItem value="9">9:00 a.m.</MenuItem>
                                                                <MenuItem value="10">10:00 a.m.</MenuItem>
                                                                <MenuItem value="11">11:00 a.m.</MenuItem>
                                                                <MenuItem value="12">12:00 p.m.</MenuItem>
                                                                <MenuItem value="13">13:00 p.m.</MenuItem>
                                                                <MenuItem value="14">14:00 p.m.</MenuItem>
                                                                <MenuItem value="15">15:00 p.m.</MenuItem>
                                                                <MenuItem value="16">16:00 p.m.</MenuItem>
                                                                <MenuItem value="17">17:00 p.m.</MenuItem>
                                                                <MenuItem value="18">18:00 p.m.</MenuItem>
                                                                <MenuItem value="19">19:00 p.m.</MenuItem>
                                                                <MenuItem value="20">20:00 p.m.</MenuItem>
                                                                <MenuItem value="21">21:00 p.m.</MenuItem>
                                                                <MenuItem value="22">22:00 p.m.</MenuItem>
                                                                <MenuItem value="23">23:00 p.m.</MenuItem>
                                                                <MenuItem value="24">24:00 a.m.</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Time</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-timezone-label">Timezone Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-timezone-label"
                                                                id="simple-select-timezone"
                                                                value={timeZone}
                                                                label="Select Field"
                                                                onChange={handleChangeTimeZone}
                                                            >
                                                                <MenuItem value="US/Eastern">US/Eastern</MenuItem>
                                                                <MenuItem value="US/Central">US/Central</MenuItem>
                                                                <MenuItem value="US/Pacific">US/Pacific</MenuItem>
                                                                <MenuItem value="UTC">UTC</MenuItem>
                                                                <MenuItem value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
                                                                <MenuItem value="Europe/Berlin">Europe/Berlin</MenuItem>
                                                                <MenuItem value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
                                                                <MenuItem value="Europe/London">Europe/London</MenuItem>
                                                                <MenuItem value="Europe/Madrid">Europe/Madrid</MenuItem>
                                                                <MenuItem value="Europe/Paris">Europe/Paris</MenuItem>
                                                                <MenuItem value="Europe/Rome">Europe/Rome</MenuItem>
                                                                <MenuItem value="EuropeAmsterdam">EuropeAmsterdam</MenuItem>
                                                                <MenuItem value="Europe/Zurich">Europe/Zurich</MenuItem>
                                                                <MenuItem value="Asia/Bangkok">Asia/Bangkok</MenuItem>
                                                                <MenuItem value="Asia/Dubai">Asia/Dubai</MenuItem>
                                                                <MenuItem value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
                                                                <MenuItem value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
                                                                <MenuItem value="Asia/Istanbul">Asia/Istanbul</MenuItem>
                                                                <MenuItem value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
                                                                <MenuItem value="Asia/Manila">Asia/Manila</MenuItem>
                                                                <MenuItem value="Asia/Qatar">Asia/Qatar</MenuItem>
                                                                <MenuItem value="Asia/Seoul">Asia/Seoul</MenuItem>
                                                                <MenuItem value="Asia/Taipei">Asia/Taipei</MenuItem>
                                                                <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Timezone</FormHelperText>
                                                        </FormControl>
                                                        <p className='text-gray-500 text-xs mt-4'>
                                                            Setting the frequency to send emails every quarter on the {selectQuarterlyMonthField} on Day {selectMonthdayField} at hour {selectTimeField}.
                                                        </p>
                                                    </div>
                                                ) : selectFrequencyField === 'yearly' ? (
                                                    <div className='mt-1'>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-month-label">Month Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-month-label"
                                                                id="simple-select-month"
                                                                value={selectMonthField}
                                                                label="Select Field"
                                                                onChange={handleChangeMonthFrequency}
                                                            >
                                                                <MenuItem value="1">January</MenuItem>
                                                                <MenuItem value="2">February</MenuItem>
                                                                <MenuItem value="3">March</MenuItem>
                                                                <MenuItem value="4">April</MenuItem>
                                                                <MenuItem value="5">May</MenuItem>
                                                                <MenuItem value="6">June</MenuItem>
                                                                <MenuItem value="7">July</MenuItem>
                                                                <MenuItem value="8">August</MenuItem>
                                                                <MenuItem value="9">September</MenuItem>
                                                                <MenuItem value="10">October</MenuItem>
                                                                <MenuItem value="11">November</MenuItem>
                                                                <MenuItem value="12">December</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select the Month in the Year</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-month-day-label">Day Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-month-day-label"
                                                                id="simple-select-month-day"
                                                                value={selectMonthdayField}
                                                                label="Select Field"
                                                                onChange={handleChangeMonthdayFrequency}
                                                            >
                                                                <MenuItem value="1">1</MenuItem>
                                                                <MenuItem value="2">2</MenuItem>
                                                                <MenuItem value="3">3</MenuItem>
                                                                <MenuItem value="4">4</MenuItem>
                                                                <MenuItem value="5">5</MenuItem>
                                                                <MenuItem value="6">6</MenuItem>
                                                                <MenuItem value="7">7</MenuItem>
                                                                <MenuItem value="8">8</MenuItem>
                                                                <MenuItem value="9">9</MenuItem>
                                                                <MenuItem value="10">10</MenuItem>
                                                                <MenuItem value="11">11</MenuItem>
                                                                <MenuItem value="12">12</MenuItem>
                                                                <MenuItem value="13">13</MenuItem>
                                                                <MenuItem value="14">14</MenuItem>
                                                                <MenuItem value="15">15</MenuItem>
                                                                <MenuItem value="16">16</MenuItem>
                                                                <MenuItem value="17">17</MenuItem>
                                                                <MenuItem value="18">18</MenuItem>
                                                                <MenuItem value="19">19</MenuItem>
                                                                <MenuItem value="20">20</MenuItem>
                                                                <MenuItem value="21">21</MenuItem>
                                                                <MenuItem value="22">22</MenuItem>
                                                                <MenuItem value="23">23</MenuItem>
                                                                <MenuItem value="24">24</MenuItem>
                                                                <MenuItem value="25">25</MenuItem>
                                                                <MenuItem value="26">26</MenuItem>
                                                                <MenuItem value="27">27</MenuItem>
                                                                <MenuItem value="28">28</MenuItem>
                                                                <MenuItem value="29">29</MenuItem>
                                                                <MenuItem value="30">30</MenuItem>
                                                                <MenuItem value="31">31</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Day of the Month</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-time-label">Time Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-time-label"
                                                                id="simple-select-time"
                                                                value={selectTimeField}
                                                                label="Select Field"
                                                                onChange={handleChangeTime}
                                                            >
                                                                <MenuItem value="1">1:00 a.m.</MenuItem>
                                                                <MenuItem value="2">2:00 a.m.</MenuItem>
                                                                <MenuItem value="3">3:00 a.m.</MenuItem>
                                                                <MenuItem value="4">4:00 a.m.</MenuItem>
                                                                <MenuItem value="5">5:00 a.m.</MenuItem>
                                                                <MenuItem value="6">6:00 a.m.</MenuItem>
                                                                <MenuItem value="7">7:00 a.m.</MenuItem>
                                                                <MenuItem value="8">8:00 a.m.</MenuItem>
                                                                <MenuItem value="9">9:00 a.m.</MenuItem>
                                                                <MenuItem value="10">10:00 a.m.</MenuItem>
                                                                <MenuItem value="11">11:00 a.m.</MenuItem>
                                                                <MenuItem value="12">12:00 p.m.</MenuItem>
                                                                <MenuItem value="13">13:00 p.m.</MenuItem>
                                                                <MenuItem value="14">14:00 p.m.</MenuItem>
                                                                <MenuItem value="15">15:00 p.m.</MenuItem>
                                                                <MenuItem value="16">16:00 p.m.</MenuItem>
                                                                <MenuItem value="17">17:00 p.m.</MenuItem>
                                                                <MenuItem value="18">18:00 p.m.</MenuItem>
                                                                <MenuItem value="19">19:00 p.m.</MenuItem>
                                                                <MenuItem value="20">20:00 p.m.</MenuItem>
                                                                <MenuItem value="21">21:00 p.m.</MenuItem>
                                                                <MenuItem value="22">22:00 p.m.</MenuItem>
                                                                <MenuItem value="23">23:00 p.m.</MenuItem>
                                                                <MenuItem value="24">24:00 a.m.</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Time</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                                            <InputLabel id="simple-select-timezone-label">Timezone Selection</InputLabel>
                                                            <Select
                                                                labelId="simple-select-timezone-label"
                                                                id="simple-select-timezone"
                                                                value={timeZone}
                                                                label="Select Field"
                                                                onChange={handleChangeTimeZone}
                                                            >
                                                                <MenuItem value="US/Eastern">US/Eastern</MenuItem>
                                                                <MenuItem value="US/Central">US/Central</MenuItem>
                                                                <MenuItem value="US/Pacific">US/Pacific</MenuItem>
                                                                <MenuItem value="UTC">UTC</MenuItem>
                                                                <MenuItem value="Europe/Amsterdam">Europe/Amsterdam</MenuItem>
                                                                <MenuItem value="Europe/Berlin">Europe/Berlin</MenuItem>
                                                                <MenuItem value="Europe/Copenhagen">Europe/Copenhagen</MenuItem>
                                                                <MenuItem value="Europe/London">Europe/London</MenuItem>
                                                                <MenuItem value="Europe/Madrid">Europe/Madrid</MenuItem>
                                                                <MenuItem value="Europe/Paris">Europe/Paris</MenuItem>
                                                                <MenuItem value="Europe/Rome">Europe/Rome</MenuItem>
                                                                <MenuItem value="EuropeAmsterdam">EuropeAmsterdam</MenuItem>
                                                                <MenuItem value="Europe/Zurich">Europe/Zurich</MenuItem>
                                                                <MenuItem value="Asia/Bangkok">Asia/Bangkok</MenuItem>
                                                                <MenuItem value="Asia/Dubai">Asia/Dubai</MenuItem>
                                                                <MenuItem value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</MenuItem>
                                                                <MenuItem value="Asia/Hong_Kong">Asia/Hong_Kong</MenuItem>
                                                                <MenuItem value="Asia/Istanbul">Asia/Istanbul</MenuItem>
                                                                <MenuItem value="Asia/Jerusalem">Asia/Jerusalem</MenuItem>
                                                                <MenuItem value="Asia/Manila">Asia/Manila</MenuItem>
                                                                <MenuItem value="Asia/Seoul">Asia/Seoul</MenuItem>
                                                                <MenuItem value="Asia/Taipei">Asia/Taipei</MenuItem>
                                                                <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select Timezone</FormHelperText>
                                                        </FormControl>
                                                        <p className='text-gray-500 text-xs mt-4'>
                                                            Setting the frequency to trigger emails every year on month {selectMonthField} on Day {selectMonthdayField} at hour {selectTimeField}.
                                                        </p>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                    

                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2 className='font-bold text-2xl'>Step 4 (Optional): Set Trigger Rules</h2>
                                        <Box mt={"1rem"}>
                                            <Formik
                                                onSubmit={handleFormTriggerSubmit}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleBlur,
                                                    handleSubmit,
                                                }) => (
                                                <form onSubmit={handleSubmit}>
                                                
                                                <h2 className='text-md w-[45%]'>I would also like to setup rules using customer fields to trigger the emails in addition to the frequency. i.e. Send email once a quarter and only send if these rules are true.</h2>

                                                {triggerFields.map((field, index) => (
                                                    <Box key={field.id}>
                                                        <div className="span-4">
                                                            <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                                <InputLabel id={`field-label-${field.id}`}>Select a Field</InputLabel>
                                                                <Select
                                                                    labelId={`field-label-${field.id}`}
                                                                    id={`field-select-${field.id}`}
                                                                    value={field.selectField}
                                                                    label="Select Field"
                                                                    onChange={(event) => handleChangeTriggerField(event, index, values)}
                                                                >
                                                                    {customers.slice(0,1).map((item) => (
                                                                        Object.entries(item).map(([key]) => {
                                                                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                            if (key.includes('id') || key.includes('object_type') || key.includes('key_id') || 
                                                                                key.includes('score_history') || key.includes('healthscore_reasons') || key.includes('notes') ) {
                                                                                return null;
                                                                            } else {
                                                                            return (
                                                                                <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                                            );
                                                                            }
                                                                        })
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText>Select from Customer Fields</FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                        <div className="span-4">
                                                            <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                                <InputLabel id={`operator-label-${field.id}`}>Operators</InputLabel>
                                                                <Select
                                                                    labelId={`operator-label-${field.id}`}
                                                                    id={`operator-select-${field.id}`}
                                                                    value={field.selectOperator}
                                                                    label="Select Operator"
                                                                    onChange={(event) => handleChangeTriggerOperator(event, index)}
                                                                >
                                                                    {operators.map((item) => (
                                                                        Object.entries(item).map(([key]) => {
                                                                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                            return (
                                                                                <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                                            );
                                                                        })
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText>Select an Operator</FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                        <TextField
                                                            id={`input-value-${field.id}`}
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="Value"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => handleChangeTriggerValue(event, index)}  // sets the value for the field in the state 'fields' which is an array
                                                            value={inputTriggerValues[`inputValue${field.id}`]}
                                                            name="inputTriggerValue"
                                                            error={!!touched.inputTriggerValue && !!errors.inputTriggerValue}
                                                            helperText={touched.inputTriggerValue && errors.inputTriggerValue}
                                                            sx={{ gridColumn: "span 4", marginTop: "1rem"}}
                                                        />
                                                        <div className='mt-1'>
                                                            <p className='text-gray-500 text-xs'>
                                                                Set the Value for the email to trigger and send. Multiple values must be separated by commas.
                                                            </p>
                                                        </div>
                                                    </Box>
                                                ))}
                                                <div className='flex flex-col'>
                                                    <Button
                                                        sx={{ marginTop: "2rem", border: '1px solid black', width: 165}}
                                                        variant='contained'
                                                        color='secondary'
                                                        onClick={handleAddTriggerField}
                                                        >+ Add Rule Logic
                                                    </Button>
                                                </div>
                                                
                                                </form>
                                                )}
                                            </Formik>
                                        </Box>
                                    </div>



                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2 className='font-bold text-2xl'>Final Step: Name & Schedule</h2>
                                        <div className='mt-8 w-[50%] flex flex-col items-start justify-center'>
                                            <p className='text-md font-bold'>Recurring Campaign Name</p>
                                            <input 
                                                type='text' 
                                                value={recurringCampaignName} 
                                                onChange={(e) => {handleChangeRecurringCampaignName(e)}}
                                                className='w-full p-2 border border-gray-300 rounded-lg mt-2'
                                            />
                                        </div>
                                        
                                        <Button
                                            sx={{ marginTop: "2rem", border: '1px solid black', width: 165 }} 
                                            onClick={(event) => handleFormTriggerSubmit()} 
                                            color="secondary" 
                                            variant="contained"
                                        >
                                            Schedule
                                        </Button>
                                    </div>
                                    


                                </div>

                            </div>
                        </div>
                    </Box>
                </div>
            </Box>
        </div>
    );
};


export default Campaigns;
