import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Header from '../../components/Header';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Checkbox from '@mui/material/Checkbox';
import { ToastNotify } from '../../components/ToastNotify';
import CircularProgress from '@mui/material/CircularProgress';


const SendEmail = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('');

    const [templatesData, setTemplatesData] = useState([]);

    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectField, setSelectField] = useState('');

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
      setIsChecked(event.target.checked);
    };


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchCampaignTemplates = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listtemplates';
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                          })
    
            const result = await response.json();

            // console.log('this is result:')
            // console.log(result)

            // const myJsonString = JSON.stringify(result);
            // const jsonObject = JSON.parse(myJsonString);

            // console.log('this is list templates jsonObject:')
            // console.log(jsonObject)

            if (result === 'No result is returned') {
                const jsonArray = [{
                            Name: 'Example Campaign Template 1',
                            CreatedTimestamp: '2024-10-01',
                        }]
                setTemplatesData(jsonArray);
                setIsLoading(false);
            } else {

                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);

                const newdataStr = jsonObject.replace(/datetime\.datetime\((\d{4}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d+).*?\)/g, (match, year, month, day, hour, minute, second, micro) => {
                    // Constructing an ISO string representation (ignoring timezone and microseconds for simplicity)
                    return `"${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}"`;
                });

                // there was an erroneous paranthesis inside the string after modifying the timestamps, replace ) with empty string
                const newdataStr2 = newdataStr.replace(/\)/g, '');

                // replacing single quotes with double quotes for valid json array format
                const newdataStr3 = newdataStr2.replace(/\[{'/g, '[{"').replace(/'}]/g, '"}]').replace(/', '/g, '", "').replace(/': '/g, '": "').replace(/', "/g, '", "').replace(/", '/g, '", "');

                // there was still a single quote on one of the values, so I replaced it with double quotes
                const newdataStr4 = newdataStr3.replace(/\'/g, '"');

                // console.log('this is newdataStr4:')
                // console.log(newdataStr4)

                const jsonArray = JSON.parse(newdataStr4);

                // console.log('this is jsonArray:')
                // console.log(jsonArray)

                const specificArray = jsonArray.filter(item => item.Name.includes(kind_id));
        
                setTemplatesData(specificArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchCampaignTemplates();
    }, []);


    const handleSendEmail = async () => {
        try {
            // console.log('this is the inputs')
            // console.log(recipient, subject, content);

            // {
            //     sender: "", (required)
            //     recipients: array, (required)
            //     subject: ""  (required)
            //     body_html: "" (required)
            //     body_text: "" (required)
            // }

            // check if recipient has multiple email addresses separated by commas, if so, add them into an array
            const recipientArray = recipient.split(',');
            // console.log('this is the recipient array', recipientArray);

            // if a template is selected, use the content of the selected template
            if (selectField !== '' & content === '') {
                const selectedTemplate = selectField;
                // make an API request to get the content of the selected template
                const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/gettemplate';
                const data = {
                    "template_name": selectedTemplate
                };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                                })

                const result = await response.json();
                 // convert result object to array
                const resultArray = [result];

                const jsonArray = resultArray.map((item, index) => ({
                    TemplateName: item.TemplateName,
                    SubjectPart: item.SubjectPart,
                    HtmlPart: item.HtmlPart
                }));

                const templateHtmlContent = jsonArray[0].HtmlPart;
                const templateSubject = jsonArray[0].SubjectPart;

                // inside content, remove all html tags and keep only the text
                const templateTextContent = templateHtmlContent.replace(/<[^>]*>?/gm, '');

                const sendUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/sendemail';
                const sendData = {
                    "sender": "admin@mountxcrm.com", 
                    "recipients": recipientArray, // [recipient]
                    "subject": templateSubject,
                    "body_html": templateHtmlContent,
                    "body_text": templateTextContent
                        };
                const response2 = await fetch(sendUrl, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(sendData)
                                })

                const result2 = await response2.json();

                // console.log('this is the email send result', result);
                setStatus(result2.status);

            } else {
                if (recipient === '' || subject === '' || content === '') {
                    ToastNotify('error', 'Recipients, Subject, and Content are required.');
                    return;
                }

                // inside content, remove all html tags and keep only the text
                const contentText = content.replace(/<[^>]*>?/gm, '');

                const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/sendemail';
                const data = {
                    "sender": "admin@mountxcrm.com", 
                    "recipients": recipientArray, // [recipient]
                    "subject": subject,
                    "body_html": content,
                    "body_text": contentText
                        };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                                })

                const result = await response.json();

                // console.log('this is the email send result', result);
                setStatus(result.status);
            }

        } catch (error) {
            console.error('Error sending email:', error);
            setStatus('Error sending email');
        }
    };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <div
            className='w-full h-auto flex-col justify-between items-center m-1 p-2.5'
        >
            <Box m="30px">
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
                <Header
                        title="Email Campaign"
                        subtitle="Send One Time Email"
                />
                <div 
                    className='app__tierlevel-container'
                    style={{'marginTop': '20px', 'marginBottom':'20px'}}
                >
                </div>
                <div className='app__container'>
                    <Box sx={{ width: '100%' }}>
                        <div 
                            className="app__nps-container"
                            style={{'display': 'flex', 'flexDirection': 'column'}}
                        >
                            <div 
                                className="app__lifecycle-details-container"
                                style={{'width': '100%', 
                                        'height': 'auto', 
                                        'display': 'flex', 
                                        'flexDirection': 'column', 
                                        'justifyContent': 'center'
                                    }}
                            >
                                <div
                                    className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'
                                >
                                    <label className='text-lg font-bold'>Email Recipients:</label>
                                    <input className='text-black mt-4 border-[1px] border-gray-200 w-full h-[30px]' type="email" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
                                    
                                    <h2 className='mt-4 font-bold text-lg'>Select a Campaign Template</h2>
                                    <div
                                        className="w-full h-auto flex-col justify-start"
                                    >
                                        <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                                            <InputLabel id="demo-simple-select-helper-label">Templates</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={selectField}
                                                label="Select Field"
                                                onChange={handleChange}
                                                sx={{width: "100%"}}
                                            >
                                                {   templatesData.map((item) => (
                                                    Object.entries(item).map(([key, value]) => {
                                                    const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                    if (key.includes('Name')) {
                                                        const newValue = value.substring(20).replace(/\_/g, ' ');
                                                        return (
                                                            <MenuItem value={value}>{newValue}</MenuItem>
                                                        )
                                                        } else {
                                                            return null;
                                                        }
                                                })
                                                ))}
                                            </Select>
                                            <FormHelperText>Select Template</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className='mt-4'>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            className='w-[160px] h-8 font-black border-2 border-gray-500' 
                                            onClick={handleSendEmail}
                                        >
                                            Send Email
                                        </Button>
                                    </div>
                                    
                                    <h2 className='font-bold text-md mt-8'>Or, enter new content if you do not want to use an existing template (leave blank if using existing template).</h2>
                                    <label className='font-bold'>Email Subject:</label>
                                    <input className='border-[1px] border-gray-200 w-full h-[30px]' type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    <label className='font-bold'>HTML or Text Content:</label>
                                    <textarea className='border-[1px] border-gray-200 w-full h-[500px]' value={content} onChange={(e) => setContent(e.target.value)} />
                                    <div className='sm:flex sm:flex-col md:flex md:flex-col gap-8 mt-8 items-start w-full h-auto'>
                                        <div className='mt-2'>
                                            <h2 className='font-bold text-md'>Save content as a new Template?</h2>
                                            <Checkbox
                                                sx={{ '&:hover': { backgroundColor: 'transparent',}, }}
                                                checked={isChecked}
                                                onChange={handleCheckBoxChange}
                                                color="primary"
                                                backgroundColor="transparent"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <label className='font-bold ml-4'>Template Name:</label>
                                            <input className='border-[1px] border-gray-200 w-full h-[30px]'></input>
                                        </div>
                                        
                                    </div>
                                    <div className='mt-8'>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            className='w-[160px] h-8 font-black border-2 border-gray-500' 
                                            onClick={handleSendEmail}
                                        >
                                            Send Email
                                        </Button>
                                    </div>
                                    
                                    <div className='mt-4 py-5 w-auto h-auto'>
                                        <h2 className='font-bold'>Status: </h2>
                                        <p>{status}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Box>
                </div>
            </Box>
        </div>
    );
};


export default SendEmail;
