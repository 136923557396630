import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import CircularProgress from '@mui/material/CircularProgress';

import { Formik } from "formik";
import { Box, Button, TextField } from "@mui/material";

import { tokens, useCustomSidebarHeight } from "../../theme";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ToastNotify } from '../../components/ToastNotify';
import { date } from 'yup';
import SalesData from '../../components/SalesData';


// for recurring campaigns/emails

const operators = [
    {
        "Equal to": "=",
        "Not equal to": "!=",
        "Contains": "contains",
    }
]

// for recurring campaigns/emails

const SalesSearch = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [salesData, setSalesData] = useState([]);
    const [chosenSalesData, setChosenSalesData] = useState([]);

    // for selection of product
    const [selectedProduct, setSelectedProduct] = useState('');

    // for list of products
    const [productsList, setProductsList] = useState([]);


    // customers data for both filtering customers and filtering customer fields for the trigger of emails
    const [customers, setCustomers] = useState([]);
    // for filtering customers (actual selected customers to send emails to)
    const [chosenCustomers, setChosenCustomers] = useState([]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // for filtering customers
    const [fields, setFields] = useState([]);  // this is to store the filtered customer rules, basically all the selected fields, operators, and values 
    const [inputValues] = useState({ inputValue: '' });  // this is only used to display the value for TextField objects after operators (not storing actual input values from user, that gets stored in fields state above) and to assign an id to each TextField that gets generated dynamically from the user clicking button to add more logic


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    // fetching Sales so the user can filter on specific customers and products to view
    useEffect(() => {
        const fetchSalesData = async () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "sale"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {

            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setSalesData(jsonArray);
                // setSelectedProduct(jsonArray[0].product);

                const uniqueProductsList = [...new Map(jsonArray.map(item => [item['product'], item])).values()];
                setProductsList(uniqueProductsList);
                
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchSalesData();
    }, []);


    // fetching Customers so the user can filter on specific customers
    useEffect(() => {
        const fetchCustData = async () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {

            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchCustData();
    }, []);




    // for filtering customers
    const handleChangeField = (event, index, values) => {
        const newFields = [...fields];
        newFields[index].selectField = event.target.value;
        setFields(newFields);
        // console.log('this is fields: ')
        // console.log(fields)
    };

    // for filtering customers
    const handleChangeOperator = (event, index) => {
        const newFields = [...fields];
        newFields[index].selectOperator = event.target.value;
        setFields(newFields);
    };

    // for filtering customers (field input for value of the selected field)
    const handleChangeValue = (event, index) => {
        const newFields = [...fields];
        newFields[index].values = event.target.value;
        setFields(newFields);
    };

    // button function for adding more filters (while also increasing page size) for filtering customers
    const handleAddField = () => {
        // Add a new field to the list
        setFields([...fields, { id: fields.length + 1, selectField: '', selectOperator: '' }]);

        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 30;
        setSidebarHeight(newHeight + 'vh');
    };


    

    // for filtering customers and products/items
    const handleFilterCustomers = (event) => {
        event.preventDefault();

        // console.log('this is the fields state: ')
        // console.log(fields)

        if (fields.length === 0) {
            // filter the salesData array to only include the selected product
            const filteredSales = salesData.filter((sale) => sale.product === selectedProduct);
            setChosenSalesData(filteredSales);
        } else {

            try {
                // filter customers based on the logic in the Fields state
                salesData.filter((customer) => {
                    // for every key inside customer, replace the keys with spaces with underscores and make it lowercase
                    const customerModified = Object.fromEntries(Object.entries(customer).map(([key, value]) => [key.replace(/ /g, '_').toLowerCase(), value]));

                    fields.map((field) => {
                        if (field.selectOperator === 'Equal to') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values)
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue === dateValue) {
                                    // console.log('this is the customer that matches the = filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() === field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the = filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Not equal to') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue != dateValue) {
                                    // console.log('this is the customer that matches the != filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() != field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the != filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Less than') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue < dateValue) {
                                    // console.log('this is the customer that matches the < filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() < field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the < filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Less than or equal to') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                // console.log('this is the modified field which includes date: ')
                                // console.log(modifiedField)
                                const dateValue = new Date(field.values);
                                // console.log('this is customerModified[modifiedField]: ')
                                // console.log(customerModified[modifiedField])
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue <= dateValue) {
                                    // console.log('this is the customer that matches the <= filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() <= field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the <= filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Greater than') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue > dateValue) {
                                    // console.log('this is the customer that matches the > filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() > field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the > filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Greater than or equal to') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue >= dateValue) {
                                    // console.log('this is the customer that matches the >= filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase() >= field.values.toLowerCase()) {
                                // console.log('this is the customer that matches the >= filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Between') {
                            const valuesArray = field.values.split(',');
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue1 = new Date(valuesArray[0]);
                                const dateValue2 = new Date(valuesArray[1]);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue > dateValue1 && dateCustValue < dateValue2) {
                                    // console.log('this is the customer that matches the date between filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField] > valuesArray[0] && customerModified[field.selectField] < valuesArray[1]) {
                                // console.log('this is the customer that matches the between filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        } else if (field.selectOperator === 'Contains') {
                            const modifiedField = field.selectField.replace(/ /g, '_').toLowerCase();
                            if (modifiedField.includes('date')) {
                                const dateValue = new Date(field.values);
                                const dateCustValue = new Date(customerModified[modifiedField]);
                                if (dateCustValue == dateValue) {
                                    // console.log('this is the customer that matches the date contains filter: ')
                                    // console.log(customer)
                                    const product = customerModified['product']
                                    const custName = customerModified['customer_name']
                                    chosenCustomers.push({ product: product, name: custName});
                                }
                            }
                            if (customerModified[modifiedField].toLowerCase().includes(field.values.toLowerCase())) {
                                // console.log('this is the customer that matches the contain filter: ')
                                // console.log(customer)
                                const product = customerModified['product']
                                const custName = customerModified['customer_name']
                                chosenCustomers.push({ product: product, name: custName});
                            }
                        }
                    })
                })
            } catch (error) {
                console.error('Error filtering customers:', error);
            }

            // console.log('this is the chosenCustomers before teh counts: ')
            // console.log(chosenCustomers)

            // removing duplicates from the chosenCustomers array
            const uniqueCustomers = [...new Map(chosenCustomers.map(item => [item['name'], item])).values()];

            // console.log('this is the unique customers: ')
            // console.log(uniqueCustomers)


            const custsinSalesData = salesData.filter((sale) => uniqueCustomers.map((cust) => cust.name).includes(sale.customer_name));
            // console.log('this is the custsinSalesData array after filtering: ')
            // console.log(custsinSalesData)

            // filter the salesData array to only include the selected product
            const filteredSales = custsinSalesData.filter((sale) => sale.product === selectedProduct);

            setChosenSalesData(filteredSales);
        }
    };


   

    const handleChangeProductField = (event) => {
        setSelectedProduct(event.target.value);
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <div
            className='w-full h-auto flex-col justify-between items-center m-1 p-2.5'
        >
            <Box m="10px">
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
                <Header
                        title="Sales"
                        subtitle="Search Page"
                />
                {/* <div 
                    className='app__tierlevel-container'
                    style={{'marginTop': '20px', 'marginBottom':'20px'}}
                >
                    <Button
                        sx={{ marginTop: "1rem", width: 165, border: '1px solid black', }}
                        variant='contained'
                        color='secondary'
                        onClick={(event) => navigate('/sales/add', {replace: true})}  // navigate to the sales page
                        >+ Add a Sale
                    </Button>
                </div> */}
                <div className='app__container'>
                    <Box sx={{ width: '100%' }}>
                        <div 
                            style={{'display': 'flex', 'flexDirection': 'column'}}
                        >
                            <div 
                                style={{'width': '100%', 
                                        'height': 'auto', 
                                        'display': 'flex', 
                                        'flexDirection': 'column', 
                                        'justifyContent': 'center'
                                    }}
                            >
                                <div
                                    className='flex flex-col gap-4 items-start p-4 h-auto'
                                >
                                    <div className='mt-4 p-4 border border-gray-200 flex flex-col w-full md:w-1/2 h-auto rounded-lg shadow-lg shadow-gray-300'>
                                        <h2
                                            className='font-bold text-lg'
                                        >
                                            Step 1: Choose Product/Item
                                        </h2>
                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                            <InputLabel id={`field-label-product`}>Product/Item</InputLabel>
                                            <Select
                                                labelId={`field-label-product-select`}
                                                id={`field-select-product-select`}
                                                value={selectedProduct}
                                                label="Select Product"
                                                onChange={(event) => handleChangeProductField(event)}
                                            >
                                                {productsList.map((item) => (
                                                    Object.entries(item).map(([key, value]) => {
                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                        if (key === 'product')  {
                                                            return (
                                                                <MenuItem key={key} value={value}>{value}</MenuItem>
                                                            )
                                                            
                                                        } else {
                                                            return (
                                                                null
                                                            );
                                                        }
                                                    })
                                                ))}
                                            </Select>
                                            <FormHelperText>Select Products/Items from Sales</FormHelperText>
                                        </FormControl>

                                        <h2
                                            className='font-bold text-lg mt-4'
                                        >
                                            Step 2 (Optional): Filter on Account/Customer Name
                                        </h2>
                                        <Formik
                                        >
                                            {({
                                            values,
                                            errors,
                                            touched,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            }) => (
                                            <form onSubmit={handleSubmit}>

                                            {fields.map((field, index) => (
                                                <Box key={field.id}>
                                                    <div className="span-4">
                                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                            <InputLabel id={`field-label-${field.id}`}>Field</InputLabel>
                                                            <Select
                                                                labelId={`field-label-${field.id}`}
                                                                id={`field-select-${field.id}`}
                                                                value={field.selectField}
                                                                label="Select Field"
                                                                onChange={(event) => handleChangeField(event, index, values)}
                                                            >
                                                                {customers.slice(0,1).map((item) => (
                                                                    Object.entries(item).map(([key]) => {
                                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                        if (key.includes('customer_name') ) {
                                                                            return <MenuItem key={key} value={key}>{formattedKey}</MenuItem>
                                                                        } else {
                                                                        return (
                                                                            null
                                                                        );
                                                                        }
                                                                    })
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select from Account Fields</FormHelperText> */}
                                                        </FormControl>
                                                    </div>
                                                    <div className="span-4">
                                                        <FormControl sx={{ width: "100%", marginTop: "1rem" }}>
                                                            <InputLabel id={`operator-label-${field.id}`}>Operators</InputLabel>
                                                            <Select
                                                                labelId={`operator-label-${field.id}`}
                                                                id={`operator-select-${field.id}`}
                                                                value={field.selectOperator}
                                                                label="Select Operator"
                                                                onChange={(event) => handleChangeOperator(event, index)}
                                                            >
                                                                {operators.map((item) => (
                                                                    Object.entries(item).map(([key]) => {
                                                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                                                        return (
                                                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                                                        );
                                                                    })
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>Select an Operator</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <TextField
                                                        id={`input-value-${field.id}`}
                                                        fullWidth
                                                        variant="filled"
                                                        type="text"
                                                        label="Value"
                                                        onBlur={handleBlur}
                                                        onChange={(event) => handleChangeValue(event, index)}  // sets the value for the field in the state 'fields' which is an array
                                                        value={inputValues[`inputValue${field.id}`]}
                                                        name="inputValue"
                                                        error={!!touched.inputValue && !!errors.inputValue}
                                                        helperText={touched.inputValue && errors.inputValue}
                                                        sx={{ gridColumn: "span 4", marginTop: "1rem"}}
                                                    />
                                                    <div className='mt-1'>
                                                        <p className='text-gray-500 text-xs'>
                                                            Value of account/customer name to search for.
                                                        </p>
                                                    </div>
                                                </Box>
                                            ))}
                                            <Button
                                                sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={handleAddField}
                                                >+ Add Name Filter
                                            </Button>
                                            <Button
                                                sx={{ marginTop: "2rem", marginLeft: {xs: "0rem", md: "2rem"}, width: 165, border: '1px solid black', }}
                                                variant='contained'
                                                color='secondary'
                                                onClick={(event) => handleFilterCustomers(event)}
                                                > Search
                                            </Button>
                                            </form>
                                            )}
                                        </Formik>

                                        <h2
                                            className='font-bold text-lg mt-8'
                                        >
                                            Filtered Sales:  {chosenSalesData.length}
                                        </h2>
                                        <div className='mt-4 border border-gray-200 flex flex-col w-full h-auto rounded-lg shadow-lg shadow-gray-300'>
                                            { chosenSalesData && chosenSalesData.length > 0 ? (
                                                <div className='flex flex-col w-full h-auto'>
                                                    <SalesData salesData={chosenSalesData} />
                                                </div>
                                            ) : <div className='flex flex-col w-full h-[8rem]'>
                                                </div>
                                            }
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Box>
        </div>
    );
};


export default SalesSearch;
