import React from 'react';
import { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastNotify } from "../../components/ToastNotify";
import mountxLogo from '../../assets/mountxcrmlogo1.png';
import boatImg from '../../assets/boat.png';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    message: yup.string(),
});


const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
};



const ContactUs = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();


    const handleFormSubmit = (values) => {

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

        // get all the values including the values in the select fields
        const allValues = {...values}

        // get today's timestamp in YYYY-MM-DD format
        const today = new Date();

        allValues['date'] = today.toISOString().split('T')[0];

        const json_data = {"kind_id": "contactusmessages", 
                            "data": allValues};

        const url = process.env.REACT_APP_API_URL + '/api/v1/create'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                ToastNotify('success', 'Thank you for contacting us. We will respond to your inquiry as soon as we can.')
                navigate('/', {replace: true})
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong')
                console.error(error);
            });

    };

    return (
        <div
            className='flex flex-col xl:flex-row w-full h-screen bg-white'
        >
            <div 
                className='flex flex-1 justify-center items-center flex-col py-10' 
            >
                <div className='flex-1 flex-row'>
                    <img 
                        src={mountxLogo}
                        alt="camp"
                        onClick={() => navigate('/')}
                        style={{
                            height: '50px', 
                            width: '50px', 
                            zIndex: '0',
                            position: 'absolute',
                            top: '1rem',
                            left: '1rem',
                            cursor: 'pointer',
                        }}
                    >
                    </img>
                </div>
                <h1 className='text-5xl font-bold text-black'>Contact Us</h1>
                <p className='text-gray-500 w-[40%] mt-4'>
                    Reach out to us for any queries or support. We will get back to you as soon as possible.
                </p>
                <Box m="20px" marginTop="100px">
                    <Header title="Contact Support" subtitle="We just need a few quick details." />

                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                            >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName}
                                sx={{ gridColumn: "span 2", 
                                backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                input: {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiFilledInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                },
                                '.MuiFilledInput-underline:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                },
                                '.MuiFilledInput-underline:hover:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiFilledInput-underline:after': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                                sx={{ gridColumn: "span 2", 
                                backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                input: {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiFilledInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                },
                                '.MuiFilledInput-underline:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                },
                                '.MuiFilledInput-underline:hover:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiFilledInput-underline:after': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                 }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 4", 
                                backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                input: {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                },
                                '.MuiFilledInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                },
                                '.MuiFilledInput-underline:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                },
                                '.MuiFilledInput-underline:hover:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiFilledInput-underline:after': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                }}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={10}
                                variant="outlined"
                                type="text"
                                label="Message"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.message}
                                name="message"
                                error={!!touched.message && !!errors.message}
                                helperText={touched.message && errors.message}
                                sx={{ gridColumn: "span 4", border: '1px solid black',
                                backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                input: {
                                    color: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiFilledInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                },
                                '.MuiFilledInput-underline:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                },
                                '.MuiFilledInput-underline:hover:before': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                '.MuiFilledInput-underline:after': {
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                },
                                 }}
                            />
                            </Box>
                            <Box display="flex" justifyContent="end" mt="20px">
                            <Button 
                                type="submit" 
                                color="secondary" 
                                variant="contained"
                            >
                                Submit
                            </Button>
                            </Box>
                        </form>
                        )}
                    </Formik>
                </Box>
            </div>
            <img
                src={boatImg}
                alt='loginLogo'
                className='hidden xl:block h-screen w-1/2 object-cover bg-no-repeat'
            >
            </img>
        </div>
    )
}

export default ContactUs;