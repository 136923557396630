import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';


const SalesData = ({ salesData }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // sort salesData by sales_date descending
    // salesData.sort((a, b) => new Date(b.sale_date) - new Date(a.sale_date));

    // // reset the id for the filteredSales array
    salesData.map((item, index) => {
        item.id = index + 1;
    });


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    const salesColumns = [
        { 
            field: "id", 
            headerName: "Row",
            width: 15,
        },
        // { field: "contact_keyid", headerName: "Contact ID"}, 
        {
            field: "sale_date",
            headerName: "Sale Date",
            flex: 1,
            renderCell: ({ row: { sale_date }}) => {
                return (
                    <p style={{ textDecoration: 'none', color: "inherit" }}>{sale_date.split('-')[1] + '/' + sale_date.split('-')[2] + '/' + sale_date.split('-')[0]}</p>
                )
            }
        },
        { 
        field: "customer_name", 
        headerName: "Account Name", 
        flex: 1,
        textDecoration: "none",
        color: "inherit",
        cellClassName: "name-column--cell",
        renderCell: (params) => {
            const cellValue = params.value;
            return (
                <Link 
                    to={`/account-profile/${cellValue}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                >
                    {cellValue}
                </Link>
            );
            },
        },
        { 
            field: "product", 
            headerName: "Product/Item",
            flex: 1,
        },
        { 
            field: "sale_amount", 
            headerName: "Sale Amount",
            flex: 1,
        },
        { 
            field: "owner_name", 
            headerName: "Sold By",
            flex: 1,
        },
    ]



    return (
        <Box m='10px'>
            <Header
                title="Sales"
                subtitle="View Sales Data"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{"& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={salesData}
                    columns={salesColumns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default SalesData;
