import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';


const Addnps = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [custData, setCustData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);



    const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };


    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
    
        Papa.parse(file, {
          complete: (result) => {
            // 'result.data' contains the parsed CSV data
            // console.log('Parsed CSV Data:', result.data);
            setCsvData(result.data);
          },
          header: true, // Set to true if your CSV file has a header row
        });
    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    const handleUpload = () => {
        // console.log('This is the CSV Data:');
        // console.log(csvData);

        try {
            csvData.map((item, index) => {
                const custName = item.customer_name;
                const npsScore = item.nps_score;
                // today's date in YYYY-MM-DD format
                const today = new Date().toISOString().slice(0, 10);
    
                // iterate through custData and update nps_score_history for each customer
                custData.map((item, index) => {
                    if (item.customer_name === custName) {
                        // parse this array [{"x":"2023-01", "y":"50"}, {"x":"2023-02", "y":"70"}, {"x":"2023-03", "y":"95"}]
                        const parsedArray = JSON.parse(item.nps_score_history);
    
                         // If you want to transform the array to the desired format
                        const transformedArray = parsedArray.map(item => ({
                            x: item.x,
                            y: parseInt(item.y, 10) // Convert 'y' to an integer
                            }));
    
                        // push {"x":${today}, "y":${npsScore}} into transformedArray
                        transformedArray.push({"x":`${today}`, "y":`${npsScore}`});
    
                        const inner_data = {
                                            "nps_score": npsScore,
                                            "nps_score_history": transformedArray,
                                        };
    
                        const checkUser = localStorage.getItem('user');
    
                        const fetchedUserString = JSON.stringify(checkUser); 
                        const fetchedUserObject = JSON.parse(fetchedUserString);
                        const fetchedUserinner = JSON.parse(fetchedUserObject);
                        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
                        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
                        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
                
                        const json_data = {"kind_id": kind_id, 
                                            "key_id": custName, 
                                            "data": inner_data
                                            };
                
                        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
                
                        fetch(url, {
                            method: 'POST',
                            body: JSON.stringify(json_data),
                            headers: headers
                            })
                            .then(response => response.json())
                            .then(result => {
                                // Handle the API response or perform any necessary actions
                                console.log('Changes Saved: ')
                                console.log(result);
                            })
                            .catch(error => {
                                // Handle errors that occurred during the API call
                                console.error(error);
                            });
                    }
                })
    
            })

            ToastNotify('success', 'NPS Scores Uploaded Successfully');
        } catch (error) {
            console.log(error);
            ToastNotify('error', 'Error, something went wrong');
        }

    };
    
    
    const csvheaders = ['customer_name', 'nps_score']; // Add your headers here

    const downloadCsv = () => {
        const csvContent = `${csvheaders.join(',')}\n${custData.map(row => Object.values(row).join(',')).join('\n')}`;

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'nps_template.csv'; // Set your desired filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };




    // fetching customers data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                customer_id: 1,
                                customer_name: 'Example Customer 1',
                                nps_score: '',
                            }]
                    setCustData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    customer_id: item.customer_id,
                    customer_name: item.customer_name,
                    nps_score: '',
                    nps_score_history: item.nps_score_history,
                    }));
            
                    setCustData(jsonArray);
                }
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
            };
    
        fetchData();
    }, []);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m="30px">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
            <Header
                    title="Add NPS Scores"
                    subtitle="Add NPS Scores from Outside Sources or Third Party Apps"
            />
            <div 
                className='app__tierlevel-container'
                style={{'marginTop': '20px', 'marginBottom':'20px'}}
            >
                {/* <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => navigate('/npsdata')}
                >
                    See All NPS Responses
                </Button> */}
            </div>
            <div className='app__container'>
                <Box sx={{ width: '100%' }}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        Tabs were originally here
                    </Box> */}
                        <div 
                            className="app__nps-container"
                            style={{'display': 'flex', 'flexDirection': 'column'}}
                        >
                            <div 
                                className="app__lifecycle-details-container"
                                style={{'width': '80%', 
                                        'height': 'auto', 
                                        'display': 'flex', 
                                        'flexDirection': 'column', 
                                        'justifyContent': 'center'
                                    }}
                            >

                                <div>
                                    <h3 className='font-bold'>Upload NPS via CSV</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row', 
                                            'width': 'auto',
                                            'height': '300px',
                                            'margin': '1rem 0 1rem 0', 
                                            'border': '1px solid lightgrey',
                                            'padding': '12px',
                                            'justifyContent': 'center',
                                        }}
                                >
                                    <div 
                                        style={{'margin': '0 0 0 0', 
                                                'padding': '5px',
                                                'width': '400px',
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center',
                                                'alignText': 'center',
                                                'borderRight': '1px solid lightgrey',
                                            }}
                                    >
                                        <div>
                                            <p>Download CSV Template</p>
                                        </div>
                                        <div className='flex flex-col w-auto h-auto'>
                                            <Button
                                                sx={{"marginTop": "1rem", "width": "200px", "height": "50px"}} 
                                                color='secondary'
                                                variant='contained' 
                                                onClick={downloadCsv}
                                            >
                                                Download CSV
                                            </Button>
                                        </div>
                                    </div>
                                    <div 
                                        style={{'margin': '0 1rem 0 0',
                                                'padding': '5px',
                                                'width': '200px', 
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center',
                                                'alignText': 'center',
                                                'borderRight': '1px solid lightgrey',
                                            }}
                                    >
                                        <div>
                                            <p className='text-gray-500'>Add NPS Scores to CSV column 'nps_score'</p>
                                        </div>
                                    </div>
                                    <div 
                                        style={{'margin': '0 0 0 0', 
                                                'padding': '5px',
                                                'width': '400px',
                                                'display': 'flex', 
                                                'flexDirection': 'column', 
                                                'justifyContent': 'center', 
                                                'alignItems': 'center',
                                                'alignText': 'center',
                                            }}
                                    >
                                        <div>
                                            <p>Upload CSV Template with NPS Scores</p>
                                        </div>
                                        <div>
                                            <div 
                                                {...getRootProps()} 
                                                style={{
                                                    border: '2px dashed #cccccc',
                                                    borderRadius: '4px',
                                                    padding: '20px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                }}>
                                                <input {...getInputProps()} />
                                                {
                                                isDragActive ?
                                                    <p>Drop the CSV file here...</p> :
                                                    <p>Drag 'n' drop a CSV file here, or click to select one</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-auto h-auto'>
                                            <Button
                                                sx={{"marginTop": "1rem", "width": "200px", "height": "50px"}} 
                                                color='secondary'
                                                variant='contained' 
                                                onClick={handleUpload}
                                            >
                                                Upload CSV
                                            </Button>
                                        </div>
                                    </div>

                                </div>


                                {/* <div>
                                    <h3 className='font-bold'>Add NPS via API</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row',
                                            'width': 'auto',
                                            'height': '400px',
                                            'justifyContent': 'space-between', 
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey',
                                            'padding': '5px'
                                        }}
                                >
                                    Coming soon..
                                </div> */}


                                {/* <div className='app__hsmanagement-edit-properties-title'>
                                    <h3 className='font-bold'>Upload NPS via Cloud Bucket</h3>
                                </div>
                                <div 
                                    style={{'display': 'flex', 
                                            'flexDirection': 'row', 
                                            'justifyContent': 'space-between', 
                                            'margin': '0 0 1rem 0', 
                                            'border': '1px solid lightgrey', 
                                            'cursor': 'pointer', 
                                            'padding': '10px'
                                        }}
                                >
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'width': '50px'}}
                                    >
                                        Google Cloud Bucket URL:
                                    </p>
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                                    >
                                        Coming soon..
                                    </p>
                                    <p 
                                        className='app__add-property-fieldnames-text'
                                        style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                                    >

                                    </p>
                                </div> */}
                                
                            </div>
                        </div>
                </Box>
                <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => navigate('/npsdata')}
                >
                    All Customers NPS Scores
                </Button>
            </div>
        </Box>
  )
}

export default Addnps;