import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Select, Button, TextField, IconButton, useTheme, Typography  } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from "../../components/ToastNotify";



const UnsubscribePage = () => {

    // customerid also contains the kind_id as the first part of the string before the hyphen
    const { customerid } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [emailAddress, setEmailAddress] = useState('');
    const [customerName, setCustomerName] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // fetching the email address of the customer using the customerid params
    useEffect(() => {
        setIsLoading(true);

        // parsing the kind_id from the customerid params
        const kind_id = customerid.split('-')[0];

        const fetchEmailAddress = async () => {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                            "filter2": {"filter_field": "customer_id", 
                                "filter_op": "=",
                                "filter_value": customerid},
                        } 
                        };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                        })

            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                ToastNotify('error', 'Error, something went wrong.');
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));

                const emailAddy = jsonArray[0]['Email Address'];
                //console.log('this is emailAddy within getEmailAddress Function: ', emailAddy)
                const customerName = jsonArray[0]['customer_name'];

                setEmailAddress(emailAddy);
                setCustomerName(customerName);
                setIsLoading(false);
            }
        };

        fetchEmailAddress()
    }, [customerid]);


    const handleUnsubscribeAll = async (event) => {
        event.preventDefault();

        // customerid also contains the kind_id as the first part of the string before the hyphen
        const kind_id = customerid.split('-')[0];

        const unsubscribeData = {};
        unsubscribeData['kind_id'] = kind_id;
        unsubscribeData['customer_id'] = customerid;
        unsubscribeData['customer_name'] = customerName;
        unsubscribeData['email_address'] = emailAddress;
        unsubscribeData['status'] = 'unsubscribed';
        // today's date and time
        const today = new Date();
        unsubscribeData['date'] = today.toISOString().split('T')[0] + ' ' + today.toTimeString().split(' ')[0]

        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        const json_data = {"kind_id": "unsubscribe",
                            "key_id": customerid,
                            "data": unsubscribeData};

        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('New Rule Action Request has been created:');
                // console.log(result);
                ToastNotify('success', 'Successfully unsubscribed.');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Error, something went wrong.');
            });
    }


    const handleSubscribeAll = async (event) => {
        event.preventDefault();

        // customerid also contains the kind_id as the first part of the string before the hyphen
        const kind_id = customerid.split('-')[0];

        const subscribeData = {};
        subscribeData['kind_id'] = kind_id;
        subscribeData['customer_id'] = customerid;
        subscribeData['email_address'] = emailAddress;
        subscribeData['status'] = 'subscribed';
        // today's date and time
        const today = new Date();
        subscribeData['date'] = today.toISOString().split('T')[0] + ' ' + today.toTimeString().split(' ')[0]

        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        const json_data = {"kind_id": "unsubscribe",
                            "key_id": customerid,
                            "data": subscribeData};

        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('New Rule Action Request has been created:');
                // console.log(result);
                ToastNotify('success', 'Successfully Subscribed.');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Error, something went wrong.');
            });
    }


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
            <Box m='14px' className='w-full flex flex-col justify-center items-center pt-16'>
                {/* <Header
                    title="Unsubscribe"
                    className='justify-start '
                /> */}
                    <Box
                        className='flex-col w-[75%] justify-center items-center mt-16 border-[1px] border-gray-500 rounded-md p-8'
                    >
                        <div className='flex-row'>
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ fontSize: '2rem', fontWeight: 'bold', }}
                            >
                                Unsubscribe Options:
                            </Typography>
                        </div>
                        {/* <div className='mt-4 flex-row gap-8 xs:flex xs:flex-col xs:mt-16'>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={(event) => {}}
                                // className='xs:w-[300px] h-[40px] border-[1px] border-black rounded-[5px] p-[5px] text-center font-bold align-middle m-[15px] 0 5px 20px'
                                sx={{ fontSize: '14px', width: '14rem', height: 40, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }}
                            >
                                Unsubscribe Partial
                            </Button>
                            <p className='text-xs text-gray-500 justify-start mt-[17px] p-[5px]'>Unsubscribe from all marketing, newsletter, promotional emails only.  You will still receive important notifications.</p>
                        </div> */}
                        <div className='mt-4 flex-row gap-8 xs:flex xs:flex-col xs:mt-16'>
                            <Button
                                variant='contained'
                                color='secondary'
                                backgroundColor={colors.greenAccent[500]}
                                onClick={(event) => handleUnsubscribeAll(event)}
                                sx={{fontSize: '14px', width: '14rem', height: 40, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }}
                            >
                                Unsubscribe ALL
                            </Button>
                            <p className='text-xs text-gray-500 justify-start mt-[17px] p-[5px]'>Unsubscribe from ALL emails including marketing and important notifications.</p>
                        </div>
                        <div className='flex-row mt-12'>
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ fontSize: '2rem', fontWeight: 'bold' }}
                            >
                                Subscribe Options:
                            </Typography>
                        </div>
                        {/* <div className='mt-4 flex-row gap-8 xs:flex xs:flex-col xs:mt-16'>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={(event) => {}}
                                sx={{fontSize: '14px', width: '14rem', height: 40, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }}
                            >
                                Subscribe Partial
                            </Button>
                            <p className='text-xs text-gray-500 justify-start mt-[17px] p-[5px]'>Unsubscribe from all marketing, newsletter, promotional emails only.  You will still receive important notifications.</p>
                        </div> */}
                        <div className='mt-4 flex-row gap-8 xs:flex xs:flex-col xs:mt-16'>
                            <Button
                                variant='contained'
                                color='secondary'
                                backgroundColor={colors.greenAccent[500]}
                                onClick={(event) => handleSubscribeAll(event)}
                                sx={{fontSize: '14px', width: '14rem', height: 40, border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }}
                            >
                                Subscribe to ALL
                            </Button>
                            <p className='text-xs text-gray-500 justify-start mt-[17px] p-[5px]'>Subscribe to ALL emails including marketing and important notifications.</p>
                        </div>
                    </Box>
            </Box>
    );
};

export default UnsubscribePage;