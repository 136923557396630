// NAVIGATION
export const NAV_LINKS = [
  { href: '/', key: 'home', label: 'Home' },
  { href: '/', key: 'how_hilink_work', label: 'How Hilink Work?' },
  { href: '/', key: 'services', label: 'Services' },
  { href: '/', key: 'pricing ', label: 'Pricing ' },
  { href: '/', key: 'contact_us', label: 'Contact Us' },
];


// CAMP SECTION
export const PEOPLE_URL = [
  '../assets/person-1.png',
  '../assets/person-2.png',
  '../assets/person-3.png',
  '../assets/person-4.png',
];

// FEATURES SECTION
export const FEATURES = [
  {
    title: 'Action Requests',
    icon: '../assets/map.svg',
    variant: 'green',
    description:
      'Setup action requests that trigger based on custom rules you set up. We provide a solution for admins and managers to mass assign or manually individually assign action requests to their team members, set due dates, keep track of the progress and get notified when the task is completed.',
  },
  {
    title: 'Healthscores',
    icon: '../assets/calendar.svg',
    variant: 'green',
    description:
      "Easily set up, calculate, and manage healthscores for your customer base. Setup alerts or actions when customers are at risk.",
  },
  {
    title: 'Leverage A.I.',
    icon: '../assets/tech.svg',
    variant: 'green',
    description:
      'Generative AI Technology can recommend next best actions, summarize leads, analyze sentiment, and even craft email messages on your behalf. Easily setup automated emails on your behalf so you can do the important things.',
  },
  {
    title: 'Customer Lifecycle',
    icon: '../assets/location.svg',
    variant: 'orange',
    description:
      'Lots of customers can start every day, week, or month. Ensure all customers complete their lifecycle journey with your company through custom stages and actions.',
  },
];

// FOOTER SECTION
export const FOOTER_LINKS = [
  {
    title: 'Learn More',
    links: [
      { href: '/FAQ', label: 'About Us' },
      { href: '/contact/support', label: 'Contact Us' },
    ],
  },
  {
    title: 'Our Solutions',
    links: [
      { href: '/features', label: 'Digital Lifecycle'},
      { href: '/features', label: 'Healthscores' },
      // { href: '/features', label: 'AI Automation' },
      { href: '/features', label: 'Team Management' },
      { href: '/features', label: 'CRM' },
    ]
  },
];

export const FOOTER_CONTACT_INFO = {
  title: 'Contact Us',
  links: [
    // { label: 'Phone', value: '123-456-7890' },
    { label: 'Email', value: 'support@mountxcrm.com' },
  ],
};

export const SOCIALS = {
  title: 'Social',
  links: [
    '../assets/facebook.svg',
    '../assets/instagram.svg',
    '../assets/twitter.svg',
    '../assets/youtube.svg',
    '../assets/wordpress.svg',
  ],
};
