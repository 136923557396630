import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from "formik";
import { Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from '../../components/ToastNotify';
import { useCustomSidebarHeight } from "../../theme";



const SurveyQuestionsEdit = () => {

    // surveyId is actually the survey name with the clientID and _srvy_ in the name
    const { surveyId } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // default questions for the survey
    const questionsArray = [
        {
            "label": "",
        },

    ];

    const [customQuestions, setCustomQuestions] = useState(questionsArray);
    // when fetching survey questions from datastore, API normally adds add key_id to results so we can use the key_id to update the survey questions
    const [keyId, setKeyId] = useState('')

    const [subject, setSubject] = useState('');
    const [surveyEmailContent, setSurveyEmailContent] = useState('');

    // state to edit the survey email content - button click
    const [editboolean, setEditBoolean] = useState(false);

    const theme = useTheme();
    const isNonMobile = useMediaQuery(theme.breakpoints.up("md"));

    const navigate = useNavigate();

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    const [user, setUser] = useState([]);

    // const [surveyQuestions, setSurveyQuestions] = useState([]);

    // for the input of the questions
    const [fields, setFields] = useState([]);  // this is to store the question value inputs from the user
    const [inputValues] = useState({ inputValue: '' });  // this is only used to display the value for TextField objects (not storing actual input values from user, that gets stored in fields state above) and to assign an id to each TextField that gets generated dynamically from the user clicking button to add more logic or questions

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    // fetch survey questions based on surveyId from url param
    useEffect(() => {
        const fetchSurveyQuestions = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // datastore formatted surveyId
        const underlineSurveyId = surveyId.replace(/-/g, '_');

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "surveyquestions"},
                            "filter2": {"filter_field": "surveyid", 
                                "filter_op": "=",
                                "filter_value": underlineSurveyId},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                // this is how the datastore surveyquestions object is structured
                const jsonArray = [{
                            object_type: 'surveyquestions',
                            surveyid: underlineSurveyId,
                            kind_id: kind_id,
                            questions: [{"id": 1, "values": "Example Question: How satisified are you with our product and/or service?"}],
                }]
                // setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));

                // console.log('this is jsonArray:')
                // console.log(jsonArray)

                // this is how the datastore surveyquestions object is structured
                // also returns key_id in the jsonArray since API adds key_id to results
                // const jsonArray = [{
                //     object_type: 'surveyquestions',
                //     key_id: actual keyid of datastore entity,
                //     surveyid: underlineSurveyId,
                //     kind_id: kind_id,
                //     questions: [{"id": 1, "values": "Example Question: How satisified are you with our product and/or service?"}],
                // }]

                // for each question in the survey, add it to the customQuestions array
                const questionsArray = [];
                jsonArray[0].questions.map((item, index) => {
                    questionsArray.push({label: item.values});
                });
                setCustomQuestions(questionsArray);
                setKeyId(jsonArray[0].key_id);
        
                // setIsLoading(false);  // going to wait for the fetchTemplate to finish before setting isLoading to false
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchSurveyQuestions();
    }, []);


    // fetch survey html template based on surveyId from url param
    useEffect(() => {
        const fetchTemplate = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            const surveyName = surveyId.replace(/\ /g, '_').replace(/\-/g, '_');

            const json_data = {"template_name": surveyName};

            const getTemplateUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/gettemplate';

            const response = await fetch(getTemplateUrl, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(json_data)
                          })
    
            const result = await response.json();

            // !! IMPORTANT !! the template field names are different!!
            // template name is TemplateName and subject is SubjectPart and html_part is HtmlPart

            // console.log('result: ', result);

            // console.log(typeof result);

            // convert result object to array
            const resultArray = [result];

            const jsonArray = resultArray.map((item, index) => ({
                TemplateName: item.TemplateName,
                SubjectPart: item.SubjectPart,
                HtmlPart: item.HtmlPart
            }));

            // console.log('jsonArray[0]: ', jsonArray[0]);
            // console.log(typeof jsonArray[0])
            setSubject(jsonArray[0].SubjectPart);
            setSurveyEmailContent(jsonArray[0].HtmlPart);
            setIsLoading(false);
        }
        fetchTemplate();
    }, [surveyId]);






    const handleSubmitSaveSurvey = (event) => {
        event.preventDefault();

        // datastore formatted surveyId
        const underlineSurveyId = surveyId.replace(/-/g, '_');


        if (customQuestions.length === 0) {
            ToastNotify('error', 'A survey must have at least one question');
            return;
        }

        const checkUser = localStorage.getItem('user');
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // const noSpacesTemplateName = templateName.replace(/\ /g, '_');

        // // template_name must be kind_id + templateName (without any spaces in templateName)
        // const newTemplateName = kind_id + '_' + 'srvy' + '_' + templateName.replace(/\ /g, '_');

        // // to remove any html tags from the content
        // const contentText = surveyEmailContent.replace(/<[^>]*>?/gm, '');

        // convert customQuestions array to JSON format
        const jsonFormatCustomQuestions = customQuestions.map((item, index) => {
            return {id: index + 1, values: item.label};
        });

        const surveyData = {}
        surveyData['kind_id'] = kind_id;
        surveyData['object_type'] = 'surveyquestions';
        surveyData['surveyid'] = underlineSurveyId; 
        surveyData['questions'] = jsonFormatCustomQuestions;
    
        const json_data = { "kind_id": kind_id,
                            "key_id": keyId,
                            "data": surveyData
                        }

        const updateUrl = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(updateUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                // ToastNotify('success', 'Survey Questions have been saved successfully');
                // navigate('/surveys/templates', {replace: true});
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });
        
        // to save survey template to AWS SES
        // template_name must be kind_id + templateName (without any spaces in templateName)
        const surveyTemplateName = surveyId.replace(/\-/g, '_');

        // to remove any html tags from the content
        const contentText = surveyEmailContent.replace(/<[^>]*>?/gm, '');

        const template_data = {"template_name": surveyTemplateName, 
                            "subject": subject, 
                            "html_part": surveyEmailContent, 
                            "text_part": contentText
                        };

        const createUrl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/updatetemplate';

        fetch(createUrl, {
            method: 'POST',
            body: JSON.stringify(template_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Template has been deleted: ')
                // console.log(result);
                ToastNotify('success', 'Survey was saved successfully');
                // navigate('/surveys/templates', {replace: true});
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Something went wrong');
            });

    };



    // to capture the Survey Question input fields
    const handleQuestionLabelChange = (index, label) => {
        const updatedQuestions = [...customQuestions];
        updatedQuestions[index].label = label;
        setCustomQuestions(updatedQuestions);
    };


    const handleRemoveQuestion= (index) => {
        const updatedQuestions = customQuestions.filter((_, i) => i !== index);
        setCustomQuestions(updatedQuestions);
    };


    const handleAddQuestion = () => {
        // adding a new question to the view titled 'Question 2', 'Question 3', etc.
        const newQuestion = {
            label: `Question ${customQuestions.length + 1}`,
          };
        setCustomQuestions([...customQuestions, newQuestion]);
        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 15;
        setSidebarHeight(newHeight + 'vh');
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="30px" width="90%">
            <Header title="Edit" subtitle="Survey Questions" />

            <div className='mt-4 border-[1px] border-gray-200 flex-col w-[50%] h-auto rounded-lg shadow-lg shadow-gray-300'>
                <div className='w-full h-auto flex-col p-4'>
                    <div className='flex-col w-full mt-4'>
                        <label className='font-bold'>Survey Name:</label>
                        <p className='text-gray-500 font-md'>{surveyId.substring(25).replace(/-/g, ' ')}</p>
                    </div>
                    <div className='mt-8'>
                        <label className='font-bold'>Template Preview:</label>
                    </div>
                    <div className='mt-4 w-full h-[500px] border-[1px] border-gray-300 rounded-2xl overflow-hidden'>
                        <iframe 
                            srcDoc={surveyEmailContent}
                            width="100%"
                            sandbox="allow-scripts"
                            style={{ width: '100%', height: '100%', marginTop: '1rem'}}
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                    <div className='flex-col w-full mt-2'>
                        <Button
                            sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                            variant='contained'
                            color='secondary'
                            onClick={() => setEditBoolean(true)}
                        >
                            Edit Survey Email
                        </Button>
                        {editboolean ? (
                            <>
                            <div className='flex-col w-full mt-4'>
                                <label className='font-bold'>Survey Email Content:</label>
                                <textarea
                                    className='border-[1px] border-gray-200 w-full h-[300px] text-gray-500'
                                    value={surveyEmailContent}
                                    onChange={(e) => setSurveyEmailContent(e.target.value)}
                                />
                            </div>
                            <div className='flex-col w-full mt-8'>
                                <label className='font-bold'>Survey Subject Line:</label>
                                <input className='border-[1px] border-gray-200 w-full text-gray-500' type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </div>
                            </>
                        ) : null    
                        }
                    </div>
                    <div className='flex-col w-full mt-12'>
                        <label className='font-bold'>Survey Email Questions:</label>
                    </div>
                    <div className='flex-col w-full'>
                        {customQuestions.map((question, index) => (
                            <div key={index}>
                                <input
                                    className='w-[70%] border-[1px] border-gray-400 rounded-md p-2 m-2 text-gray-500'
                                    type="text"
                                    value={question.label}
                                    onChange={(e) => handleQuestionLabelChange(index, e.target.value)}
                                />
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => handleRemoveQuestion(index)}
                                >
                                    Remove
                                </Button>
                            </div>
                        ))}
                    </div>
                    
                    <Button
                        sx={{ marginTop: "2rem", width: 165, border: '1px solid black', }}
                        variant='contained'
                        color='secondary'
                        onClick={handleAddQuestion}
                        >+ Add Question
                    </Button>
                </div>
            </div>

            <div className='flex w-[50%] justify-end'>
                <Button
                    sx={{ marginTop: "3rem", width: 165, border: '1px solid black'}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitSaveSurvey(event)}
                >
                    Save Survey
                </Button>
            </div>
        </Box>
    );
}

export default SurveyQuestionsEdit;





{/* <Formik>
{({ errors, touched, handleBlur, handleSubmit }) => (
    <form onSubmit={handleSubmit}>
    {fields.map((field, index) => (
        <Box key={field.id}>
        {Object.entries(field).map(([key, keyValue]) => {
            if (key === 'questions') {
            // Return the results of mapping over keyValue (if it's an object with entries).
            return Object.entries(keyValue).map(([key2, keyValue2]) => {
                    return (
                            <TextField
                                id={`input-value-${field.id}`}
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Survey Question"
                                onBlur={handleBlur}
                                onChange={(event) => handleChangeValue(event, index)}
                                value={keyValue2.values}
                                name="surveyquestion"
                                error={!!touched.editedField && !!errors.editedField}
                                helperText={touched.editedField && errors.editedField}
                                sx={{ gridColumn: "span 4", marginTop: "1rem"}}
                            />
                    );
            });
            }
            return null; // This line makes sure every path returns a value.
        })}
        </Box>
    ))}
    </form>
)}
</Formik> */}