import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';


const Pie = () => {
  const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

  const navigate = useNavigate();
  const [user, setUser ] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pieData, setPieData] = useState([]);


  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
            "kind_id": kind_id, 
            "filters": {
              "filter1": {"filter_field": "object_type", 
                    "filter_op": "=",
                    "filter_value": "customer"},
              "filter2": {"filter_field": "owner_name", 
                    "filter_op": "=",
                    "filter_value": loginUserName},
              } 
              };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);
        if (jsonObject.retrieved_data === 'No result is returned') {
          const pieArray = [];
          // dummy data for pie chart
          pieArray.push({
            id: "Example Customer 1",
            label: "Example Customer 1",
            value: 300,
            color: "hsl(104, 70%, 50%)",
          });
          pieArray.push({
            id: "Example Customer 2",
            label: "Example Customer 2",
            value: 500,
            color: "hsl(162, 70%, 50%)",
          });
          pieArray.push({
            id: "Example Customer 3",
            label: "Example Customer 3",
            value: 700,
            color: "hsl(291, 70%, 50%)",
          });
      
          setPieData(pieArray);
      } else {
          const jsonArray = jsonObject.retrieved_data.map(item => ({
            ...item,
          }));

          setCustomers(jsonArray);


          // this contains all the customer's data for pie chart after looping through and adding to this array
          const pieArray = [];
            
          // Process each element in the array
          for (const element of jsonArray) {

            const customername = element.customer_name;
            const amount = element.amount;

            pieArray.push({
                id: customername,
                label: customername,
                value: amount,
            });
          };

          // console.log('this is pieArray:');
          // console.log(pieArray);

          setPieData(pieArray);
        }

      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);


  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }
  
  return (
    <Box m="20px">
      <Header title="Pie Chart" subtitle="% of Amount" />
      {customers.length === 0 ? <div className="pt-4"><h2 className="font-bold text-gray-500">No customers under current Account Owner.</h2></div> : null }
      <Box height="75vh">
        <PieChart customData={pieData}/>
      </Box>
    </Box>
  );
};

export default Pie;
