import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { FcGoogle } from 'react-icons/fc';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastNotify } from '../../components/ToastNotify';
import mountxLogo from '../../assets/mountxcrmlogo1.png';
import boatImg from '../../assets/boat.png';
import CircularProgress from '@mui/material/CircularProgress';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";


const Register = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [ captcha, setCaptcha ] = useState(null);

    const onCaptchaChange = (value) => {
        // console.log('Captcha value:', value);
        setCaptcha(value);
      };


    const responseGoogle = (response) => {
        setIsLoading(true);

        try {
            const decoded = jwt_decode(response.credential);

            const { email, name, sub, picture } = decoded;

            // checking if user already exists or not, if not then create a new user, if exists then login the user
            const readUrl = process.env.REACT_APP_API_URL + '/api/v1/read'

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            // Encode username and password for Basic Auth
            const encodedCredentials = window.btoa(`${username}:${password}`);

            // Setup headers
            const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            const checkData = {
                "kind_id": "users", 
                "filters": {
                    "filter1": {"filter_field": "user_email", 
                        "filter_op": "=",
                        "filter_value": email.toLowerCase()},
                    } 
            };

            fetch(readUrl, {
                method: 'POST',
                body: JSON.stringify(checkData),
                headers: headers,
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {

                // create the user and new account and new bucket if the user does not exist
                if (result.retrieved_data === 'No result is returned') {
                    // company ID or kind id
                    var timestamp = new Date().getTime();
                    let kind_id = 'client' + timestamp.toString()

                    let user_type = 'admin'

                    const loginData = {
                        "user_kind_id": kind_id,
                        "user_id": sub,
                        "user_name": name,
                        "user_owner_name": name,
                        "user_email": email.toLowerCase(),
                        "user_type": user_type,
                        "user_login_type": "google",
                        "user_image": picture,
                    };

                    localStorage.setItem('user', JSON.stringify(loginData));

                    const user_id = 'User' + sub

                    const json_data = {"kind_id": "users", 
                                        "key_id": user_id, 
                                        "data": loginData};

                    const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'

                    const username = process.env.REACT_APP_API_USERNAME;
                    const password = process.env.REACT_APP_API_PASSWORD;

                    // Encode username and password for Basic Auth
                    const encodedCredentials = window.btoa(`${username}:${password}`);

                    // Setup headers
                    const headers = new Headers({
                    'Authorization': `Basic ${encodedCredentials}`,
                    'Content-Type': 'application/json'
                    });

                    fetch(createUrl, {
                        method: 'POST',
                        body: JSON.stringify(json_data),
                        headers: headers
                        })
                        .then(loginresponse => loginresponse.json())
                        .then(result => {
                            const json_data = {"bucketName": kind_id};

                            const createBucketUrl = process.env.REACT_APP_API_URL + '/api/v1/createbucket'

                            const username = process.env.REACT_APP_API_USERNAME;
                            const password = process.env.REACT_APP_API_PASSWORD;

                            // Encode username and password for Basic Auth
                            const encodedCredentials = window.btoa(`${username}:${password}`);

                            // Setup headers
                            const headers = new Headers({
                            'Authorization': `Basic ${encodedCredentials}`,
                            'Content-Type': 'application/json'
                            });

                            fetch(createBucketUrl, {
                                method: 'POST',
                                body: JSON.stringify(json_data),
                                headers: headers
                                })
                                .then(loginresponse => loginresponse.json())
                                .then(result => {
                                    console.log('Bucket created')
                                    console.log(result)
                                })
                                .catch(error => {
                                    setError(error);
                                    console.log(error);
                                });
                            navigate('/dashboard', { replace: true})
                        })
                        .catch(error => {
                            setError(error);
                            console.log(error);
                        });
                } else {
                    const myJsonString = JSON.stringify(result);
                    const jsonObject = JSON.parse(myJsonString); 
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        user_kind_id: item.user_kind_id,
                        user_id: item.user_id,
                        user_email: item.user_email,
                        user_name: item.user_name,
                        user_owner_name: item.user_owner_name,
                        user_type: item.user_type,
                        user_login_type: item.user_login_type,
                        user_image: item.user_image,
                    }));

                    localStorage.setItem('user', JSON.stringify(jsonArray[0]));

                    ToastNotify('success', 'You already have an account. Logging you in..')

                    navigate('/dashboard', { replace: true})
                }
            });

            
        } catch (error) {
            setError(error);
            ToastNotify('error', 'Failed to login with Google.')
            console.log(error);
        }
        
    };


    const isNonMobile = useMediaQuery("(min-width:200px)");

    const checkoutSchema = yup.object().shape({
        companyName: yup.string().required("required"),
        address1: yup.string().required("required"),
        fullName: yup.string().required("required"),
        email: yup.string().email("invalid email").required("required"),
        password: yup.string().required("required"),
    });
    
    
    const initialValues = {
        email: "",
        password: "",
        companyName: "",
        address1: "",
        fullName: "",
    };



    const handleRegister = (event, values) => {
        event.preventDefault();

        if (captcha === null) {
            ToastNotify('error', 'Please verify the Captcha.')
            return
        }

        if (values.email === "" || values.password === "" || values.companyName === "" || values.address1 === "" || values.fullName === "") {
            ToastNotify('error', 'Please fill in all the required fields.')
            return
        }

        // checking if user already exists or not, if not then create a new user, if exists then login the user
        const readUrl = process.env.REACT_APP_API_URL + '/api/v1/read'

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        // Encode username and password for Basic Auth
        const encodedCredentials = window.btoa(`${username}:${password}`);

        // Setup headers
        const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

        const checkData = {
            "kind_id": "users", 
            "filters": {
                "filter1": {"filter_field": "user_email", 
                    "filter_op": "=",
                    "filter_value": values.email.toLowerCase()},
                } 
        };


        fetch(readUrl, {
            method: 'POST',
            body: JSON.stringify(checkData),
            headers: headers,
        })
        .then(loginresponse => loginresponse.json())
        .then(result => {

            // create the user and new account and new bucket if the user does not exist
            if (result.retrieved_data === 'No result is returned') {
                try {
                    setIsLoading(true);
                 
                    var timestamp = new Date().getTime();
                    let kind_id = 'client' + timestamp.toString()  // companyID or kind_id of the database of datastore
                    let user_id = timestamp.toString()
                    let key_id = 'User' + user_id
        
                    let user_type = 'admin'
                    let emailLowerCased = values.email.toLowerCase()
                    let companyNameLowerCased = values.companyName.toLowerCase()
            
            
                    const registerData = {
                        "user_kind_id": kind_id,
                        "user_company_name": companyNameLowerCased,
                        "user_id": user_id,
                        "user_owner_name": values.fullName,
                        "user_email": emailLowerCased,
                        "user_password": values.password,
                        "user_type": user_type,
                        "user_login_type": "standard",
                        "user_image": "none",
                    };
            
                    localStorage.setItem('user', JSON.stringify(registerData));
        
        
                    const json_data = {"kind_id": "users", 
                                        "key_id": key_id, 
                                        "data": registerData};
        
                    const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'
        
                    const username = process.env.REACT_APP_API_USERNAME;
                    const password = process.env.REACT_APP_API_PASSWORD;
        
                    // Encode username and password for Basic Auth
                    const encodedCredentials = window.btoa(`${username}:${password}`);
        
                    // Setup headers
                    const headers = new Headers({
                    'Authorization': `Basic ${encodedCredentials}`,
                    'Content-Type': 'application/json'
                    });
        
                    fetch(createUrl, {
                        method: 'POST',
                        body: JSON.stringify(json_data),
                        headers: headers
                        })
                        .then(loginresponse => loginresponse.json())
                        .then(result => {
        
                            const json_data = {"bucketName": kind_id};
        
                            const createBucketUrl = process.env.REACT_APP_API_URL + '/api/v1/createbucket'
        
                            const username = process.env.REACT_APP_API_USERNAME;
                            const password = process.env.REACT_APP_API_PASSWORD;
        
                            // Encode username and password for Basic Auth
                            const encodedCredentials = window.btoa(`${username}:${password}`);
        
                            // Setup headers
                            const headers = new Headers({
                            'Authorization': `Basic ${encodedCredentials}`,
                            'Content-Type': 'application/json'
                            });
        
                            fetch(createBucketUrl, {
                                method: 'POST',
                                body: JSON.stringify(json_data),
                                headers: headers
                                })
                                .then(loginresponse => loginresponse.json())
                                .then(result => {
                                    console.log('Bucket created')
                                    console.log(result)
                                })
                                .catch(error => {
                                    setError(error);
                                    console.log(error);
                                });
                            navigate('/dashboard', { replace: true})
                        })
                        .catch(error => {
                            setError(error);
                            console.log(error);
                        });
                } catch (error) {
                    setError(error);
                    console.log(error);
                    ToastNotify('error', 'Something went wrong. Failed to register.')
                }  

            } else {

                ToastNotify('error', 'This Email Address is already registered. Please login.')

            }
        });

    }


    // used only for Formik, it is needed but when actually clicking the register button, the above handleRegister function is used.
    const handleFormSubmit = (values) => {
        // console.log(values);
        // console.log(values.email);
        return values
    };


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } 
    }


  return (
    <>
    <div className='flex-1 flex-row'>
        <img 
            src={mountxLogo}
            alt="camp"
            onClick={() => navigate('/')}
            style={{
                height: '50px', 
                width: '50px', 
                zIndex: '10',
                position: 'absolute',
                top: '1rem',
                left: '1rem',
                cursor: 'pointer',
            }}
        >
        </img>
    </div>
    <div
        className='flex flex-col xl:flex-row w-full h-screen bg-white'
    >
        <div 
            className='flex flex-1 justify-center items-center flex-col py-24' 
        >
            <div>
                <h2 className='text-3xl md:h2-bold pt-1 sm:pt-6 text-mainColor'>Register to MountX CRM</h2>
                <p className='text-light-300 pt-5 small-medium md:base-regular'>To use MountX CRM register with your information</p>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, paddingTop: "10px",
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyName}
                                name="companyName"
                                error={!!touched.companyName && !!errors.companyName}
                                helperText={touched.companyName && errors.companyName}
                                sx={{ gridColumn: "span 4", 
                                        backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                        input: {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiInputLabel-root': {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiFilledInput-root': {
                                            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                        },
                                        '.MuiFilledInput-underline:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                        },
                                        '.MuiFilledInput-underline:hover:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                        '.MuiFilledInput-underline:after': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                 }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address1}
                                name="address1"
                                error={!!touched.address1 && !!errors.address1}
                                helperText={touched.address1 && errors.address1}
                                sx={{ gridColumn: "span 4", 
                                        backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                        input: {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiInputLabel-root': {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiFilledInput-root': {
                                            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                        },
                                        '.MuiFilledInput-underline:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                        },
                                        '.MuiFilledInput-underline:hover:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                        '.MuiFilledInput-underline:after': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                 }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Full Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fullName}
                                name="fullName"
                                error={!!touched.fullName && !!errors.fullName}
                                helperText={touched.fullName && errors.fullName}
                                sx={{ gridColumn: "span 4", 
                                        backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                        input: {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiInputLabel-root': {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiFilledInput-root': {
                                            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                        },
                                        '.MuiFilledInput-underline:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                        },
                                        '.MuiFilledInput-underline:hover:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                        '.MuiFilledInput-underline:after': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 4", 
                                        backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                        input: {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiInputLabel-root': {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiFilledInput-root': {
                                            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                        },
                                        '.MuiFilledInput-underline:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                        },
                                        '.MuiFilledInput-underline:hover:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                        '.MuiFilledInput-underline:after': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                 }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{ gridColumn: "span 4",
                                        backgroundColor: theme.palette.mode === 'dark' ? 'colors.background' : 'white',
                                        input: {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiInputLabel-root': {
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        },
                                        '.MuiFilledInput-root': {
                                            backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[900],
                                        },
                                        '.MuiFilledInput-underline:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.42)',
                                        },
                                        '.MuiFilledInput-underline:hover:before': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                        '.MuiFilledInput-underline:after': {
                                            borderBottomColor: theme.palette.mode === 'dark' ? 'black' : 'black',
                                        },
                                }}
                            />
                        </Box>
                        <Box marginTop="2rem">
                            <ReCAPTCHA
                                sitekey="6LcU90spAAAAAP1y8uy6P2p4kgxQyy43CeKD3FcY"
                                onChange={onCaptchaChange}
                            />
                        </Box>
                        <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" justifyContent="start" mt="20px">
                            <Button
                                sx={{"margintop": "1rem", gridColumn: "span 4"}} 
                                color='secondary'
                                variant='contained'
                                onClick={(event) => handleRegister(event, values)}
                            >
                                Register
                            </Button>
                            {/* <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    onClick={handleFormSubmit(values)}
                                    sx={{ gridColumn: "span 4" }}
                                >
                                Sign in
                            </Button> */}
                        </Box>
                    </form>
                    )}
                </Formik>
            </div>
            <div className='pt-2 pb-2'>
                <p className='text-light-300 font-bold pt-5 small-medium md:base-regular'>Or, Register using Google</p>
            </div>
            <div className='app__login-container-content-googlelogin'>
                <GoogleLogin 
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                        <button
                            type='button'
                            className='googlelogin-button bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none'
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            style={{'justifyContent': 'center', 
                                    'alignItems': 'center',
                                    'width': '200px',
                                    'height': '100px',
                                    'backgroundColor': 'lightgreen', 
                                    'fontSize': '30px', 
                                    'color': 'black',
                                    'cursor': 'pointer',
                                    }}
                        >
                            <FcGoogle className='mr-4' />Register with Google
                        </button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                />
            </div>
            <div className='pt-2 pb-2'>
                <p className='text-light-300 pt-5 small-medium text-black'>Already have an account? Login <a className='text-blue-500' href='/login'>here</a></p>
            </div>
        </div>
        <img
            src={boatImg}
            alt='loginLogo'
            className='hidden xl:block h-screen w-1/2 object-cover bg-no-repeat'
        >
        </img>
    </div>
    </>
  )
};

export default Register;