import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from '../../../components/ToastNotify';
import CircularProgress from '@mui/material/CircularProgress';


const Addproperty = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const navigate = useNavigate();

    const [custData, setCustData] = useState([]);
    const [editedPropertyData, setEditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const [user,setUser] = useState(null);

    const [selectField, setSelectField] = useState('');


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);



    useEffect(() => {  // retrieving customers for customer fields but only setting the first customer in the state as we only need 1 customer to get the customer fields as it's universal for all customers
        const fetchCustData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                                } 
                                };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                                })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString); 
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));

                setCustData(jsonArray[0]);
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchCustData();
    }, []);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    const handleInputChangeAddProperty = (index, key, value) => {
        setEditedPropertyData((prevData) => {
        const updatedData = [...prevData];
        if (!updatedData[index]) {
            updatedData[index] = {};
        }
        updatedData[index][key] = value;
        return updatedData;
        });
    };


    const handleSubmitAddProperty = (event, editedData) => {
        event.preventDefault();

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const currentTimestamp = Date.now();
        const hspropertykey = 'hsproperty' + currentTimestamp

        const innerArray = editedData["0"]

        innerArray['hs_property_keyid'] = hspropertykey
        innerArray['hs_name'] = 'main healthscore'
        innerArray['object_type'] = 'hs_property'

        const json_data = {"kind_id": kind_id, 
                            "key_id": hspropertykey, 
                            "data": innerArray};

        const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'

        fetch(createUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Property has been created: ')
                // console.log(result);
                ToastNotify("success", "Healthscore property created")
                navigate('/healthscores/manage', { replace: true})
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify("error", "Error, Something went wrong.")
                console.error(error);
            });




        // DON'T NEED THIS CODE ANYMORE, we don't need to add the healthscore property to the healthscore object because the newly created healthscore property is an entity on its own with object_type = 'hs_property'
        // const updateUrl = process.env.REACT_APP_API_URL + '/api/v1/update'

        // Create an array to store unique hs_key_id values
        // const uniqueKeyIds = [];


        // we need to add the new healthscore property to ALL compnay healthscore objects??
        // hsData.forEach((item, index) => {
        //     Object.entries(item).forEach(([key]) => {
        //         const hs_key_id = hsData[index]["customer_name"];
        //         // const hs_key_id_formatted = hs_key_id.replace(/\s/g, '')  // remove spaces in customer name (DONT NEED)
        //         const hs_key_id_concat = hs_key_id + 'healthscore';

        //         // Check if the hs_key_id already exists in the array
        //         if (!uniqueKeyIds.includes(hs_key_id)) {
        //             uniqueKeyIds.push(hs_key_id);

        //             const hs_property_name = editedPropertyData["0"]["hs_property_name"];
        //             const formatted_prop_name = hs_property_name.replace(/ /g, '_').toLowerCase();  // replace spaces with _
        //             // const formatted_prop_name = hs_property_name.replace(/\s/g, '').toLowerCase();  // remove spaces entirely
        //             const update_json_data = {
        //                 "kind_id": kind_id,
        //                 "key_id": hs_key_id_concat,
        //                 "data": { [formatted_prop_name]: 'null' }
        //             };

        //             fetch(updateUrl, {
        //                 method: 'POST',
        //                 body: JSON.stringify(update_json_data),
        //                 headers: {
        //                 'Content-Type': 'application/json'
        //                     }
        //                 })
        //                 .then(loginresponse => loginresponse.json())
        //                 .then(result => {
        //                     // Handle the API response or perform any necessary actions
        //                     console.log('Property has been added to customer healthscore: ');
        //                     console.log(result);
        //                     navigate('/healthscores/manage', { replace: true})
        //                 })
        //                 .catch(error => {
        //                     // Handle errors that occurred during the API call
        //                     console.error(error);
        //                 });
        //         }
        //     });
        // });

    };


    const handleChange = (event) => {
        handleInputChangeAddProperty("0", 'hs_property_name', event.target.value)
        setSelectField(event.target.value);
      };

  return (
    <Box m="35px">
        <Header
                title="Add New Healthscore Property"
                subtitle="Choose an existing field or create a new field to calculate healthscores."
        />
        <h3 className='font-bold text-2xl'>Choose an Existing Field</h3>
        <div>
            <div
                className="w-full md:w-1/3 h-auto flex-col justify-start p-4"
            >
                <FormControl sx={{ m: 1, width: "60%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Existing Fields</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        { Object.entries(custData).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

                            if (key.includes('object_type') || key.includes('kind_id') || key.includes('score_history') ||
                                key.includes('key_id') || key.includes('healthscore') ||
                                key.includes('customer_name') || key.includes('owner_name')) {
                                return null;
                            } else {
                                return (
                                    <MenuItem value={formattedKey}>{formattedKey}</MenuItem>
                                )
                            }
                        })}
                    </Select>
                    <FormHelperText>Choose an Existing Field from the Customers Object</FormHelperText>
                </FormControl>
            </div>
            <div 
                className="w-full md:w-1/3 h-auto flex-col justify-start p-4"
            >
                <h3 className='font-bold text-lg'>Propery Weight Definition:</h3>
                <p className='text-sm text-gray-500 mt-4'>
                    Choose how much of a weight this field should have in the overall healthscore calculation. If you only create 1 property, then this can be set to 1 and it will be 100% of the healthscore calculation.  If you have 2 properties, then you can set each property to be 0.50 but it is not required. If you create 3 properties and all 3 fields are required to be in the healthscore calculation, then 2 properties can be 0.33 with 1 of them being 0.34 so all 3 add up to a sum of 1. Or, you can set one at 0.25, another at 0.50, and the last one at 0.25.  The sum of all property weights must be equal to 1 unless you choose to use one field that sets the overall healthscore depending on the different values in the field. 
                </p>
                <br>
                </br>
                <p className='text-sm text-gray-500 mt-8'>
                    For example, if you have a field with 5 different unique values in it, and you want this field to be the only property in the healthscore calculation, then you must create 5 properties with a weight of 1 for all the 5 unique values since they are independent of each other. i.e. Lifecycle Stage field, you can create a property with a weight of 1 for the value of 'Onboarding' and create another property with a weight of 1 for the value of 'Use' and so on. You can create as many properties with a weight of 1 for all the different values from the field since the total healthscore calculation will be based on the current value.
                </p>
                <div
                    className='flex flex-row w-full mt-8 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Weight:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8'
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_weight', event.target.value)}}
                        />
                    </div>
                    
                </div>
                <p className='text-sm text-gray-500 mt-8'>
                    State if this field should be `{'>'}`, `{'<'}`, or `{'='}` to the property value you set below.
                </p>
                <div
                    className='flex flex-row w-full mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Operator:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8'
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_operator', event.target.value)}}
                        />
                    </div>
                   
                </div>
                <p className='text-sm text-gray-500 mt-8'>
                    Set the property value.
                </p>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Value:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8 '
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_value', event.target.value)}}
                        />
                    </div>
                    
                </div>
                <p className='text-sm text-gray-500 mt-8'>
                    Now, if the field meets the requirements you set above, then set the score for this property to be 100.  However, if this property logic is to decrease the healthscore then set this to be 0 or a low number close to 0. For example, when field Lifecycle Stage = 'New' then Score can be 0. 
                </p>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Score:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8 '
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_score', event.target.value)}}
                        />
                    </div>
                   
                </div>
            </div>
            <div className='p-4'>
                <Button
                    sx={{"margintop": "2rem"}} 
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitAddProperty(event, editedPropertyData)}
                >
                    Add Property
                </Button>
            </div>
        </div>


        <div 
            className="flex flex-col mt-16 p-4"
        >
            <h3 className='font-bold text-2xl'>Or, create a new Field</h3>
            <p className='text-gray-500'>This will create a new Healthscore property AND create a new customer field under a customer's profile 'Customer Details'</p>
            <div 
                className="w-full md:w-1/3 h-auto flex-col justify-start"
            >
                <div
                    className='flex flex-row mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Name:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8'
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_name', event.target.value)}}
                        />
                    </div>
                    
                </div>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Weight:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8'
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_weight', event.target.value)}}
                        />
                    </div>
                </div>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Operator:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8'
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_operator', event.target.value)}}
                        />
                    </div>
                </div>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Value:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8 '
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_value', event.target.value)}}
                        />
                    </div>
                </div>
                <div
                    className='flex flex-row  mt-4 mb-4 justify-between'
                >
                    <div className='w-[75px]'>
                        <p className='font-bold'>
                            Property Score:
                        </p>
                    </div>
                    <div className='w-full'>
                        <input
                            className='border-[1px] border-gray-500 w-full h-[30px] ml-8 '
                            type="text"
                            onChange={(event) => {handleInputChangeAddProperty("0", 'hs_property_score', event.target.value)}}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Button
                    sx={{"margintop": "1rem"}} 
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitAddProperty(event, editedPropertyData)}
                >
                    Add Property
                </Button>
            </div>
        </div>
    </Box>
  );
}

export default Addproperty;