import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import BarChartAR from "../../components/BarChartAR";



const Bar = () => {

  const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

  const navigate = useNavigate();

  const [user, setUser ] = useState(null);
  const [actionRequests, setActionRequests ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const ARdata = actionRequests.length > 0 ? 
        [
          {
            xAxis: (actionRequests.slice(0,1).map((ar) => ar.owner_name)),
            OverdueColor: "hsl(296, 70%, 50%)",  
            Open: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'open' ? count + 1 : count, 0)),  // open AR's
            OpenColor: "hsl(97, 70%, 50%)",
            Closed: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'closed' ? count + 1 : count, 0)),  // closed AR's
            ClosedColor: "hsl(344, 70%, 50%)",
            WIP: (actionRequests.reduce((count, ar) => ar.status.toLowerCase() === 'wip' ? count + 1 : count, 0)), // WIP AR's
            WIPColor: "hsl(291, 70%, 50%)",
          },
        ]
        : [
          {
            xAxis: "Owner has no Action Requests",
            Overdue: 0,  // overdue AR's
            OverdueColor: "hsl(296, 70%, 50%)",  
            Open: 0,  // open AR's
            OpenColor: "hsl(97, 70%, 50%)",
            Closed: 0,  // closed AR's
            ClosedColor: "hsl(344, 70%, 50%)",
            WIP: 0, // WIP AR's
            WIPColor: "hsl(291, 70%, 50%)",
          },
        ];


   // for action requests data
   useEffect(() => {
    const fetchActionRequests = async () => {
      setIsLoading(true);
      try {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        if (checkUser === null) {
            navigate('/login', {replace: true})
        } else {
            const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

            Object.entries(userInfojsonObject).map(([key, value]) => {
                // Perform operations with the key-value pair
                const obj = { key, value }; // Create an object with key-value pair
                UserjsonArray.push(obj);
            });
            setUser(UserjsonArray);
        };

         // Make an API request to retrieve data from the Google Cloud Datastore
        const urlAR = process.env.REACT_APP_API_URL + '/api/v1/read'
        const dataAR = {
             "kind_id": kind_id, 
             "filters": {
               "filter1": {"filter_field": "object_type", 
                     "filter_op": "=",
                     "filter_value": "cta"},
               "filter2": {"filter_field": "owner_name", 
                     "filter_op": "=",
                     "filter_value": loginUserName},
               } 
               };
        const responseAR = await fetch(urlAR, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(dataAR)
                      })

        const resultAR = await responseAR.json();
        const myJsonStringAR = JSON.stringify(resultAR);
        const jsonObjectAR = JSON.parse(myJsonStringAR); 
        const jsonArrayAR = jsonObjectAR.retrieved_data.map(item => ({
              ...item,
        }));

        //  console.log(jsonArrayAR)
 
         setActionRequests(jsonArrayAR);
         
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchActionRequests();
  }, []);


  return (
    <Box m="20px">
      <Header title="Activities" subtitle="Status of Activities" />
      {actionRequests.length === 0 ? <div className="pt-4"><h2 className="font-bold text-gray-500">No Activities under current Owner.</h2></div> : null }
      <Box height="75vh">
        <BarChartAR data={ARdata}/>
      </Box>
    </Box>
  );
};

export default Bar;
