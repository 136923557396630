import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


const SearchPage = () => {

    const { searchTerm } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [actionRequests, setActionRequests ] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [HSPropertyData, setHSPropertyData] = useState([]);
    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // pagination code
    const [page, setPage] = useState(1);

    const itemsPerPage = 5; // items per page
    const totalPages = Math.ceil(customers.length / itemsPerPage);

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = customers.slice(startIndex, endIndex);



    useEffect(() => {

        
        const fetchData = async () => {
          setIsLoading(true);
          try {

            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            if (checkUser === null) {
                navigate('/login', {replace: true})
            } else {
                const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

                Object.entries(userInfojsonObject).map(([key, value]) => {
                    // Perform operations with the key-value pair
                    const obj = { key, value }; // Create an object with key-value pair
                    UserjsonArray.push(obj);
                });
                setUser(UserjsonArray);
            };

            // capitalize first letter in customer name from params searchTerm
            const capitalizedSearchTerm = searchTerm.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                "kind_id": kind_id, 
                "filters": {
                  "filter1": {"filter_field": "object_type", 
                        "filter_op": "=",
                        "filter_value": "customer"},
                  "filter2": {"filter_field": "customer_name", 
                        "filter_op": "=",
                        "filter_value": capitalizedSearchTerm},
                  } 
                  };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                      "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                      } 
                };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                              })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                const jsonArray = jsonObject.retrieved_data.map(item => ({
                    ...item,
                  }));

                const filtered = jsonArray.filter( (customer) => customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                setCustomers(filtered);
            } else {
                const jsonArray = jsonObject.retrieved_data.map(item => ({
                    ...item,
                  }));
                setCustomers(jsonArray);
            } 
            
          } catch (error) {
            setError(error.message);
          }
          setIsLoading(false);
        };
    
        fetchData();
    }, [searchTerm]);



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div className='mt-24 flex flex-col justify-center items-center'><h2 className='text-xl font-bold text-black'>Sorry, no customers were found.</h2></div>;
        }
    }



    return (
        <Box m="30px">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
            <Header
                    title="Search Results"
                    subtitle="View Accounts"
            />
            <div className='app__hsmanagement-container'>
                <div className='app__hsmanagement-edit-properties-title'>
                    <h3>Account Results</h3>
                </div>
                <div 
                    className="flex flex-col"
                >
                    <div 
                        className="flex flex-col w-full md:w-1/2 justify-center"
                    >
                        {displayedData?.map((customer) => (
                        <div 
                            style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'margin': '1rem 0 1rem 0', 'border': '1px solid lightgrey', 'cursor': 'pointer', 'padding': '10px'}}
                            onClick={() => {navigate(`/account-profile/${customer.customer_name}`)}}
                        >
                            <p 
                                className='app__add-property-fieldnames-text'
                                style={{'padding': '5px', 'fontSize': '18px',}}
                            >
                                {customer.customer_name}
                            </p>
                            <p 
                                className='app__add-property-fieldnames-text'
                                style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                            >
                                Lifecycle Stage:
                                <span> </span> 
                                {customer.lifecycle_stage}
                            </p>
                            <p 
                                className='app__add-property-fieldnames-text'
                                style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center'}}
                            >
                                Healthscore:
                                <span> </span> 
                                {customer.healthscore}
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
                <Stack spacing={2}>
                    <Pagination 
                        count={totalPages}
                        page={page}
                        color="secondary"
                        showFirstButton 
                        showLastButton
                        onChange={(event, value) => setPage(value)}
                    />
                </Stack>
                {/* <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => {}}
                >
                    Button Placeholder
                </Button> */}
            </div>
        </Box>
    );
}

export default SearchPage;

