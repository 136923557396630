import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';



const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    fieldName: yup.string().required("required"),
});


const initialValues = {
    fieldName: "",
    // fieldValue: "",
};



const LeadNewField = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });


    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);
    const [ data, setData ] = useState([]);
    const [ error, setError] = useState(null);
    const [ isLoading, setIsLoading] = useState(false);
    const [ noOpportunities, setNoOpportunities ] = useState(false);


    useEffect(() => {
        const fetchData = async () => {

            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            if (checkUser === null) {
                navigate('/login', {replace: true})
            } else {
                const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

                Object.entries(userInfojsonObject).map(([key, value]) => {
                    // Perform operations with the key-value pair
                    const obj = { key, value }; // Create an object with key-value pair
                    UserjsonArray.push(obj);
                });
                setUser(UserjsonArray);
            };

            
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                                "filter1": {"filter_field": "object_type", 
                                        "filter_op": "=",
                                        "filter_value": "lead"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setNoOpportunities(true);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map(item => ({
                        ...item,
                    }));
                    setData(jsonArray);
                }

                } catch (error) {
                    setError(error.message);
                }
        }
        fetchData();
    }, []);


    // only creating a new field and not entering the value for the field.  Customer has to enter the value in the lead profile page
    const handleFormSubmit = async (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // console.log(values);

        // values has an array {fieldName: 'test_field', fieldValue: 'test_value'}, add 'test_fied' as the key and 'test_value' as the value to the data[0] array
        const actualFieldName = values.fieldName.toLowerCase();

        // lowercase the first letter in actualFieldName
        const newFieldName = actualFieldName.charAt(0).toLowerCase() + actualFieldName.slice(1);

        // const newFieldValue = values.fieldValue;
        
        try {
            data.map((item) => {
                const newData = {};
                newData[newFieldName] = "";
    
                const json_data = {"kind_id": kind_id, 
                                    "key_id": item.key_id, 
                                    "data": newData};
    
                const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(json_data),
                    headers: headers
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Handle the API response or perform any necessary actions
                        // console.log('New field has been added for customer: ' + item.customer_name)
                        // console.log(result);
                    })
                    .catch(error => {
                        // Handle errors that occurred during the API call
                        console.error(error);
                    });
            });
            ToastNotify("success", "New field has been added");
        } catch (error) {
            ToastNotify("error", "something went wrong");
            console.error(error);
        }

    };



    if (noOpportunities) {
        return <div className='flex flex-col justify-center items-center text-xl'>You have no leads to add fields...add a lead here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addlead')}>Add New Lead</Button></div>;
    }


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }    



    return (
        <Box m="20px" width="90%">
        <Header title="New Field" subtitle="Leads" />

        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? "span 1" : "span 4" },
                    }}
                >
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Field Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fieldName}
                        name="fieldName"
                        error={!!touched.fieldName && !!errors.fieldName}
                        helperText={touched.fieldName && errors.fieldName}
                        sx={{ gridColumn: "span 4" }}
                    />
                    <div className="w-auto col-span-4">
                        <p className="text-xs text-gray-500">
                            This field will be added to all existing and new Leads.  Enter the field value for a specific lead in the lead details page.
                        </p>
                    </div>

                    {/* <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Field Value"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fieldValue}
                        name="fieldValue"
                        error={!!touched.fieldValue && !!errors.fieldValue}
                        helperText={touched.fieldValue && errors.fieldValue}
                        sx={{ gridColumn: "span 4" }}
                    /> */}
                </Box>
                <Box display="flex" justifyContent="start" mt="20px">
                    <Button
                        sx={{ width: 200, }}
                        type="submit" 
                        color="secondary" 
                        variant="contained"
                    >
                        Add
                    </Button>
                </Box>
            </form>
            )}
        </Formik>
        </Box>
    );
};



export default LeadNewField;
