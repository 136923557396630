import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, Typography} from '@mui/material';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';



const Nps = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);
    const [editedData, setEditedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);
    
    
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        if (user_type === 'admin') {
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
    
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: "Example Customer 1",
                                nps_score: "65"
                            }]
                    setData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        customer_name: item.customer_name,
                        nps_score: item.nps_score,
                    }));
                    setData(jsonArray);
                }

    
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        } else {
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
    
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: "Example Customer 1",
                                nps_score: "65"
                            }]
                    setData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        customer_name: item.customer_name,
                        nps_score: item.nps_score,
                    }));
                    setData(jsonArray);
                }
                
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        }

        };

        fetchData();
    }, []);


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    const columns = [
        { field: "id", headerName: "ID"}, 
        { 
        field: "customer_name", 
        headerName: "Customer Name", 
        flex: 1,
        textDecoration: "none",
        color: "inherit",
        cellClassName: "name-column--cell",
        renderCell: (params) => {
            const cellValue = params.value;
            return (
            <Link 
                to={`/customer-profile/${cellValue}`}
                style={{ textDecoration: 'none', color: "inherit" }}
            >
                {cellValue}
            </Link>
            );
        },
        },
        { 
        field: "nps_score",
        headerName: "NPS Score", 
        flex: 1,
        justifyContent: "flex-start",
        renderCell: ({ row: { nps_score_value }}) => {
            return (
            <Box
                width="100%"
                m="0 auto"
                p="5px"
                display="flex"
                justifyContent="left"
                backgroundColor = {
                    nps_score_value >= "80"
                    ? green[100] :
                    nps_score_value >= "60"
                    ? yellow[100] :
                    nps_score_value < "60"
                    ? red[100]
                    : red[100]
                }
                borderRadius="4px"
            >
                {nps_score_value < "60" && <AdminPanelSettingsOutlinedIcon />}
                {nps_score_value >= "60" && <SecurityOutlinedIcon />}
                {nps_score_value >= "80" && <LockOpenOutlinedIcon />}
                <Typography color={grey} sx={{ ml: "5px" }}>
                {nps_score_value}
                </Typography>
            </Box>
            )
        }
        },
    ]


    return (
        <Box m='20px'>
            <Header
                title="NPS"
                subtitle="List of NPS Scores"
            />
            <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => navigate('/addnps')}
                >
                    Add NPS Scores
            </Button>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{"& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );

}


export default Nps;