import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ToastNotify } from '../../components/ToastNotify';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { saveLog } from "../../utils/logChanges";


// regex to check if the user type is either admin, manager or user (case insensitive)
const userTypes = /^(Admin|Manager|User)$/i;



const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().email("invalid email"),
    phone: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    country: yup.string(),
    contact_type: yup.string(),
    title: yup.string(),
    notes: yup.string(),
    // userType: yup
    //     .string()
    //     .matches(userTypes, "User Type is not valid, enter Admin, Manager or User")
    //     .required("required"),
});


const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    contact_type: "",
    title: "",
    notes: "",
};



const NewContact = () => {

    const { customername } = useParams();
  
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    
    const handleFormSubmit = (values) => {
      // console.log(values);

      const checkUser = localStorage.getItem('user');
    
      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const companyName = fetchedUserinner.user_company_name  // using this variable to get company name if any
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });


      const newContactData = {
        "object_type": "contact",
        "contact_customer_name": customername.trim(),
        "contact_name": values.name.trim(),
        "contact_email": values.email.trim(),
        "contact_phone": values.phone,
        "contact_address": values.address,
        "contact_city": values.city,
        "contact_state": values.state,
        "contact_zipcode": values.zipcode,
        "contact_country": values.country,
        "contact_type": values.contact_type,
        "contact_title": values.title,
        "contact_notes": values.notes,
      };

      const json_data = {"kind_id": kind_id, 
                          "data": newContactData};

      const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'


      fetch(createUrl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(loginresponse => loginresponse.json())
          .then(result => {
              // navigate('/dashboard', { replace: true})
              // console.log('New Member Added')
              // console.log(result);
              saveLog(new Date().toLocaleString(), 'Account', values.email.trim(),  customername, 'Contact', `Added Contact ${values.name.trim()} to ${customername}`);
              ToastNotify('success', 'New Contact Added');
          })
          .catch(error => {
              console.error(error);
              ToastNotify('error', 'Error, something went wrong');
          });
    };




    return (
      <Box m="30px" width="50%">
        <Header title="New Contact" subtitle={`Add Contact for ${customername}`} />

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Contact Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contact_type}
                  name="contact_type"
                  error={!!touched.contact_type && !!errors.contact_type}
                  helperText={touched.contact_type && errors.contact_type}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="City"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  name="city"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="State"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.state}
                  name="state"
                  error={!!touched.state && !!errors.state}
                  helperText={touched.state && errors.state}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Zipcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zipcode}
                  name="zipcode"
                  error={!!touched.zipcode && !!errors.zipcode}
                  helperText={touched.zipcode && errors.zipcode}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Country"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  name="country"
                  error={!!touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Notes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.notes}
                  name="notes"
                  error={!!touched.notes && !!errors.notes}
                  helperText={touched.notes && errors.notes}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box display="flex" justifyContent="start" mt="20px">
                <Button 
                  type="submit" 
                  color="secondary" 
                  variant="contained"
                  sx={{ width: "200px" }}
                >
                  Add New Contact
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Button
              sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
              variant="contained"
              onClick={(event) => navigate(`/account-profile/${customername}#1`)}
          >
              Back to Account Profile
          </Button>
      </Box>
    );
};



export default NewContact;
