import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you're using react-router
import CircularProgress from '@mui/material/CircularProgress';



// this page is for actual customer answering survey questions after being directed from email link
const SurveyPage = () => {


    const { surveyId } = useParams();

    const [surveyData, setSurveyData] = useState(null);

    const [templatesData, setTemplatesData] = useState(null);


    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    // no, don't fetch campaign templates, need to fetch survey questions from datastore
    // fetching campaign templates
    // useEffect(() => {
    //     const fetchCampaignTemplates = async () => {
    //       setIsLoading(true);

    //       const checkUser = localStorage.getItem('user');

    //       const fetchedUserString = JSON.stringify(checkUser); 
    //       const fetchedUserObject = JSON.parse(fetchedUserString);
    //       const fetchedUserinner = JSON.parse(fetchedUserObject);
    //       const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    //       const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    //       const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

    //       try {
    //         // Make an API request to retrieve data from the Google Cloud Datastore
    //         const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/listtemplates';
    //         const response = await fetch(url, {
    //                         method: 'POST',
    //                         headers: headers,
    //                       })
    
    //         const result = await response.json();

    //         // console.log('this is result:')
    //         // console.log(result)

    //         // const myJsonString = JSON.stringify(result);
    //         // const jsonObject = JSON.parse(myJsonString);

    //         // console.log('this is list templates jsonObject:')
    //         // console.log(jsonObject)

    //         if (result === 'No result is returned') {
    //             const jsonArray = [{
    //                         Name: 'Example Campaign Template 1',
    //                         CreatedTimestamp: '2024-10-01',
    //                     }]
    //             setTemplatesData(jsonArray);
    //             setIsLoading(false);
    //         } else {

    //             const myJsonString = JSON.stringify(result);
    //             const jsonObject = JSON.parse(myJsonString);

    //             const newdataStr = jsonObject.replace(/datetime\.datetime\((\d{4}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d{1,2}), (\d+).*?\)/g, (match, year, month, day, hour, minute, second, micro) => {
    //                 // Constructing an ISO string representation (ignoring timezone and microseconds for simplicity)
    //                 return `"${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}"`;
    //             });

    //             // there was an erroneous paranthesis inside the string after modifying the timestamps, replace ) with empty string
    //             const newdataStr2 = newdataStr.replace(/\)/g, '');

    //             // replacing single quotes with double quotes for valid json array format
    //             const newdataStr3 = newdataStr2.replace(/\[{'/g, '[{"').replace(/'}]/g, '"}]').replace(/', '/g, '", "').replace(/': '/g, '": "').replace(/', "/g, '", "').replace(/", '/g, '", "');

    //             // there was still a single quote on one of the values, so I replaced it with double quotes
    //             const newdataStr4 = newdataStr3.replace(/\'/g, '"');

    //             // console.log('this is newdataStr4:')
    //             // console.log(newdataStr4)

    //             const jsonArray = JSON.parse(newdataStr4);

    //             // console.log('this is jsonArray:')
    //             // console.log(jsonArray)
        
    //             setTemplatesData(jsonArray);
    //             setIsLoading(false);
    //         }
    //       } catch (error) {
    //         setError(error.message);
    //         setIsLoading(false);
    //       }
    //     };
    
    //     fetchCampaignTemplates();
    // }, []);

    
    // useEffect(() => {
    //     // Fetch survey data based on `surveyId`
    //     // Example: fetch(`https://api.yourdomain.com/surveys/${surveyId}`).then(...)
    // }, [surveyId]);


    const handleSubmit = (responses) => {
        // Send survey responses to your backend
        // Example: fetch(`https://api.yourdomain.com/surveys/${surveyId}/responses`, { method: 'POST', body: JSON.stringify(responses) })
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <div className='flex-col justify-center h-auto'>
            <div className='flex flex-col gap-4 items-start p-4 h-auto'>
                <div className='flex flex-col gap-4 items-start p-4 w-full h-auto border-[1px] border-gray-200 rounded-lg shadow-lg shadow-gray-300'>
                    <h2
                        className='font-bold text-2xl'
                    >
                        Survey Questions
                    </h2>

                </div>
            </div>
        </div>
    );
};

export default SurveyPage;
