import { Link } from 'react-router-dom';
import { FOOTER_CONTACT_INFO, FOOTER_LINKS, SOCIALS } from '../constants';



const Footer = () => {
    return (
        <footer className="w-full flexCenter mb-24">
            <div className="padding-container max-container flex w-full flex-col gap-14">
                <div className="flex flex-col items-start justify-center gap-[10%] md:flex-row">
                    <Link to="/" className="mb-10">
                        {/* <img src="../../assets/hilink-logo.svg" alt="logo" width={74} height={29}/> */}
                        <h2 className='text-black bold-18'>MountX CRM</h2>
                    </Link>

                    <div className='flex flex-wrap gap-10 sm:justify-between md:flex-1'>
                        {FOOTER_LINKS.map((columns) => (
                            <div className="flex flex-col gap-5">
                            <h4 className="text-black bold-18 whitespace-nowrap">{columns.title}</h4>
                                <ul className="regular-14 flex flex-col gap-4 text-gray-500">
                                {columns.links.map((item) => (
                                    <Link to={item.href} alt={item.label}>
                                        {item.label}
                                    </Link>
                                ))}
                                </ul>
                            </div>
                        ))}

                        <div className="flex flex-col gap-5">
                            <div className="flex flex-col gap-5">
                            <h4 className="text-black bold-18 whitespace-nowrap">{FOOTER_CONTACT_INFO.title}</h4>
                                {FOOTER_CONTACT_INFO.links.map((link) => (
                                <Link
                                    to="/"
                                    alt={link.label}
                                    className="flex gap-4 md:flex-col lg:flex-row"
                                >
                                    <p className="text-black whitespace-nowrap">
                                        {link.label}:
                                    </p>
                                    <p className="medium-14 whitespace-nowrap text-blue-700">
                                        {link.value}
                                    </p>
                                </Link>
                                ))}
                            </div>
                        </div>

                        {/* <div className="flex flex-col gap-5">
                            <div className="flex flex-col gap-5">
                            <h4 className="text-black bold-18 whitespace-nowrap">{SOCIALS.title}</h4>
                                <ul className="regular-14 flex gap-4 text-gray-500">
                                {SOCIALS.links.map((link) => (
                                    <Link to="/" alt={link}>
                                        <img src={link} alt="logo" width={24} height={24} />
                                    </Link>
                                ))}
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="border bg-gray-20" />
                <p className="regular-14 w-full text-center text-gray-500">2023 MountX CRM | All rights reserved</p>
            </div>
        </footer>
    )
}


export default Footer;