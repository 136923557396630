import { Box, Button, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastNotify } from "../../components/ToastNotify";
import ModeEditIcon from '@mui/icons-material/ModeEdit';



const Team = () => {

  const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [user, setUser ] = useState(null);
  const [usersData, setUsersData ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const checkUser = localStorage.getItem('user');
  const fetchedUserString = JSON.stringify(checkUser); 
  const fetchedUserObject = JSON.parse(fetchedUserString);
  const fetchedUserinner = JSON.parse(fetchedUserObject);
  const original_user_id = fetchedUserinner.user_id // using this variable so that the user can't delete themselves
  

  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);



  useEffect(() => {
        const fetchUsers = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const userEmail = fetchedUserinner.user_email  // using this variable to filter on datastore query
          const user_id = fetchedUserinner.user_id // using this variable so that the user can't delete themselves
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "users", 
                        "filters": {
                          "filter1": {"filter_field": "user_kind_id", 
                                "filter_op": "=",
                                "filter_value": kind_id},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                    id: 1,
                    user_owner_name: loginUserName,
                    user_id: user_id,
                    user_type: user_type,
                    user_type2: user_type,
                    user_email: userEmail
                        }]
                setUsersData(jsonArray);
                setIsLoading(false);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
                user_type2: item.user_type
                }));
        
                setUsersData(jsonArray);

                setIsLoading(false);
            }
          } catch (error) {
            setError(error);
            setIsLoading(false);
          }
        };
    
        fetchUsers();
  }, []);



  const handleInputChange = (index, field, value) => {

    const checkUser = localStorage.getItem('user');

    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

    // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
    const indexMinusOne = index - 1;

    const usersObject = usersData[indexMinusOne];
    const key_id__ = usersObject.key_id;

    usersData[indexMinusOne][field] = value;

    const { id, user_type2, ...arrayWithoutSpecificKeys } = usersData[indexMinusOne];  // removing the id and user_type2 from the array to only use ...arrayWithoutSpecificKeys


    const json_data = {"kind_id": "users", 
                        "key_id": key_id__, 
                        "data": arrayWithoutSpecificKeys
                    };

    const url = process.env.REACT_APP_API_URL + '/api/v1/update'

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(json_data),
        headers: headers
        })
        .then(response => response.json())
        .then(result => {
            // Handle the API response or perform any necessary actions
            // console.log('Changes Saved: ')
            // console.log(result);
            ToastNotify('success', 'Changes Saved');
            window.location.reload();
        })
        .catch(error => {
            // Handle errors that occurred during the API call
            ToastNotify('error', 'Error, something went wrong');
            console.error(error);
        });
  };


  const handleDelete = (user_id) => {
    // console.log(values);

    try {

        const key_id = "User" + user_id;
    
        const Deleteurl = process.env.REACT_APP_API_URL + '/api/v1/delete';
    
        const json_data = {"kind_id": "users", 
                            "key_id": key_id
                          };
    
        fetch(Deleteurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('New Customer has been created');
                // console.log(result);
                ToastNotify("success", "User Deleted");
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify("error", "Something went wrong");
            });
      
    } catch (error) {
      ToastNotify("error", "Something went wrong.");
      console.log(error);
    }
    
};




  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "user_owner_name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "user_email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "user_type",
      headerName: "Modify User",
      flex: 1,
      editable: true,
      renderCell: (params) => (
          <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <span style={{width: '75px'}}>{params.value}</span>
          <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px" }} />
          </div>
      ),
      renderEditCell: (params) => {
          return (
              <Select
                  value={params.value}
                  onChange={(event) =>
                  handleInputChange(
                      params.id,
                      params.field,
                      event.target.value
                  )
                  }
                  style={{ height: "30px", width: "150px", fontSize: "12px" }}
              >
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"manager"}>Manager</MenuItem>
                  <MenuItem value={"team user"}>Team User</MenuItem>
                  <MenuItem value={"individual user"}>Individual User</MenuItem>
              </Select>
          );
      },
      renderHeader: () => (
          <Box display="flex" alignItems="center">
              <span>Modify User</span>
              {/* <ModeEditIcon style={{ marginLeft: "8px" }} /> */}
          </Box>
      ),
    },
    {
      field: "user_type2",
      headerName: "User Indicator",
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row: { user_type2 } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              user_type2 === "admin"
                ? colors.greenAccent[600]
                : user_type2 === "manager"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {user_type2 === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {user_type2 === "manager" && <SecurityOutlinedIcon />}
            {user_type2 === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {user_type2}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Delete User",
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row: { user_id } }) => {
        return (
          (user_id === original_user_id) ?
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
            >
              <p className="text-xs text-gray-500">Original Admin, can't Delete.</p>
            </Box>
          : (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
          >
            <Button
              color="error"
              variant="contained"
              size="small"
              sx={{ fontSize: "10px" }}
              onClick={(event) => handleDelete(user_id)}
            >
              Delete User
            </Button>
          </Box>
          )
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header 
        title="Team Members" 
        subtitle="Manage" 
      />
      <Button
          sx={{margin: "1rem 0 0.1rem 0", width: 200, height: 40,}} 
          color='secondary'
          variant='contained'
          onClick={(event) => {navigate('/addteam')}}
      >
          Add Team Member
      </Button>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          width: { xs: "1000px", md: "100%"},
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
          // checkboxSelection 
          rows={usersData} 
          columns={columns} 
        />
      </Box>
    </Box>
  );
};

export default Team;
