import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import "react-pro-sidebar/dist/css/styles.css";
import { tokens, useCustomSidebarHeight } from "../../theme";
// import { useThemeMediaQuery } from '@mui/material';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';




const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: '#e0e0e0', // original: colors.grey[100], new: '#b3b9c6', new: #e0e0e0
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SubMenuItem = ({ title, to, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: '#e0e0e0', // original: colors.grey[100], new: '#b3b9c6', new: #e0e0e0
        paddingTop: "15px",
      }}
      onClick={() => setSelected(title)}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};


const Sidebar = () => {

  // custom function to use sidebar height coming from useCustomSidebarHeight hook in theme.js
  const { sidebarHeight } = useCustomSidebarHeight();
  
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const [isOpen, setIsOpen] = useState(isLargeScreen);  // Tracks if the sidebar is open or closed

  //const [sidebarWidth, setSidebarWidth] = useState('250px');  // Width of the sidebar when it is open

  const location = useLocation();

  // if there is no localStorage.getItem('user') then do nothing
  function get_kind_id() {
    if (localStorage.getItem('user')) {
      const checkUser = localStorage.getItem('user');
      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id 
      return kind_id;
    }
    return 'client1234567890';
  }

  const kind_id = get_kind_id();

  


  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth >= theme.breakpoints.values.md) {
        setIsOpen(true);
      }   
      else {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [theme]);

  // NOT USING ANYMORE since the dynamic location path doesn't work
  // List of route paths where the Sidebar should not appear
  // const excludedPaths = ['/','/login','/register','/contact/sales','/contact/support','/features','/FAQ','/pricing','/unsubscribe',];

  // Check if the current path is in the excludedPaths list
  // let shouldShowSidebar = !excludedPaths.includes(location.pathname);

  // USING THIS INSTEAD
  let shouldShowSidebar = true;

  switch (shouldShowSidebar) {
    case location.pathname.includes('unsubscribe'):
      shouldShowSidebar = false;
      break;
    case location.pathname === '/':
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('login'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('register'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('contact/sales'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('contact/support'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('features'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('FAQ'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('FAQ/privacypolicy'):
      shouldShowSidebar = false;
      break;
    case location.pathname.includes('oauth2/callback'):
        shouldShowSidebar = false;
        break;
    case location.pathname.includes('pricing'):
      shouldShowSidebar = false;
      break;
    default:
      shouldShowSidebar = true;
  }

  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const [isOpenSales, setIsOpenSales] = useState(false);

  const handleNotificationMouseEnterAccount = () => {
    setIsOpenAccount(true);
  };

  const handleNotificationMouseLeaveAccount = () => {
      setIsOpenAccount(false);
  };

  const handleNotificationMouseEnterSales = () => {
    setIsOpenSales(true);
  };

  const handleNotificationMouseLeaveSales = () => {
    setIsOpenSales(false);
  };

  


  return (
    <div 
      style={{display: "flex",
              height: sidebarHeight,
              // backgroundColor: colors.primary[400],
            }}
    >
    {shouldShowSidebar && (
    <>
    {!isOpen && (
      <Box onClick={() => setIsOpen(!isOpen)} sx={{ mt: '0.5rem', position: 'absolute'}}>
        <IconButton>
          <MenuIcon sx={{ fontSize: '35px', }}/>
        </IconButton>
      </Box>
    )}
    
    <Box
      sx={{
        width: isOpen ? '250px' : { xs: '0px', md: '0px' },
          overflow: 'hidden',
          transition: 'width 0.3s ease',
          height: sidebarHeight,
          '@media (max-width: 600px)': {
            // position: 'fixed',
            height:  sidebarHeight, // '100vh',
            zIndex: 1300,
            // width: isOpen ? '250px' : '0',
            width: isOpen ? '250px' : { xs: '0px', md: '0px' }, // 25px when closed on small screens
          },
        "& .pro-sidebar": {
          width: isOpen ? '250px' : { xs: '0px', md: '0px' },
          height: sidebarHeight,
          // overflowY: "auto", // Allows scrolling if content exceeds viewport height
        },
        "& .pro-sidebar-inner": {
          width: isOpen ? '250px' : { xs: '0px', md: '0px' },
          height: sidebarHeight,
          backgroundColor: '#121621',  // original: `${colors.primary[400]} !important`,  new: '#1C2536 !important' // `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          width: isOpen ? '250px' : { xs: '0px', md: '0px' },
          padding: "5px 10px 5px 10px !important",
          // backgroundColor: `${colors.primary[400]} !important`,
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={!isOpen} >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem onClick={() => setIsOpen(false)}>
              <IconButton>
                <CloseIcon sx={{ color:"#b3b9c6" }}/> 
              </IconButton>
            {isOpen && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="1rem"
                ml="15px"
              >
                {/* original: {colors.grey[100]} */}
                <Typography variant="h3" color="#e0e0e0">  
                  MountX CRM
                </Typography>
                {/* <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton> */}
              </Box>
            )}
          </MenuItem>

          {isOpen && (
          <Box paddingLeft={isOpen ? undefined : "0%"} >
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color= "#b3b9c6" // original: {colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Accounts"
              to="/accounts"
              icon={<AccountBalanceWalletOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Box sx={{ position: 'relative', '&:hover .submenuAccount': { maxHeight: '150px' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', p: 2 }}>
                <AccountBalanceWalletOutlinedIcon />
                <Box sx={{ ml: 2, color: "#e0e0e0" }} ><Link to="/accounts"/>Accounts</Box>
              </Box>
              <Box
                className="submenuAccount"
                sx={{
                  left: 0,
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  maxHeight: 0,
                  transition: 'all 0.5s ease',
                  '& a': {
                    display: 'block',
                    padding: 1,
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                }}
              >
                <div className="ml-16 flex flex-col w-full h-auto">
                  <div className="flex flex-row justify-center items-center w-full h-auto">
                    <StoreOutlinedIcon sx={{ pb: 2, fontSize: "35px" }}/>
                    <SubMenuItem
                      title="Retail"
                      to="/accounts/retail"
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                  <div className="flex flex-row justify-center items-center w-full h-auto">
                    <AccountTreeOutlinedIcon sx={{ pb: 2, fontSize: "35px" }}/>
                    <SubMenuItem
                      title="Distributor"
                      to="/accounts/distributor"
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                </div>

              </Box>
            </Box> */}
            {
              kind_id === 'client1717414405544' || kind_id === 'client1703727947492' ? null :
              (
                <>
                <Item
                  title="Contacts"
                  to="/contacts"
                  icon={<GroupOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                  <Item
                  title="Leads"
                  to="/leads"
                  icon={<InputOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Opportunities"
                  to="/opportunities"
                  icon={<PaymentsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Customer Lifecycle"
                  to="/lifecycle"
                  icon={<TimelineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                </>
              )
            }
            <Box sx={{ position: 'relative', '&:hover .submenu': { maxHeight: '150px' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', p: 2 }}>
                <MonetizationOnOutlinedIcon />
                <Box sx={{ ml: 2, color: "#e0e0e0" }} ><Link to="/sales"/>Sales</Box>
              </Box>
              <Box
                className="submenu"
                sx={{
                  left: 0,
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  maxHeight: 0,
                  transition: 'all 0.5s ease',
                  '& a': {
                    display: 'block',
                    padding: 1,
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                }}
              >
                <div className="ml-16 flex flex-col w-full h-auto">
                  <div className="flex flex-row justify-center items-center w-full h-auto">
                    <ReceiptOutlinedIcon sx={{ pb: 2, fontSize: "35px" }}/>
                    <SubMenuItem
                      title="New"
                      to="/sales/add"
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                  <div className="flex flex-row justify-center items-center w-full h-auto">
                    <ManageSearchOutlinedIcon sx={{ pb: 2, fontSize: "35px" }}/>
                    <SubMenuItem
                      title="Search"
                      to="/sales/search"
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                </div>

              </Box>
            </Box>
            <Item
              title="Healthscores"
              to="/healthscores"
              icon={<HealthAndSafetyIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Activities"
              to="/activities"
              icon={<PendingActionsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Events"
              to="/events"
              icon={<EventOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="History logs"
              to="/logs"
              icon={<PublishedWithChangesOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="NPS"
              to="/nps"
              icon={<AddReactionOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Contacts"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Typography
              variant="h6"
              color= "#b3b9c6" // original: {colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Campaigns
            </Typography>
            <Item
              title="Templates"
              to="/campaigns/templates"
              icon={<SubjectOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="One Time Email"
              to="/sendemail"
              icon={<MailOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Schedule Recurring"
              to="/campaigns"
              icon={<ScheduleSendOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Delete Recurring"
              to="/campaigns/manage"
              icon={<ManageHistoryOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Subscriptions"
              to="/campaigns/subscriptions"
              icon={<ContactMailOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Surveys
            </Typography> */}
            <Item
              title="Surveys"
              to="/surveys"
              icon={<QuizOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Create Surveys"
              to="/surveys/create"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              NPS
            </Typography>
            <Item
              title="Create NPS"
              to="/nps/create"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="NPS"
              to="/nps/templates"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Typography
              variant="h6"
              color= "#b3b9c6"  // {colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Bar Charts
            </Typography>
            <Item
              title="Activities"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Revenue"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Healthscores"
              to="/line"
              icon={<SsidChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Custom Bar Chart"
              to="/barchart/custom"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Location of Customers"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="NPS Data"
              to="/npsdata"
              icon={<PendingActionsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contracts"
              to="/contracts"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Typography
              variant="h6"
              color= "#b3b9c6"  // {colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Settings
            </Typography>
            <Item
              title="Manage"
              to="/manage"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
    </>
    )}
    </div>
  );
};

export default Sidebar;



// {!isOpen && (
//   <Box mb="25px" height="auto">
//     <Box display="flex" justifyContent="center" alignItems="center">
//       {/* {userImage === 'none' ? 
//         (
//           <img
//             alt="profile-user"
//             width="100px"
//             height="100px"
//             src="../../assets/person-1.png"
//             style={{ cursor: "pointer", borderRadius: "50%" }}
//           />
//         )
//         :
//         (
//           <img
//             alt="profile-user"
//             width="100px"
//             height="100px"
//             src={userImage}
//             style={{ cursor: "pointer", borderRadius: "50%" }}
//           />
//         )
//       }  */}
      
//     </Box>
//     <Box textAlign="center">
//       <Typography
//         variant="h2"
//         color={colors.grey[100]}
//         fontWeight="bold"
//         sx={{ m: "10px 0 0 0" }}
//       >
//         {/* {loginUserName} */}
//       </Typography>
//       <Typography variant="h5" color={colors.greenAccent[500]}>
//         {/* {userType} */}
//       </Typography>
//     </Box>
//   </Box>
// )}