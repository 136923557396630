import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";



const DeleteFieldsMenu = () => {


    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);



    return (
        <Box m="30px" width="90%">
            <Header title="Delete Fields" subtitle="Choose Object" />
            <Box
                className='flex flex-col w-full md:w-1/3 justify-items-start mt-16 border-[1px] border-gray-500 rounded-md p-8'
            >
                <div className='justify-center flex flex-col p-4 gap-8'>
                    <h2 className='font-bold text-xl'>Lead Object</h2>
                    <div className='mt-2.5 w-full'>
                        <Button
                            sx={{ m: "15px 0 5px 20px", fontSize: "1rem", width: "100%", height: "50px", border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }}
                            color='secondary'
                            variant='contained'
                            onClick={(event) => navigate('/lead/deletefield')}
                        >
                            Delete Lead Fields
                        </Button>
                    </div>
                    <h2 className='font-bold text-xl'>Opportunity Object</h2>
                    <div className='mt-2.5 w-full'>
                        <Button
                            sx={{ m: "15px 0 5px 20px", fontSize: "1rem", width: "100%", height: "50px", border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }} 
                            color='secondary'
                            variant='contained'
                            onClick={(event) => navigate('/opportunity/deletefield')}
                        >
                            Delete Opportunity Fields
                        </Button>
                    </div>
                    <h2 className='font-bold text-xl'>Account Object</h2>
                    <div className='mt-2.5 w-full'>
                        <Button
                            sx={{ m: "15px 0 5px 20px", fontSize: "1rem", width: "100%", height: "50px", border: '1px solid black', borderRadius: '5px', padding: '5px', textAlign: 'center', alignItems: 'center', fontWeight: "bold" }} 
                            color='secondary'
                            variant='contained'
                            onClick={(event) => navigate('/customer/deletefield')}
                        >
                            Delete Account Fields
                        </Button>
                    </div>
                </div>
            </Box>
        </Box> 
    );
}

export default DeleteFieldsMenu;

