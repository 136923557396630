import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from '../../components/ToastNotify';



const LifecycleManagement = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

     // default stages used for customStages state, value increments of 5, must be max of 100 - can't go over 100
     const marks = [
        {
            value: 0,
            label: 'New',
        },
        {
            value: 5,
            label: 'Onboarding',
        },
        {
            value: 10,
            label: 'Use',
        },
        {
            value: 15,
            label: 'Engage',
        },
        {
            value: 20,
            label: 'Optimize',
        },
        {
            value: 25,
            label: 'Renew',
        },
    ];

    const [customStages, setCustomStages] = useState(marks);


    const [HSPropertyData, setHSPropertyData] = useState([]);
    const [keyDict, setKeyDict] = useState([]);  // this is a key lookup array of all the hs_property_keyids corresponding to the index when they were retrieved from the database
    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectField, setSelectField] = useState('');




    const handleStageLabelChange = (index, label) => {
        const updatedStages = [...customStages];
        updatedStages[index].label = label;
        setCustomStages(updatedStages);
    };
    
    const handleAddStage = () => {

        // if customStages length is 7 or more, return
        if (customStages.length >= 7) {
            ToastNotify('error', 'You can only have a maximum of 7 stages. Please remove a stage to add a new one.');
            return;
        }

        const newStage = {
            value: customStages.length * 5, // Assumes each stage is 5 units apart
            label: `Stage ${customStages.length + 1}`,
          };

        setCustomStages([...customStages, newStage]);
    };
    
    const handleRemoveStage = (index) => {
        const updatedStages = customStages.filter((_, i) => i !== index);
        setCustomStages(updatedStages);
    };

    const handleSaveStages = (stages) => {
        // console.log('this is stages inside handleSaveStages')
        // console.log(stages)
        const checkUser = localStorage.getItem('user');
    
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const stages_object = {}
        stages_object['kind_id'] = kind_id;
        stages_object['object_type'] = 'lifecycle_stages'
        stages_object['stages'] = stages;

        const json_data = {"kind_id": kind_id,
                            "key_id": "lifecyclestages",
                            "data": stages_object};

        const url = process.env.REACT_APP_API_URL + '/api/v1/create'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Stages have been updated: ')
                // console.log(result);
                ToastNotify('success', 'Stages saved');
                // window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Error, something went wrong');
                console.error(error);
            });
    }

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchLifecycleStages = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "lifecycle_stages"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{

                        }]
                setIsLoading(false);
                return null;
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));
        
                const stagesArray = jsonArray["0"].stages

                // console.log('this is stagesArray: ')
                // console.log(stagesArray)

                setCustomStages(stagesArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchLifecycleStages();
      }, []);





    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    return (
        <Box m="30px" width="90%">
            <Header title="Lifecycle Stages Management" subtitle="Add, Edit or Delete Stages" />

            <div className='flex-col'>
                {customStages.map((stage, index) => (
                    <div key={index}>
                        {
                            stage.label == 'New' ? (
                                <input
                                    disabled
                                    className='border-[1px] border-gray-400 rounded-md p-2 m-2 text-black'
                                    value={stage.label}
                                />
                            ) : (
                                <input
                                    className='border-[1px] border-gray-400 rounded-md p-2 m-2 text-black'
                                    type="text"
                                    value={stage.label}
                                    onChange={(e) => handleStageLabelChange(index, e.target.value)}
                                />
                            )
                        }
                        {/* <input
                            className='border-[1px] border-gray-400 rounded-md p-2 m-2 text-black'
                            type="text"
                            value={stage.label}
                            onChange={(e) => handleStageLabelChange(index, e.target.value)}
                        /> */}
                        {
                            stage.label == 'New' ? null : (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => handleRemoveStage(index)}
                                >
                                    Remove
                                </Button>
                            )
                        }
                    </div>
                ))}
                <Button
                    variant='contained'
                    sx={{ marginTop: "1rem", marginLeft: "1rem", width: 200, border: `1px solid ${colors.greenAccent[200]}`}}
                    onClick={handleAddStage}
                >
                    Add Stage
                </Button>
                {/* <div className='mt-8'> */}
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{ marginTop: "1rem", marginLeft: "1rem", width: 200,}}
                        onClick={(event) => {handleSaveStages(customStages)}}
                    >
                        Save Stages
                    </Button>
                {/* </div> */}
                
                {/* Use customStages in your component */}
                {/* <ul>
                    {customStages.map((stage, index) => (
                        <li key={index}>{`${stage.label} - ${stage.value}`}</li>
                    ))}
                </ul> */}
            </div>

            {/* <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 5" },
                                }}
                            >
                                {HSPropertyData.map((item, index) => (
                                    <>
                                        {Object.entries(item).map(([fieldName, fieldValue]) => (  // [fieldName, fieldValue] is the key, value pairs of the JSON object 'item'
                                            fieldName.includes('hs_property_keyid') || fieldName.includes('hs_name') ? null :
                                                (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        variant='filled'
                                                        type="text"
                                                        // remove the last character in fieldName
                                                        label={(fieldName.charAt(3).toUpperCase() + fieldName.slice(4).replace(/_/g, ' ')).slice(0).slice(0, -1) + ' ' + (parseInt(fieldName.slice(-1)) + 1).toString()}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 1" }}
                                                    />
                                                </div>
                                                )
                                        ))}
                                    </>
                                ))}
                            </Box>
                            <Box 
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                            >
                                <Button 
                                    type="button"
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => {navigate('/healthscores/manage/addproperty')}}
                                >
                                    Create New Property
                                </Button>
                                <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => handleFormSubmit(values)}
                                >
                                    Save Edit
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>

            <h3 className='font-bold text-2xl mt-16'>Delete a Healthscore Property</h3>
            <div
                className="w-[40%] h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "50%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Existing Properties</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {   HSPropertyData.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('hs_property_name')) {
                                return (
                                    <MenuItem value={value}>{value}</MenuItem>
                                )
                                } else {
                                    return null;
                                }
                        })
                        ))}
                    </Select>
                    <FormHelperText>Delete a Property from one of the existing Healthscore Properties</FormHelperText>
                </FormControl>
            </div>
            <div>
                <Button
                    sx={{marginTop: "1rem"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitDeleteProperty(event, selectField)}
                >
                    Delete Property
                </Button>
            </div> */}
        </Box>
    );
}

export default LifecycleManagement;

