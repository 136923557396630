import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToastNotify } from '../../components/ToastNotify';



// regex to check if the user type is either admin, manager or user (case insensitive)
const userTypes = /^(Admin|Manager|User)$/i;



const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    user_password: yup.string().min(8, "Password must be at least 8 characters"),
    // userType: yup
    //     .string()
    //     .matches(userTypes, "User Type is not valid, enter Admin, Manager or User")
    //     .required("required"),
});


const initialValues = {
    fullName: "",
    email: "",
    user_password: "",
};



const NewTeamPage = () => {
  
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectField, setSelectField] = useState('user');



    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    
    const handleFormSubmit = (values) => {
      // console.log(values);

      const checkUser = localStorage.getItem('user');
    
      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const companyName = fetchedUserinner.user_company_name  // using this variable to get company name if any
      const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

      var timestamp = new Date().getTime();
      let user_id = timestamp.toString()
      let key_id = 'User' + user_id

      const user_type = selectField.toLowerCase();


      const newMemberData = {
          "user_kind_id": kind_id,
          "user_company_name": companyName,
          "user_id": user_id,
          "user_owner_name": values.fullName,
          "user_email": values.email,
          "user_type": user_type,
          "user_password": values.user_password,
          "user_login_type": "standard",
          "user_image": "none",
      };

      const json_data = {"kind_id": "users", 
                          "key_id": key_id, 
                          "data": newMemberData};

      const createUrl = process.env.REACT_APP_API_URL + '/api/v1/create'


      fetch(createUrl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(loginresponse => loginresponse.json())
          .then(result => {
              // navigate('/dashboard', { replace: true})
              // console.log('New Member Added')
              // console.log(result);
              ToastNotify('success', 'New Member Added');
          })
          .catch(error => {
              console.error(error);
              ToastNotify('error', 'Error, something went wrong');
          });
    };


    const handleSelectionChange = (event) => {
      setSelectField(event.target.value);
    };



    return (
      <Box m="30px" width="50%">
        <Header title="Add New Team Member" subtitle="Create a New Member Profile" />

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  name="fullName"
                  error={!!touched.fullName && !!errors.fullName}
                  helperText={touched.fullName && errors.fullName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Initial Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.user_password}
                  name="user_password"
                  error={!!touched.user_password && !!errors.user_password}
                  helperText={touched.user_password && errors.user_password}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl sx={{ gridColumn: "span 4" }}>
                    <InputLabel id="demo-simple-select-helper-label">User Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select User Type"
                        onChange={handleSelectionChange}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="manager">Manager</MenuItem>
                      <MenuItem value="team user">Team User</MenuItem>
                      <MenuItem value="individual user">Individual User</MenuItem>
                    </Select>
                    <FormHelperText>Team User, Individual User, Manager, or another Admin?</FormHelperText>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="start" mt="20px">
                <Button 
                  type="submit" 
                  color="secondary" 
                  variant="contained"
                >
                  Add New Member
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
};



export default NewTeamPage;
