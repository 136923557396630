import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { saveLog } from "../../utils/logChanges";


const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    customerName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    // product_of_interest: yup.string(),
    // startDate: yup.string(),
    // email: yup.string().email("invalid email"),
    // contact: yup
    //     .string()
    //     .matches(phoneRegExp, "Phone number is not valid"),
    // address1: yup.string(),
    accountOwner: yup.string()
});


const initialValues = {
    customerName: "",
    // product_of_interest: "",
    // startDate: "",
    // email: "",
    // contact: "",
    // address1: "",
    accountOwner: "",
};


const NewCustomerPage = () => {

  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;

  // Encode username and password for Basic Auth
  const encodedCredentials = window.btoa(`${username}:${password}`);

  // Setup headers
  const headers = new Headers({
  'Authorization': `Basic ${encodedCredentials}`,
  'Content-Type': 'application/json'
  });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [selectFieldOwnerName, setSelectFieldOwnerName] = useState('');  // need this to set the owner_name field in the form
  const [selectFieldCategory, setSelectFieldCategory] = useState('');
  const [selectFieldBusiness, setSelectFieldBusiness] = useState('');  
  const [selectFieldProduct, setSelectFieldProduct] = useState('');
  
  const [custFields, setCustFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkUser = localStorage.getItem('user');
  const fetchedUserString = JSON.stringify(checkUser); 
  const fetchedUserObject = JSON.parse(fetchedUserString);
  const fetchedUserinner = JSON.parse(fetchedUserObject);
  const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
  const user_type = fetchedUserinner.user_type  // using this variable to get user_type
  const kind_id = fetchedUserinner.user_kind_id


  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);


  // fetching users
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": "users", 
                    "filters": {
                      "filter1": {"filter_field": "user_kind_id", 
                            "filter_op": "=",
                            "filter_value": kind_id},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);

        if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{

                    }]
            setUsersData(jsonArray);
            setIsLoading(false);
        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
            id: index + 1,
            ...item,
            }));
    
            setUsersData(jsonArray);
            setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

      fetchUsers();
  }, []);


  useEffect(() => {
    const fetchCustDataforColumns = async () => {
      
      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);
        if (jsonObject.retrieved_data === 'No result is returned') {
            // const jsonArray = [{ }]
            // setCustFields(jsonArray);

        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
            }));
    
            // get the keys or fields from the first object in the array
            const keys = Object.keys(jsonArray[0]);
            // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
            const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                            && item !== 'object_type' && item !== 'customer_name' && item !== 'id');
            setCustFields(filteredKeys);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustDataforColumns();
  }, []);


  

  const handleFormSubmit = (values) => {
    // console.log(values);

    try {
      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      const NewCustomerData = {}

      // add all the fields from custFields array to NewCustomerData since we need to add all the already created fields for existing customers with empty values
      custFields.map((item) => {
        NewCustomerData[item] = "";
      });

      // add kind_id to NewCustomerData
      NewCustomerData["kind_id"] = kind_id;
      // add customerName to customer_name
      // ensure there are no leading and trailing spaces
      NewCustomerData["customer_name"] = values.customerName.trim();
      // required customer_id field, it is kind_id + '-' + unixTimestamp
      // generate a unix timestamp to the millisecond and convert it to a string
      const unixTimestamp = new Date().getTime().toString();
      NewCustomerData["customer_id"] = kind_id + '-' + unixTimestamp;
      // required object_type field
      NewCustomerData["object_type"] = "customer";
      // required category field
      NewCustomerData["category"] = "";
      // required status field
      NewCustomerData["status"] = "";
      // required lifecycle_stage field
      NewCustomerData["lifecycle_stage"] = "New";
      // required healthscore field
      NewCustomerData["healthscore"] = "100";
      // required healthscore_reasons field?
      NewCustomerData["healthscore_reasons"] = [];
      // required amount field for Pie Chart
      NewCustomerData["amount"] = "";
      // required created_date field
      const today = new Date().toISOString().slice(0, 10);
      NewCustomerData["created_date"] = today
      // required healthscore score_history field
      const hsArray = [{"x": today, "y": "100"}]
      // convert hsArray to string
      const hsArrayString = JSON.stringify(hsArray);
      NewCustomerData["score_history"] = hsArrayString
      // required notes field
      NewCustomerData["notes"] = "";

      // if values.accountOwner is empty, set it to loginUserName which is owner_name from above.  If it is not empty, then add it to customerData (changing accountOwner to owner_name as per the datastore schema)
      if (selectFieldOwnerName == "") {
        NewCustomerData["owner_name"] = loginUserName;
      } else {
        NewCustomerData["owner_name"] = selectFieldOwnerName;
      }

      if (kind_id === 'client1717414405544' || kind_id === 'client1703727947492') {
        NewCustomerData["category"] = selectFieldCategory;
        NewCustomerData["business"] = selectFieldBusiness;
        NewCustomerData["product_of_interest"] = selectFieldProduct; // values.product_of_interest.trim();
      }

      const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

      const json_data = {"kind_id": kind_id, 
                              "key_id": values.customerName, 
                              "data": NewCustomerData};

      fetch(Createurl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(response => response.json())
          .then(result => {
              // Handle the API response or perform any necessary actions
              // console.log('New Customer has been created');
              // console.log(result);
              const customerNameforLog = values.customerName.trim();
              saveLog(new Date().toLocaleString(), 'Account', customerNameforLog, customerNameforLog, '', `Created New Account: ${customerNameforLog}`)
              ToastNotify("success", "New Account has been created");
          })
          .catch(error => {
              // Handle errors that occurred during the API call
              console.error(error);
              ToastNotify("error", "Something went wrong");
          });
      
    } catch (error) {
      ToastNotify("error", "Something went wrong.");
      console.log(error);
    }
    
  };


  const handleChangeOwner = (event) => {
    const owner = event.target.value;
    setSelectFieldOwnerName(owner);
  };

  const handleChangeCategory = (event) => {
    const category = event.target.value;
    setSelectFieldCategory(category);
  };

  const handleChangeBusiness = (event) => {
    const business = event.target.value;
    setSelectFieldBusiness(business);
  };

  const handleChangeProduct = (event) => {
    const product = event.target.value;
    setSelectFieldProduct(product);
  };


  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  return (
    <Box m="30px" width="90%">
      <Header title="New Account" subtitle="Create a New Account Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: {xs:"100%", md:"50%"},
                display: "grid",
                gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                gap: "30px"
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Account Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.customerName}
                name="customerName"
                error={!!touched.customerName && !!errors.customerName}
                helperText={touched.customerName && errors.customerName}
                sx={{ gridColumn: "span 4" }}
              />
              { 
                kind_id === 'client1717414405544' || kind_id === 'client1703727947492' ? 
                (
                  <>
                    <FormControl sx={{ gridColumn: "span 4"}}>
                      <InputLabel id={`select-category-label-input`}>Category</InputLabel>
                      <Select
                          labelId={`select-category-label-selectid`}
                          id={`select-category-select-id`}
                          value={selectFieldCategory}
                          label="Select Category"
                          onChange={(event) => handleChangeCategory(event)}
                      >
                        <MenuItem key="Retail" value="Retail">Retail</MenuItem>
                        <MenuItem key="Ecommerce" value="Ecommerce">Ecommerce</MenuItem>
                        <MenuItem key="Distributor" value="Distributor">Distributor</MenuItem>
                        <MenuItem key="Government" value="Government">Government</MenuItem>
                      </Select>
                      <FormHelperText>Select Category</FormHelperText>
                    </FormControl>
                    <FormControl sx={{ gridColumn: "span 4"}}>
                      <InputLabel id={`select-business-label-input`}>Business</InputLabel>
                      <Select
                          labelId={`select-business-label-selectid`}
                          id={`select-business-select-id`}
                          value={selectFieldBusiness}
                          label="Select Business"
                          onChange={(event) => handleChangeBusiness(event)}
                      >
                        <MenuItem key="B2G" value="B2G">B2G</MenuItem>
                        <MenuItem key="B2B" value="B2B">B2B</MenuItem>
                        <MenuItem key="B2B2C" value="B2B2C">B2B2C</MenuItem>
                        <MenuItem key="B2C" value="B2C">B2C</MenuItem>
                      </Select>
                      <FormHelperText>Select Business</FormHelperText>
                    </FormControl>
                    <FormControl sx={{ gridColumn: "span 4"}}>
                      <InputLabel id={`select-product-label-input`}>Product</InputLabel>
                      <Select
                          labelId={`select-product-label-selectid`}
                          id={`select-product-select-id`}
                          value={selectFieldProduct}
                          label="Select Product"
                          onChange={(event) => handleChangeProduct(event)}
                      >
                        <MenuItem key="QuickFinder™ COVID-19 / Flu Antigen Self Test" value="QuickFinder™ COVID-19 / Flu Antigen Self Test">QuickFinder™ COVID-19 / Flu Antigen Self Test</MenuItem>
                        <MenuItem key="OHC COVID-19 / Flu Antigen Test Pro" value="OHC COVID-19 / Flu Antigen Test Pro">OHC COVID-19 / Flu Antigen Test Pro</MenuItem>
                      </Select>
                      <FormHelperText>Select Product of Interest</FormHelperText>
                    </FormControl>
                    {/* <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Product of Interest"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product_of_interest}
                      name="product_of_interest"
                      error={!!touched.product_of_interest && !!errors.product_of_interest}
                      helperText={touched.product_of_interest && errors.product_of_interest}
                      sx={{ gridColumn: "span 4" }}
                    /> */}
                  </>
                ) :
                null
              }
              <div className="col-span-4">
                <p className="text-xs text-gray-500 ">
                  The account owner is the person who is responsible for the
                  account.  If you don't make a selection, the account owner will be set to you.
                </p>
              </div>
              <FormControl sx={{ gridColumn: "span 4"}}>
                <InputLabel id={`select-owner-label-input`}>Owner</InputLabel>
                <Select
                    labelId={`select-owner-label-selectid`}
                    id={`select-owner-select-id`}
                    value={selectFieldOwnerName}
                    label="Select Owner"
                    onChange={(event) => handleChangeOwner(event)}
                >
                    {usersData.map((item) => (
                            // const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                <MenuItem key={item.user_owner_name} value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>Select an Owner for this Account</FormHelperText>
            </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="start" mt="40px">
              <Button
                sx={{ width: "200px" }}
                color="secondary" 
                variant="contained"
                onClick={(event) => handleFormSubmit(values)}
              >
                Add New Account
              </Button>
              <div className="mt-8 w-[150px]">
                <p className="text-xs text-gray-500 ">
                  You may add account fields in the account profile page after the account has been created or in the Manage Settings.
                </p>
              </div>
            </Box>
          </form>
        )}
      </Formik>

      <div className="mt-24">
        <h2 className="text-md font-bold">Looking to mass add mutliple accounts by CSV file? Click below.</h2>
        <Button
          sx={{ width: "200px", marginTop: "2rem"}}
          color="secondary" 
          variant="contained"
          onClick={(event) => navigate('/addmultipleaccounts')}
        >
          Add Multiple Accounts
        </Button>
      </div>
    </Box>
  );
};



export default NewCustomerPage;
