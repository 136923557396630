import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import Slider from '@mui/material/Slider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ToastNotify } from '../../components/ToastNotify';
import CircularProgress from '@mui/material/CircularProgress';


const Lifecycle = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // default stages used for customStages state, if admin edits and saves new stages, it will be updated/loaded in the state
    // value increments of 5, must be max of 100 - can't go over 100
    const marks = [
        {
            value: 0,
            label: 'New',
        },
        {
            value: 5,
            label: 'Onboarding',
        },
        {
            value: 10,
            label: 'Use',
        },
        {
            value: 15,
            label: 'Engage',
        },
        {
            value: 20,
            label: 'Optimize',
        },
        {
            value: 25,
            label: 'Renew',
        },
    ];

    
    const [customStages, setCustomStages] = useState(marks);

    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [filterName, setFilterName] = useState('');


    const [HSPropertyData, setHSPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [ noCustomers, setNoCustomers ] = useState(false);


    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchLifecycleStages = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "lifecycle_stages"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);

            if (jsonObject.retrieved_data === 'No result is returned') {
                // do nothing
                return null
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    ...item,
                }));
        
                const stagesArray = jsonArray["0"].stages

                // console.log('this is stagesArray: ')
                // console.log(stagesArray)

                setCustomStages(stagesArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchLifecycleStages();
    }, []);
    




    // slider values when changed
    const [sliderValue, setSliderValue] = useState('');

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        // console.log('this is the new sliderValue: ' + newValue)
    };


    const saveSliderStageChange = (customerName) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // in the customers array, find the customer_name that matches the customer_name in the url
        const filteredCust = customers.filter(customer => customer.customer_name === customerName);

        // console.log('this is filteredCust: ')
        // console.log(filteredCust)

        filteredCust[0].lifecycle_stage = getTextStageValue(sliderValue, customStages);

        const json_data = {"kind_id": kind_id, 
                            "key_id": customerName, 
                            "data": filteredCust[0]};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                ToastNotify('success', 'Changes saved');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Error, Something went wrong');
                console.error(error);
            });
    };


    // pagination code to move on to next set of items from pressing Next Arrow Icon
    const [page, setPage] = useState(1);

    const itemsPerPage = 5; // items per page
    const totalPages = Math.ceil(customers.length / itemsPerPage);

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = customers.slice(startIndex, endIndex);


    

    // console.log('this is marks: ' + marks[1].value)
    


    
    function getStageValue(stage, customStages) {

        // only prints out if there's a 7th stage
        // console.log('this is customStages: ' + customStages[6].label)

        if (customStages[0] !== undefined) {
            if (stage === customStages[0].label) {
                return 0
            }
        }
        if (customStages[1] !== undefined) {
            if (stage === customStages[1].label) {
                return 5
            }
        }
        if (customStages[2] !== undefined) {
            if (stage === customStages[2].label) {
                return 10
            }
        }
        if (customStages[3] !== undefined) {
            if (stage === customStages[3].label) {
                return 15
            }
        }
        if (customStages[4] !== undefined) {
            if (stage === customStages[4].label) {
                return 20
            }
        }
        if (customStages[5] !== undefined) {
        if (stage === customStages[5].label) {
            return 25
        }
        }
        if (customStages[6] !== undefined) {
            if (stage === customStages[6].label) {
                return 30
            }
        }
        if (customStages[7] !== undefined) {
            if (stage === customStages[7].label) {
                return 35
            }
        }
        if (customStages[8] !== undefined) {
            if (stage === customStages[8].label) {
                return 40
            }
        }
        if (customStages[9] !== undefined) {
            if (stage === customStages[9].label) {
                return 45
            }
        }
        if (customStages[10] !== undefined) {
            if (stage === customStages[10].label) {
                return 50
            }
        }
        if (customStages[11] !== undefined) {
            if (stage === customStages[11].label) {
                return 55
            }
        }
        if (customStages[12] !== undefined) {
            if (stage === customStages[12].label) {
                return 60
            }
        }
        if (customStages[13] !== undefined) {
            if (stage === customStages[13].label) {
                return 65
            }
        }
        if (customStages[14] !== undefined) {
            if (stage === customStages[14].label) {
                return 70
            }
        }
        if (customStages[15] !== undefined) {
            if (stage === customStages[15].label) {
                return 75
            }
        }
        if (customStages[16] !== undefined) {
            if (stage === customStages[16].label) {
                return 80
            }
        }
        if (customStages[17] !== undefined) {
            if (stage === customStages[17].label) {
                return 85
            }
        }
        if (customStages[18] !== undefined) {
            if (stage === customStages[18].label) {
                return 90
            }
        }
        if (customStages[19] !== undefined) {
            if (stage === customStages[19].label) {
                return 95
            }
        }
    };


    function getTextStageValue(stage, customStages) {
        if (stage === 0) {
            return customStages[0].label
        }
        if (stage === 5) {
            return customStages[1].label
        }
        if (stage === 10) {
            return customStages[2].label
        }
        if (stage === 15) {
            return customStages[3].label
        }
        if (stage === 20) {
            return customStages[4].label
        }
        if (stage === 25) {
            return customStages[5].label
        }
        if (stage === 30) {
            return customStages[6].label
        }
        if (stage === 35) {
            return customStages[7].label
        }
        if (stage === 40) {
            return customStages[8].label
        }
        if (stage === 45) {
            return customStages[9].label
        }
        if (stage === 50) {
            return customStages[10].label
        }
        if (stage === 55) {
            return customStages[11].label
        }
        if (stage === 60) {
            return customStages[12].label
        }
        if (stage === 65) {
            return customStages[13].label
        }
        if (stage === 70) {
            return customStages[14].label
        }
        if (stage === 75) {
            return customStages[15].label
        }
        if (stage === 80) {
            return customStages[16].label
        }
        if (stage === 85) {
            return customStages[17].label
        }
        if (stage === 90) {
            return customStages[18].label
        }
        if (stage === 95) {
            return customStages[19].label
        }
    };
    

    function valuetext(value) {
        return `${value}`;
    };

    function valueLabelFormat(value) {
        return customStages.findIndex((mark) => mark.value === value) + 1;
    };


    // fetching customer data for lifecycle data
    useEffect(() => {
        const fetchCustData = async () => {
          setIsLoading(true);
          try {
            const UserjsonArray = [];

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


            if (user_type.includes('admin') || user_type.includes('manager') || user_type.includes('team user') ) {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                    "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                    } 
                    };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                // console.log('this is jsonObject:')  
                // console.log(jsonObject) // returns object {retrieved_data: "No result is returned"}
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setNoCustomers(true);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map(item => ({
                        ...item,
                    }));
                    setCustomers(jsonArray);
                    setIsLoading(false);
                }
            } else {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                    "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                    "filter2": {"filter_field": "owner_name", 
                            "filter_op": "=",
                            "filter_value": loginUserName},
                    } 
                    };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                // console.log('this is jsonObject:')  
                // console.log(jsonObject) // returns object {retrieved_data: "No result is returned"}
                if (jsonObject.retrieved_data === 'No result is returned') {
                    setNoCustomers(true);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map(item => ({
                        ...item,
                    }));
                    setCustomers(jsonArray);
                }
            }
            
            
          } catch (error) {
            setError(error.message);
          }
          // setIsLoading(false);
        };
    
        fetchCustData();
    }, []);



    




    const [isFilterButtonClicked, setFilterButtonClicked] = useState(false);


    const handleFilterChange = (event) => {
        setFilterName(event.target.value);
    };


    const submitFilter = (event) => {

        if (filterName === '') {
            ToastNotify('error', 'Please enter a customer name');
            return null
        } else {
            const filtered = customers.filter(
                (customer) =>
                  customer.customer_name.toLowerCase().includes(filterName.toLowerCase())
              );
              setFilteredCustomers(filtered);
    
              setFilterButtonClicked(true);
        }
    };


    const clearFilter = () => {
        setFilterName('');
        setFilteredCustomers([]);
        setFilterButtonClicked(false);
    };

    if (noCustomers) {
        return <div className='flex flex-col justify-center items-center text-xl'>You have no accounts or customers...add an account here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addaccount')}>Add New Account</Button></div>;
    }


    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
        <Box m="30px" width="90%">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
            <Header
                    title="Customer Lifecycle"
                    subtitle="View & Edit Lifecycle Stages"
            />
            <div className='w-full h-full flex flex-col'>
                <h3 className='text-md font-bold'>Search Customer</h3>
                <input
                    type="text"
                    className='mt-2 border-[1px] border-gray-400 w-[200px] h-[30px] rounded-md p-2 text-black'
                    placeholder="Enter Customer Name"
                    value={filterName}
                    onChange={handleFilterChange}
                />
                <div className='mt-2'>
                {!isFilterButtonClicked && (
                    <Button 
                        onClick={submitFilter}
                        color='secondary'
                        variant='contained'
                        sx={{"marginTop": "1rem"}} 
                    >
                        Set Filter
                    </Button>
                )}
                    <Button 
                        onClick={clearFilter}
                        color='secondary'
                        variant='contained'
                        sx={{"marginTop": "1rem", "marginLeft": "1rem"}} 
                    >
                        Clear Filter
                    </Button>
                </div>
                
                
                <div 
                    className="flex flex-col w-full"
                >
                    <div 
                        className="w-full h-auto flex flex-col justify-center"
                    >
                        {filteredCustomers?.map((cust) => (
                        <>
                        <div className='mt-12'>
                            <h3 className='text-lg font-bold'>Edit Lifecycle Stage</h3>
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row'}, // Responsive flexDirection
                                height: 'auto',
                                width: { xs: '600px', md: '100%'},
                                alignContent: 'center',
                                alignItems: 'center',
                                gap: '3.5rem',
                                margin: '1rem 0 1rem 0',
                                border: '1px solid lightgrey',
                                padding: '10px'
                            }}
                        >
                            <p 
                                className='font-bold text-lg'
                                style={{'padding': '5px', 'width': '150px', cursor: 'pointer'}}
                                onClick={(event) => {navigate(`/customer-profile/${cust.customer_name}`)}}
                            >
                                {cust.customer_name}
                            </p>
                            <Box sx={{ display: 'flex', width: '100%', marginLeft: '1rem'}}>
                                <Slider
                                    aria-label="Restricted values"
                                    defaultValue={getStageValue(cust.lifecycle_stage, customStages)}
                                    valueLabelFormat={valueLabelFormat}
                                    getAriaValueText={valuetext}
                                    step={null}
                                    valueLabelDisplay="off"
                                    marks={customStages}
                                    max={customStages[customStages.length - 1].value}
                                    color='secondary'
                                    onChange={(event, newValue) => handleSliderChange(event, newValue)}
                                    sx= {{ width: { xs: '500px', sm: '500px', md: '100%'}, }}
                                />
                                {/* <Slider
                                    aria-label="Restricted values"
                                    defaultValue={getStageValue(customer.lifecycle_stage, customStages)}
                                    valueLabelFormat={valueLabelFormat}
                                    getAriaValueText={valuetext}
                                    step={null}
                                    valueLabelDisplay="off"
                                    marks={marks}
                                    color='secondary'
                                    onChange={(event, newValue) => handleSliderChange(event, newValue)}
                                /> */}
                            </Box>
                            <Button 
                                color='secondary'
                                variant='contained'
                                onClick={(event) => saveSliderStageChange(cust.customer_name)}
                                className='w-[120px] h-[35px]'
                            >
                                Save
                            </Button>
                            <div 
                                className='app__add-property-fieldnames-text'
                                style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center', 'cursor': 'pointer'}}
                                onClick={(event) => {navigate(`/customer-profile/${cust.customer_name}#2`)}}
                            >
                                <p className='font-bold'>Healthscore:</p>
                                <span> </span> 
                                {cust.healthscore}
                            </div>
                            <div 
                                className='app__add-property-fieldnames-text'
                                style={{'padding': '5px', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'alignItems': 'center', 'cursor': 'pointer'}}
                                onClick={(event) => {navigate(`/customer-profile/${cust.customer_name}`)}}
                            >
                                <p className='font-bold'>Account Owner:</p>
                                <span> </span> 
                                {cust.owner_name}
                            </div>
                        </Box>
                        </>
                        ))}
                    </div>
                </div>
                {/* <Stack spacing={2}>
                    <Pagination 
                        count={totalPages}
                        page={page}
                        color="secondary"
                        showFirstButton 
                        showLastButton
                        onChange={(event, value) => setPage(value)}
                    />
                </Stack> */}
                {/* <Button
                    sx={{"marginTop": "1rem"}} 
                    color='secondary'
                    variant='contained' 
                    onClick={(event) => {}}
                >
                    Button Placeholder
                </Button> */}
            </div>
        </Box>
    );
}

export default Lifecycle;

