import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const ScorecardManagement = () => {

    const { customername } = useParams();

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [HSPropertyData, setHSPropertyData] = useState([]);
    const [keyDict, setKeyDict] = useState([]);  // this is a key lookup array of all the hs_property_keyids corresponding to the index when they were retrieved from the database
    const [editedPropertyData, seteditedPropertyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectField, setSelectField] = useState('');

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchHSProperties = async () => {
          setIsLoading(true);

          const checkUser = localStorage.getItem('user');

          const fetchedUserString = JSON.stringify(checkUser); 
          const fetchedUserObject = JSON.parse(fetchedUserString);
          const fetchedUserinner = JSON.parse(fetchedUserObject);
          const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
          const user_type = fetchedUserinner.user_type  // using this variable to get user_type
          const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "hs_property"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();

            // console.log('this is the result after fetching HS properties:')
            // console.log(result)

            const resultInnerArray = result?.retrieved_data

            // console.log('this is the result after accessing retrieved_data:')
            // console.log(resultInnerArray)

            // console.log('this is the length of resultInnerArrayString:')
            // console.log(resultInnerArray.length)

            if (resultInnerArray != 'No result is returned') {
                const myJsonString = JSON.stringify(resultInnerArray);
                const jsonObject = JSON.parse(myJsonString); 
                const jsonArray = jsonObject.map((item, index) => ({
                    hs_property_keyid: item.hs_property_keyid,
                    hs_name: item.hs_name,
                    hs_property_name: item.hs_property_name,
                    hs_property_weight: item.hs_property_weight,
                    hs_property_operator: item.hs_property_operator,
                    hs_property_value: item.hs_property_value,
                    hs_property_score: item.hs_property_score,
                }));

                // combining all JSON objects in jsonArray into one new array with unique key names
                const newHSPropertyArray = [];
                // keys for hsproperty_keyid
                const hsKeys = []

                jsonArray.map((item, index) => (
                    Object.entries(item).map(([key, value]) => {
                        newHSPropertyArray[key + index.toString()] = value;
                        if (key === 'hs_property_keyid') {
                            hsKeys[index] = value;
                        }
                    })
                ));

                setKeyDict(hsKeys);

                // console.log('this is hsKeys:')
                // console.log(hsKeys)


                // console.log('this is newHSPropertyArray:')
                // console.log(newHSPropertyArray);

                const newHSPropertyArrayObject = [newHSPropertyArray];

                // console.log('this is newHSPropertyArrayObject:')
                // console.log(newHSPropertyArrayObject)
        
                setHSPropertyData(newHSPropertyArrayObject);
                setIsLoading(false);
            } else {
                // setIsLoading(false);
                // return null;
                const examplejsonArray = [{ 
                    "hs_property_keyid0": "example: 'hs1_property1'",
                    "hs_name0": "example: 'main healthscore'",
                    "hs_property_name0": "example: 'emails received'",
                    "hs_property_weight0": "example: '0.25'",
                    "hs_property_operator0": "example: '>='",
                    "hs_property_value0": "example: '10'",
                    "hs_property_score0": "example: '95'",
                }]
                setHSPropertyData(examplejsonArray);
                setIsLoading(false);
            }
          } catch (error) {
            setError(error.message);
            setIsLoading(false);
          }
        };
    
        fetchHSProperties();
      }, []);




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    const handleSubmitDeleteProperty = (event, selectField) => {
        event.preventDefault();

        // find the hs_property_keyid in HSPropertyData by using the value of selectField
        const hspropertykeyidArray = HSPropertyData.map((item, index) => (
            Object.entries(item).map(([key, value]) => {
              if (value === selectField) {
                  return key;
              } else {
                  return null;
              }
            })
        ));

        const hspropertykeyid = hspropertykeyidArray.flat().filter(value => value !== null)[0];

        // console.log('this is hspropertykeyid:')
        // console.log(hspropertykeyid)

        // extract the numbers from the hspropertykeyid
        const hspropertyIndexforDict = hspropertykeyid.match(/\d+/g).map(Number);

        // using the index, get the value of the keyDict array which is the key_id to use for deleting property
        const keyid_DictValue = keyDict[hspropertyIndexforDict];

        // console.log('this is keyid_DictValue:')
        // console.log(keyid_DictValue)

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        const json_data = {"kind_id": kind_id, 
                            "key_id": keyid_DictValue};

        const deleteUrl = process.env.REACT_APP_API_URL + '/api/v1/delete';

        fetch(deleteUrl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(loginresponse => loginresponse.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                console.log('Property has been deleted: ')
                console.log(result);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });
    };


    const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


    const checkoutSchema = yup.object().shape({
        editedField: yup.string().required("required"),
        customerName: yup.string().required("required"),
        startDate: yup.string().required("required"),
        email: yup.string().email("invalid email").required("required"),
        contact: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("required"),
        // address1: yup.string().required("required"),
        address2: yup.string().required("required"),
    });


    // const newHSPropertyArray = [];
    
    // HSPropertyData.map((item, index) => (
    //     Object.entries(item).map(([key, value]) => {
    //         if (key != 'hs_property_keyid') {
    //             newHSPropertyArray[key + index.toString()] = value;
    //         } 
    //     })
    // ));

    
    // console.log('this is newHSPropertyArray:')
    // console.log(newHSPropertyArray)



    const initialValues = HSPropertyData[0];


    const handleFormSubmit = (values) => {

        // console.log('this is values inside form submit')
        // console.log(values)

        // get the key of the values array
        var key = Object.keys(values)[0];
        // get the last character of the var key
        var lastChar = key.slice(-1);
        // convert the last character to an integer
        var lastCharInt = parseInt(lastChar);
        // using lastChartInt as the key for the keyDict array, get the value
        var keyDictValue = keyDict[lastCharInt];

        // console.log('this is keyDictValue')
        // console.log(keyDictValue);
        
        // get the value of key minus the last character of the var key
        const keyMinusLastChar = key.slice(0, -1);

        const data = {}

        data[keyMinusLastChar] = values[key];


        // console.log('this is data')
        // console.log(data)

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
        

        const json_data = {"kind_id": kind_id, 
                            "key_id": keyDictValue, 
                            "data": data};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                console.log('Customer Info Changes Saved: ')
                console.log(result);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });

    };


    const handleChange = (event) => {
        setSelectField(event.target.value);
    };


    return (
        <Box m="30px" width="90%">
            <Header title="Healthscores Management" subtitle="Create, Edit, or Delete Healthscore Properties" />

            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 5" },
                                }}
                            >
                                {HSPropertyData.map((item, index) => (
                                    <>
                                        {Object.entries(item).map(([fieldName, fieldValue]) => (  // [fieldName, fieldValue] is the key, value pairs of the JSON object 'item'
                                            fieldName.includes('hs_property_keyid') || fieldName.includes('hs_name') ? null :
                                                (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        variant='filled'
                                                        type="text"
                                                        // remove the last character in fieldName
                                                        label={(fieldName.charAt(3).toUpperCase() + fieldName.slice(4).replace(/_/g, ' ')).slice(0).slice(0, -1) + ' ' + (parseInt(fieldName.slice(-1)) + 1).toString()}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 1" }}
                                                    />
                                                </div>
                                                )
                                        ))}
                                    </>
                                ))}
                            </Box>
                            <Box 
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                            >
                                <Button 
                                    type="button"
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => {navigate('/healthscores/manage/addproperty')}}
                                >
                                    Create New Property
                                </Button>
                                <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    onClick={(event) => handleFormSubmit(values)}
                                >
                                    Save Edit
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>

            <h3 className='font-bold text-2xl mt-16'>Delete a Healthscore Property</h3>
            <div
                className="w-full md:w-1/3 h-auto flex-col justify-start"
            >
                <FormControl sx={{width: "100%", marginTop: "1rem"}}>
                    <InputLabel id="demo-simple-select-helper-label">Existing Properties</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectField}
                        label="Select Field"
                        onChange={handleChange}
                    >
                        {   HSPropertyData.map((item) => (
                            Object.entries(item).map(([key, value]) => {
                            const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                            if (key.includes('hs_property_name')) {
                                return (
                                    <MenuItem value={value}>{value}</MenuItem>
                                )
                                } else {
                                    return null;
                                }
                        })
                        ))}
                    </Select>
                    <FormHelperText>Delete a Property from one of the existing Healthscore Properties</FormHelperText>
                </FormControl>
            </div>
            <div className='pb-4'>
                <Button
                    sx={{marginTop: "1rem"}}
                    type='button'
                    color='secondary'
                    variant='contained'
                    onClick={(event) => handleSubmitDeleteProperty(event, selectField)}
                >
                    Delete Property
                </Button>
            </div>
        </Box>






        // <Box m="20px">
        //     <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp" rel="stylesheet"></link>
        //     <Header
        //             title="Scorecard Management"
        //             subtitle="Add, Edit, Delete Scorecard Properties"
        //     />
        //     <div className='app__hsmanagement-container'>
        //         <Button
        //             sx={{"marginBottom": "1rem"}} 
        //             color='secondary'
        //             variant='contained'
        //             onClick={(event) => {navigate('/managescorecards/addproperty')}}
        //         >
        //             Add New Property
        //         </Button>
        //         <div className='app__hsmanagement-edit-properties-title'>
        //             <h3>Edit Existing Scorecard Properties</h3>
        //         </div>
        //         <form>
        //             {/* <Box
        //                 display="grid"
        //                 gap="30px"
        //                 gridTemplateColumns="repeat(8, minmax(0, 1fr))"
        //                 sx={{
        //                     "& > div": { gridColumn: isNonMobile ? undefined : "span 8" },
        //                 }}
        //             > */}
        //             <table 
        //                 style={{"width": "100%", "border": "1.5px solid #ddd", "text-align": "center"}}
        //             >
        //                 <thead>
        //                     <tr>
        //                         <th>Healthscore Name</th>
        //                         <th>Property Name</th>
        //                         <th>Property Weight</th>
        //                         <th>Property Operator</th>
        //                         <th>Property Value</th>
        //                         <th>Property Score</th>
        //                         <th>Delete Property</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody 
        //                     style={{"border": "none", 'outline': 'none', 'text-align': 'center'}}
        //                 >
        //                     {HSPropertyData.map((item, index) => (
        //                         <tr className='app__hsmanagement-table-table-row' key={index + 1}>
        //                             {Object.entries(item).map(([key]) => {
        //                                 if (key === "hs_property_keyid") {
        //                                     // Skip this item and move on to the next
        //                                     return null;
        //                                 }
        //                                 const editedValue = editedPropertyData.hasOwnProperty(index) && editedPropertyData[index].hasOwnProperty(key)
        //                                                     ? editedPropertyData[index][key]
        //                                                     : item[key];
        //                                 return (
        //                                     <td style={{'height': '1.5rem', 'marginTop': '2rem'}}>
        //                                         <input
        //                                             style={{'height': '40px', 'backgroundColor': colors.grey[900], 'border': 'none', 'outline': 'none', 'text-align': 'center', 'fontFamily': 'inherit'}}
        //                                             type="text"
        //                                             value={editedValue}
        //                                             onChange={(event) => handleInputChangeHS(index, key, event.target.value)}
        //                                         />
        //                                     </td>
        //                                 );
        //                             })}
        //                             <Button
        //                                 color='secondary'
        //                                 variant='contained'
        //                                 onClick={(event) => handleSubmitDeleteProperty(event, item)}
        //                             >
        //                                 Delete Property
        //                             </Button>
        //                         </tr>
        //                     ))}
        //                 </tbody>
        //             </table>
        //             {/* </Box> */}
        //         </form>
        //         <Button
        //             sx={{"marginTop": "1rem"}} 
        //             color='secondary'
        //             variant='contained' 
        //             onClick={(event) => handleSubmitHS(event, HSPropertyData, editedPropertyData)}
        //         >
        //             Save Changes
        //         </Button>
        //     </div>
        // </Box>
    );
}

export default ScorecardManagement;

