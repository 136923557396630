import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { saveLog } from "../../utils/logChanges";


const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

// date in the format MM/DD/YYYY
const dateinValidFormat = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;

// date in the format YYYY-MM-DD
// const dateinValidFormat = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    customerName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    saleDate: yup.string().matches(dateinValidFormat, "Enter Date in the correct format MM/DD/YYYY").required("required"),
    saleProduct: yup.string().required("required"),
    saleAmount: yup.string().required("required"),
    // startDate: yup.string(),
    // email: yup.string().email("invalid email"),
    // contact: yup
    //     .string()
    //     .matches(phoneRegExp, "Phone number is not valid"),
    // address1: yup.string(),
    accountOwner: yup.string()
});


const initialValues = {
    customerName: "",
    saleDate: "",
    saleProduct: "",
    saleAmount: "",
    // startDate: "",
    // email: "",
    // contact: "",
    // address1: "",
    accountOwner: "",
};


const NewSale = () => {
  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;

  // Encode username and password for Basic Auth
  const encodedCredentials = window.btoa(`${username}:${password}`);

  // Setup headers
  const headers = new Headers({
  'Authorization': `Basic ${encodedCredentials}`,
  'Content-Type': 'application/json'
  });
    
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectFieldOwnerName, setSelectFieldOwnerName] = useState('');  // need this to set the owner_name field in the form
  
  const [custFields, setCustFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);


  // fetching users
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      const checkUser = localStorage.getItem('user');

      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": "users", 
                    "filters": {
                      "filter1": {"filter_field": "user_kind_id", 
                            "filter_op": "=",
                            "filter_value": kind_id},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);

        if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{

                    }]
            setUsersData(jsonArray);
            setIsLoading(false);
        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
            id: index + 1,
            ...item,
            }));
    
            setUsersData(jsonArray);
            setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

      fetchUsers();
  }, []);



  // fetching customers to check if the new sale for the account or customer name exists
  useEffect(() => {
    const fetchCustData = async () => {

    const checkUser = localStorage.getItem('user');

    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id  

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    const encodedCredentials = window.btoa(`${username}:${password}`);

    const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

    try {
        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "customer"},
                      } 
                      };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);
        if (jsonObject.retrieved_data === 'No result is returned') {
            const jsonArray = [{
                        id: 1,
                        customer_name: 'Example Customer 1',
                        owner_name: 'Example Owner 1',
                        lifecycle_stage: 'Purchase',
                        healthscore: '90',
                        healthscore_color: 'green',
                        cta_open: '2',
                        industry: 'Example Industry',
                        specialty: 'Example Specialty',
                        last_touch_date: '2023-09-01',
                        cta_count: '2',
                        created_date: '2023-09-01',
                        start_date: '2023-09-01',
                        renewal_date: '2024-09-01',
            }]
            setCustomers(jsonArray);
        } else {
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                id: index + 1,
                ...item,
            }));
    
            setCustomers(jsonArray);
        }
      } catch (error) {
        setError(error.message);
      }

    };

    fetchCustData();
}, []);



  

  const handleFormSubmit = (values) => {
    // console.log(values);

    try {
      const checkUser = localStorage.getItem('user');
      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      const NewSaleData = {}

      // add kind_id to NewSaleData
      NewSaleData["kind_id"] = kind_id;
      // add account name or a.k.a. customer_name
      // ensure there are no leading and trailing spaces
      if (values.customerName.trim() === "") {
        ToastNotify("error", "Account Name is required.");
        return;
      }

      if (values.saleProduct.trim() === "") {
        ToastNotify("error", "Product or Item Sold is required.");
        return;
      }

      const customerName = values.customerName.trim();
      const customerNameExists = customers.filter((customer) => customer.customer_name === customerName);

      if (customerNameExists.length === 0) {
        ToastNotify("error", "Account Name does not exist. Please check the spelling and names are case sensitive.");
        return;
      }

      NewSaleData["customer_name"] = values.customerName.trim();
      // generate a unix timestamp to the millisecond and convert it to a string
      const unixTimestamp = new Date().getTime().toString();
      // required object_type field
      NewSaleData["object_type"] = "sale";
      // required category field
      // convert values.saleDate to YYYY-MM-DD format
      const newFormatSaleDate = values.saleDate.split('/')[2] + '-' + values.saleDate.split('/')[0] + '-' + values.saleDate.split('/')[1];
      NewSaleData["sale_date"] = newFormatSaleDate;
      // required status field
      NewSaleData["product"] = values.saleProduct;
      // required sale amount field
      NewSaleData["sale_amount"] = values.saleAmount;
      // required created_date field
      const today = new Date().toISOString().slice(0, 10);
      NewSaleData["created_date"] = today

      // if values.accountOwner is empty, set it to loginUserName which is owner_name from above.  If it is not empty, then add it to customerData (changing accountOwner to owner_name as per the datastore schema)
      if (selectFieldOwnerName == "") {
        NewSaleData["owner_name"] = loginUserName;
      } else {
        NewSaleData["owner_name"] = selectFieldOwnerName;
      }

      const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

      const json_data = {"kind_id": kind_id, 
                        "data": NewSaleData};

      fetch(Createurl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(response => response.json())
          .then(result => {
              // Handle the API response or perform any necessary actions
              // console.log('New Customer has been created');
              // console.log(result);
              saveLog(new Date().toLocaleString(), 'Sale', values.customerName.trim(), values.saleProduct, '', `New Sale ${values.saleProduct} for ${values.customerName.trim()}`)
              ToastNotify("success", "New Sale has been created");
          })
          .catch(error => {
              // Handle errors that occurred during the API call
              console.error(error);
              ToastNotify("error", "Something went wrong.");
          });
      
    } catch (error) {
      ToastNotify("error", "Something went wrong.");
      console.log(error);
    }
    
  };


  const handleChangeOwner = (event) => {
    const owner = event.target.value;
    setSelectFieldOwnerName(owner);
  };


  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  return (
    <Box m="30px" width="90%">
      <Header title="New Sale" subtitle="Create a New Sale" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: {xs:"100%", md:"50%"},
                display: "grid",
                gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                gap: "30px"
              }}
            >
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                    Who is this sale for? Enter the Account Name (Case Sensitive).
                    </p>
                </div>
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Account Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customerName}
                    name="customerName"
                    error={!!touched.customerName && !!errors.customerName}
                    helperText={touched.customerName && errors.customerName}
                    sx={{ gridColumn: "span 4" }}
                />
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                    Sale Date (MM/DD/YYYY)
                    </p>
                </div>
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Sale Date"
                    placeholder='i.e. MM/DD/YYYY format'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.saleDate}
                    name="saleDate"
                    error={!!touched.saleDate && !!errors.saleDate}
                    helperText={touched.saleDate && errors.saleDate}
                    sx={{ gridColumn: "span 4" }}
                />
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                    Product or Item Sold
                    </p>
                </div>
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Product or Item Sold"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.saleProduct}
                    name="saleProduct"
                    error={!!touched.saleProduct && !!errors.saleProduct}
                    helperText={touched.saleProduct && errors.saleProduct}
                    sx={{ gridColumn: "span 4" }}
                />
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                    Sale Amount
                    </p>
                </div>
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Sale Amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.saleAmount}
                    name="saleAmount"
                    error={!!touched.saleAmount && !!errors.saleAmount}
                    helperText={touched.saleAmount && errors.saleAmount}
                    sx={{ gridColumn: "span 4" }}
                />
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                    The Sale Owner is the person who made the sale.  If you don't make a selection, the Sale Owner will be set to you.
                    </p>
                </div>
                <FormControl sx={{ gridColumn: "span 4"}}>
                    <InputLabel id={`select-owner-label-input`}>Sale Owner</InputLabel>
                    <Select
                        labelId={`select-owner-label-selectid`}
                        id={`select-owner-select-id`}
                        value={selectFieldOwnerName}
                        label="Select Owner"
                        onChange={(event) => handleChangeOwner(event)}
                    >
                        {usersData.map((item) => (
                                // const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                    <MenuItem key={item.user_owner_name} value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Select an Owner for this Sale</FormHelperText>
                </FormControl>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="start" mt="40px">
                <Button
                    sx={{ width: "200px" }}
                    color="secondary" 
                    variant="contained"
                    onClick={(event) => handleFormSubmit(values)}
                >
                    Add New Sale
                </Button>
                </Box>
            </form>
        )}
        </Formik>
    </Box>
  );
};



export default NewSale;
