import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from "@mui/material";
import { tokens, useCustomSidebarHeight } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ToastNotify } from '../../../components/ToastNotify';


const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    ruleName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    actionRequestName: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    noDuplicates: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed"),
    owner_name: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed"),
    priority: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    customer_name: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed"),
    category: yup.string().matches(noSpecialCharsRegExp, "Special characters are not allowed").required("required"),
    guide: yup.string(),
    due_date: yup.date(),
});


const initialValues = {
    ruleName: "",
    actionRequestName: "",
    noDuplicates: "",
    category: "",
    priority: "",
    guide: "",
    due_date: "",
};

const operators = [
    {
        "Equal to": "=",
        "Not equal to": "!=",
        "Less than": "<",
        "Less than or equal to": "<=",
        "Greater than": ">",
        "Greater than or equal to": ">=",
        "Between": "between",
        "Contains": "contains",
    }
]



const RuleBased = () => {
    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    // to adjust the sidebar height dynamically
    const { sidebarHeight, setSidebarHeight } = useCustomSidebarHeight();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    // need customers data to loop over and create action requests for each customer
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [ARmetaData, setARmetaData] = useState([]);  // this is to store name, priority, category, due date, etc. for the action request
    const [fields, setFields] = useState([]);  // this is to store the rules, basically all the selected fields, operators, and values 
    const [inputValues] = useState({ inputValue: '' });  // this is only used for to display the value for TextField objects after operators (not storing actual input values from user, that gets stored in fields state above) and to assign an id to each TextField that gets generated dynamically

    const [isChecked, setIsChecked] = useState(true);

    const handleCheckBoxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    
    useEffect(() => {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };

    }, []);


    useEffect(() => {
        const fetchCustData = async () => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": kind_id, 
                        "filters": {
                            "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString);
            if (jsonObject.retrieved_data === 'No result is returned') {
                const jsonArray = [{
                            id: 1,
                            customer_name: 'Example Customer 1',
                            owner_name: 'Example Owner 1',
                            lifecycle_stage: 'Purchase',
                            healthscore: '90',
                            healthscore_color: 'green',
                            cta_open: '2',
                            industry: 'Example Industry',
                            specialty: 'Example Specialty',
                            last_touch_date: '2023-09-01',
                            cta_count: '2',
                            created_date: '2023-09-01',
                            start_date: '2023-09-01',
                            renewal_date: '2024-09-01',
                }]
                setCustomers(jsonArray);
            } else {
                const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                }));
        
                setCustomers(jsonArray);
            }
          } catch (error) {
            setError(error.message);
          }

        };
    
        fetchCustData();
    }, []);



    const handleFormSubmit = () => {
        // console.log('these are the values:')
        // console.log(values);

        // console.log('these are the fields:')
        // console.log(fields);

        // console.log('this is the ARmetaData:')
        // console.log(ARmetaData);
    
        const checkUser = localStorage.getItem('user');
    
        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });
    
        const NewARData = {}
    
        // add kind_id to NewARData
        NewARData["kind_id"] = kind_id;
        // required rule name
        NewARData["rule_name"] = ARmetaData.ruleName;
        // required action request name field
        NewARData["cta_name"] = ARmetaData.actionRequestName;
        // required no_duplicates field (true or false)
        if (isChecked === true) {
            NewARData["no_duplicates"] = "true";
        } else {
            NewARData["no_duplicates"] = "false";
        }
        // required object_type field
        NewARData["object_type"] = "ctarule";
        // required created_date field
        const today = new Date().toISOString().slice(0, 10);
        NewARData["created_date"] = today;
        NewARData["created_by"] = loginUserName;
        // required priority field
        NewARData["priority"] = ARmetaData.priority;
        // required status field
        NewARData["rules"] = fields;
    
        
        // if values.category is not empty, then add it to NewARData
        if (ARmetaData.category !== "") {
            NewARData["category"] = ARmetaData.category;
        }
        // if values.due_date is not empty, then add it to NewARData
        if (ARmetaData.due_date !== "") {
            NewARData["due_date"] = ARmetaData.due_date;
        }

    
        const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

        const json_data = {"kind_id": kind_id, 
                            "data": NewARData};

        fetch(Createurl, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('New Rule for Activities has been created:');
                // console.log(result);
                ToastNotify('success', 'New Rule has been created');
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
                ToastNotify('error', 'Error, something went wrong.');
            });


        const createARurl = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/createar';

        const createar_data = {"kind_id": kind_id };

        fetch(createARurl, {
            method: 'POST',
            body: JSON.stringify(createar_data),
            headers: headers
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.log(error);
            });
        
    };



    const handleChangeField = (event, index, values) => {
        const newFields = [...fields];
        newFields[index].selectField = event.target.value;
        setFields(newFields);
        // console.log('this is fields: ')
        // console.log(fields)
        setARmetaData(values);
        // console.log('this is values inside ARmetaData after setting values: ')
        // console.log(ARmetaData)
    };

    const handleChangeOperator = (event, index) => {
        const newFields = [...fields];
        newFields[index].selectOperator = event.target.value;
        setFields(newFields);
    };


    const handleChangeValue = (event, index) => {
        const newFields = [...fields];
        newFields[index].values = event.target.value;
        setFields(newFields);
    };


    const handleAddField = () => {
        // Add a new field to the list
        setFields([...fields, { id: fields.length + 1, selectField: '', selectOperator: '' }]);

        // increasing the sidebar height dynamically
        const newHeight = parseInt(sidebarHeight) + 30;
        setSidebarHeight(newHeight + 'vh');
    };



    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }

    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }


    return (
      <Box m="30px" width="90%">
        <Header title="Create" subtitle="Rules to trigger Activities based on Account Fields" />
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>

            <Box
              sx={{
                width: {xs:"100%", md:"50%"},
                display: "grid",
                gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                gridGap: "1rem",
                marginTop: "2rem",
              }}
            >
              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Rule Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ruleName}
                  name="ruleName"
                  error={!!touched.ruleName && !!errors.ruleName}
                  helperText={touched.ruleName && errors.ruleName}
                  sx={{ gridColumn: "span 4" }}
              />
              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Activity Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.actionRequestName}
                  name="actionRequestName"
                  error={!!touched.actionRequestName && !!errors.actionRequestName}
                  helperText={touched.actionRequestName && errors.actionRequestName}
                  sx={{ gridColumn: "span 4" }}
              />
              <h3 className='text-s text-gray-500 grid-cols-1 p-2'>
                No Duplicates
              </h3>
              <p className='text-xs text-gray-500 grid-cols-1'>
                (Optional) : Uncheck checkbox to allow duplicate activities with the same name. If checked, the rule will not create another activity if the same name already exists.
              </p>
              <Checkbox
                  sx={{ gridColumn: "span 1", '&:hover': { backgroundColor: 'transparent',}, }}
                  checked={isChecked}
                  onChange={handleCheckBoxChange}
                  color="primary"
                  backgroundColor="transparent"
                  inputProps={{ 'aria-label': 'controlled' }}
              />
              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Priority"
                  placeholder='i.e. Critical, High, Medium, or Low'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.priority}
                  name="priority"
                  error={!!touched.priority && !!errors.priority}
                  helperText={touched.priority && errors.priority}
                  sx={{ gridColumn: {xs:"span 4", md:"span 2"}  }}
              />
              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category"
                  placeholder='i.e. risk mitigation, customer onboarding, monthly check-in, etc.'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.category}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                  sx={{ gridColumn: {xs:"span 4", md:"span 2"} }}
              />
            </Box>

            {fields.map((field, index) => (
                <Box key={field.id}>
                    <div className="span-4">
                        <FormControl sx={{ width: {xs:"100%", md:"50%"}, marginTop: "1rem" }}>
                            <InputLabel id={`field-label-${field.id}`}>Select a Field</InputLabel>
                            <Select
                                labelId={`field-label-${field.id}`}
                                id={`field-select-${field.id}`}
                                value={field.selectField}
                                label="Select Field"
                                onChange={(event) => handleChangeField(event, index, values)}
                            >
                                {customers.slice(0,1).map((item) => (
                                    Object.entries(item).map(([key]) => {
                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                        if (key.includes('id') || key.includes('object_type') || key.includes('key_id') || 
                                            key.includes('score_history') || key.includes('healthscore_reasons') || key.includes('notes') ) {
                                            return null;
                                        } else {
                                          return (
                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                          );
                                        }
                                    })
                                ))}
                            </Select>
                            <FormHelperText>Select from Customer Fields</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="span-4">
                        <FormControl sx={{ width: {xs:"100%", md:"50%"}, marginTop: "1rem" }}>
                            <InputLabel id={`operator-label-${field.id}`}>Operators</InputLabel>
                            <Select
                                labelId={`operator-label-${field.id}`}
                                id={`operator-select-${field.id}`}
                                value={field.selectOperator}
                                label="Select Operator"
                                onChange={(event) => handleChangeOperator(event, index)}
                            >
                                {operators.map((item) => (
                                    Object.entries(item).map(([key]) => {
                                        const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
                                        return (
                                            <MenuItem key={key} value={formattedKey}>{formattedKey}</MenuItem>
                                        );
                                    })
                                ))}
                            </Select>
                            <FormHelperText>Select an Operator</FormHelperText>
                        </FormControl>
                    </div>
                    <Box sx={{
                            width: {xs:"100%", md:"50%"},
                            display: "grid",
                            gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                            gridGap: "1rem",
                        }}
                    >
                        <TextField
                            id={`input-value-${field.id}`}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Value"
                            onBlur={handleBlur}
                            onChange={(event) => handleChangeValue(event, index)}  // sets the value for the field in the state 'fields' which is an array
                            value={inputValues[`inputValue${field.id}`]}
                            name="inputValue"
                            error={!!touched.inputValue && !!errors.inputValue}
                            helperText={touched.inputValue && errors.inputValue}
                            sx={{ gridColumn: {xs:"span 2", md:"span 4"}, marginTop: "1rem"}}
                        />
                    </Box>
                    
                    <div className='mt-1'>
                        <p className='text-gray-500 text-xs'>
                            Set the Value for the activity to trigger. Multiple values must be separated by commas.
                        </p>
                    </div>
                </Box>
            ))}
            <Button
              sx={{ marginTop: "2rem", width: 200, border: `1px solid ${colors.greenAccent[200]}` }}
              variant='contained'
              onClick={handleAddField}
            >+ Add Rule Logic
            </Button>
            </form>
            )}
          </Formik>
          <Button
            sx={{ marginTop: "2rem", width: 200}} 
            onClick={(event) => handleFormSubmit()} 
            color="secondary" 
            variant="contained"
        >
            Create
          </Button>
        </Box>
    );
}

export default RuleBased;

