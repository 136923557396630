


export const saveLog = async (dateTime, crmObject, identifier, name, field, message) => {

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id  

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    const encodedCredentials = window.btoa(`${username}:${password}`);

    const headers = new Headers({
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/json'
        });

    const NewLogsData = {}

    // add kind_id to NewLogsData
    NewLogsData["kind_id"] = kind_id;
    NewLogsData["owner_name"] = loginUserName;
    // local user's timestamp
    NewLogsData["date_time"] = dateTime;
    // created_date needed to filter logs when fetching on the frontend
    // today's date in the format 'YYYY-MM-DD' in the user's local timezone
    const localDate = new Date().toLocaleString()
    const localDateSplit = localDate.split(",")[0].split("/");
    const localYear = localDateSplit[2];
    const localMonth = localDateSplit[0];
    const localDay = localDateSplit[1];
    const localDateFormatted = `${localYear}-${localMonth}-${localDay}`;
    NewLogsData["created_date"] = localDateFormatted;
    NewLogsData["crm_object"] = crmObject;
    NewLogsData["identifier"] = identifier;
    // name is the account name or activity name or opportunity name or lead name or contact name, etc.
    NewLogsData["name"] = name;
    NewLogsData["field"] = field;
    NewLogsData["message"] = message;
    // required 'read_users' field but no data required for it
    NewLogsData["read_users"] = [];


    const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

    const json_data = {"kind_id": "logs",
                        "data": NewLogsData};

    fetch(Createurl, {
        method: 'POST',
        body: JSON.stringify(json_data),
        headers: headers
        })
        .then(response => response.json())
        .then(result => {
            // Handle the API response or perform any necessary actions
            // console.log('New Customer has been created');
            // console.log(result);
            // ToastNotify("success", "Logs saved");
        })
        .catch(error => {
            // Handle errors that occurred during the API call
            console.error(error);
            // ToastNotify("error", "Something went wrong");
        });
  };