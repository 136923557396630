import { Box } from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import CircularProgress from '@mui/material/CircularProgress';


const Line = () => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // for healthscores array
  const [hsHistoryData, setHsHistoryData] = useState([]);


  useEffect(() => {
    const UserjsonArray = [];

    const checkUser = localStorage.getItem('user');

    if (checkUser === null) {
    
        navigate('/login', {replace: true})
    
    } else {
    const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

    Object.entries(userInfojsonObject).map(([key, value]) => {
        // Perform operations with the key-value pair
        const obj = { key, value }; // Create an object with key-value pair
        UserjsonArray.push(obj);
    });
    
    setUser(UserjsonArray);
    };
}, []);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // Make an API request to retrieve data from the Google Cloud Datastore
        const url = process.env.REACT_APP_API_URL + '/api/v1/read'
        const data = {
            "kind_id": kind_id, 
            "filters": {
              "filter1": {"filter_field": "object_type", 
                    "filter_op": "=",
                    "filter_value": "customer"},
              "filter2": {"filter_field": "owner_name", 
                    "filter_op": "=",
                    "filter_value": loginUserName},
              } 
              };
        const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })

        const result = await response.json();
        const myJsonString = JSON.stringify(result);
        const jsonObject = JSON.parse(myJsonString);
        if (jsonObject.retrieved_data === 'No result is returned') {
          const transformedArray = [{
              x: new Date().toISOString().slice(0, 10),
              y: 100
          }];
          // final array for line chart
          const HShistoryArray = [
                  {
                      id: "Example Customer 1",
                      color: colors.greenAccent[500],
                      data: transformedArray,
                  },
              ];
      
          setHsHistoryData(HShistoryArray);
      } else {
          const jsonArray = jsonObject.retrieved_data.map(item => ({
            ...item,
          }));

          setCustomers(jsonArray);


          // this contains all the customer's score_history after looping through and adding to this array
          const HShistoryArray = [];
            
          // Process each element in the array
          for (const element of jsonArray) {

            const customername = element.customer_name;

            const jsonString = element.score_history;

            // console.log('this is jsonString for customer: ' + customername)
            // console.log(jsonString)
            
            // check if jsonString is actually an array, if it is an array, then convert it to a string object
            if (Array.isArray(jsonString)) {
              const stringifiedJsonString = JSON.stringify(jsonString);
              const parsedArray = JSON.parse(stringifiedJsonString);
              const transformedArray = parsedArray.map(item => ({
                  x: item.x,
                  y: parseInt(item.y, 10) // Convert 'y' to an integer
              }));

              HShistoryArray.push({
                  id: customername,
                  color: colors.greenAccent[500],
                  data: transformedArray,
              });

            } else {
              // Parse the JSON string into a JavaScript array
              const parsedArray = JSON.parse(jsonString);
              
              // If you want to transform the array to the desired format
              const transformedArray = parsedArray.map(item => ({
                  // convert item.x to a date in YYYY-MM-DD format
                  x: new Date(item.x).toISOString().substring(0, 10),
                  y: parseInt(item.y, 10) // Convert 'y' to an integer
              }));
              
              // console.log('this is transformedArray:')
              // console.log(transformedArray);
      
              // console.log('this is CustHSLinedata:')
              // console.log(CustHSLinedata)
      
              // final array for line chart
              HShistoryArray.push({
                      id: customername,
                      color: colors.greenAccent[500],
                      data: transformedArray,
                    });
            }
          };

          // console.log('HShistoryArray:');
          // console.log(HShistoryArray);

          setHsHistoryData(HShistoryArray);
        }

      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);


  // for updating HS and not have to wait for it to finish
  // useEffect(() => {

  //   const updateHs = () => {
  //     const checkUser = localStorage.getItem('user');

  //     const fetchedUserString = JSON.stringify(checkUser); 
  //     const fetchedUserObject = JSON.parse(fetchedUserString);
  //     const fetchedUserinner = JSON.parse(fetchedUserObject);
  //     const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
  //     const user_type = fetchedUserinner.user_type  // using this variable to get user_type
  //     const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

  //     const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/updatehs';

  //     const data = {
  //       "kind_id": kind_id, 
  //         };
      
  //     const response = fetch(url, {
  //           method: 'POST',
  //           headers: headers,
  //           body: JSON.stringify(data)
  //         })
      
  //     return "updating healthscores..";
  //   };

  // updateHs();

  // }, []);




  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  
  return (
    <Box m="20px">
      <Header title="Healthscores" subtitle="Trending Healthscores by month" />
      {customers.length === 0 ? <div className="pt-4"><h2 className="font-bold text-gray-500">No customers under current Account Owner.</h2></div> : null }
      <Box height="75vh">
        <LineChart customData={hsHistoryData}/>
      </Box>
    </Box>
  );
};

export default Line;
