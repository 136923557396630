import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastNotify } from "../../components/ToastNotify";
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerStyles.css';  // custom styles for datepicker


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { saveLog } from "../../utils/logChanges";


const noSpecialCharsRegExp = /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

// date in the format MM/DD/YYYY
const dateinValidFormat = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;

// const dateinValidFormat = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({
    eventName: yup.string().required("required"),
    // eventStartDate: yup.string().matches(dateinValidFormat, "Enter Date in the correct format MM-DD-YYYY").required("required"),
    // eventEndDate: yup.string().matches(dateinValidFormat, "Enter Date in the correct format MM-DD-YYYY"),
    cost: yup.string(),
    attendees: yup.string().required("required"),
    notes: yup.string()
});


const initialValues = {
    eventName: "",
    // eventStartDate: "",
    // eventEndDate: "",
    cost: "",
    attendees: "",
    notes: "",
};


const EventsCreate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);

  const [selectFieldCategory, setSelectFieldCategory] = useState('');
  const [selectFieldProduct, setSelectFieldProduct] = useState('');


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkUser = localStorage.getItem('user');
  const fetchedUserString = JSON.stringify(checkUser); 
  const fetchedUserObject = JSON.parse(fetchedUserString);
  const fetchedUserinner = JSON.parse(fetchedUserObject);
  const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
  const user_type = fetchedUserinner.user_type  // using this variable to get user_type
  const kind_id = fetchedUserinner.user_kind_id


  useEffect(() => {
      const UserjsonArray = [];

      const checkUser = localStorage.getItem('user');

      if (checkUser === null) {
      
          navigate('/login', {replace: true})
      
      } else {
      const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

      Object.entries(userInfojsonObject).map(([key, value]) => {
          // Perform operations with the key-value pair
          const obj = { key, value }; // Create an object with key-value pair
          UserjsonArray.push(obj);
      });
      
      setUser(UserjsonArray);
      };
  }, []);





  const handleFormSubmit = (values) => {
    // console.log(values);

    try {
      const checkUser = localStorage.getItem('user');
      const fetchedUserString = JSON.stringify(checkUser); 
      const fetchedUserObject = JSON.parse(fetchedUserString);
      const fetchedUserinner = JSON.parse(fetchedUserObject);
      const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
      const user_type = fetchedUserinner.user_type  // using this variable to get user_type
      const kind_id = fetchedUserinner.user_kind_id  

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const encodedCredentials = window.btoa(`${username}:${password}`);

      const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
          });

      const NewEventData = {}

      // add kind_id to NewEventData
      NewEventData["kind_id"] = kind_id;
      // add account name or a.k.a. customer_name
      // ensure there are no leading and trailing spaces
      NewEventData["event_name"] = values.eventName.trim();
      // generate a unix timestamp to the millisecond and convert it to a string
      const unixTimestamp = new Date().getTime().toString();
      // required object_type field
      NewEventData["object_type"] = "event";
      // convert values.eventStartDate to YYYY-MM-DD format
      // const newFormatStartDate = values.eventStartDate.split('/')[2] + '-' + values.eventStartDate.split('/')[0] + '-' + values.eventStartDate.split('/')[1];
      
      const newStartDateFormatted = startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear();
      const newEndDateFormatted = endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear();
      const startDateString = newStartDateFormatted.toString();
      const endDateString = newEndDateFormatted.toString();
      const newFormatStartDate = startDateString.split('/')[2] + '-' + startDateString.split('/')[0] + '-' + startDateString.split('/')[1];
      // convert values.eventEndDate to YYYY-MM-DD format
      const newFormatEndDate = endDateString.toString().split('/')[2] + '-' + endDateString.split('/')[0] + '-' + endDateString.split('/')[1];
      //const newFormatEndDate = values.eventEndDate.split('/')[2] + '-' + values.eventEndDate.split('/')[0] + '-' + values.eventEndDate.split('/')[1];
      NewEventData["event_start_date"] = newFormatStartDate;
      // required status field
      NewEventData["event_end_date"] = newFormatEndDate;
      NewEventData["cost"] = values.cost;
      // required sale amount field
      NewEventData["attendees"] = values.attendees;
      // required created_date field
      const today = new Date().toISOString().slice(0, 10);
      NewEventData["created_date"] = today;
      NewEventData["created_by"] = loginUserName;
      NewEventData["notes"] = values.notes;

      if (kind_id === 'client1717414405544' || kind_id === 'client1703727947492') {
        NewEventData["category"] = selectFieldCategory;
        NewEventData["product_of_interest"] =  selectFieldProduct; // values.product_of_interest.trim();
      }


      const Createurl = process.env.REACT_APP_API_URL + '/api/v1/create'

      const json_data = {"kind_id": kind_id, 
                        "data": NewEventData};

      fetch(Createurl, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: headers
          })
          .then(response => response.json())
          .then(result => {
              // Handle the API response or perform any necessary actions
              // console.log('New Customer has been created');
              // console.log(result);
              saveLog(new Date().toLocaleString(), 'Event', '', values.eventName.trim(), "Event", `Created Event ${values.eventName.trim()}, starting on ${values.eventStartDate} and ending on ${values.eventEndDate} with ${values.attendees} as attendees.`);
              ToastNotify("success", "New Event has been created");
          })
          .catch(error => {
              // Handle errors that occurred during the API call
              console.error(error);
              ToastNotify("error", "Something went wrong.");
          });
      
    } catch (error) {
      ToastNotify("error", "Something went wrong.");
      console.log(error);
    }
    
  };



  const handleChangeCategory = (event) => {
    const category = event.target.value;
    setSelectFieldCategory(category);
  };

  const handleChangeProduct = (event) => {
    const product = event.target.value;
    setSelectFieldProduct(product);
  };





  if (isLoading) {
    return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
  }

  if (error) {
      if (error.message === 'Failed to fetch') {
          window.location.reload();
      } else {
          return <div>Error: {error}</div>;
      }
  }


  return (
    <Box m="30px" width="90%">
      <Header title="New Event" subtitle="Create a New Event" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: {xs:"100%", md:"50%"},
                display: "grid",
                gridTemplateColumns: isNonMobile ? "repeat(4, 1fr)" : "repeat(1, 1fr)",
                gap: "30px"
              }}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Event Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventName}
                    name="eventName"
                    error={!!touched.eventName && !!errors.eventName}
                    helperText={touched.eventName && errors.eventName}
                    sx={{ gridColumn: "span 4" }}
                />
                
                {/* <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Start Date (MM-DD-YYYY)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventStartDate}
                    name="eventStartDate"
                    error={!!touched.eventStartDate && !!errors.eventStartDate}
                    helperText={touched.eventStartDate && errors.eventStartDate}
                    sx={{ gridColumn: "span 4" }}
                /> */}
                {/* <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="End Date (MM-DD-YYYY)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventEndDate}
                    name="eventEndDate"
                    error={!!touched.eventEndDate && !!errors.eventEndDate}
                    helperText={touched.eventEndDate && errors.eventEndDate}
                    sx={{ gridColumn: "span 4" }}
                /> */}
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cost}
                    name="cost"
                    error={!!touched.cost && !!errors.cost}
                    helperText={touched.cost && errors.cost}
                    sx={{ gridColumn: "span 4" }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Attendees"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.attendees}
                    name="attendees"
                    error={!!touched.attendees && !!errors.attendees}
                    helperText={touched.attendees && errors.attendees}
                    sx={{ gridColumn: "span 4" }}
                />
                { 
                  kind_id === 'client1717414405544' || kind_id === 'client1703727947492' ? 
                  (
                    <>
                      <FormControl sx={{ gridColumn: "span 4"}}>
                        <InputLabel id={`select-category-label-input`}>Category</InputLabel>
                        <Select
                            labelId={`select-category-label-selectid`}
                            id={`select-category-select-id`}
                            value={selectFieldCategory}
                            label="Select Category"
                            onChange={(event) => handleChangeCategory(event)}
                        >
                          <MenuItem key="Retail" value="Retail">Retail</MenuItem>
                          <MenuItem key="Ecommerce" value="Ecommerce">Ecommerce</MenuItem>
                          <MenuItem key="Distributor" value="Distributor">Distributor</MenuItem>
                          <MenuItem key="Government" value="Government">Government</MenuItem>
                        </Select>
                        <FormHelperText>Select Category</FormHelperText>
                      </FormControl>
                      <FormControl sx={{ gridColumn: "span 4"}}>
                      <InputLabel id={`select-product-label-input`}>Product</InputLabel>
                      <Select
                          labelId={`select-product-label-selectid`}
                          id={`select-product-select-id`}
                          value={selectFieldProduct}
                          label="Select Product"
                          onChange={(event) => handleChangeProduct(event)}
                      >
                        <MenuItem key="QuickFinder™ COVID-19 / Flu Antigen Self Test" value="QuickFinder™ COVID-19 / Flu Antigen Self Test">QuickFinder™ COVID-19 / Flu Antigen Self Test</MenuItem>
                        <MenuItem key="OHC COVID-19 / Flu Antigen Test Pro" value="OHC COVID-19 / Flu Antigen Test Pro">OHC COVID-19 / Flu Antigen Test Pro</MenuItem>
                      </Select>
                      <FormHelperText>Select Product of Interest</FormHelperText>
                    </FormControl>
                      {/* <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Product of Interest"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.product_of_interest}
                        name="product_of_interest"
                        error={!!touched.product_of_interest && !!errors.product_of_interest}
                        helperText={touched.product_of_interest && errors.product_of_interest}
                        sx={{ gridColumn: "span 4" }}
                      /> */}
                    </>
                  ) :
                  null
                }
                <div className="flex flex-col gap-4 w-full">
                    <p className="text-md font-bold text-gray-500 ">
                        Start Date
                    </p>
                    <DatePicker
                      selected={startDate} 
                      onChange={(date) => setStartDate(date)}
                      className="custom-datepicker"
                  />
                    <p className="text-md font-bold text-gray-500 ">
                        End Date
                    </p>
                    <DatePicker
                    selected={endDate} 
                    onChange={(date) => setEndDate(date)}
                    className="custom-datepicker"
                  />
                </div>
                <div className="col-span-4">
                    <p className="text-md font-bold text-gray-500 ">
                        Notes about the Event
                    </p>
                </div>
                <TextField
                    fullWidth
                    multiline
                    rows={16}
                    type="text"
                    label=""
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes}
                    name="notes"
                    error={!!touched.editedField && !!errors.editedField}
                    helperText={touched.editedField && errors.editedField}
                    sx={{ gridColumn: "span 4",  }}  // width: {xs: "100%", md:"30%"}
                />
                <Button 
                    type="submit" 
                    color="secondary" 
                    variant="contained"
                    sx={{width: '200px', mt: "1rem", mb: "1rem", }}
                    // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                >
                    Create Event
                </Button>
            </Box>
            </form>
        )}
        </Formik>
    </Box>
  );
};



export default EventsCreate;
