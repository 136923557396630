import { toast } from 'react-toastify';


export const ToastNotify = (indicator, message) => {

  const notifysuccess = () => toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    });

  const notifyfail = () => toast.error(message, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    });

  if (indicator === "success") {
    return notifysuccess()
  } else {
    return notifyfail()
  }
  
}