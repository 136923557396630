import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';



const Npsdata = () => {

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);
    const [editedData, setEditedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);


    const handleInputChange = (index, field, value) => {

        setEditedData((prevData) => {
          const updatedData = [...prevData];
          if (!updatedData[index]) {
              updatedData[index] = {};
          }
          updatedData[index][field] = value;
          return updatedData;
          });
    
        // console.log('this is the handleinputchange value: ')
        // console.log(value)
    
        // console.log('this is the setEditedData: ')
        // console.log(editedData)
    };

    // modified version of handleSubmit which includes customer_name being passed in from DataGrid's value object "value.row"
    const handleSubmit = (event, index, cta_keyid, customerName, originalData, editedData) => {
        event.preventDefault();
    
        // originalData or data from setData has a JSON array like { "0": {"healthscore": "65", "specialty": "tv streaming"}}
        // editedData also has a JSON array like { "0": {"healthscore": "65", "specialty": "tv streaming"}}
    
        // don't need this when customerName is being passed in as variable
        // const customerNameArray = originalData.map((item, index) => (
        //     Object.entries(item).map(([key, value]) => {
        //       if (key === 'customer_name') {
        //           return value;
        //       } else {
        //           return null;
        //       }
        //     })
        // ));
    
        // don't need this when customerName is being passed in as variable
        // const customerName = customerNameArray.flat().filter(value => value !== null)[0];
    
    
        // if you want to update the healthscore object do:  customerName + "healthscore"
        // if you want to update the customer object do:     leave CustomerName as is
        // if you want to update the cta object:  use the cta_keyid field to update DataStore's KEY_ID
        // const concatcustomerNameCTA = customerName + "CTA" + index;
    
        // console.log('This is the customerNameHS: ');
        // console.log(concatcustomerNameHS);
    
        // console.log('This is the editedData[index]: ')
        // console.log(editedData[index])
    
        // leave editedData[index] as is.
        const json_data = {"kind_id": "client000000001", 
                            "key_id": cta_keyid, 
                            "data": editedData[index]
                        };
    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'
    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                console.log('Changes Saved: ')
                console.log(result);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });
    };


    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const UserjsonArray = [];

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query

        if (checkUser === null) {
            navigate('/login', {replace: true})
        } else {
            const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});

            Object.entries(userInfojsonObject).map(([key, value]) => {
                // Perform operations with the key-value pair
                const obj = { key, value }; // Create an object with key-value pair
                UserjsonArray.push(obj);
            });
            setUser(UserjsonArray);
        };

          try {
            // Make an API request to retrieve data from the Google Cloud Datastore
            const url = process.env.REACT_APP_API_URL + '/api/v1/read'
            const data = {
                        "kind_id": "client000000001", 
                        "filters": {
                          "filter1": {"filter_field": "object_type", 
                                "filter_op": "=",
                                "filter_value": "customer"},
                          "filter2": {"filter_field": "owner_name", 
                                "filter_op": "=",
                                "filter_value": loginUserName},
                          } 
                          };
            const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(data)
                          })
    
            const result = await response.json();
            const myJsonString = JSON.stringify(result);
            const jsonObject = JSON.parse(myJsonString); 
            const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
              id: index + 1,
              customer_name: item.customer_name,
              owner_name: item.owner_name,
              lifecycle_stage: item.lifecycle_stage,
              healthscore: item.healthscore,
              healthscore_color: item.healthscore_color,
              cta_open: item.cta_open,
              industry: item.industry,
              specialty: item.specialty,
              last_touch_date: item.last_touch_date,
              cta_count: item.cta_count,
              created_date: item.created_date,
              start_date: item.start_date,
              renewal_date: item.renewal_date,
            }));
    
            setData(jsonArray);
          } catch (error) {
            setError(error.message);
          }
          setIsLoading(false);
        };
    
        fetchData();
    }, []);


    
    if (isLoading) {
        return <div>Loading...</div>;
      }
    
    if (error) {
    return <div>Error: {error}</div>;
    }

    
    // field must be the same actual field name from jsonArray above
    const columns = [
        { field: "id", headerName: "ID"}, 
        { 
        field: "customer_name", 
        headerName: "Customer Name", 
        flex: 1,
        textDecoration: "none",
        color: "inherit",
        cellClassName: "name-column--cell",
        renderCell: (params) => {
            const cellValue = params.value;
            return (
            <Link 
                to={`/customer-profile/${cellValue}`}
                style={{ textDecoration: 'none', color: grey[700] }}
            >
                {cellValue}
            </Link>
            );
        },
        },
        {
        field: "lifecycle_stage",
        headerName: "Lifecycle Stage",
        flex: 1
        },
        {
        field: "industry",
        headerName: "Industry",
        flex: 1,
        editable: true,
        renderEditCell: (value) => (  // value is a json object with row as one of the keys and then your data's column names as the 2nd key
            <div style={{ "height": "85px", "width": "150px", "fontSize": "9px", "margin": "0 0 0 0"}}>
                <h1>
                <input
                    type="text"
                    onChange={(event) => handleInputChange(value.id, value.field, event.target.value)}
                    style={{ "height": "20px", "width": "150px", "fontSize": "9px"}}
                />
                </h1>
                <button 
                    onClick={(event) => handleSubmit(event, value.id, value.row.cta_keyid, value.row.customer_name, data, editedData)}
                    style={{ "border-radius": "0.7rem", "height": "20px", "width": "70px", "fontSize": "9px", 
                            "margin": "-1rem 0 1rem 2rem", "backgroundColor": "rgb(99, 102, 241)", "color": "white", 
                            "cursor": "pointer", }}
                >
                    Save Changes
                </button>
            </div>
        )
        },
        { 
          field: "specialty", 
          headerName: "Specialty",
          flex: 1,
        },
        { 
        field: "healthscore", 
        headerName: "Healthscore Indicator", 
        flex: 1,
        justifyContent: "flex-start",
        renderCell: ({ row: { healthscore }}) => {
            return (
            <Box
                width="100%"
                m="0 auto"
                p="5px"
                display="flex"
                justifyContent="left"
                backgroundColor = {
                healthscore >= "80"
                    ? green[100] :
                    healthscore >= "70"
                    ? yellow[100] :
                    healthscore < "70"
                    ? red[100]
                    : red[100]
                }
                borderRadius="4px"
            >
                {healthscore <  "70" && <LockOpenOutlinedIcon />}
                {healthscore >= "70" && <SecurityOutlinedIcon />}
                {healthscore >= "80" && <AdminPanelSettingsOutlinedIcon />}
                <Typography color={grey} sx={{ ml: "5px" }}>
                {healthscore}
                </Typography>
            </Box>
            )
        }
        },
        { 
          field: "start_date", 
          headerName: "Start Date",
          flex: 1,
        },
        { 
          field: "renewal_date", 
          headerName: "Renewal Date",
          flex: 1,
        },
        { 
        field: "last_touch_date", 
        headerName: "Last Touch Date",
        flex: 1,
        },
        { 
        field: "cta_open", 
        headerName: "Open Action Requests", 
        flex: 1,
        },
        { 
        field: "created_date", 
        headerName: "Created Date", 
        flex: 1,
        },
        { 
          field: "owner_name", 
          headerName: "Account Owner", 
          flex: 1,
        },
    ]


    return (
        <Box m='30px'>
            <Header
                title="NPS"
                subtitle="List of NPS scores"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{"& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default Npsdata;
