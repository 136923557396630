import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CircularProgress from '@mui/material/CircularProgress';
import { saveLog } from '../../utils/logChanges';


const Actionrequests = () => {

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [usersData, setUsersData] = useState([]);

    const [editedData, setEditedData] = useState([]);  // for action request Notes field


    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);



    // fetching action requests data
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true); 

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        if (user_type === 'admin' || user_type === 'manager') {
            try { 
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'

                const localurl = 'http://127.0.0.1:5000/api/v1/read';

                const data = {
                    "kind_id": kind_id, 
                    "filters": {
                        "filter1": {"filter_field": "object_type", 
                            "filter_op": "=",
                            "filter_value": "cta"},
                        } 
                    };
                
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                key_id: '0000000',
                                customer_name: 'Example Customer 1',
                                status: 'OPEN',
                                status2: 'OPEN',
                                notes: 'Example Notes: Worked on this after onboarding call.',
                                owner_name: 'Example Owner 1',
                                created_by: 'Example Owner 2',
                                action_request_name: 'Example Action Request 1',
                                category: 'Example Category 1',
                                priority: 'High',
                                created_date: '2021-10-01',
                                created_by: 'Example Owner 1',
                                due_date: '2021-10-01',
                                guide: 'Example Playbook 1'
                            }]
                    setData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    key_id: item.key_id,
                    customer_name: item.customer_name,
                    status: item.status.toUpperCase(),
                    status2: item.status.toUpperCase(),
                    notes: item.notes,
                    owner_name: item.owner_name, 
                    created_by: item.created_by,
                    action_request_name: item.action_request_name,
                    category: item.category,
                    priority: item.priority,
                    created_date: item.created_date,
                    guide: item.guide,
                    due_date: item.due_date,
                    }));
            
                    setData(jsonArray);
                }
            } catch (error) {
                setError(error.message);
            }
        } else {
            try {
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "cta"},
                                "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);

                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                key_id: '0000000',
                                customer_name: 'Example Customer 1',
                                status: 'OPEN',
                                status2: 'OPEN',
                                notes: 'Example Notes: Worked on this after onboarding call.',
                                owner_name: 'Example Owner 1',
                                created_by: 'Example Owner 2',
                                action_request_name: 'Example Action Request 1',
                                category: 'Example Category 1',
                                priority: 'High',
                                created_date: '2021-10-01',
                                created_by: 'Example Owner 1',
                                due_date: '2021-10-01',
                                guide: 'Example Playbook 1'
                            }]
                    setData(jsonArray);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,
                        key_id: item.key_id,
                        customer_name: item.customer_name,
                        status: item.status.toUpperCase(),
                        status2: item.status.toUpperCase(),
                        notes: item.notes,
                        owner_name: item.owner_name,
                        created_by: item.created_by,
                        action_request_name: item.action_request_name,
                        category: item.category,
                        priority: item.priority,
                        created_date: item.created_date,
                        guide: item.guide,
                        due_date: item.due_date,
                    }));
            
                    setData(jsonArray);
                }
            } catch (error) {
                setError(error.message);
            }
        }

          setIsLoading(false);
        };
    
        fetchData();
    }, []);


    // fetching users data for reassign owner field
    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": "users", 
                            "filters": {
                            "filter1": {"filter_field": "user_kind_id", 
                                    "filter_op": "=",
                                    "filter_value": kind_id},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);

                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{

                            }]
                    setUsersData(jsonArray);
                    setIsLoading(false);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                    id: index + 1,
                    ...item,
                    }));
            
                    setUsersData(jsonArray);
                    setIsLoading(false);
                }
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };
    
        fetchUsers();
    }, []);



    const handleInputChange = (index, field, value) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // index is the id from the useEffect when fetching action requests data, the id is the index + 1 so got to minus 1 to get the correct index
        const indexMinusOne = index - 1;

        const ctaObject = data[indexMinusOne];
        const key_id__ = ctaObject.key_id;

        data[indexMinusOne][field] = value;

        const { id, status2, ...arrayWithoutSpecificKeys } = data[indexMinusOne];


        const json_data = {"kind_id": kind_id, 
                            "key_id": key_id__, 
                            "data": arrayWithoutSpecificKeys
                        };
    
        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

    
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                // console.log('Changes Saved: ')
                // console.log(result);
                saveLog(new Date().toLocaleString(), 'Activity', key_id__, arrayWithoutSpecificKeys.action_request_name, field, `Changed ${field} to ${value}`);
                window.location.reload();
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                console.error(error);
            });

    };

    
    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
      }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }

    

    const columns = [
        { 
            field: "id", 
            headerName: "Row",
            width: 10
        }, 
        { 
            field: "customer_name", 
            headerName: "Customer Name", 
            flex: 1,
            width: 150,
            textDecoration: "none",
            color: "inherit",
            // cellClassName: "name-column--cell",
            renderCell: (params) => {
                const cellValue = params.value;
                return (
                <Link 
                    to={`/account-profile/${cellValue}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                >
                    {cellValue}
                </Link>
                );
            },
        },
        {
            field: "action_request_name",
            headerName: "Activity Name",
            flex: 1,
            width: 150,
            textDecoration: "none",
            color: "inherit",
            cellClassName: "name-column--cell",
            renderCell: ({ row: { key_id, action_request_name }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {action_request_name}
                    </Link>
                )
            }
        },
        {
            field: "status",
            headerName: "Edit Status",
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <span style={{width: '75px'}}>{params.value}</span>
                <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px" }} />
                </div>
            ),
            renderEditCell: (params) => {
                return (
                    <Select
                        value={params.value}
                        onChange={(event) =>
                        handleInputChange(
                            params.id,
                            params.field,
                            event.target.value
                        )
                        }
                        style={{ height: "30px", width: "150px", fontSize: "12px" }}
                    >
                        <MenuItem value={"closed"}>CLOSED</MenuItem>
                        <MenuItem value={"wip"}>WIP</MenuItem>
                        <MenuItem value={"open"}>OPEN</MenuItem>
                    </Select>
                );
            },
            renderHeader: () => (
                <Box display="flex" alignItems="center">
                    <span>Edit Status</span>
                    {/* <ModeEditIcon style={{ marginLeft: "8px" }} /> */}
                </Box>
            ),
        },
        { 
            field: "status2", 
            headerName: "Indicator",
            flex: 1,
            justifyContent: "flex-start",
            renderCell: ({ row: { status }}) => {
                return (
                <Box
                    width="100%"
                    m="0 auto"
                    p="5px"
                    display="flex"
                    justifyContent="left"
                    backgroundColor = {
                    status.toLowerCase() == "closed"
                        ? green[100] :
                        status.toLowerCase() == "wip"
                        ? yellow[100] :
                        status.toLowerCase() == "open"
                        ? red[100]
                        : red[100]
                    }
                    borderRadius="4px"
                >
                    {status.toLowerCase() == "open" && <LockOpenOutlinedIcon />}
                    {status.toLowerCase() == "wip" && <SecurityOutlinedIcon />}
                    {status.toLowerCase() == "closed" && <AdminPanelSettingsOutlinedIcon />}
                    <Typography color="black" sx={{ ml: "5px" }}>
                    {status}
                    </Typography>
                </Box>
                )
            }
        },
        { 
            field: "notes",
            headerName: "Notes",
            flex: 1,
            renderCell: ({ row: { key_id, notes }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {notes}
                    </Link>
                )
            }
            // editable: true,
            // renderCell: (params) => (
            //     <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            //         <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px"}} />
            //         <p style={{marginLeft: "10px", width: '100px'}}>{params.value}</p>
            //     </div>
            // ),
            // renderEditCell: (value) => (
            //     <div className="w-full h-8 flex flex-col justify-center">
            //         <h1>
            //             <input
            //                 className='w-full h-[25px] border-2 border-gray-500 -mt-1'
            //                 placeholder={value.value}
            //                 type="text"
            //                 onChange={(event) => NotesHandleInputChange(value.id, value.field, event.target.value)}
            //             />
            //         </h1>
            //     <Button
            //         sx={{ width: "50%", height: "15px", fontSize: "10px", justifyContent: "center", alignItems: "center", marginTop: "5px", marginLeft: "5px"}}
            //         color='secondary'
            //         variant='contained'
            //         onClick={(event) => NotesHandleSubmit(event, value.id, value.row.key_id, editedData)}
            //     >
            //         Save Changes
            //     </Button>
            //     {/* <button onClick={(event) => NotesHandleSubmit(event, value.id, value.row.key_id, editedData)}>Save Changes</button> */}
            //     </div>
            // )
        },
        { 
            field: "priority",
            headerName: "Priority",
            flex: 1,
            renderCell: ({ row: { key_id, priority }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {priority}
                    </Link>
                )
            }
        },
        { 
            field: "category",
            headerName: "Category",
            flex: 1,
            renderCell: ({ row: { key_id, category }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {category}
                    </Link>
                )
            }
        },
        { 
            field: "created_date",
            headerName: "Created Date",
            flex: 1,
            renderCell: ({ row: { key_id, created_date }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {created_date ? created_date.split('-')[1] + '/' + created_date.split('-')[2] + '/' + created_date.split('-')[0] : ''}
                    </Link>
                )
            }
        },
        { 
            field: "created_by",
            headerName: "Created By",
            flex: 1,
            renderCell: ({ row: { key_id, created_by }}) => {
                return (
                    <Link 
                        to={`/activities/${key_id}`}
                        style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {created_by}
                    </Link>
                )
            }
        },
        { 
            field: "due_date",
            headerName: "Due Date",
            flex: 1,
            renderCell: ({ row: { key_id, due_date }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {due_date ? due_date.split('-')[1] + '/' + due_date.split('-')[2] + '/' + due_date.split('-')[0] : ''}
                    </Link>
                )
            }
        },
        { 
            field: "guide",
            headerName: "Guide",
            flex: 1,
            renderCell: ({ row: { key_id, guide }}) => {
                return (
                    <Link 
                    to={`/activities/${key_id}`}
                    style={{ textDecoration: 'none', color: "inherit" }}
                    >
                        {guide}
                    </Link>
                )
            }
        },
        {
            field: "owner_name",
            headerName: "Assignee",
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                  <span style={{width: '75px'}}>{params.value}</span>
                  <ModeEditIcon style={{ marginLeft: "5px", fontSize: "15px"}} />
                </div>
              ),
            renderEditCell: (params) => {
                return (
                    <Select
                        value={params.value}
                        onChange={(event) =>
                        handleInputChange(
                            params.id,
                            params.field,
                            event.target.value
                        )
                        }
                        style={{ height: "30px", width: "150px", fontSize: "12px" }}
                    >
                        {usersData.map((item) => (
                            <MenuItem value={item.user_owner_name}>{item.user_owner_name}</MenuItem>
                        ))}
                    </Select>
                );
              },
            renderHeader: () => (
                <Box display="flex" alignItems="center">
                    <span>Assignee</span>
                    {/* <ModeEditIcon style={{ marginLeft: "8px" }} /> */}
                </Box>
                ),
        },
    ]


    return (
        <Box m='20px'>
            <Header
                title="Activities"
                subtitle="List of Activities"
            />
            <Button
                variant='contained'
                color='secondary'
                sx={{ width: "200px", mt: "1rem", }}
                onClick={() => navigate('/activities/create')}
            >
                Create New Activity
            </Button>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    width: { xs: "1300px", sm: "1300px", md: "100%"},
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default Actionrequests;



