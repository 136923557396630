import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import { Formik } from "formik";
import { ToastNotify } from '../../components/ToastNotify';
import CircularProgress from '@mui/material/CircularProgress';
import { saveLog } from '../../utils/logChanges';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerStylesEP.css';  // custom styles for datepicker

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const EventsPage = () => {

    const { eventkeyidparams } = useParams();

    const checkUser = localStorage.getItem('user');
    const fetchedUserString = JSON.stringify(checkUser); 
    const fetchedUserObject = JSON.parse(fetchedUserString);
    const fetchedUserinner = JSON.parse(fetchedUserObject);
    const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    const kind_id = fetchedUserinner.user_kind_id  

    const username = process.env.REACT_APP_API_USERNAME;
    const password = process.env.REACT_APP_API_PASSWORD;

    // Encode username and password for Basic Auth
    const encodedCredentials = window.btoa(`${username}:${password}`);

    // Setup headers
    const headers = new Headers({
    'Authorization': `Basic ${encodedCredentials}`,
    'Content-Type': 'application/json'
    });

    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState([]);  

    const [selectStatusField, setSelectStatusField] = useState('');
    const [selectOwnerField, setSelectOwnerField] = useState('');
    const [selectFieldProduct, setSelectFieldProduct] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [user, setUser] = useState(null);


    const isNonMobile = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
      }, []);


    
    // if envetkeyidparams is 0000000, then redirect to /events
    useEffect(() => {
        setIsLoading(true);
        if (eventkeyidparams === '0000000') {
            navigate('/events', {replace: true})
        }
    }, [eventkeyidparams]);



    // fetch specific event for this event page
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');
            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

            const username = process.env.REACT_APP_API_USERNAME;
            const password = process.env.REACT_APP_API_PASSWORD;

            const encodedCredentials = window.btoa(`${username}:${password}`);

            const headers = new Headers({
                'Authorization': `Basic ${encodedCredentials}`,
                'Content-Type': 'application/json'
                });

            try { 
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'

                // convert eventkeyidparams to a number
                const eventkeyidparamsNumber = Number(eventkeyidparams)

                const data = {
                    "kind_id": kind_id, 
                    "key_id": eventkeyidparamsNumber
                    };
                
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
        
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                key_id: '0000000',
                                event_name: 'Example Event Name',
                                event_start_date: '2024-05-01',
                                event_end_date: '2024-05-03',
                                attendees: 'Bob, David, and I',
                                notes: 'Event is to meet with customer.',
                                created_by: 'Example Owner 1',
                                created_date: '2024-04-15',
                            }]
                    setData(jsonArray);
                    setInitialValues(jsonArray[0]);
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        key_id: item.key_id,
                        ...item,
                    }));

                    if (kind_id === 'client1717414405544' || kind_id === 'client1703727947492') {
                        const reorderedArray = jsonArray.map((item) => ({
                            event_name: item.event_name,
                            event_start_date: item.event_start_date,
                            event_end_date: item.event_end_date,
                            cost: item.cost,
                            category: item.category,
                            product_of_interest: item.product_of_interest,
                            created_by: item.created_by,
                            created_date: item.created_date,
                            attendees: item.attendees,
                            notes: item.notes,
                            key_id: item.key_id
                        }))
                        setData(reorderedArray);
                        setInitialValues(reorderedArray[0]);
                        setSelectFieldProduct(reorderedArray[0].product_of_interest);
                        setStartDate(new Date(reorderedArray[0].event_start_date.split('-')[0], reorderedArray[0].event_start_date.split('-')[1] - 1, reorderedArray[0].event_start_date.split('-')[2]));
                        setEndDate(new Date(reorderedArray[0].event_end_date.split('-')[0], reorderedArray[0].event_end_date.split('-')[1] - 1, reorderedArray[0].event_end_date.split('-')[2]));
                    } else {
                    // reorder the array so that the event_name is first, event_start_date is second, event_end_date is third, and created_by is fourth
                    const reorderedArray = jsonArray.map((item) => ({
                        event_name: item.event_name,
                        event_start_date: item.event_start_date,
                        event_end_date: item.event_end_date,
                        cost: item.cost,
                        created_by: item.created_by,
                        created_date: item.created_date,
                        attendees: item.attendees,
                        notes: item.notes,
                        key_id: item.key_id
                    }))

                    setData(reorderedArray);
                    // console.log('this is jsonArray[0]: ', jsonArray[0])
                    setInitialValues(jsonArray[0]);
                    setStartDate(new Date(reorderedArray[0].event_start_date.split('-')[0], reorderedArray[0].event_start_date.split('-')[1] - 1, reorderedArray[0].event_start_date.split('-')[2]));
                    setEndDate(new Date(reorderedArray[0].event_end_date.split('-')[0], reorderedArray[0].event_end_date.split('-')[1] - 1, reorderedArray[0].event_end_date.split('-')[2]));
                    }
                }
            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };
    
        fetchData();
    }, []);





    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error.message === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error.message}</div>
            // navigate('/actionrequests', {replace: true})
            // return <div className='flex flex-col justify-center items-center text-xl'>You have no customers...add a customer here: <Button sx={{marginTop: '2rem'}} variant='contained' color='secondary' onClick={(event) => navigate('/addcustomer')}>Add New Customer</Button></div>;
        }
    }


    const handleChangeProduct = (event) => {
        const product = event.target.value;
        setSelectFieldProduct(product);
      };


    const handleFormSubmit = (values) => {

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

        // convert eventkeyidparams to a number
        const eventkeyidparamsNumber = Number(eventkeyidparams)

        // get all the values including the values in the select fields if any
        const allValues = {...values }

        const newStartDateFormatted = startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear();
        const newEndDateFormatted = endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear();
        const startDateString = newStartDateFormatted.toString();
        const endDateString = newEndDateFormatted.toString();
        const newFormatStartDate = startDateString.split('/')[2] + '-' + startDateString.split('/')[0] + '-' + startDateString.split('/')[1];
        // convert values.eventEndDate to YYYY-MM-DD format
        const newFormatEndDate = endDateString.toString().split('/')[2] + '-' + endDateString.split('/')[0] + '-' + endDateString.split('/')[1];

        allValues.event_start_date = newFormatStartDate;
        allValues.event_end_date = newFormatEndDate;
        allValues.product_of_interest = selectFieldProduct;

        const json_data = {"kind_id": kind_id, 
                            "key_id": eventkeyidparamsNumber, 
                            "data": allValues};

        const url = process.env.REACT_APP_API_URL + '/api/v1/update'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: headers
            })
            .then(response => response.json())
            .then(result => {
                // Handle the API response or perform any necessary actions
                saveLog(new Date().toLocaleString(), 'Event', eventkeyidparams,  allValues.event_name, "Event", `Updated Event`);
                ToastNotify('success', 'Changes Saved')
            })
            .catch(error => {
                // Handle errors that occurred during the API call
                ToastNotify('error', 'Something went wrong')
                console.error(error);
            });

    };



    return (
        <Box m="30px" width="90%">
            <Header title="Event Page" subtitle="Edit Details" />

            <div className='mt-4 w-full md:w-[50%] flex flex-row gap-8'>
                <h3 className='text-xl font-bold'>Event Name:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.event_name))}</p>
            </div>
            <div className='mt-4 w-full md:w-[50%] flex flex-row gap-8'>
                <h3 className='text-xl font-bold'>Event Start Date:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.event_start_date.split('-')[1] + '/' + item.event_start_date.split('-')[2] + '/' + item.event_start_date.split('-')[0]))}</p>
            </div>
            <div className='mt-4 w-full md:w-[50%] flex flex-row gap-8'>
                <h3 className='text-xl font-bold'>Event End Date:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.event_end_date.split('-')[1] + '/' + item.event_end_date.split('-')[2] + '/' + item.event_end_date.split('-')[0]))}</p>
            </div>
            <div className='mt-4 w-full md:w-[50%] flex flex-row gap-8'>
                <h3 className='text-xl font-bold'>Created By:</h3>
                <p className='text-xl text-gray-500'>{data.map((item) => (item.created_by))}</p>
            </div>

            <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={initialValues}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                sx={{
                                    marginTop: "2rem",
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                }}
                            >
                                {data.map((item, index) => (
                                    <>
                                        {Object.keys(item).map((fieldName) => (
                                            fieldName.includes('action_request_name') || fieldName.includes('created_date') || fieldName.includes('notes') ||
                                            fieldName.includes('owner_name') || fieldName.includes('status') ||
                                            fieldName.includes('object_type') || fieldName.includes('kind_id') || fieldName.includes('healthscore_reasons') || 
                                            fieldName.includes('nps_score_history') || fieldName.includes('customer_img_url') || 
                                            fieldName.includes('healthscore_history') || fieldName.includes('key_id') || 
                                            fieldName.includes('event_start_date') || fieldName.includes('event_end_date') ||
                                            fieldName.includes('customer_name') || fieldName.includes('score_history') || fieldName.includes('product_of_interest') ||
                                            fieldName.includes('healthscore_category') || fieldName.includes('healthscore_color') ? null :
                                            (
                                                <div>
                                                    <TextField
                                                        // fullWidth
                                                        type="text"
                                                        label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values[fieldName]}
                                                        name={fieldName}
                                                        error={!!touched.editedField && !!errors.editedField}
                                                        helperText={touched.editedField && errors.editedField}
                                                        sx={{ gridColumn: "span 1", width: {xs: "100%", md:"30%"} }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </>
                                ))}
                                {
                                    kind_id === 'client1717414405544' || kind_id === 'client1703727947492' ?
                                    (
                                        <>
                                        <div className='w-full'>
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                            sx={{
                                                width: "30%",
                                                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
                                            }}
                                        >
                                        <FormControl sx={{ gridColumn: "span 1"}}>
                                            <InputLabel id={`select-product-label-input`}>Product</InputLabel>
                                            <Select
                                                labelId={`select-product-label-selectid`}
                                                id={`select-product-select-id`}
                                                value={selectFieldProduct}
                                                label="Select Product"
                                                onChange={(event) => handleChangeProduct(event)}
                                            >
                                                <MenuItem key="QuickFinder™ COVID-19 / Flu Antigen Self Test" value="QuickFinder™ COVID-19 / Flu Antigen Self Test">QuickFinder™ COVID-19 / Flu Antigen Self Test</MenuItem>
                                                <MenuItem key="OHC COVID-19 / Flu Antigen Test Pro" value="OHC COVID-19 / Flu Antigen Test Pro">OHC COVID-19 / Flu Antigen Test Pro</MenuItem>
                                            </Select>
                                            <FormHelperText>Select Product of Interest</FormHelperText>
                                        </FormControl>
                                        </Box>
                                        </div>
                                        </>
                                    ) : null
                                }
                                <div className="flex flex-col gap-4 col-span-1 w-[25%]">
                                    <p className="text-md font-bold text-gray-500 ">
                                        Start Date
                                    </p>
                                    <DatePicker
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)}
                                        className="custom-datepicker"
                                    />
                                    <p className="text-md font-bold text-gray-500 ">
                                        End Date
                                    </p>
                                    <DatePicker
                                        selected={endDate} 
                                        onChange={(date) => setEndDate(date)}
                                        className="custom-datepicker"
                                    />
                                </div>
                                <div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={16}
                                        type="text"
                                        label=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.notes}
                                        name="notes"
                                        error={!!touched.editedField && !!errors.editedField}
                                        helperText={touched.editedField && errors.editedField}
                                        sx={{ gridColumn: "span 1", width: {xs: "100%", md:"30%"} }}
                                    />
                                </div>
                            </Box>
                            <Box 
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between" 
                                mt="20px"
                                gap={"20px"}
                            >
                                <Button 
                                    type="submit" 
                                    color="secondary" 
                                    variant="contained"
                                    sx={{width: '200px'}}
                                    // onClick={(event) => handleFormSubmit(values)}  don't need onClick since the type="sumbit" will handle the handleFormSubmit function located at the top under Formik
                                >
                                    Save Edits
                                </Button>
                            </Box>
                        </form>
                        )
                    }
            </Formik>
            <Button
                sx={{"margin": "2rem 0 0 0", "width": "200px", "border": `1px solid ${colors.greenAccent[200]}`}} 
                variant="contained"
                onClick={(event) => navigate(`/events`)}
            >
                Back to Events
            </Button>
        </Box>
    );
}

export default EventsPage;