import React from 'react'
import { motion } from 'framer-motion';
import HomeTopBar from '../../components/HomeTopBar';
import mountainChosen2 from '../../assets/mountain_chosen2.png';


const crmFeatures = [{feature_name: "Customer Data Storage",
                    feature_description: "Centralize all your customer information in one secure, easily accessible place. Import customer data easily via CSV or other methods. Say goodbye to scattered data and hello to organized efficiency."},
                    {feature_name: "Innovative healthscores", 
                    feature_description: "Understand your customers better with our dynamic Healthscore system. Track engagement, satisfaction, and overall relationship health at a glance - empowering you to take action when it matters most.  Customize and edit Healthscores and their properties, track trending healthscores over time, manage and ensure your customers are not at risk."}, 
                    {feature_name: "Team Collaboration Made Easy: action requests",
                    feature_description: "Coordinate with your team like never before through Action Requests. Assign tasks, track progress, get notified, and achieve your goals together, all within MountX CRM. You can also create custom rules to trigger action requests based on any custom fields you create."}, 
                    // {feature_name: "NPS", 
                    // feature_description: "Create and manage NPS surveys, track NPS scores over time, and get notified when a customer submits a NPS survey."}, 
                    // {feature_name: "surveys",
                    // feature_description: "Customize and manage survey questions, use AI to summarize survey responses, and get notified when a customer submits a survey."}, 
                    {feature_name: "Lifecycle Management",
                    feature_description: "Navigate your customers through their lifecycle with your brand effortlessly. From acquisition to retention, our tools help you maintain meaningful connections at every stage.  Set custom customer lifecycle stages, track lifecycles over time, and automatically trigger actions or calculate healthscores when customers enter or exits lifecycle stages."},
                    ]



const Features = () => {


    return (
        <>
            <HomeTopBar />
            <div
                className='flex flex-col xl:flex-row w-full h-screen bg-[#f6f9fc]'
            >
                <img 
                    src={mountainChosen2}
                    alt="mountchosen2"
                    className='w-full h-[175%] xl:h-full object-cover object-center absolute z-0 top-0 left-0'
                />
                <div 
                    className='flex flex-1 justify-center items-center flex-col py-10 z-10' 
                >
                    <h1 className='pt-8 xl:pt-0 text-5xl font-bold text-center'>Features for any Business Type</h1>
                    <div className='flex flex-col xl:flex-row flex-wrap justify-center items-center m-4'>
                        <motion.div className='flex flex-col xl:flex-row flex-wrap justify-center items-center text-center m-4'>
                            {crmFeatures?.map((feat) => (
                                <motion.div
                                    whileInView={{opacity: [0, 1]}}
                                    transition={{ duration: 2, type: 'tween',  }}
                                    className='flex-col text-center m-4'
                                    key={feat.feature_name}
                                    // increase the size of the card on hover }}
                                    sx={{'hover': {scale: 1.5}}} 
                                >
                                    <div 
                                        className='hover:bg-gray-300 
                                        hover:text-white 
                                        ease-in duration-300 
                                        flex-col 
                                        xl:flex-row 
                                        flex-wrap 
                                        h-[300px] 
                                        xl:h-[400px]
                                        w-full 
                                        xl:w-[400px]  
                                        border-[1px] 
                                        border-black 
                                        rounded-lg 
                                        bg-[#fff] 
                                        items-center 
                                        justify-center 
                                        p-4'
                                    >
                                        <h4 className='font-bold mt-4 text-lg xl:text-2xl'>{feat.feature_name.toUpperCase()}</h4>
                                        <p className='text-[#0a2540] mt-4 text-md xl:text-xl'>{feat.feature_description}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            </div>
        </>

        
    )
}

export default Features;