import React from 'react'

import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { useNavigate } from 'react-router-dom';
import HomeTopBar from "../../components/HomeTopBar";


const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  

  return (
    <>
    <HomeTopBar />
    <Box m="20px" padding={"6rem"} display="flex" flexDirection="column" justifyContent="center" alignContent="center">
      <Header title="Privacy Policy" subtitle="Terms and Conditions" />

      <div className='flex flex-col justify-center w-[60%]'>
        <b>Privacy Policy for MountX CRM</b>
        <br />
        <p>
            At MountX CRM, accessible from https://www.mountxcrm.com, one of our main priorities is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by MountX CRM and how we use it.
        </p>
        <br />
        <b>Google Calendar Integration</b>
        <p>
            MountX CRM integrates with Google Calendar to provide viewing, scheduling, and calendar management functionalities directly within the app. By authenticating and linking your Google Calendar with MountX CRM, you authorize us to access your Google Calendar account as follows:
        </p>
        <br />
        <p>
            <b>Read Access:</b> With your permission, MountX CRM will have access to view calendar events on your Google Calendar.
        </p>
        <br />
        <p>
            <b>Write Access:</b> With your permission, MountX CRM can also create, update, or delete Google calendar events.
        </p>
        <br />
        <p>
            This access helps MountX CRM to offer a seamless scheduling and team availability experience by syncing your Google calendar events.
        </p>
        <br />
        <b>Information Collection and Use</b>
        <p>
            For a better experience while using our service, we may require you to provide us with certain personally identifiable information, including but not limited to your email address and Google calendar data. The information that we request will be securely stored by us in Google Cloud's servers and never be shared with any 3rd parties.
        <br />
            MountX CRM will only use your Google calendar data to display your events to you and your team and allow you to modify/edit your calendar events.  MountX CRM will never share it or any other information collected from you with any 3rd parties or organizations.
        <br />
        </p>
        <br />
        <p>
            If you have any questions or require more information about our Privacy Policy, do not hesitate to contact us.
        </p>
      </div>
        
    </Box>
    </>
  );
};

export default PrivacyPolicyPage;
