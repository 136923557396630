import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import { blue, green, grey, yellow, red} from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    Popover,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    TextField
  } from '@mui/material';
import { saveUserSelectedColumns } from '../../utils/saveColumns';


  



const Scorecards = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const [selectedColumns, setSelectedColumns] = useState(['id', 'customer_name', 'healthscore']); // Add default columns here
    const [AllPossibleColumns, setAllPossibleColumns] = useState([]); // Add all possible columns here

    const [anchorEl, setAnchorEl] = useState(null);

    const [columnNumbers, setColumnNumbers] = useState(
        AllPossibleColumns.reduce((acc, column) => {
          acc[column.field] = 0; // Default numbers for each column
          return acc;
        }, {})
    );

    useEffect(() => {
        const UserjsonArray = [];
    
        const checkUser = localStorage.getItem('user');
    
        if (checkUser === null) {
        
            navigate('/login', {replace: true})
        
        } else {
        const userInfojsonObject = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : navigate('/login', {replace: true});
    
        Object.entries(userInfojsonObject).map(([key, value]) => {
            // Perform operations with the key-value pair
            const obj = { key, value }; // Create an object with key-value pair
            UserjsonArray.push(obj);
        });
        
        setUser(UserjsonArray);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);

        const checkUser = localStorage.getItem('user');

        const fetchedUserString = JSON.stringify(checkUser); 
        const fetchedUserObject = JSON.parse(fetchedUserString);
        const fetchedUserinner = JSON.parse(fetchedUserObject);
        const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
        const user_type = fetchedUserinner.user_type  // using this variable to get user_type
        const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


        if (user_type === 'admin' || user_type.includes('manager') || user_type.includes('team user')) {
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
    
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: "Example Customer 1",
                                healthscore: "65",
                                case_open_count: "3",
                                cta_open_count: "2",
                                nps_score: "55",
                                last_touch_date: "2021-09-01",
                                recalls_adoption: "false",
                                reminders_adoption: "false",
                                reviews_adoption: "false"
                            }]
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);

                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index + 1,  //adding one so the row id doesn't start with 0
                        ...item,
                    }));
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    // const keys = Object.keys(jsonArray[0]);
                    // setAllPossibleColumns(keys.map((key) => ({ field: key, headerName: key })));

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type' && item !== 'healthscore_reasons');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);
                }

    
            } catch (error) {
                setError(error.message);
            }

        } else {
            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "customer"},
                            "filter2": {"filter_field": "owner_name", 
                                  "filter_op": "=",
                                  "filter_value": loginUserName},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })
    
                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    const jsonArray = [{
                                id: 1,
                                customer_name: "Example Customer 1",
                                healthscore: "65",
                                case_open_count: "3",
                                cta_open_count: "2",
                                nps_score: "55",
                                last_touch_date: "2021-09-01",
                                recalls_adoption: "false",
                                reminders_adoption: "false",
                                reviews_adoption: "false"
                            }]
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);

                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index,
                        ...item,
                    }));
        
                    // data.map((item, index) => (
                    //   <div key={index}>
                    //       {Object.entries(item).map(([key, value]) => {
                    //       const formattedKey = key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
        
                    setData(jsonArray);

                    // get the keys from the first object in the array
                    const keys = Object.keys(jsonArray[0]);
                    // if keys == 'key_id' or 'kind_id' or other fields that don't belong then remove it from the array
                    const filteredKeys = keys.filter(item => item !== 'key_id' && item !== 'kind_id' && item !== 'score_history' 
                                                    && item !== 'object_type' && item !== 'healthscore_reasons');
                    // if filteredKeys == 'customer_name' then remove '_' and capitalize the words
                    const formattedKeys = filteredKeys.map((key) => ({ field: key, headerName: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()) }));
                    setAllPossibleColumns(formattedKeys);

                    // setAllPossibleColumns(keys.map((key) => ({ field: key, headerName: key })));
                }
                
            } catch (error) {
                setError(error.message);
            }
        }
        setIsLoading(false);
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchColumns = async () => {
            setIsLoading(true);

            const checkUser = localStorage.getItem('user');

            const fetchedUserString = JSON.stringify(checkUser); 
            const fetchedUserObject = JSON.parse(fetchedUserString);
            const fetchedUserinner = JSON.parse(fetchedUserObject);
            const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
            const user_type = fetchedUserinner.user_type  // using this variable to get user_type
            const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });


            try {
                // Make an API request to retrieve data from the Google Cloud Datastore
                const url = process.env.REACT_APP_API_URL + '/api/v1/read'
                const data = {
                            "kind_id": kind_id, 
                            "filters": {
                            "filter1": {"filter_field": "object_type", 
                                    "filter_op": "=",
                                    "filter_value": "columns"},
                            "filter2": {"filter_field": "owner_name", 
                                    "filter_op": "=",
                                    "filter_value": loginUserName},
                            "filter3": {"filter_field": "page", 
                                    "filter_op": "=",
                                    "filter_value": "healthscores"},
                            } 
                            };
                const response = await fetch(url, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data)
                            })

                const result = await response.json();
                const myJsonString = JSON.stringify(result);
                const jsonObject = JSON.parse(myJsonString);
                if (jsonObject.retrieved_data === 'No result is returned') {
                    return null;
                } else {
                    const jsonArray = jsonObject.retrieved_data.map((item, index) => ({
                        id: index,
                        ...item,
                    }));
                    setSelectedColumns(jsonArray[0]["selected_columns"]);
                    setColumnNumbers(jsonArray[0]["column_order"]);
                }

            } catch (error) {
                setError(error.message);
            }
            setIsLoading(false);
        };

        fetchColumns();
    }, []);



    // for updating HS and not have to wait for it to finish
    // useEffect(() => {

    //     const updateHs = () => {
    //     const checkUser = localStorage.getItem('user');

    //     const fetchedUserString = JSON.stringify(checkUser); 
    //     const fetchedUserObject = JSON.parse(fetchedUserString);
    //     const fetchedUserinner = JSON.parse(fetchedUserObject);
    //     const loginUserName = fetchedUserinner.user_owner_name  // using this variable to filter on datastore query
    //     const user_type = fetchedUserinner.user_type  // using this variable to get user_type
    //     const kind_id = fetchedUserinner.user_kind_id  

const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const encodedCredentials = window.btoa(`${username}:${password}`);

        const headers = new Headers({
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json'
            });

    //     const url = 'https://daring-bit-306611.wl.r.appspot.com/api/v1/updatehs';

    //     const data = {
    //         "kind_id": kind_id, 
    //         };
        
    //     const response = fetch(url, {
    //             method: 'POST',
    //             headers: headers,
    //             body: JSON.stringify(data)
    //         })
        
    //     return "updating healthscores..";
    //     };

    // updateHs();

    // }, []);



    const handleColumnsChange = (newColumns) => {
        setSelectedColumns(newColumns);
        // setAnchorEl(null); // Close the popover after selecting columns

        // saving the selected columns and column order to datastore for the user and for this specific page
        // saveUserSelectedColumns("healthscores", newColumns, columnNumbers)
        
      };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    if (isLoading) {
        return <div className='mt-24 flex flex-col justify-center items-center'><CircularProgress color="secondary" /></div>;
    }
    
    if (error) {
        if (error === 'Failed to fetch') {
            window.location.reload();
        } else {
            return <div>Error: {error}</div>;
        }
    }



    const ModifiedColumnsforGrid = () => {

        const modifiedColumns = AllPossibleColumns.map((item) => {
                    if (item.field === 'healthscore') {
                        return { 
                                ...item, 
                                width: 140,
                                justifyContent: "flex-start",
                                renderCell: ({ row: { healthscore }}) => {
                                    return (
                                    <Box
                                        width="100%"
                                        m="0 auto"
                                        p="5px"
                                        display="flex"
                                        justifyContent="left"
                                        backgroundColor = {
                                        healthscore == "100"
                                            ? green[100] :
                                        healthscore >= "80"
                                            ? green[100] :
                                        healthscore >= "60"
                                            ? yellow[100] :
                                        healthscore < "60"
                                            ? red[100]
                                            : red[100]
                                        }
                                        borderRadius="4px"
                                    >
                                        {healthscore < "60" && <SentimentDissatisfiedOutlinedIcon />}
                                        {healthscore >= "60" && healthscore < "80" && <SentimentNeutralOutlinedIcon />}
                                        {healthscore >= "80" && <SentimentSatisfiedAltOutlinedIcon />}
                                        <Typography color="black" sx={{ ml: "5px" }}>
                                        {healthscore}
                                        </Typography>
                                    </Box>
                                    )
                                }
                            }
                    } else if (item.field === 'customer_name') {
                        return { 
                            ...item,
                            width: 140,
                            textDecoration: "none",
                            color: "inherit",
                            cellClassName: "name-column--cell",
                            renderCell: (params) => {
                                const cellValue = params.value;
                                return (
                                    <div>
                                        <Link 
                                            to={`/account-profile/${cellValue}`}
                                            style={{ textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                        >
                                            {cellValue}
                                        </Link>
                                    </div>
                                    
                                );
                            },
                        }
                    } else if (item.field === 'id') {
                        return { 
                            ...item,
                            width: 15,
                            headerName: 'Row',
                        }
                    } else {
                        return item;
                    }
                })

        const modifiedFilteredSortedColumns = modifiedColumns.filter((column) => selectedColumns.includes(column.field)).sort((a, b) => columnNumbers[a.field] - columnNumbers[b.field])

        return modifiedFilteredSortedColumns;
    };


    return (
        <Box m='20px'>
            <Header
                title="Healthscores"
                subtitle="List of Healthscores"
            />
            <Button 
                variant="contained" 
                onClick={handleClick}
                sx={{ border: `1px solid ${colors.greenAccent[200]}`, }}
            >
                Select Columns
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                <Box p={2}>
                <Typography>Select Columns, set Order, and click Save.</Typography>
                <Divider />
                <List>
                    {AllPossibleColumns.map((column) => (
                    <ListItem key={column.field}>
                        <ListItemText primary={column.headerName} />
                        <IconButton
                            onClick={() =>
                                handleColumnsChange(
                                selectedColumns.includes(column.field)
                                    ? selectedColumns.filter((col) => col !== column.field)
                                    : [...selectedColumns, column.field]
                                )
                            }
                            >
                            {selectedColumns.includes(column.field) ? (
                                <CheckCircleIcon />
                            ) : (
                                <RadioButtonUncheckedIcon />
                            )}
                        </IconButton>
                        <TextField
                            sx={{ width: "75px" }}
                            // do not allow negative numbers
                            InputProps={{ inputProps: { min: 1 } }}
                            type="number"
                            value={columnNumbers[column.field]}
                            onChange={(e) =>
                                setColumnNumbers({
                                ...columnNumbers,
                                [column.field]: parseInt(e.target.value, 10) || 0,
                                })
                            }
                        />
                    </ListItem>
                    ))}
                </List>
                <Divider />
                <Button
                    sx={{ mt: 2 }}
                    variant='contained'
                    onClick={(event) => saveUserSelectedColumns("healthscores", selectedColumns, columnNumbers)}
                >
                    Save
                </Button>
                </Box>
            </Popover>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{"& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.grey[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.grey[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={data}
                    // columns={AllPossibleColumns
                    //         .filter((column) => selectedColumns.includes(column.field))
                    //         .sort((a, b) => columnNumbers[a.field] - columnNumbers[b.field])}
                    columns={ModifiedColumnsforGrid()}
                    components={{ Toolbar: GridToolbar }}
                    onColumnsChange={handleColumnsChange}
                />
            </Box>
        </Box>
    );

}


export default Scorecards;